/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Promotion extends Model {
  static fields () {
    return {
      id: this.string(),
      name: this.string()
    }
  }

  static gets (shopId, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shop-types/${shopId}/promotions`,
      dataTransformer: (response) => {
        return response.data.map((e) => {
          if(!e.id) {
            e.id = e.name
          }
          return e
        })
      },
      ...options,
    }).then((res) => res.response)
  }

  static create (shopId, regionID, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/shop-types/${shopId}/regions/${regionID}/promotions`,
      ...options,
    }).then((res) => res.response)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/v1/promotions/${id}`,
      dataTransformer: () => [],
      ...options,
    }).then((res) => res.response)
  }

  static delete (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/v1/promotions/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static public (shopId, regionID, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/shop-types/${shopId}/regions/${regionID}/promotions/public`,
      ...options,
    }).then((res) => res.response)
  }
}

Promotion.entity = 'promotion'

export default Promotion