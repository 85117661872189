<template>
  <div>
    <SideBar bar_name="Reward Items" :user="user"></SideBar>

    <v-main>
      <v-container>
        <Tab :tabs="tabs">
          <template v-slot="slotProps">
            <AgentProduct v-bind="{ user: slotProps.user, tabId: slotProps.tabId, tabName: slotProps.tabName }" />
          </template>
        </Tab>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Tab from "@/components/Tab";
import AgentProduct from "@/components/AgentProduct";

export default {
  name: "Product",
  components: {
    SideBar,
    Tab,
    AgentProduct
  },
  data() {
    return {
      tabs: this.$catalogTabs,
      user: this.$user,
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
