<template>
  <div>
    <SideBar bar_name="ช้างขายดี! ช้างขายดี!" :user="user"></SideBar>

    <v-main>
      <v-container>
        <Tab :tabs="tabs">
          <template v-slot="slotProps">
            <AgentPromotion v-bind="{ user: slotProps.user, tabId: slotProps.tabId, tabName: slotProps.tabName }" />
          </template>
        </Tab>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Tab from "@/components/Tab";
import AgentPromotion from "@/components/AgentPromotion";

export default {
  name: "Promotion",
  components: {
    SideBar,
    Tab,
    AgentPromotion,
  },
  data() {
    return {
      tabs: this.$agentTabs,
      user: this.$user,
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

