<template>
  <div>
    <v-layout wrap>
      <!-- <v-flex xs4 md4 v-if="user.role == 'ci'">
            <v-file-input outlined label="File input" @change="onFileChange" v-model="files"></v-file-input>
          </v-flex>

          <v-flex xs4 md4>
            <v-btn style="margin: 3%; " :disabled="file == null" @click="uploadSale" v-if="user.role == 'ci'">Upload</v-btn>

            <v-btn style="margin: 3%; " @click="downloadSale" v-if="user.role != 'normal'">Download</v-btn>
      </v-flex>-->

      <v-flex xs2 md2 v-if="user.role != 'normal'">
            <v-btn style="margin: 3%; " @click="downloadSale">Download</v-btn>
      </v-flex>

      <v-flex xs4 md4 style="margin: 10px;"></v-flex>
      <v-flex xs12 md12 style="margin-top: 10px;">
        <v-card>
          <v-card-title>
            Sale
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              style="margin-right: 2%"
              @change="getSalebyQuery"
            ></v-text-field>
            <v-select
              outlined
              :items="regions"
              item-text="label"
              item-value="id"
              label="Region"
              style="width: 5%"
              v-model="region"
              @change="getSalebyRegion"
              v-if="regions.length > 2"
            ></v-select>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="sale_data"
            :footer-props="{'items-per-page-options': $itemsPerPage}"
            :items-per-page="perPage"
            class="elevation-1"
            :loading="loading"
            :options.sync="options"
            :server-items-length="countItems"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.areas="{ item }">{{ tableAreas(item) }}</template>

            <v-icon @click="dialog = true">search</v-icon>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />Uploading....
          <br />
          <br />

          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Region from "@/store/models/region";
import Employee from '@/store/models/employee'

export default {
  name: "SaleManagement",
  components: {},
  props: ["user"],
  computed: {
    regions() {
      return Region.getsAll();
    }
  },
  data() {
    return {
      search: "",
      dialog: false,
      region: "All",
      files: [],
      file: null,
      dialog_loading: false,
      loading: false,
      headers: [
        {
          text: "Name",
          sortable: false,
          value: "full_name"
        },
        {
          text: `Position`,
          value: "position",
          sortable: false
        },
        {
          text: "Company",
          sortable: false,
          value: "company"
        },
        {
          text: "พื้นที่ขาย",
          value: "areas",
          sortable: false,
          width: "250px"
        },
        {
          text: "Email",
          value: "email",
          sortable: false
        },
        {
          text: "เบอร์มือถือ",
          value: "phone",
          sortable: false
        }
      ],
      sale_data: [],
      options: {},
      perPage: 10,
      countItems: 0
    };
  },
  watch: {
    options: {
      handler () {
        this.getSale()
      },
      deep: false,
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.region = this.user.region;
    },
    onFileChange(e) {
      console.log(e);
      this.file = e;
    },
    uploadSale() {
      console.log("uploading....");
      this.dialog_loading = true;
      let formData = new FormData();

      formData.append("file", this.file);

      this.$http
        .post(`/api/upload/sales`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then(response => {
          console.log(response.data);
          this.files = [];
          this.getSale();
          this.dialog_loading = false;
        })
        .catch(e => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },
    downloadSale() {
      const FileDownload = require("js-file-download");

      let data = { params: {} }
      if (this.user.role === "ci") {
        if(this.region === "All") {
          data = { params: {} }
        } else {
          data = { params: { regionID: this.region } }
        }
      }

      if (this.search) {
        data.params.query = this.search
      }

      Employee.download(data)
        .then(response => {
          FileDownload(response, `sale_data_region${this.region && '_'+this.region || ''}.xlsx`);
        })
        .catch(e => {
          console.error(e.response);
        });
    },
    getSale() {
      this.loading = true

      let data = { params: {} }
      if (this.region && this.region !== "All") {
        data.params.regionID = this.region;
      }

      if (this.search) {
        data.params.query = this.search
      }

      if (this.options && this.options.itemsPerPage) {
        data.params.perPage = this.options.itemsPerPage
      }

      if (this.options && this.options.page) {
        data.params.page = this.options.page
      }

      Employee.gets(data)
        .then(response => {
          this.sale_data = response && response.data || [];
          this.countItems = response && response.total;
          this.loading = false;
        })
        .catch(e => {
          console.error(e.response);
          this.loading = false;
        });
    },
    getSalebyQuery() {
      if(this.options.page != 1) {
        this.options.page = 1
      } else {
        this.getSale()
      }
    },
    getSalebyRegion() {
      if(this.options.page != 1) {
        this.options.page = 1
      } else {
        this.getSale()
      }
    },
    tableAreas(item) {
      if(item.ral === "super-admin") {
        return "ทุกพื้นที่"
      } else if(item.ral === "region-admin") {
        return item.regions.reduce((o, e) => {
          if(e.label) {
            o += (o)? ", " : ""
            o += e.label
          }

          return o
        }, '')
      } else {
        return item.areas.reduce((o, e) => {
          if(e.name) {
            o += (o)? ", " : ""
            o += e.name
          }

          return o
        }, '')
      }
    }
  }
};
</script>
