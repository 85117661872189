export const parseJSON = (text = "") => {
  try {
    return JSON.parse(text)
  } catch (error) {
    return {}
  }
}

export const appList = {
  'Chang Family': {
    name: 'Chang Family',
    switch: 'Chang TT',
  },
  'Chang TT': {
    name: 'Chang TT',
    switch: 'Chang Family',
  },
}
export const appName = appList['Chang Family'].name;

export const userRoles = {
  'super-admin': 'Super Admin',
  'region-admin': 'Region Admin',
  'area-admin': 'Area Admin',
  'sub-area-admin': 'Sub Area Admin',
}

export const agentTabs = {
  1: 'Agent',
  2: 'Sub Agent',
  3: 'Sub Direct'
}

export const catalogTabs = {
  1: 'Agent',
  2: 'Sub'
}

export const itemsPerPage = [10, 20, 30, 40, 50, 100]

export const months = {
  "JAN": "มกราคม",
  "FEB": "กุมภาพันธ์",
  "MAR": "มีนาคม",
  "APR": "เมษายน",
  "MAY": "พฤษภาคม",
  "JUN": "มิถุนายน",
  "JUL": "กรกฎาคม",
  "AUG": "สิงหาคม",
  "SEP": "กันยายน",
  "OCT": "ตุลาคม",
  "NOV": "พฤศจิกายน",
  "DEC": "ธันวาคม",
}

export const shopRelation = {
  "master": "เจ้าของ",
  "spouse": "คู่สมรส",
  "child": "บุตร/ธิดา",
}

// <, >, =, !=, <=, =>
export const operator = [
  "=",
  "!=",
  "<",
  "<=",
  ">",
  ">="
]

export const smSendtype = [
  { text: "รวม", value: "list" },
  { text: "แยก", value: "single" },
];

export const stringDateAdToBe = (date) => {
  if(!date) {
    return date;
  }

  const DATE_REGEXP = new RegExp('^([0-9]{4})-(0?[1-9]|1[0-2])-(0?[1-9]|[1-2][0-9]|3[0-1])$', 'gi');
  const dateStr = date.replace(DATE_REGEXP,
  (str, year, month, day) => {
    return `${day}/${month}/${parseInt(year, 10) + 543}`;
  });

  return dateStr;
}

export const stringDateBeToAd = (date) => {
  if(!date) {
    return date;
  }

  const DATE_REGEXP = new RegExp('^(0?[1-9]|[1-2][0-9]|3[0-1])-(0?[1-9]|1[0-2])-([0-9]{4})$', 'gi');
  const dateStr = date.replace(DATE_REGEXP,
  (str, day, month, year) => {
    return `${parseInt(year, 10) - 543}-${month}-${day}`;
  });

  return dateStr;
}
