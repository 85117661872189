import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","content-class":"lightbox-dialog","data-testid":"lightBox"},on:{"click:outside":_vm._close},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"text-right py-2"},[_c(VIcon,{staticClass:"mr-3",attrs:{"large":"","color":"white"},on:{"click":_vm._close}},[_vm._v("mdi-close-circle")])],1),(_vm.items.length > 1)?_c(VCarousel,{attrs:{"height":"80vh"}},_vm._l((_vm.items),function(item,i){return _c(VCarouselItem,{key:i},[_c(VImg,{attrs:{"src":item.src || item.image || item,"contain":true,"height":"100%"}})],1)}),1):_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"fix-height"},[_c(VImg,{attrs:{"src":item.src || item.image || item,"contain":true,"height":"100%"}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }