<template>
  <div>
    <SideBar bar_name="User Management" :user="user"></SideBar>

    <v-main id="content">
      <v-container id="container">
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-card>
              <v-card-title>
                Users
                <v-spacer></v-spacer>

                <v-text-field v-model="search" append-icon="search" label="Search"></v-text-field>

                <v-btn
                  icon
                  @click="openAddDialog"
                  large
                  v-if="user.role == 'ci'"
                  id="addNewUserBtn"
                >
                  <v-icon>add_circle</v-icon>
                </v-btn>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="users"
                :footer-props="{'items-per-page-options': $itemsPerPage}"
                :items-per-page="-1"
                class="elevation-1"
                :search="search"
                :loading="loading"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.regions="{ item }">
                  {{listRegions(item.regions)}}
                </template>

                <template v-slot:item.areas="{ item }">
                  {{listAreas(item.areas)}}
                </template>

                <!-- <template v-slot:item.actions="{ item }" v-if="user.role == 'ci'">
                  <div style="display: flex; justify-content: space-around;">
                    <v-icon @click="openEditDialog(item)">edit</v-icon>
                    <v-icon
                      @click="openDeleteDialog(item)"
                      :disabled="user.email == item.email"
                    >delete</v-icon>
                  </div>
                </template> -->
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- <div>------------------------------------- Edit User Dialog -----------------------------------------------------</div> -->

      <v-dialog v-model="dialog_edit" width="50%" persistent scrollable>
        <v-card>
          <v-card-title primary-title style="margin-bottom: 20px">Edit User</v-card-title>

          <v-card-text style="padding-top: 5px;">
            <v-text-field outlined label="User Name" v-model="each_user.name"></v-text-field>

            <!-- <v-text-field outlined label="Email" v-model="each_user.email"></v-text-field> -->

            <v-select
              outlined
              :items="['ci', 'admin', 'normal']"
              label="Role"
              v-model="each_user.role"
              @change="onRoleChangeEdit"
            ></v-select>

            <v-select
              outlined
              :items="regions"
              item-text="label"
              item-value="id"
              label="Region"
              v-model="each_user.region"
              :disabled="each_user.role == 'ci'"
            ></v-select>

            <v-checkbox v-model="selected" label="Agent" value="John"></v-checkbox>
            <v-checkbox v-model="selected" label="Sub Agent" value="Jacob"></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog_edit = false">Close</v-btn>
            <v-btn
              text
              @click="editEachUser"
              :disabled="each_user.username == '' || each_user.role == '' || each_user.region == '' "
            >Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <div>------------------------------------- Add User Dialog -----------------------------------------------------</div> -->

      <v-dialog v-model="dialog_add" width="50%" persistent scrollable>
        <v-card>
          <v-card-title primary-title style="margin-bottom: 20px">Add New User</v-card-title>

          <v-card-text style="padding-top: 5px;">
            <v-text-field id="addUserCode" outlined label="Code" v-model="code"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog_add = false">Close</v-btn>
            <v-btn
              text
              @click="signup"
              :disabled="!this.code"
            >Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <div>------------------------------------- Delete User Dialog -----------------------------------------------------</div> -->

      <v-dialog v-model="dialog_delete" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Confirm delete?</v-card-title>
          <v-card-text>Are you sure that you want to delete this user. This user will be deleted permanently.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" @click="dialog_delete = false" text>Cancel</v-btn>
            <v-btn color="red" text @click="deleteUser">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Region from "@/store/models/region";
import User from "@/store/models/user";

export default {
  name: "userManagement",
  components: {
    SideBar
  },
  computed: {
    regions() {
      return Region.all();
    }
  },
  data() {
    return {
      selected: [],
      cropped: null,
      search: "",
      dialog_edit: false,
      dialog_add: false,
      dialog_delete: false,
      user: this.$user,
      username: "",
      role: "",
      region: "",
      email: "",
      code: "",
      users: [],
      each_user: {},
      loading: true,
      mapping: { 1: "Yes", 0: "No" },
      headers: [
        {
          text: "Name",
          sortable: false,
          value: "name"
        },
        {
          text: `Phone`,
          value: "phone",
          sortable: false
        },
        {
          text: `Email`,
          value: "email",
          sortable: false
        },
        {
          text: "Role",
          value: "position",
          sortable: false
        },
        {
          text: "Region",
          value: "regions",
          sortable: false
        },
        {
          text: "Area",
          value: "areas",
          sortable: false
        },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   sortable: false,
        //   align: "center"
        // }
      ],
      headers2: [
        {
          text: "Month",
          align: "center",
          value: "name",
          sortable: false
        },
        {
          text: "Remaining Point (End 31/dec/19)",
          align: "center",
          value: "remaing_point1",
          sortable: false
        }
      ]
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.getUsers();
    },
    getUsers() {
      User.gets()
        .then(response => {
          this.users = response.data.map((e) => {
            e.name = `${e.firstName} ${e.lastName}`;
            return e;
          })
          this.loading = false;
          return this.users;
        })
        .catch(e => {
          this.loading = false;
          console.error(e.response.data);
        });
    },
    onRoleChange() {
      if (this.role == "ci") {
        this.region = 9;
      } else {
        this.region = "";
      }
    },
    onRoleChangeEdit() {
      if (this.each_user.role == "ci") {
        this.each_user.region = 9;
      } else {
        this.each_user.region = "";
      }
    },
    openAddDialog() {
      this.code = "";
      this.dialog_add = true;
    },
    openEditDialog(ele) {
      console.log(ele);
      this.each_user = ele;
      this.dialog_edit = true;
    },
    openDeleteDialog(ele) {
      this.each_user = ele;
      this.dialog_delete = true;
    },
    signup() {
      this.loading = true;
      let formData = new FormData();
      formData.append("code", this.code.trim());

      User.create({ data: formData })
        .then(response => {
          console.log(response.data);
          this.getUsers();
        })
        .catch(e => {
          this.loading = false;
          console.error(e.response.data);
        });

      this.dialog_add = false;
    },
    editEachUser() {
      let formData = new FormData();

      formData.append("username", this.each_user.name);
      formData.append("role", this.each_user.role);
      formData.append("region", this.each_user.region);
      formData.append("email", this.each_user.email);

      this.$http
        .put(`/api/users/${this.each_user.id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then(response => {
          console.log(response.data);
          this.getUsers();
        })
        .catch(e => {
          console.error(e.response.data);
        });

      this.dialog_edit = false;
    },
    deleteUser() {
      this.loading = true;
      this.$http
        .delete(`/api/users/${this.each_user.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then(response => {
          console.log(response.data);
          this.getUsers();
        })
        .catch(e => {
          this.loading = false;
          console.error(e.response.data);
        });

      this.dialog_delete = false;
    },
    changeFormat(input) {
      return this.mapping[input];
    },
    listRegions(regions) {
      return regions && regions.reduce((o, e) => {
        if(o) {
          o += `, ${e.region && e.region.label}`
        } else {
          o = e.region && e.region.label
        }

        return o
      }, '')
    },
    listAreas(areas) {
      return areas && areas.reduce((o, e) => {
        if(o) {
          o += `, ${e.area && e.area.name}`
        } else {
          o = e.area && e.area.name
        }

        return o
      }, '')
    }
  }
};
</script>
