/* eslint-disable */
import { Model } from '@vuex-orm/core'

class gwCoupon extends Model {
  static fields() {
    return {
      region: this.string(''),
      area: this.string(''),
      agentCode: this.string(''),
      name: this.string(''),
      totalCoupon: this.number(0),
      id: this.number(),
      subAgentID: this.number(),
      gwAnnouncementID: this.attr(null),
      group: this.string(''),
      date: this.string(''),
      fullCode: this.string(''),
      code: this.string(''),
      runNumber: this.number(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
      subAgent: this.attr(null),
    }
  }

  static getCoupons(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `v1/gw-coupons/count_coupons`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static getRegions(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `v1/gw-coupons/regions`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static getAreas(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `v1/gw-coupons/areas`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static getAgents(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `v1/gw-coupons/agents`,
        ...options,
      })
      .then((res) => res.response.data)
  }

  static randomCoupons(options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `v1/gw-coupons/luckydraws`,
        dataTransformer: () => {
          return []
        },
        ...options,
      })
      .then((res) => res.response)
  }
}

gwCoupon.entity = 'gwCoupon'

export default gwCoupon
