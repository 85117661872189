/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Redeemed extends Model {
  static fields () {
    return {
      id: this.number(),
      shopId: this.number(),
      refId: this.number(),
      shopPointsId: this.number(),
      campaignYear: this.string(),
      rewardId: this.number(),
      rewardCode: this.string(),
      rewardTitle: this.string(),
      rewardPoint: this.number(),
      quantity: this.number(),
      totalPoint: this.number(),
      status: this.string(),
      linePushMessageId: this.number(),
      CreatedAt: this.string(),
      UpdatedAt: this.string(),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: '/v1/redeem/history',
      dataTransformer: (response) => response && response.data && response.data.data || [],
      ...options
    }).then((res) => res.response.data)
  }

  static status (options = {}) {
    return this.api().request({
      method: 'PUT',
      url: '/v1/redeem/status',
      ...options
    }).then((res) => res.response)
  }
}

Redeemed.entity = 'redeemed'

export default Redeemed