<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="ค้นหาด้วย รหัส ชื่อร้าน ชื่อคน เลขบ้ตร"
              style="margin-right: 2%"
              :hide-details="true"
              @keydown.enter="_getByQuery"
            ></v-text-field>
          </v-card-title>

          <Loading v-if="loading" />

          <v-card-text v-if="!loading">
            <v-card class="mb-8" v-for="element in items" :key="element.id">
              <v-card-text>
                <div class="title text--primary mb-3">
                  {{ element.code }} {{ element.name }}
                  {{
                    `(${
                      element.type &&
                      element.type.name &&
                      element.type.name.toUpperCase()
                    }${
                      element.region &&
                      element.region.label &&
                      ", " + element.region.label.toUpperCase()
                    }${
                      element.area &&
                      element.area.name &&
                      ", " + element.area.name.toUpperCase()
                    })`
                  }}
                  <v-btn
                    class="sync-btn float-right"
                    :loading="loading"
                    :disabled="loading"
                    color="success"
                    @click="_sync(element)"
                  >
                    Sync
                  </v-btn>
                </div>
                <v-data-table
                  class="clean-tb"
                  :headers="headers"
                  :items="element.shopUsers || []"
                  :hide-default-footer="true"
                  :loading="loading"
                >
                  <template v-slot:item.status="{ item }">
                    <span :class="item.status !== 'REGISTERED' && 'red--text'">
                      {{
                        (item.status === "REGISTERED" && "ลงทะเบียนแล้ว") ||
                        "ยังไม่ลงทะเบียน"
                      }}
                    </span>
                  </template>
                  <template v-slot:item.birthDate="{ item }">
                    {{ _birthDate(item.birthDate) }}
                  </template>
                  <template v-slot:item.type="{ item }">
                    {{ _shopRelation(item.type) }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      class="mx-2 float-right"
                      :loading="loading"
                      :disabled="loading || item.status !== 'REGISTERED'"
                      color="#F4CCCC"
                      @click="_unlink(item)"
                    >
                      Unlink
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <!-- Not found -->
            <NotFound
              v-if="!loading && items.length === 0"
              text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
            />
            <!-- Infinite scroll -->
            <infinite-loading v-if="!loading" @infinite="_infinite">
              <span slot="no-results"></span>
              <span slot="no-more"></span>
              <span slot="spinner"></span>
              <span slot="error"></span>
            </infinite-loading>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="confirm.status" persistent max-width="600">
      <v-card>
        <v-card-title class="headline"
          >Confirm {{ confirm.type }}?</v-card-title
        >
        <v-card-text
          >Are you sure you want to {{ confirm.type }}
          {{ (confirm.data && confirm.data.name) || "this" }}.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="loading"
            @click="confirm.status = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            :color="(confirm.type === 'sync' && 'success') || 'error'"
            :loading="loading"
            @click="_confirm"
            text
            >{{ confirm.type }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

import Region from "@/store/models/region";
import Agent from "@/store/models/agent";

import NotFound from "@/components/NotFound";
import Loading from "@/components/Loading";

export default {
  name: "AgentLineManagement",
  components: {
    NotFound,
    Loading,
  },
  props: ["user", "tabId", "tabName"],
  computed: {
    regions() {
      return Region.getsAll();
    },
  },
  data() {
    return {
      search: "",
      region: "All",
      loading: false,
      options: {
        page: 1,
        perpage: 10,
      },
      confirm: {
        status: false,
      },
      headers: [
        {
          text: "ชื่อ-นามสกุล",
          value: "name",
          width: "20%",
        },
        { text: "เลขบัตรประชาชน", value: "citizenId", width: "20%", },
        { text: "วันเกิด", value: "birthDate", width: "20%", },
        { text: "ความสัมพันธ์", value: "type", width: "10%", },
        { text: "สถานะ", align: "center", value: "status", width: "10%", },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "action",
          width: 100,
        },
      ],
      agent: [],
      items: [],
      infinite: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.options.page = 1;
      const size = this.options.page * this.options.perpage;

      this.loading = true;
      this.options.params = "";
      if (this.search) {
        this.options.params = {
          query: this.search,
        };
      }

      const agent = await Agent.users(this.options);
      this.agent = agent && agent.data;
      this.items = (this.agent && this.agent.slice(0, size)) || [];
      this.loading = false;
    },
    _getByQuery() {
      this.fetchData();
    },
    _infinite($state) {
      const agent = this.agent || [];

      this.options.page++;
      const size = this.options.page * this.options.perpage;
      this.items = (agent && agent.slice(0, size)) || [];

      if (this.items.length < agent.length) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    _shopRelation(rel = "master") {
      return this.$shopRelation[rel] || "-";
    },
    _birthDate(birthdate = "") {
      let bd = moment(birthdate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (bd === "Invalid date") {
        return "";
      }
      return bd;
    },
    _sync(data = {}) {
      this.confirm = {
        status: true,
        type: "sync",
        data,
      };
    },
    _unlink(data = {}) {
      this.confirm = {
        status: true,
        type: "unlink",
        data,
      };
    },
    async _confirm() {
      this.loading = true;
      this.confirm.status = false;
      const confirm = this.confirm;
      const data = confirm.data || {};

      let res;
      if (confirm.type === "sync") {
        res = await Agent.sync(data.code, {}).catch((err) =>
          console.error(err)
        );
      } else if (confirm.type === "unlink") {
        const form = new FormData();
        form.append("user_id", data.userId);

        res = await Agent.unlink({ data: form }).catch((err) =>
          console.error(err)
        );
      }
      if (res) {
        this.fetchData();
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>