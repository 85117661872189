<template>
  <div>
    <SideBar :bar_name="tabName" :user="user"></SideBar>
    <v-main>
      <v-container>
        <Smart v-bind="{ user, tabName }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Smart from "@/components/Alert/Smart";

export default {
  name: "SmartALert",
  components: {
    SideBar,
    Smart,
  },
  data() {
    return {
      user: this.$user,
      tabName: "Smart Alert",
    };
  },
};
</script>
