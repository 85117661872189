<template>
  <div>
    <v-layout wrap>
      <!-- <v-flex xs4 md4 v-if="user.role == 'ci'">
        <v-file-input outlined label="File input" @change="onFileChange" v-model="files"></v-file-input>
      </v-flex>

      <v-flex xs4 md4>
        <v-btn
          style="margin: 3%; "
          @click="uploadAgent"
          v-if="user.role == 'ci'"
          :disabled="file == null"
        >Upload</v-btn>

        <v-btn style="margin: 3%; " @click="downloadAgent" v-if="user.role != 'normal'">Download</v-btn>
      </v-flex>-->

      <v-flex xs2 md2 v-if="user.role != 'normal'">
        <v-btn style="margin: 3%; " @click="downloadAgent">Download</v-btn>
      </v-flex>
      <v-flex xs4 md4 style="margin: 10px;"></v-flex>

      <v-flex xs12 md12 style="margin-top: 10px;">
        <v-card>
          <v-card-title>
            {{tabName}}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              style="margin-right: 2%"
              @change="getAgentbyQuery"
            ></v-text-field>

            <v-select
              outlined
              :items="regions"
              item-text="label"
              item-value="id"
              label="Region"
              style="width: 5%"
              v-model="region"
              @change="getAgentbyRegion"
              v-if="regions.length > 2"
            ></v-select>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="agents"
            :footer-props="{'items-per-page-options': $itemsPerPage}"
            :items-per-page="perPage"
            class="elevation-1"
            :loading="loading"
            :options.sync="options"
            :server-items-length="countItems"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon @click="openEditDialog(item)">search</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Detail Dialog -->
    <v-dialog v-model="dialog" width="50%" persistent scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Agent Detail</v-card-title>

        <v-card-text>
          <v-data-iterator :items="agent" hide-default-footer>
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.name">
                  <v-card-title>
                    <h4>{{ item.name }}</h4>
                  </v-card-title>
                  <!-- <v-divider></v-divider> -->
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>ภาค:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.region && item.region.label }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>พื้นที่ขาย:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.area && item.area.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>รหัสลูกค้า:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.code }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>ชื่อลูกค้า:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>เบอร์โทรศัพท์:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.phone_number }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>สถานะ:</v-list-item-content>
                      <v-list-item-content
                        class="align-end"
                      >{{ item.type && item.type.name && item.type.name.toUpperCase() }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>ชื่อผู้จัดการเบียร์:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.sale && item.sale.full_name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>พื้นที่ขาย:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ showAreas(item.sale && item.sale.areas) }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>ที่อยู่ตามจดทะเบียน:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.address }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>เบียร์ (ประเภทเอเจนท์):</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.tier && item.tier.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>ชื่อ (agent_name):</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.master && item.master.name }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>ความสัมพันธ์:</v-list-item-content>
                      <v-list-item-content
                        class="align-end"
                      >{{ item.master && item.master.name && "เจ้าของ" }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>วันเกิด:</v-list-item-content>
                      <v-list-item-content
                        class="align-end"
                      >{{ item.master && item.master.birthDay }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>อายุ:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.master && item.master.age }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>ชื่อคู่สมรส:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.spouse && item.spouse.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>วันเกิดคู่สมรส:</v-list-item-content>
                      <v-list-item-content
                        class="align-end"
                      >{{ item.spouse && item.spouse.birthDay }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>ชื่อผู้สืบทอดกิจการ:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.child && item.child.name }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>วันเกิดผู้สืบทอดกิจการ:</v-list-item-content>
                      <v-list-item-content class="align-end">{{ item.child && item.child.birthDay }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="headline grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />Uploading....
          <br />
          <br />

          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment"
import _ from "lodash"

import Region from "@/store/models/region"
import Agent from "@/store/models/agent"

export default {
  name: "AgentManagement",
  components: {},
  props: ["user", "tabId", "tabName"],
  computed: {
    regions() {
      return Region.getsAll();
    }
  },
  data() {
    return {
      search: "",
      dialog: false,
      dialog_loading: false,
      files: [],
      file: null,
      region: "All",
      loading: true,
      headers: [
        {
          text: "ภาค",
          value: "region.label",
          sortable: false
        },
        {
          text: "พื้นที่ขาย",
          align: "left",
          value: "area.name"
        },
        {
          text: "ชื่อลูกค้า",
          align: "left",
          value: "name"
        },
        {
          text: `เบอร์โทรศัพท์`,
          value: "phone_number"
        },
        {
          text: `ชื่อผู้จัดการเบียร์`,
          value: "sale.full_name"
        },
        {
          text: `ชื่อ(เจ้าของ)`,
          value: "master.name"
        },
        {
          text: "More Detail",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],
      agents: [],
      agent: {},
      options: {},
      perPage: 10,
      countItems: 0
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (
          ne.page != pe.page ||
          ne.itemsPerPage != pe.itemsPerPage
        ) {
          this.getAgent();
        }

        // Sort
        if (
          (ne.sortBy && ne.sortBy[0] && (ne.sortBy[0] != pe.sortBy[0])) ||
          (ne.sortDesc && ne.sortDesc[0] != undefined && pe.sortDesc && (ne.sortDesc[0] != pe.sortDesc[0]))
        ) {
          this.getAgent();
        }
      },
      deep: true
    }
  },
  methods: {
    onFileChange(e) {
      this.file = e;
      // this.url = URL.createObjectURL(e);
    },
    uploadAgent() {
      this.dialog_loading = true;
      let formData = new FormData();
      formData.append("file", this.file);

      Agent.upload(formData)
        .then(() => {
          this.files = [];
          this.getAgent();
          this.dialog_loading = false;
        })
        .catch(e => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },
    downloadAgent() {
      const FileDownload = require("js-file-download");

      Agent.download(this.tabId)
        .then(response => {
          FileDownload(response, `agent_data_shop_type_${this.tabId}.xlsx`);
        })
        .catch(e => {
          console.error(e.response);
        });
    },
    openEditDialog(ele) {
      this.agent = [ele];
      this.dialog = true;
      // this.$nextTick(() => {
      //   document.getElementsByClassName("v-card__text")[0].scrollTop = 0;
      // });
    },
    getAgent() {
      let data = { params: {} };
      if (this.region && this.region !== "All") {
        data.params.regionID = this.region;
      }

      if (this.search) {
        data.params.query = this.search;
      }

      if (this.options && this.options.itemsPerPage) {
        data.params.perPage = this.options.itemsPerPage;
      }

      if (this.options && this.options.page) {
        data.params.page = this.options.page;
      }

      if(this.options && this.options.sortBy && this.options.sortBy[0]) {
        let sort = "asc"
        if(this.options.sortDesc && this.options.sortDesc[0]) {
          sort = "desc"
        }
        let field = _.cloneDeep(this.options.sortBy[0])
        if(field === "master.name") {
          field = "shopUsers.name"
        }

        data.params.order = `${field}:${sort}`
      }

      Agent.gets(this.tabId, data)
        .then(response => {
          this.agents =
            (response &&
              response.data &&
              response.data.map(e => {
                e = {
                  ...e,
                  ...this.getShopUser(e.shopUsers)
                };

                return e;
              })) ||
            [];
          this.countItems = response && response.total;
          this.loading = false;
        })
        .catch(e => {
          console.error(e.response);
          this.loading = false;
        });
    },
    getAgentbyQuery() {
      this.options.page = 1;
      this.getAgent();
    },
    getAgentbyRegion() {
      this.options.page = 1;
      this.getAgent();
    },
    getShopUser(user = []) {
      return user.reduce(
        (o, e) => {
          // BirthDate
          if(e.birthDay) {
            e.birthDay = moment(e.birthDate).format("DD/MM/YYYY");
            if (e.birthDay === "Invalid date") {
              e.birthDay = "";
            }
            e.age = moment().diff(moment(e.birthDate), "years");
          }

          // Type
          switch (e.type) {
            case "master":
              o["master"] = e;
              break;

            case "spouse":
              if (!o["spouse"]) {
                o["spouse"] = e;
              }
              break;

            case "child":
              if (!o["child"]) {
                o["child"] = e;
              }
              break;

            default:
              if (!(o["master"] && o["master"].name)) {
                o["master"] = e;
              }
              break;
          }

          return o;
        },
        {
          master: { name: "" }
        }
      );
    },
    showAreas(areas = []) {
      return areas.reduce((o, e) => {
        if(e.name) {
          o += (o)? ", " : ""
          o += e.name
        }

        return o
      }, '')
    }
  }
};
</script>
