<template>
  <v-layout v-if="value" wrap>
    <!-- Form -->
    <v-flex xs12 md12 class="form-alert">
      <v-form ref="form" v-model="valid" @submit.prevent="_save">
        <v-row no-gutters>
          <v-col cols="12" offset-1>
            <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
              <v-icon left> mdi-less-than </v-icon>
              Back
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field
              v-model="form.title"
              label="ชื่อ"
              :rules="rules.required"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="form.type"
              :items="type"
              item-text="text"
              item-value="value"
              label="ส่งแบบ (แหล่งข้อมูล)"
              :rules="rules.required"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-select
              v-model="form.rcpt"
              :items="rcpt"
              item-text="text"
              item-value="value"
              label="ผู้รับ"
              :rules="rules.required"
              @change="_changeRcpt"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.rcptCode"
              label="รหัสผู้รับ"
              :rules="rules.code"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-select
              v-model="form.source"
              :items="source"
              item-text="text"
              item-value="value"
              label="แหล่งข้อมูล"
              :rules="rules.required"
              @change="_changeSource"
            >
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.sourceCode"
              label="รหัสแหล่งข้อมูล"
              :rules="rules.code"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-menu
              v-model="vdate.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.start"
                  label="วันที่เริ่มต้น"
                  :hide-details="true"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.start"
                @input="vdate.start = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="vdate.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.end"
                  label="วันที่สิ้นสุด"
                  :hide-details="true"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.end"
                @input="vdate.end = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- Filter -->
        <v-row
          justify="end"
          align-content="end"
          class="mx-1 filter-button"
          no-gutters
        >
          เงื่อนไข
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            color="success"
            :disabled="!(form.rcpt && form.source)"
            @click="_addFilter"
            fab
            small
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-row>
        <v-row class="my-4 mx-1 rounded-lg filter-alert" no-gutters>
          <v-col>
            <v-row v-for="(v, i) in form.filter" :key="i" no-gutters>
              <v-col cols="4">
                <v-combobox
                  v-model="v.subject"
                  :items="variable"
                  item-text="name"
                  item-value="value"
                  label="ตัวแปร"
                  prepend-icon="mdi-variable-box"
                  :disabled="!(form.rcpt && form.source)"
                  @click:prepend="_variable('subject', i)"
                  single-line
                  return-object
                >
                </v-combobox>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="v.operator"
                  :items="$operator"
                  item-text="text"
                  item-value="value"
                  label="เงื่อนไข"
                  :disabled="!(form.rcpt && form.source)"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="v.object"
                  label="ค่าเปรียบเทียบ"
                  :disabled="!(form.rcpt && form.source)"
                  prepend-icon="mdi-variable-box"
                  @click:prepend="_variable('object', i)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Date Filter -->
        <v-row
          justify="end"
          align-content="end"
          class="mt-1 mx-1 date-filter-label"
          no-gutters
        >
          เงื่อนไขวันที่
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="mt-4 mx-1 rounded-lg filter-alert" no-gutters>
          <v-col>
            <v-tabs
              v-model="form.datetype"
              background-color="transparent"
              color="success"
              class="pl-1"
            >
              <v-tab v-for="item in tabs" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="form.datetype">
              <v-tab-item v-for="(item, i) in tabs" :key="item">
                <v-row no-gutters>
                  <!-- วันที่ = 0, เดือน = 1, ไตรมาส = 2, วันที่ปิดสเต็ป = 3 -->
                  <!-- วันที่ -->
                  <v-col cols="3" v-if="i === 0">
                    <v-menu
                      v-model="vdate.date"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.dateValue"
                          label="วันที่"
                          :rules="form.datetype === 0 ? rules.required : []"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.dateValue"
                        @input="vdate.date = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" v-if="i === 0">
                    <v-text-field
                      v-model="form.dateFrequency"
                      type="number"
                      label="ความถี่"
                      :rules="form.datetype === 0 ? rules.number : []"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" v-if="i === 0">
                    <v-select
                      v-model="form.dateUnit"
                      :items="dtypes"
                      label="หน่วย"
                      :rules="form.datetype === 0 ? rules.required : []"
                    >
                    </v-select>
                  </v-col>
                  <!-- เดือน -->
                  <v-col cols="3" v-if="i === 1 || i === 2">
                    <v-select
                      v-model="form.dateSince"
                      :items="[
                        { text: `ต้น${item}`, value: 'begin' },
                        { text: `สิ้น${item}`, value: 'end' },
                      ]"
                      label="ช่วง"
                      :rules="
                        form.datetype === 1 || form.datetype === 2
                          ? rules.required
                          : []
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="3" v-if="i !== 0">
                    <v-select
                      v-model="form.datePeriod"
                      :items="[
                        { text: 'ก่อน', value: 'before' },
                        { text: 'หลัง', value: 'after' },
                      ]"
                      label="ก่อน/หลัง"
                      :rules="form.datetype !== 0 ? rules.required : []"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="3" v-if="i !== 0">
                    <v-text-field
                      v-model="form.range"
                      type="number"
                      label="จำนวน(วัน)"
                      :rules="form.datetype !== 0 ? rules.number : []"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- All -->
                  <v-col cols="3">
                    <v-menu
                      :ref="`tmenu${i}`"
                      :v-model="`tmenu${i}`"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.sendMessageTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.sendMessageTime"
                          label="เวลาส่ง"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        :v-if="`tmenu${i}`"
                        v-model="form.sendMessageTime"
                        full-width
                        @click:minute="
                          $refs[`tmenu${i}`][0].save(form.sendMessageTime)
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

        <v-row
          justify="end"
          align-content="end"
          class="mx-1 filter-button"
          no-gutters
        >
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            color="primary"
            @click="_variable()"
            fab
            dark
            small
          >
            <v-icon dark> mdi-variable </v-icon>
          </v-btn>
          <v-btn class="mx-2" color="primary" @click="_link()" fab dark small>
            <v-icon dark> mdi-link-variant </v-icon>
          </v-btn>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-textarea
              outlined
              v-model="form.message"
              label="ข้อความ"
              rows="10"
              :rules="rules.required"
              required
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mx-1" no-gutters>
          <v-btn
            color="primary"
            :disabled="!(valid && this.form.title)"
            @click="_testdialog"
            >Test</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" class="mr-4" @click="_back">Cancel</v-btn>
          <v-btn
            color="success"
            :disabled="!(valid && this.form.title)"
            type="submit"
            >Save</v-btn
          >
        </v-row>
      </v-form>
    </v-flex>

    <!-- ------------------------------------------ Variable Dialog ---------------------------------------------------- -->
    <SelectVariable
      v-model="varDialog.status"
      :option="varDialog"
      @click="_selectVariable"
    />

    <!-- ------------------------------------------ Link Dialog ---------------------------------------------------- -->
    <SelectLink v-model="linkDialog.status" :option="linkDialog" />

    <!-- ------------------------------------------ Test Dialog ---------------------------------------------------- -->
    <v-dialog v-model="testDialog" max-width="500px">
      <v-form ref="testForm" v-model="testValid" @submit.prevent="_test">
        <v-card>
          <v-card-title>
            <v-flex xs12 md12 class="ma-0">
              <div>กรอกข้อมูลเพื่อทดสอบ</div>
              <div v-if="testResult.status" class="caption font-chang" :class="testResult.class">{{testResult.text}}</div>
            </v-flex>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="my-2">
            <v-text-field
              v-model="userCodes"
              label="รหัสพนักงานที่ต้องการรับข้อความ"
              :rules="[...rules.required, ...rules.code]"
            ></v-text-field>
            <v-text-field
              v-model="rcptCodes"
              label="รหัสผู้รับที่ต้องการทดสอบ"
              :rules="rules.code"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error" class="mr-2" text @click="testDialog = false">
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              type="submit"
              :disabled="!(testValid && this.userCodes)"
            >
              Test
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from "moment";

import SelectVariable from "@/components/Variable/SelectVariable";
import SelectLink from "@/components/Variable/SelectLink";

import Variable from "@/store/models/variable";
import SmartAlert from "@/store/models/smartalert";

const rcpt = ["ci", "region_admin", "sale", "agent", "sub", "direct"];
const relation = {
  ci: ["agent", "sub", "direct"],
  region_admin: ["agent", "sub", "direct"],
  sale: ["agent", "sub", "direct"],
  agent: ["agent", "sub"],
  sub: ["sub"],
  direct: ["direct"],
};
const utcFormat = "YYYY-MM-DDTHH:mm:ssZ";

export default {
  name: "FormSmartAlert",
  components: {
    SelectVariable,
    SelectLink,
  },
  props: ["value", "data"],
  computed: {
    rcpt: () => rcpt,
  },
  data() {
    const rules = {
      required: [(v) => v != "" || "กรุณากรอกข้อมูล"],
      number: [(v) => v >= 0 || "กรุณากรอกข้อมูล"],
      code: [
        (v) =>
          !v || /^[0-9a-zA-Z\\,\s]+$/.test(v) || "Ex. 0062000653, 0062000654",
      ],
    };

    return {
      valid: false,
      testValid: false,
      rules,
      form: {},
      source: [],
      tabs: ["วันที่", "เดือน", "ไตรมาส", "วันที่ปิดสเต็ป"],
      dtypes: ["วัน", "เดือน"],
      type: this.$smSendtype,
      variable: [],
      varDialog: {},
      linkDialog: {},
      testDialog: false,
      testResult: {},
      vdate: {
        start: false,
        end: false,
      },
      tmenu0: false,
      tmenu1: false,
      tmenu2: false,
      tmenu3: false,
      userCodes: "",
      rcptCodes: "",
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.setData();
        }
      },
      deep: true,
    },
  },
  methods: {
    setData() {
      const data = this.data;
      let object = {};

      switch (data.dateType) {
        case "date":
          object = {
            datetype: 0,
            dateValue:
              data.dateContent &&
              data.dateContent.day &&
              moment(data.dateContent && data.dateContent.day).format(
                "YYYY-MM-DD"
              ),
            dateFrequency: data.dateContent && data.dateContent.frequency,
            dateUnit:
              data.dateContent && data.dateContent.unit === "day"
                ? "วัน"
                : "เดือน",
          };
          break;
        case "month":
          object = {
            datetype: 1,
            dateSince: data.dateContent && data.dateContent.checkpoint,
            datePeriod:
              data.dateContent && data.dateContent.range >= 0
                ? "after"
                : "before",
            range: Math.abs(data.dateContent && data.dateContent.range),
          };
          break;
        case "quarter":
          object = {
            datetype: 2,
            dateSince: data.dateContent && data.dateContent.checkpoint,
            datePeriod:
              data.dateContent && data.dateContent.range >= 0
                ? "after"
                : "before",
            range: Math.abs(data.dateContent && data.dateContent.range),
          };
          break;
        case "step":
          object = {
            datetype: 3,
            datePeriod:
              data.dateContent && data.dateContent.range >= 0
                ? "after"
                : "before",
            range: Math.abs(data.dateContent && data.dateContent.range),
          };
          break;
      }

      const start =
        data.start && moment(data.start, "YYYY-MM-DD").format("YYYY-MM-DD");

      const end =
        data.end && moment(data.end, "YYYY-MM-DD").format("YYYY-MM-DD");

      this.form = {
        ...this.data,
        ...object,
        start,
        end,
        rcptCode: data.rcptCode && data.rcptCode.join(", "),
        sourceCode: data.sourceCode && data.sourceCode.join(", "),
      };
      this._changeRcpt();
    },
    async fetchVariable() {
      this.loading = true;

      const form = this.form;
      if (form.rcpt && form.source) {
        const options = {
          params: { relation: `${this.form.rcpt}-${this.form.source}` },
        };
        const res = await Variable.list(options);
        this.variable = res || [];
      }

      this.loading = false;
    },
    _back() {
      this.$emit("input", false);
    },
    _changeRcpt() {
      this.source = relation[this.form.rcpt];
      this.form.source = this.form.source || (this.source && this.source[0]);
      this._changeSource();
    },
    _changeSource() {
      this.fetchVariable();
    },
    _addFilter() {
      return this.form && this.form.filter.push({});
    },
    _variable(operand, index) {
      const form = this.form;

      this.varDialog = {
        status: !this.varDialog.status,
        rcpt: form.rcpt,
        source: form.source,
        operand,
        index,
      };
    },
    _link() {
      this.linkDialog = {
        status: !this.linkDialog.status,
      };
    },
    _selectVariable(data) {
      const dialog = this.varDialog;
      this.form.filter[dialog.index][dialog.operand] = data;
      this.varDialog = { status: false };
    },
    _save() {
      this._submit();
    },
    _testdialog() {
      this.testDialog = true;
      this.testResult = {};
    },
    _test() {
      this._submit(true);
    },
    _submit(test = false) {
      const form = this.form;

      let dateType = "date";
      let dateContent = {
        day:
          form.dateValue &&
          moment(form.dateValue, "YYYY-MM-DD").format(utcFormat),
        frequency: Math.abs(form.dateFrequency),
        unit: form.dateUnit === "วัน" ? "day" : "month",
      };

      let multiply = 1;
      if (form.datePeriod === "before") {
        multiply = -1;
      }
      switch (this.form.datetype) {
        case 1:
          dateType = "month";
          dateContent = {
            checkpoint: form.dateSince,
            range: Math.abs(form.range) * multiply,
          };
          break;
        case 2:
          dateType = "quarter";
          dateContent = {
            checkpoint: form.dateSince,
            range: Math.abs(form.range) * multiply,
          };
          break;
        case 3:
          dateType = "step";
          dateContent = {
            checkpoint: form.dateSince,
            range: Math.abs(form.range) * multiply,
          };
          break;
      }
      const filter = form.filter.reduce((o, e) => {
        if (e.subject && e.object) {
          o.push({
            subject: this.getVariable(e.subject),
            operator: e.operator,
            object: this.getVariable(e.object),
          });
        }

        return o;
      }, []);

      const data = {
        ...form,
        start:
          (form.start &&
            moment(form.start, "YYYY-MM-DD")
              .startOf("day")
              .format(utcFormat)) ||
          undefined,
        end:
          (form.end &&
            moment(form.end, "YYYY-MM-DD").endOf("day").format(utcFormat)) ||
          undefined,
        dateType,
        dateContent,
        rcptCode:
          (form.rcptCode && form.rcptCode.split(",").map((e) => e.trim())) ||
          undefined,
        sourceCode:
          (form.sourceCode &&
            form.sourceCode.split(",").map((e) => e.trim())) ||
          undefined,
        filter,
        datetype: undefined,
        dateFrequency: undefined,
        datePeriod: undefined,
        dateSince: undefined,
        dateUnit: undefined,
        dateValue: undefined,
        runTime: undefined,
      };

      if (test) {
        this.loading = true;

        const userCodes = this.userCodes.split(",").map((e) => e.trim());
        data.rcptCode =
          (this.rcptCodes && this.rcptCodes.split(",").map((e) => e.trim())) ||
          data.rcptCode;
        const options = {
          data: {
            user_codes: userCodes,
            alert: data,
          },
        };
        SmartAlert.test(options)
          .then((res) => {
            this.testResult = {
              status: true,
              class: "success--text",
              text: "ทดสอบข้อมูลสำเร็จ"
            };
            return res;
          })
          .catch((err) => {
            this.testResult = {
              status: true,
              class: "error--text",
              text: "เกิดข้อผิดพลาดในการทดสอบข้อมูล"
            };
            return console.error(err);
          });

        this.loading = false;
      } else {
        this.$emit("click", data);
      }
    },
    getVariable(text = "") {
      const regex = /({{)(.*)(}})/g;
      const match = text.match(regex);

      if (match && match[0]) {
        return match[0].replace("{{", "").replace("}}", "") || "";
      } else {
        return text + "";
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgb(136, 136, 136) !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: #ffffff !important;
}

.col {
  padding: 10px 5px 10px 5px !important;
}

.form-alert {
  margin: 1rem 3rem;
}

.filter-alert {
  border: 1px solid rgba(0, 0, 0, 0.42);
}

.filter-button {
  top: 30px !important;
  position: relative;
  z-index: 1;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.date-filter-label {
  top: 10px !important;
  position: relative;
  z-index: 1;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.v-toolbar__content {
  padding-left: 0px;
}
</style>
