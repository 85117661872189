<template>
  <div :class="[{ show: openModal }, mode]">
    <div class="modal-content">
      <div
        v-if="modalMode && gps && userPosition"
        id="gotoCurrent"
        @click="moveToUserPos()"
      >
        <svg
          width="13"
          version="1.1"
          viewBox="0 0 500 500"
          preserveAspectRatio="xMinYMin meet"
        >
          <circle cx="250" cy="250" r="250" fill="#3085d6"></circle>
        </svg>
        <div>ตำแหน่งของฉัน</div>
      </div>
      <div class="map-container">
        <div :id="mapElmId" class="map-inner"></div>
      </div>
      <div class="bottomSection">
        <div
          v-if="openModal"
          class="btn success mr-4"
          @click="confirm()"
        >
          ยืนยัน
        </div>
        <div v-show="openModal" class="btn bg-grey" @click.stop="close()">
          ปิด
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import 'leaflet-control-geocoder';

import "leaflet/dist/leaflet.css";
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

const svgString = `<svg xmlns='http://www.w3.org/2000/svg' width="100%" height="100%" version="1.1" viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
<defs>
    <filter id="dropshadow" x="-30%" y="-30%" width="200%" height="200%" filterUnits="userSpaceOnUse">
      <feGaussianBlur in="SourceGraphic" stdDeviation="80"/> <!-- stdDeviation is how much to blur -->
      <feOffset dx="15" dy="15" result="offsetblur"/> 
      <feOffset dx="-15" dy="-15" result="offsetblur"/>
      <feMerge> 
        <feMergeNode/>
        <feMergeNode in="SourceGraphic"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <circle r="100" cx="240" cy="240" fill="rgb(102, 77, 255,0.1)"/>
    <circle r="80" cx="240" cy="240" fill="rgb(102, 77, 255)" style="filter:url(#dropshadow)">
    <animate attributeName="fill" repeatCount="indefinite" attributeType="XML" begin="0;graphics.click" dur="4s"
    values="3085d6;2569ab;3085d6" calcMode="linear" />
    </circle>
</svg>`;
const myIconUrl = encodeURI("data:image/svg+xml," + svgString).replace(
  "#",
  "%23"
);
const userIcon = L.icon({
  iconUrl: myIconUrl,
  iconSize: 60, // size of the icon
  //popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

export default {
  props: {
    mode: { default: "modal" },
    latlng: Array,
    open: Boolean,
    gps: Boolean,
  },
  name: "Map",
  data() {
    return {
      openModal: false,
      currentLatlng: null,
      isMove: false,
      mapElmId: "map_" + +new Date(),
      map: null,
      userPosMarker: null,
      userPosition: null,
      marker: null,
    };
  },
  created() {
    let key = this.generateString();
    if(this.$vnode.key) {
      key = this.$vnode.key;
    }
    this.mapElmId = "map_" + key;
  },
  mounted() {
    this.setupLeafletMap();
  },
  computed: {
    thumbnailMode() {
      return this.mode === "thumbnail";
    },
    modalMode() {
      return this.mode === "modal";
    },
  },
  watch: {
    open: {
      immediate: true,
      handler() {
        this.openModal = this.open;
        this.setView(this.latlng);
        setTimeout(() => {
          this.map.invalidateSize(true);
        }, 500);
      },
    },
    latlng: {
      immediate: true,
      handler() {
        if(this.latlng && this.latlng.length === 2) {
          this.flyTo({ lat: this.latlng[0], lng: this.latlng[1] });
        }
      },
      deep: true,
    },
  },
  methods: {
    generateString() {
      return Math.random().toString(36).substring(2)
    },
    setMarkerUserPos(lat, lng) {
      this.userPosition = [lat, lng];
      if (!this.userPosMarker) {
        this.userPosMarker = L.marker(this.userPosition, {
          icon: userIcon,
        }).addTo(this.map);
      } else {
        this.userPosMarker.setLatLng(this.userPosition);
      }
    },
    moveToUserPos() {
      this.map.flyTo(this.userPosition, 16, { animate: true, duration: 1 });
    },
    setView(latlng) {
      if(latlng && latlng.length === 2) {
        this.map && this.map.setView(
          latlng,
          15
        );
      }
    },
    flyTo(latlng = {}) {
      this.currentLatlng = latlng;
      if(this.map && latlng.lat && latlng.lng) {
        const coordinates = [latlng.lat, latlng.lng];

        if(!this.marker) {
          this.addMarker(coordinates)
        }
        this.marker.setLatLng(coordinates)
        this.map.flyTo(coordinates, this.map.getZoom(), { animate: true, duration: 0 });
      }
    },
    addMarker(latlng = []) {
      if(latlng && latlng.length === 2) {
        var icon = L.divIcon({
          className: 'map-marker-centered', iconSize: [42, 95]
        });
        this.marker = L.marker(latlng, {icon}).addTo(this.map);
      }
    },
    setupLeafletMap() {
      this.map = L.map(this.mapElmId, { zoomControl: true }).setView(
        this.latlng || [13.736717, 100.523186],
        15,
      );

      L.DomUtil.setOpacity(this.map.zoomControl.getContainer(), 0);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map);

      delete L.Icon.Default.prototype._getIconUrl;

      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      
      // Marker
      this.addMarker(this.latlng)

      if(this.modalMode) {
        L.Control.geocoder({
          collapsed: false,
          position: 'topleft',
          text: 'Search',
          title: 'Search',
          defaultMarkGeocode: false,
        })
        .on('markgeocode', (e) => {
          var center = e.geocode.center;
          this.map.setView(center, this.map.getZoom());
        }).addTo(this.map);

        if (navigator.geolocation && this.gps) {
          // getcurrent location to function
          navigator.geolocation.getCurrentPosition((p) => {
            this.setMarkerUserPos(p.coords.latitude, p.coords.longitude);
            if (!(this.latlng && this.latlng.length === 2)) {
              this.moveToUserPos();
            }
          });

          navigator.geolocation.watchPosition((p) => {
            this.setMarkerUserPos(p.coords.latitude, p.coords.longitude);
          });
        }

        this.map.on('click', (e) => {
          this.flyTo(e.latlng);
        });

        this.map.on('dblclick', () => console.debug("zoom in"));

        // this.map.on("move", () => {
        //   this.isMove = true;
        // });

        // this.map.on("moveend", () => {
        //   this.currentLatlng = { ...this.map.getCenter() };
        //   this.isMove = false;
        // });

        L.DomUtil.setOpacity(this.map.zoomControl.getContainer(), 1);
      } else {
        L.DomUtil.setOpacity(this.map.zoomControl.getContainer(), 0);
        
        this.map.dragging.disable();
        this.map.touchZoom.disable();
        this.map.doubleClickZoom.disable();
        this.map.scrollWheelZoom.disable();
        this.map.boxZoom.disable();
        this.map.keyboard.disable();
        if (this.map.tap) this.map.tap.disable();
      }
    },
    confirm() {
      this.$emit("confirm", this.currentLatlng);
    },
    close() {
      this.$emit("close");
      this.openModal = false;
    },
  },
};
</script>

<style lang="scss">
.map-marker-centered {
  background: url(~@/assets/img/map-marker.png) no-repeat;
}
</style>

<style lang="scss" scoped>
.bg-grey {
  background-color: #808080 !important;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-marker-shadow-centered {
  width: 14px;
  height: 3px;
  position: absolute;
  z-index: 401;
  left: calc(50% - 7px);
  top: calc(50% - 2px);
  transition: all 0.2s ease;
  border-radius: 100%;
  filter: blur(1px);
  background: #0000006b;
  box-shadow: 0px 0 2px 3px #0000006b;
  &.move {
    transform: scale(0.8, 0.8);
  }
}

/* The Modal (background) */
.modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  opacity: 0;
  left: 0;
  top: -100%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  &.show {
    opacity: 1;
    top: 0;
    > .modal-content {
      width: 80%;
      height: 80%;
      background-color: #fefefe;
      margin: auto;
      //   padding: 20px;
      border: 1px solid #888;
      // width: 80%;
      border-radius: 10px;
      position: relative;
      text-align: center;
      font-size: 15px;
      color: #2c2c2c;
      // overflow: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }
  }
}

.thumbnail {
  pointer-events: none;
  cursor: default;
  height: 200px;
  z-index: 1;
  position: relative;
  top: -100%;

  > .modal-content {
    width: 100%;
    background-color: #fefefe;
    margin: auto;
    //   padding: 20px;
    border: 1px solid #888;
    // width: 80%;
    border-radius: 10px;
    position: relative;
    height: 100%;
    text-align: center;
    font-size: 15px;
    color: #2c2c2c;
    // overflow: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
}

.map-inner {
  width: 100%;
  height: 100%;
}

.bottomSection {
  display: flex;
  position: absolute;
  z-index: 400;
  bottom: 15px;
  .btn {
    padding: 4px 10px;
    border-radius: 10px;
    width: 100px;
    max-width: 300px;
    cursor: pointer;
    color: white;
  }
}

#gotoCurrent {
  background: #fcfcfc;
  border-radius: 6px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
  z-index: 410;
  padding: 1px 5px;
  cursor: pointer;
  color: #535353;
  user-select: none;
  box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08),
    0 1px 3px 1px rgba(66, 66, 66, 0.16);
  > div {
    line-height: 33px;
  }
  svg {
    margin-right: 5px;
  }
  &:active {
    color: #838383;
    box-shadow: inset 0 1px 1px 0 rgba(66, 66, 66, 0.08),
      inset 0 1px 3px 1px rgba(66, 66, 66, 0.16);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    & svg > circle {
      fill: #296dad;
    }
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* Add Animation */
@-webkit-keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}

@keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}
</style>
