/* eslint-disable */
import { Model } from '@vuex-orm/core'

class CTTPartner extends Model {
  static fields() {
    return {
      buyerId: this.number(),
      sellerId: this.number(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
    }
  }

  static create(buyer_id, seller_id, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/chang-tt/partners/buyers/${buyer_id}/sellers/${seller_id}`,
      ...options,
    }).then((res) => res && res.response)
  }

  static createBulk(buyer_id, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `chang-tt/partners/shops/${buyer_id}`,
      ...options,
    }).then((res) => res && res.response)
  }

  static transfer(options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `chang-tt/partners/transfer`,
      ...options,
    }).then((res) => res && res.response)
  }

  static remove(buyer_id, seller_id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/partners/buyers/${buyer_id}/sellers/${seller_id}`,
      ...options,
    }).then((res) => res && res.response)
  }

  static removeBulk(buyer_id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `chang-tt/partners/shops/${buyer_id}`,
      ...options,
    }).then((res) => res && res.response)
  }

  static removeBuyers(seller_id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/partners/sellers/${seller_id}/buyers`,
      ...options,
    }).then((res) => res && res.response)
  }
}

CTTPartner.entity = 'partner'

export default CTTPartner