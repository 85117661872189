<template>
  <div>
    <v-layout v-if="!dialog" wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer />
            <v-select
              v-model="region"
              :items="regions"
              item-text="name"
              item-value="id"
              label="ภาค"
              :hide-details="true"
              class="mr-2 select-address"
              outlined
              dense
              @change="_changeRegion"
            />
            <v-select
              v-model="province"
              :items="provinces"
              item-text="name"
              item-value="id"
              label="จังหวัด"
              :hide-details="true"
              class="mr-2 select-address"
              outlined
              dense
              @change="_getByQuery"
            />
            <v-text-field
              v-model="search"
              outlined
              dense
              append-icon="search"
              label="ค้นหา"
              :hide-details="true"
              @keydown.enter="_getByQuery"
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-card class="mb-4">
              <v-card-text>
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="headers"
                  :items="items"
                  :footer-props="{ 'items-per-page-options': $itemsPerPage }"
                  :items-per-page="perPage"
                  :loading="isLoading"
                  :options.sync="options"
                  :server-items-length="countItems"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                >
                  <template v-slot:item.ownerName="{ item }">
                    {{ _ownerName(item.owner) }}
                  </template>

                  <template v-slot:item.verified="{ item }">
                    <span v-html="_verified(item.verified)"></span>
                  </template>

                  <template v-slot:item.registered="{ item }">
                    <span
                      v-html="
                        _registered(
                          item.owner &&
                            item.owner[0] &&
                            item.owner[0].registered
                        )
                      "
                    ></span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      :isLoading="isLoading"
                      @click="_edit(item)"
                      depressed
                      block
                      >แก้ไข</v-btn
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Form -----------------------------------------------------</div> -->
    <FormRetailShop
      v-model="dialog"
      :loading="isLoading"
      :data="form"
      :refetch="refetch"
      @submit="_save"
    />
  </div>
</template>

<script>
import _ from "lodash";

import FormRetailShop from "./RetailShop/Form.vue";

import RegionModel from "@/store/models/changtt/region";
import ProvinceModel from "@/store/models/changtt/province";
import UserModel from "@/store/models/changtt/user";
import ShopModel from "@/store/models/changtt/shop";

const formDefault = {
  filter: [{}],
  dateType: "date",
  dateContent: {},
  type: "list",
};

export default {
  name: "RetailShop",
  components: {
    FormRetailShop,
  },
  props: ["user", "tabName"],
  data() {
    return {
      search: "",
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [true],
      },
      perPage: 10,
      countItems: 0,
      regions: [],
      region: null,
      provinces: [],
      province: null,
      confirm: {
        status: false,
      },
      headers: [
        { text: "รหัสร้าน", value: "code", sortable: false },
        { text: "ชื่อร้าน", value: "name", sortable: false },
        { text: "เบอร์โทรศัพท์", value: "phone", sortable: false },
        { text: "ชื่อเจ้าของ", value: "ownerName", sortable: false },
        { text: "ภาค", value: "region.name", sortable: false },
        { text: "จังหวัด", value: "province.name", sortable: false },
        { text: "ยืนยันตัวตน", value: "verified", sortable: false },
        { text: "สถานะไลน์", value: "registered", sortable: false },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
          width: "215px",
        },
      ],
      items: [],
      dialog: false,
      form: formDefault,
      refetch: false,
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }

        // Sort
        if (ne.sortBy[0] != pe.sortBy[0] || ne.sortDesc[0] != pe.sortDesc[0]) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.fetchData();
      this.fetchRegion();
      this.fetchProvince();
    },
    async fetchData() {
      this.isLoading = true;
      let options = {
        params: {
          agentType: "retail",
        },
      };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.region) {
        options.params.regionId = this.region;
      }

      if (this.province) {
        options.params.provinceId = this.province;
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.limit = this.options.itemsPerPage;
      } else {
        options.params.limit = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      if (this.options && this.options.sortBy && this.options.sortBy[0]) {
        let sort = "asc";
        if (this.options.sortDesc && this.options.sortDesc[0]) {
          sort = "desc";
        }
        let field = _.cloneDeep(this.options.sortBy[0]);
        if (field === "dateType") {
          field = "date_type";
        }
        if (field === "runTime") {
          field = "run_time";
        }

        options.params.order = `${field}:${sort}`;
      }

      const res = await ShopModel.list(options);
      this.items = (res && res.data) || [];
      this.countItems = res && res.total;

      this.isLoading = false;
    },
    async fetchRegion() {
      this.loading = true;

      const options = {
        params: {
          listType: "all",
          order: "name:asc",
        },
      };

      const res = await RegionModel.list(options);
      this.regions = (res && res.data) || [];
      this.regions.unshift({ id: null, name: "เลือกภาค" });

      this.loading = false;
    },
    async fetchProvince() {
      this.loading = true;

      const options = {
        params: {
          listType: "all",
          order: "name:asc",
        },
      };
      if (this.region) {
        options.params.regionId = this.region;
      }

      const res = await ProvinceModel.list(options);
      this.provinces = (res && res.data) || [];
      this.provinces.unshift({ id: null, name: "เลือกจังหวัด" });

      this.loading = false;
    },
    _getByQuery() {
      this.selected = null;
      this.options.page = 1;
      this.fetchData();
    },
    _changeRegion() {
      this.fetchProvince();
      this._getByQuery();
    },
    _ownerName(owner = []) {
      return owner.reduce((o, e) => {
        const name = `${e.firstName}${e.lastName ? " " + e.lastName : ""}`;

        if (o != "") {
          o += ", ";
        }

        o += name;
        return o;
      }, "");
    },
    _verified(verified = false) {
      return verified
        ? '<span style="color: #1f9812;">ยืนยันแล้ว</span>'
        : '<span style="color: #ff0000;">ยังไม่ได้ยืนยัน</span>';
    },
    _registered(registered = false) {
      return registered
        ? '<span style="color: #1f9812;">ลงทะเบียนแล้ว</span>'
        : '<span style="color: #ff0000;">ยังไม่ได้ลงทะเบียน</span>';
    },
    _edit(data = {}) {
      this.dialog = true;
      this.form = data;
    },
    async _save(shop = {}, user = {}, add = {}, del = {}) {
      this.isLoading = true;

      const resUpUser = await this.updateUser(user, shop);
      const resUpload = await this.updateShopImage(shop);
      const resUpdateLink = await this.updateLink(shop.id, { ...add, ...del });

      if (
        (resUpload || !(shop.image instanceof File)) &&
        resUpUser &&
        resUpdateLink
      ) {
        this.fetchData();
        this.dialog = false;
        this.form = {};
      } else {
        this.refetch = false;
        setTimeout(() => {
          this.refetch = true;
        }, 100);
      }

      this.isLoading = false;
    },
    async updateShopImage(data = {}) {
      if (data.image instanceof File) {
        let formData = new FormData();
        formData.append("image", data.image);
        const options = {
          data: formData,
        };
        return ShopModel.uploadImage(data.id, options);
      }
      return;
    },
    async updateUser(user = {}, shop = {}) {
      const options = {
        data: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          gender: user.gender,
          citizenId: user.citizenId,
          birthDate: user.birthDate,
          shop: {
            id: shop.id,
            name: shop.name,
            phone: shop.phone,
            typeId: shop.type && shop.type.id,
            address: shop.address,
            provinceId: shop.province && shop.province.id,
            districtId: shop.district && shop.district.id,
            subDistrictId: shop.subDistrict && shop.subDistrict.id,
            postcode: shop.postcode,
            pointLocation: shop.pointLocation,
          },
        },
      };
      return UserModel.update(user.id, options);
    },
    async updateLink(buyerId = 0, data = {}) {
      const options = {
        data
      };
      return ShopModel.updateShopRelation(buyerId, options);
    },
  },
};
</script>

<style scoped>
.select-address {
  width: 100px;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>
