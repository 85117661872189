<template>
  <div>
    <SideBar :bar_name="`Chang TT ${tabName}`" :user="user"></SideBar>
    <v-main>
      <v-container>
        <AreaPromoterCRMComponent v-bind="{ user, tabName }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";
import AreaPromoterCRMComponent from "@/components/ChangTT/AreaPromoterCRM";

export default {
  name: "CTTAreaPromoterCRM",
  components: {
    SideBar,
    AreaPromoterCRMComponent,
  },
  data() {
    return {
      user: this.$user,
      tabName: "Area Promoter CRM",
    };
  },
};
</script>
