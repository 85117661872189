/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Notify extends Model {
  static fields () {
    return {
      id: this.number(),
      name: this.string(),
    }
  }

  static send (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/LineMessage`,
      ...options
    }).then((res) => res.response)
  }
}

Notify.entity = 'notify'

export default Notify