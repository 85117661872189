<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="ค้นหาสาขาร้านค้าที่ต้องการผูกผู้ใช้งานเพิ่ม"
              style="margin-right: 2%"
              :hide-details="true"
              @keydown.enter="_getByQuery"
            ></v-text-field>
          </v-card-title>

          <Loading v-if="loading" />

          <v-card-text v-if="!loading">
            <v-card class="mb-8" v-for="element in items" :key="element.id">
              <v-card-text>
                <div class="title text--primary mb-3">
                  {{ element.code }} {{ element.name }}
                  {{
                    `(${
                      element.region &&
                      element.region.label &&
                      element.region.label.toUpperCase()
                    }${
                      element.area &&
                      element.area.name &&
                      ", " + element.area.name.toUpperCase()
                    })`
                  }}
                </div>
                <v-data-table
                  class="agent-branch-tb"
                  :headers="headers"
                  :items="element.shopUsers || []"
                  :hide-default-footer="true"
                  :loading="loading"
                >
                  <template v-slot:item.name="{ item }">
                    <v-flex xs12 class="agent-branch-list">
                      {{ item.name }}
                    </v-flex>
                  </template>
                  <template v-slot:item.type="{ item }">
                    <v-flex xs12 class="agent-branch-list">
                      {{ _shopRelation(item.type) }}
                    </v-flex>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-flex
                      xs12
                      class="agent-branch-list"
                      :class="item.status !== 'REGISTERED' && 'red--text'"
                    >
                      {{
                        (item.status === "REGISTERED" && "ลงทะเบียนแล้ว") ||
                        "ยังไม่ลงทะเบียน"
                      }}
                    </v-flex>
                  </template>
                  <template v-slot:item.bindName="{ item }">
                    <span
                      v-if="item.shopUserLinks && item.shopUserLinks.length > 1"
                    >
                      <v-flex
                        xs12
                        class="agent-branch-list"
                        v-for="sh in item.shopUserLinks"
                        :key="sh.id"
                      >
                        {{ sh.name || "" }}
                      </v-flex>
                    </span>
                    <span v-else>
                      <v-flex
                        xs12
                        class="agent-branch-list agent-branch-notfound"
                      >
                        ไม่พบผู้ใช้งานที่ผูก
                      </v-flex>
                    </span>
                  </template>
                  <template v-slot:item.bindAgent="{ item }">
                    <v-flex
                      xs12
                      class="agent-branch-list"
                      v-for="sh in item.shopUserLinks"
                      :key="sh.id"
                    >
                      {{ (sh.shop && sh.shop.name) || "" }}
                    </v-flex>
                  </template>
                  <template v-slot:item.bindRelation="{ item }">
                    <v-flex
                      xs12
                      class="agent-branch-list"
                      v-for="sh in item.shopUserLinks"
                      :key="sh.id"
                    >
                      {{ (sh.type && _shopRelation(sh.type)) || "" }}
                    </v-flex>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-flex v-for="sh in item.shopUserLinks" :key="sh.id">
                      <v-btn
                        class="float-right"
                        :class="(sh.id && 'unlink-btn') || 'link-btn'"
                        :loading="loading"
                        :disabled="loading"
                        :color="(sh.id && '#F4CCCC') || '#D9EAD3'"
                        @click="_action(item, sh)"
                      >
                        <v-icon dark left>
                          {{ (sh.id && "mdi-minus-circle") || "mdi-plus-circle" }}
                        </v-icon>
                        {{ (sh.id && "ยกเลิกผูก") || "ผูกผู้ใช้งาน" }}
                      </v-btn>
                    </v-flex>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <!-- Not found -->
            <NotFound
              v-if="!loading && items.length === 0"
              text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
            />
            <!-- Infinite scroll -->
            <infinite-loading v-if="!loading" @infinite="_infinite">
              <span slot="no-results"></span>
              <span slot="no-more"></span>
              <span slot="spinner"></span>
              <span slot="error"></span>
            </infinite-loading>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="confirm.status" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">ยืนยัน {{ confirm.type }}?</v-card-title>
        <v-card-text>{{
          (confirm.data && confirm.data.name) || "this"
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="loading"
            @click="confirm.status = false"
            text
            >ปิด</v-btn
          >
          <v-btn
            :color="confirm.type === 'ผูกผู้ใช้งาน' ? 'success' : 'error'"
            :loading="loading"
            @click="_confirm"
            text
            >{{ confirm.type }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Select Shop User Dialog -----------------------------------------------------</div> -->

    <SelectShopUser
      v-model="select.status"
      :data="select.data"
      @select="_bind"
    />
  </div>
</template>

<script>
import SelectShopUser from "@/components/ShopUser/SelectShopUser";

import Agent from "@/store/models/agent";
import ShopUser from "@/store/models/shopuser";

import NotFound from "@/components/NotFound";
import Loading from "@/components/Loading";

export default {
  name: "AgentLineManagement",
  components: {
    NotFound,
    Loading,
    SelectShopUser,
  },
  props: ["user", "tabId", "tabName"],
  computed: {},
  data() {
    return {
      search: "",
      region: "All",
      loading: false,
      options: {
        page: 1,
        perpage: 10,
      },
      confirm: {
        status: false,
      },
      select: {
        status: false,
      },
      headers: [
        {
          text: "ชื่อ-นามสกุล",
          value: "name",
          width: "15%",
        },
        { text: "ความสัมพันธ์", value: "type", width: "12%" },
        { text: "สถานะ", align: "center", value: "status", width: "10%" },
        { text: "ชื่อผู้ใช้งานที่ผูก", value: "bindName", width: "15%" },
        { text: "เอเยนต์", value: "bindAgent" },
        { text: "ความสัมพันธ์", value: "bindRelation", width: "12%" },
        {
          text: "ดำเนินการ",
          align: "center",
          sortable: false,
          value: "action",
          fixed: true,
          width: 140,
        },
      ],
      items: [],
      infinite: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData(page = 1) {
      this.options.page = page;
      const params = {
        perPage: this.options.perpage,
        page: page,
        shopTypeId: 1,
      };
      if (this.search) {
        params.query = this.search;
      }

      if (params.page === 1) {
        this.loading = true;
        this.items = [];
      }

      const agent = await Agent.branch({ params });
      const data = this.transformData(
        (agent && Array.isArray(agent.data) && agent.data) || []
      );
      if (params.page === 1) {
        this.items = data;
      } else {
        this.items = [...this.items, ...data];
      }

      if (params.page === 1) {
        this.loading = false;
      }

      return data;
    },
    transformData(shops = []) {
      const res = shops.map((shop) => {
        if (shop.shopUsers) {
          shop.shopUsers = shop.shopUsers.map((shuser) => {
            shuser.shopUserLinks = this._transformUserLinks(
              shuser.id,
              shuser.shopUserLinks
            );
            return shuser;
          });
        }
        return shop;
      });

      return res;
    },
    _transformUserLinks(parentId, childs = []) {
      if (childs.length == 0) {
        return [{}];
      }

      const res = childs.reduce(
        (o, e) => {
          if (e.id !== parentId) {
            o.childs.push(e);
          } else {
            o.hasparent = true;
          }
          return o;
        },
        {
          childs: [],
          hasparent: false,
        }
      );

      if (res.hasparent) {
        res.childs.push({});
      }

      return res.childs;
    },
    _getByQuery() {
      this.fetchData();
    },
    async _infinite($state) {
      const page = this.options.page + 1;
      const data = await this.fetchData(page);

      if (data.length > 0) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    _shopRelation(rel = "master") {
      return this.$shopRelation[rel] || "-";
    },
    _btnPermission(parent = {}, child = {}) {
      if(!child.id) {
        const parentId = parent.id;
        const linkId = parent && parent.shopUserLink && parent.shopUserLink.shopUserId;
        if(linkId && parentId && parentId != linkId) {
          return false
        }
      }

      return true
    },
    _action(parent = {}, child = {}) {
      const shopUserLink = parent.shopUserLink && parent.shopUserLink.shopUserId;
      const childId = child.id;
      let bindId = childId;
      if(shopUserLink === childId) {
        bindId = parent.id;
      }

      if (child.id) {
        this._unbind({
          name: `${child.name || "-"} กับ ${(parent.name) || "-"}`,
          id: bindId,
        });
      } else {
        this._select(parent);
      }
    },
    _select(data = {}) {
      this.select = {
        status: true,
        type: "select",
        data,
      };
    },
    _bind(data = {}) {
      data.name = `${(data.child && data.child.name) || "-"} กับ ${(data.parent && data.parent.name) || "-"}`;
      this.confirm = {
        status: true,
        type: "ผูกผู้ใช้งาน",
        data,
      };
    },
    _unbind(data = {}) {
      this.confirm = {
        status: true,
        type: "ยกเลิกผูก",
        data,
      };
    },
    async _confirm() {
      this.loading = true;
      this.select.status = false;
      this.confirm.status = false;
      const confirm = this.confirm;
      const data = confirm.data || {};

      let res;
      if (confirm.type === "ผูกผู้ใช้งาน") {
        const options = {
          data: {
            parent: data.parent && data.parent.id,
            child: data.child && data.child.id,
          },
        };
        res = await ShopUser.bind(options).catch((err) => console.error(err));
      } else if (confirm.type === "ยกเลิกผูก") {
        res = await ShopUser.unbind(data.id).catch((err) => console.error(err));
      }
      if (res) {
        await this.fetchData();
      }
      
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.agent-branch-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 42px;
  overflow: hidden;
}

.agent-branch-notfound {
  color: rgb(153, 153, 153);
  font-style: italic;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}

.notfound {
  font-size: 1.2rem !important;
}
</style>

<style>
.agent-branch-tb .link-btn,
.agent-branch-tb .unlink-btn {
  padding: 0 10px !important;
  margin: 5px 0 !important;
  height: 32px !important;
  width: 100% !important;
}

.agent-branch-tb .link-btn > span.v-btn__content {
  color: #008000;
}

.agent-branch-tb .unlink-btn > span.v-btn__content {
  color: #ff0000;
}

.agent-branch-tb > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  vertical-align: top !important;
}

.agent-branch-tb.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none !important;
}
</style>
