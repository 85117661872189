<template>
  <div>
    <SideBar bar_name="Report Email" :user="user"></SideBar>

    <v-main>
      <v-container fluid>
        <ReportEmailComp :user="user" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar"
import ReportEmailComp from "@/components/Report/ReportEmail"

export default {
  name: "ReportEmail",
  components: {
    SideBar,
    ReportEmailComp
  },
  data() {
    return {
      user: this.$user,
    };
  }
};
</script>
