<template>
  <div style="margin-top: 10px; min-height: 800px">
    <v-layout wrap>
      <!-- <v-flex xs4 md4 v-if="user.role == 'ci'">
        <v-btn class="ma-2" @click="dialog_email = true" style="margin-left: 0px !important;">
          report Email
          <v-icon right dark>email</v-icon>
        </v-btn>
      </v-flex> -->

      <v-flex xs8 md8></v-flex>

      <v-flex xs12 md12>
        <v-card>
          <v-card-title>
            Redeemed {{ tabName }}
            <v-spacer></v-spacer>

            <div
              style="
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                margin-right: 16px;
              "
            >
              <div style="width: 20%; font-size: 12px">From :</div>

              <VueCtkDateTimePicker
                id="date_time_start"
                v-model="date_time_start"
                format="YYYY-MM-DD"
                formatted="DD-MM-YYYY"
                noLabel
                onlyDate
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                margin-right: 16px;
              "
            >
              <div style="width: 13%; font-size: 12px">To :</div>

              <VueCtkDateTimePicker
                id="stop"
                v-model="date_time_stop"
                format="YYYY-MM-DD"
                formatted="DD-MM-YYYY"
                noLabel
                onlyDate
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>

            <v-select
              outlined
              :items="regions"
              item-text="label"
              item-value="id"
              label="Region"
              style="width: 5%"
              v-model="region"
              @change="getRedeemedbyRegion"
              v-if="regions.length > 2"
            ></v-select>

            <v-flex xs12 md12></v-flex>

            <v-flex xs2 md2>
              <v-text-field
                outlined
                v-model="agentName"
                label="Agent Name"
                @change="filter"
              ></v-text-field>
            </v-flex>

            <v-flex xs2 md2>
              <v-text-field
                outlined
                v-model="reward"
                label="Reward"
                @change="filter"
              ></v-text-field>
            </v-flex>

            <v-flex xs2 md2>
              <v-text-field
                outlined
                v-model="point"
                label="Point"
                @change="filter"
              ></v-text-field>
            </v-flex>

            <v-flex xs2 md2>
              <v-text-field
                outlined
                v-model="saleName"
                label="Sale Name"
                @change="filter"
              ></v-text-field>
            </v-flex>

            <v-flex xs4 md4>
              <v-select
                outlined
                :items="statusItem"
                label="Status"
                v-model="status"
                item-text="th"
                item-value="eng"
                @change="filter"
              ></v-select>
            </v-flex>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="redeemedTransactions"
            :footer-props="{ 'items-per-page-options': $itemsPerPage }"
            :items-per-page="perPage"
            class="elevation-1"
            :search="search"
            :loading="loading"
            :options.sync="options"
            :server-items-length="countItems"
            loading-text="Loading... Please wait"
            show-select
            v-model="selected_transaction"
          >
            <template v-slot:item.name="{ item }">
              {{ item.name }}
              <!-- <v-checkbox color="black" v-model="selected_transaction" :value="item.id">
                    <template v-slot:label>
                      <div style="color: #000000">{{item.name}}</div>
                    </template>
              </v-checkbox>-->
            </template>

            <template v-slot:item.CreatedAt="{ item }">{{
              changeFormat(item.CreatedAt)
            }}</template>

            <template v-slot:item.totalPoint="{ item }">{{
              $numberFormat(item.totalPoint)
            }}</template>

            <template v-slot:item.status="{ item }">{{
              changeStatusFormat(item.status)
            }}</template>

            <template
              v-slot:item.actions="{ item }"
              v-if="user.role != 'normal'"
            >
              <div style="display: flex; justify-content: space-around">
                <v-icon
                  @click="openEditStatusDialog(item)"
                  :disabled="item.status === 'cancel'"
                  >edit</v-icon
                >
                <v-icon @click="print('item', item)">print</v-icon>
              </div>
            </template>

            <template v-slot:footer v-if="user.role != 'normal'">
              <v-btn
                class="ma-2"
                @click="OpenchangeManyStatusDialog()"
                :disabled="
                  !(selected_transaction && selected_transaction.length > 0)
                "
                >Change Status</v-btn
              >

              <v-btn
                class="ma-2"
                @click="print('select')"
                :disabled="
                  !(selected_transaction && selected_transaction.length > 0)
                "
                >Print</v-btn
              >

              <v-btn class="ma-2" @click="print('all')">Print All</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Status Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog" width="50%" persistent scrollable>
      <v-card>
        <v-card-title primary-title style="margin-bottom: 20px"
          >Edit Status</v-card-title
        >

        <v-card-text style="padding-top: 5px">
          <v-select
            outlined
            :items="statusItem"
            label="Status"
            v-model="temp_status"
            item-text="th"
            item-value="eng"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Close</v-btn>
          <v-btn text @click="changeStatus">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Status all Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="status_dialog" width="50%" persistent scrollable>
      <v-card>
        <v-card-title primary-title style="margin-bottom: 20px"
          >Edit Selected Status</v-card-title
        >

        <v-card-text style="padding-top: 5px">
          <v-select
            outlined
            :items="statusItem"
            label="Status"
            v-model="temp_status2"
            item-text="th"
            item-value="eng"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="status_dialog = false">Close</v-btn>
          <v-btn
            text
            @click="changeMultipleStatus()"
            :disabled="
              !(selected_transaction && selected_transaction.length > 0)
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Email Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_email" width="50%" persistent scrollable>
      <v-card>
        <v-card-title
          primary-title
          style="margin-bottom: 20px"
          class="headline grey lighten-2"
          >Report Email Management</v-card-title
        >

        <v-card-text style="padding-top: 5px">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="name_new"
                label="Name"
                style="margin-right: 24px"
              ></v-text-field>

              <!-- <v-spacer></v-spacer> -->

              <v-text-field v-model="email_new" label="Email"></v-text-field>

              <v-btn
                icon
                large
                v-if="user.role == 'ci'"
                id="addNewEmailBtn"
                @click="AddRedeemReportEmail"
              >
                <v-icon>add_circle</v-icon>
              </v-btn>
            </v-card-title>

            <v-data-table
              :headers="header_email"
              :items="redeemReportEmail"
              :items-per-page="-1"
              :loading="loading"
              loading-text="Loading... Please wait"
              :hide-default-footer="true"
            >
              <template v-slot:item.createtime="{ item }">{{
                changeFormat(item.createtime)
              }}</template>

              <template v-slot:item.status="{ item }">{{
                changeStatusFormat(item.status)
              }}</template>

              <template v-slot:item.actions="{ item }">
                <div style="display: flex; justify-content: space-around">
                  <v-icon @click="openEditDialog(item)">edit</v-icon>
                  <v-icon @click="openDeleteDialog(item)">delete</v-icon>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog_email = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Confirm delete email Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_delete" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Confirm delete?</v-card-title>
        <v-card-text
          >Are you sure that you want to delete this email. This user will no
          longer recieve report email.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="dialog_delete = false" text
            >Cancel</v-btn
          >
          <v-btn color="red" text @click="DeleteRedeemReportEmail"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Edit Email Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_edit" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Edit Info</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="email_to_delete.name"
            label="Name"
            style="margin-right: 24px"
          ></v-text-field>

          <v-text-field
            v-model="email_to_delete.email"
            label="Email"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="dialog_edit = false" text>Cancel</v-btn>
          <v-btn color="green" text @click="EditRedeemReportEmail">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import Region from "@/store/models/region";
import Redeemed from "@/store/models/redeemed";

moment.locale("th");

export default {
  name: "redeemedTransactions",
  components: {
    VueCtkDateTimePicker,
  },
  props: ["user", "tabId", "tabName"],
  data() {
    return {
      status_dialog: false,
      email_new: "",
      name_new: "",
      email_to_delete: {},
      search: "",
      dialog: false,
      dialog_email: false,
      dialog_delete: false,
      dialog_edit: false,
      region: "All",
      point: "",
      date: "",
      id: "",
      status: "",
      reward: "",
      redeemReportEmail: {},
      date_time_start: "",
      date_time_stop: "",
      redeemedTransactions: [],
      loading: true,
      statusItem: [
        { eng: "", th: "All" },
        { eng: "request", th: "ยื่นคำขอ" },
        { eng: "processing", th: "อยู่ระหว่างดำเนินการ" },
        { eng: "cancel", th: "ยกเลิก" },
        { eng: "purchase", th: "กำลังจัดซื้อ" },
        { eng: "shipping", th: "กำลังจัดส่ง" },
        { eng: "delivered", th: "ส่งของเรียบร้อย" },
      ],
      statusMapping: {
        request: "ยื่นคำขอ",
        processing: "อยู่ระหว่างดำเนินการ",
        cancel: "ยกเลิก",
        purchase: "กำลังจัดซื้อ",
        shipping: "กำลังจัดส่ง",
        delivered: "ส่งของเรียบร้อย",
      },
      each_transaction: {},
      agentName: "",
      saleName: "",
      temp_status: "",
      temp_status2: "processing",
      header_email: [
        {
          text: "Name",
          value: "name",
          align: "center",
        },
        {
          text: "Email",
          value: "email",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      selected_transaction: [],
      date_start: "",
      date_stop: "",
      options: {},
      perPage: 10,
      countItems: 0,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Agent Name",
          sortable: false,
          value: "shop.name",
        },
        {
          text: `Date`,
          sortable: false,
          value: "CreatedAt",
        },
        {
          text: "Reward",
          sortable: false,
          value: "rewardTitle",
        },
        {
          text: "Point",
          align: "right",
          sortable: false,
          value: "totalPoint",
        },
        // sale name not return from api now
        // {
        //   text: "Sale Name",
        //   sortable: false,
        //   value: "sale",
        // },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "120px",
        },
      ];
    },
    regions() {
      return Region.getsAll();
    },
  },
  watch: {
    options: {
      handler() {
        this.getRedeemedData();
      },
      deep: false,
    },
    date_time_start: function (v) {
      if (v) {
        this.date_start = moment(v, "YYYY-MM-DD")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.date_start = v;
      }
      this.filter();
    },
    date_time_stop: function (v) {
      console.log("date_time_stop: ", v);
      if (v) {
        this.date_stop = moment(v, "YYYY-MM-DD")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.date_stop = v;
      }
      this.filter();
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      // this.getRedeemedData();
      this.getRedeemReportEmail();
    },

    filter() {
      this.options.page = 1;
      this.getRedeemedData();
    },

    OpenchangeManyStatusDialog() {
      if (this.selected_transaction && this.selected_transaction.length > 0) {
        this.status_dialog = true;
      }
    },

    print(type, item) {
      if (type === "item") {
        localStorage.setItem("item_transaction", JSON.stringify([item]));
      } else if (type === "select") {
        localStorage.setItem(
          "selected_transaction",
          JSON.stringify(this.selected_transaction)
        );
      }

      let params = `type=${type}`;
      if (!(type === "item" || type === "select")) {
        params = `type=${type}&count=${this.countItems}&params=${this.params}`;
      }
      window.open(`/#/redeemForm?${params}`);
    },

    async changeMultipleStatus() {
      for (const i in this.selected_transaction) {
        const formData = new FormData();
        formData.append(
          "redeemID",
          this.selected_transaction[i] && this.selected_transaction[i].id
        );
        formData.append("status", this.temp_status2);

        await Redeemed.status({ data: formData }).catch((e) => {
          console.error(e.response);
        });
      }

      this.getRedeemedData();
      this.status_dialog = false;
    },

    getRedeemReportEmail() {
      // if (this.user.role == "ci") {
      //   this.$http
      //     .get(`/api/redeems/emails`, {
      //       headers: {
      //         Authorization: `Bearer ${this.token}`
      //       }
      //     })
      //     .then(response => {
      //       console.log(response.data);
      //       this.redeemReportEmail = response.data;
      //     })
      //     .catch(e => {
      //       console.error(e.response);
      //     });
      // }
    },

    getRedeemedData() {
      this.loading = true;

      let data = { params: {} };

      // Shop type
      if (this.tabId) {
        data.params.shopTypeId = this.tabId;
      }

      // Region
      if (this.region && this.region != "All") {
        data.params.regionId = this.region;
      }

      // Search
      if (this.search) {
        data.params.query = this.search;
      }

      // From date
      if (this.date_start) {
        data.params.fromDate = this.date_start;
      }

      // To date
      if (this.date_stop) {
        data.params.toDate = this.date_stop;
      }

      // Agent Name
      if (this.agentName) {
        data.params.shopName = this.agentName;
      }

      // Reward
      if (this.reward) {
        data.params.rewardName = this.reward;
      }

      // Reward point
      if (this.point) {
        data.params.rewardPoint = this.point;
      }

      // Sale name
      if (this.saleName) {
        data.params.saleName = this.saleName;
      }

      // Status
      if (this.status) {
        data.params.status = this.status;
      }

      // Per page
      if (this.options && this.options.itemsPerPage) {
        data.params.perPage = this.options.itemsPerPage;
      }

      // Page
      if (this.options && this.options.page) {
        data.params.page = this.options.page;
      }

      this.params = JSON.stringify(data);

      Redeemed.gets(data)
        .then((response) => {
          this.redeemedTransactions = (response && response.data) || [];
          this.countItems = response && response.Total;

          this.loading = false;
        })
        .catch((e) => {
          console.error(e.response);
          this.loading = false;
        });
    },

    getRedeemedbyRegion() {
      this.filter();
    },

    changeFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },

    changeStatusFormat(status) {
      return this.statusMapping[status] || status;
    },

    openEditStatusDialog(each) {
      this.each_transaction = each;
      this.dialog = true;
      this.temp_status = each.status;
    },

    changeStatus() {
      let formData = new FormData();

      formData.append(
        "redeemID",
        this.each_transaction && this.each_transaction.id
      );
      formData.append("status", this.temp_status);

      Redeemed.status({ data: formData })
        .then(() => {
          this.getRedeemedData();
        })
        .catch((e) => {
          console.error(e.response);
        });

      this.each_transaction.status = this.temp_status;

      this.dialog = false;
    },

    AddRedeemReportEmail() {
      let formData = new FormData();

      formData.append("name", this.name_new);

      formData.append("email", this.email_new);

      this.$http
        .post(`/api/redeems/emails`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.getRedeemReportEmail();
        })
        .catch((e) => {
          console.error(e.response);
        });
      this.name_new = "";
      this.email_new = "";
    },

    DeleteRedeemReportEmail() {
      this.$http
        .delete(`/api/redeems/emails/${this.email_to_delete.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.dialog_delete = false;
          this.getRedeemReportEmail();
        })
        .catch((e) => {
          console.error(e.response);
        });
    },

    openDeleteDialog(item) {
      console.log(item);
      this.email_to_delete = item;
      this.dialog_delete = true;
    },

    openEditDialog(item) {
      console.log(item);
      this.email_to_delete = item;
      this.dialog_edit = true;
    },

    EditRedeemReportEmail() {
      let formData = new FormData();

      formData.append("name", this.email_to_delete.name);

      formData.append("email", this.email_to_delete.email);

      this.$http
        .put(`/api/redeems/emails/${this.email_to_delete.id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.dialog_edit = false;
          this.getRedeemReportEmail();
        })
        .catch((e) => {
          console.error(e.response);
        });
    },
  },
};
</script>

<style scoped>
/* .v-input--selection-controls__input{
  color: #000000
} */
</style>
