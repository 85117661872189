<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 md12>
        <v-btn style="margin: 3%; float: right;" @click="postCatagory" v-if="user.role == 'ci'">Post</v-btn>
      </v-flex>
    </v-layout>

    <draggable
      v-model="catagory"
      style="display: flex; flex-wrap: wrap; "
      :animation="500"
      draggable=".item"
      @start="dragging = true"
      @end="dragging = false"
      :sort="sortable"
    >
      <v-flex xs6 md3 v-for="element in catagory" :key="element.id" class="item">
        <v-card
          max-width="180"
          max-height="200"
          style="margin: 5%; border-radius: 15px; padding: 3%;"
          v-if="element.is_public"
        >
          <v-avatar
            size="100px"
            style="display: block; margin-left:auto; margin-right:auto; border-radius: 50%;"
          >
            <img :src="`${element.logo}`" />
          </v-avatar>

          <v-card-text
            style="text-align: center; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;"
          >
            <span class="text--primary">
              <span>{{element.name}}</span>
            </span>
          </v-card-text>

          <v-card-actions style="display: flex; justify-content: center;">
            <v-btn icon color="#424242" @click="openEditDialog(element)" v-if="user.role == 'ci'">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          max-width="180"
          max-height="250"
          style="margin: 5%; border-radius: 15px; padding: 3%; background-color: #cfcfcf;"
          v-else
        >
          <v-avatar
            size="100px"
            style="display: block; margin-left:auto; margin-right:auto; border-radius: 50%;"
          >
            <img :src="`${element.logo}`" />
          </v-avatar>

          <v-card-text
            style="text-align: center; padding-bottom: 0px; padding-left: 0px; padding-right: 0px;"
          >
            <span class="text--primary">
              <span>{{element.name}}</span>
            </span>
          </v-card-text>

          <v-card-actions style="display: flex; justify-content: center;">
            <v-btn icon color="#424242" @click="openEditDialog(element)" v-if="user.role == 'ci'">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

    </draggable>

    <!-- <div>------------------------------------- Edit Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card style="padding: 20px;">
        <v-card-title class="headline">Edit Catagory</v-card-title>

        <v-avatar
          v-if="file==''"
          size="130px"
          style="display: block; margin-left:auto; margin-right:auto; margin-bottom:5%; margin-top: 5%"
        >
          <!-- <img v-if="url" :src="url" /> -->
          <img style="width: 100%" :src="`${cat.logo}`" />
        </v-avatar>

        <vue-croppie
          v-show="file!=''"
          ref="croppieRef"
          :enableResize="false"
          @result="result"
          :viewport="{ width: 100, height: 100, type: 'circle' }"
          resultType="blob"
          customClass="custom-croppie"
        ></vue-croppie>

        <v-file-input
          label="File input"
          outlined
          prepend-icon="mdi-camera"
          @change="onFileChange"
          accept="image/*"
          v-model="files"
          style="margin-top: 48px;"
        ></v-file-input>
        <!-- <input type="file" id="file" ref="file" @change="onFileChange" accept="image/*" /> -->
        <v-text-field label="title" required v-model="cat.title" outlined prepend-icon="edit"></v-text-field>

        <v-card-actions>
          <!-- <v-btn color="red" text @click="dialog_delete = true">Delete</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="editCatagory">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Add new Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_add_new" persistent max-width="350">
      <v-card style="padding: 20px;">
        <v-card-title class="headline">Add Catagory</v-card-title>

        <v-avatar
          size="130px"
          style="display: block; margin-left:auto; margin-right:auto; margin-bottom:5%; margin-top: 5%"
        >
          <img v-if="url" :src="url" />
          <img v-else src="http://ctt.trains.com/sitefiles/images/no-preview-available.png" />
        </v-avatar>

        <v-file-input
          label="File input"
          outlined
          prepend-icon="mdi-camera"
          @change="onFileChange"
          accept="image/*"
          v-model="files"
        ></v-file-input>

        <v-text-field label="name" required v-model="name" outlined prepend-icon="edit"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeAddNewDialog">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="addNewCatagory">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Delete reward items Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_delete" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Confirm delete?</v-card-title>
        <v-card-text>Are you sure that you want to delete this reward item. This catagory will be gone permanently.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="dialog_delete = false" text>Cancel</v-btn>
          <v-btn color="red" text @click="deleteCatagoty">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />กรุณารอสักครู่....
          <br />
          <br />

          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import "croppie/croppie.css"; // import the croppie css manually
import _ from "lodash";

import Catalog from '@/store/models/catalog';

export default {
  name: "Catagory",
  components: {
    draggable
  },
  props: ["user", "tabId", "tabName"],
  data() {
    return {
      dialog: false,
      name: "",
      imageData: "",
      file: "",
      url: null,
      dialog_add_new: false,
      dialog_delete: false,
      catagory: [],
      cat: {},
      files: [],
      sortable: true,
      dialog_loading: true,
      cropped: null,
      cat_name_edit: "",
      file_name: "",
      shopID: this.tabId,
      shopName: this.tabName
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (this.user.role != "ci") {
        this.sortable = false;
      }
      this.getCatagory();
    },
    getCatagory() {
      Catalog.gets({ params: { typeID: this.shopID } })
        .then(response => {
          this.catagory = response.data && response.data.catalog || [];
          this.dialog_loading = false;
        })
        .catch(e => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },
    openEditDialog(ele) {
      this.cat = _.cloneDeep(ele);
      this.file = "";
      this.url = null;
      this.dialog = true;
      this.files = [];
    },
    onFileChange(e) {
      this.file_name = e.name;
      this.file = e;
      this.url = URL.createObjectURL(e);
      e.value = undefined

      let img = new Image();

      // img.onload = () => {
      //   console.log(`the image dimensions are ${img.width}x${img.height}`);
      //   if (img.width != 128 || img.height != 128) {
      //     this.file = "";
      //     this.url = null;
      //     this.files = [];
      //     this.$swal("Wrong image dimension. Required 128 x 128");
      //   }
      // };

      this.$refs.croppieRef.bind({
        url: this.url
      });

      img.src = this.url;
    },
    handleFileUpload() {
      console.log(this.$refs.file);
      //   this.file = this.$refs.file.files[0];
    },
    openAddDialog() {
      this.title = "";
      this.file = "";
      this.url = null;
      this.dialog_add_new = true;
      this.files = [];
    },
    addNewCatagory() {
      let formData = new FormData();

      formData.append("file", this.file);
      formData.append("title", this.name);

      this.$http
        .post(`/api/catalogs`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then(response => {
          console.log(response.data);
          this.fetch();
        })
        .catch(e => {
          console.error(e.response);
        });
      this.closeAddNewDialog();
    },
    closeAddNewDialog() {
      this.name = "";
      this.file = "";
      this.url = null;
      this.dialog_add_new = false;
    },
    editCatagory() {
      this.dialog_loading = true;
      let formData = new FormData();

      formData.append("title", this.cat.title);

      if (this.file) {
        let options = {
          format: "jpeg",
          type: "blob"
        };

        this.$refs.croppieRef.result(options, output => {
          this.cropped = output;
          formData.append("file", this.cropped, this.file_name);
          
          this.updateCatalog(formData)
        });
      } else {
        this.updateCatalog(formData)
      }

      this.dialog = false;
    },
    updateCatalog(formData) {
      this.dialog_loading = true;
      Catalog.update(this.cat.id, { data: formData })
        .then(() => {
          this.getCatagory()
          this.dialog_loading = false;
        })
        .catch(e => {
          console.error(e.response);
          this.dialog_loading = false;
        });
    },
    deleteCatagoty() {
      this.dialog_loading = true;
      this.$http
        .delete(`/api/catalogs/${this.cat.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then(response => {
          console.log(response.data);
          this.fetch();
        })
        .catch(e => {
          console.error(e.response);
        });

      this.dialog_delete = false;
      this.dialog = false;
    },
    postCatagory() {
      this.dialog_loading = true;
      const catalog = this.catagory.map((e, i) => {
        e.order = i + 1
        return e
      })
      Catalog.public({ params: { typeID: this.shopID }, data: { catalog } })
        .then(() => {
          this.dialog_loading = false;
          this.fetch()
        })
        .catch(e => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },
    result(output) {
      this.cropped = output;
    },
    cropViaEvent(options) {
      this.$refs.croppieRef.result(options);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-name {
  text-align: center;
  font-size: 20px;
}
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

.custom-croppie {
  width: 128x;
  height: 128px;
  margin: 0 auto;
}
</style>
