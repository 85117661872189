<template>
  <v-row align="center" justify="center">
    <v-col cols="10">
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="12">
            <v-select v-model="selectedRoles" :items="roles" label="Employee Role" :rules="rules" multiple>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle('role')">
                  <v-list-item-action>
                    <v-icon
                      :color="selectedRoles && selectedRoles.length > 0 ? 'indigo darken-4' : ''"
                    >{{ iconRole }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip>
                  <span>{{ item }}</span>
                </v-chip>
                <span
                  v-if="(index + 1) >= selectedRoles.length"
                  class="grey--text caption"
                >({{ selectedRoles.length }} items)</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select v-model="selectedShopType" :items="sales" label="Shop Type" :rules="rules" multiple>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle('sale')">
                  <v-list-item-action>
                    <v-icon
                      :color="selectedShopType.length > 0 ? 'indigo darken-4' : ''"
                    >{{ iconSale }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip>
                  <span>{{ item }}</span>
                </v-chip>
                <span
                  v-if="(index + 1) >= selectedShopType.length"
                  class="grey--text caption"
                >({{ selectedShopType.length }} items)</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedRegions"
              :items="regions"
              item-text="label"
              item-value="id"
              label="Region"
              :rules="regionRules"
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle('region')">
                  <v-list-item-action>
                    <v-icon
                      :color="selectedRegions.length > 0 ? 'indigo darken-4' : ''"
                    >{{ iconRegion }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip>
                  <span>{{ item.label }}</span>
                </v-chip>
                <span
                  v-if="(index + 1) >= selectedRegions.length"
                  class="grey--text caption"
                >({{ selectedRegions.length }} items)</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="description"
              :counter="500"
              :rules="descriptionRules"
              label="Desciption"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn color="error" class="mr-4" @click="reset">Reset</v-btn>

            <v-btn color="success" @click="send" :disabled="!valid">Send</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Swal from 'sweetalert2'

import Region from "@/store/models/region";
import Notify from "@/store/models/notify";

export default {
  data: () => {
    return {
      valid: false,
      selectedShopType: [],
      selectedRoles: [],
      regionRules: [v => (v && v.length > 0) || "Region is required"],
      descriptionRules: [
        v => !!v || "Description is required",
        v => v.length <= 500 || "Max 500 characters"
      ],
      selectedRegions: [],
      description: ""
    };
  },
  components: {},
  props: ["user", "sales", "roles"],
  computed: {
    rules() {
      const rules = [];
      if (this.selectedShopType && this.selectedRoles) {
        const rule = () =>
          !(
            this.selectedShopType.length === 0 && this.selectedRoles.length === 0
          ) || `Select Role or Sale at lease 1 item`;
        rules.push(rule);
      }

      return rules;
    },
    regions() {
      return Region.all();
    },
    likesAllRole() {
      return (
        (this.selectedRoles && this.selectedRoles.length) === this.roles.length
      );
    },
    likesSomeRole() {
      return (
        this.selectedRoles &&
        this.selectedRoles.length > 0 &&
        !this.likesAllRole
      );
    },
    iconRole() {
      if (this.likesAllRole) return "mdi-close-box";
      if (this.likesSomeRole) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllSale() {
      return this.selectedShopType.length === this.sales.length;
    },
    likesSomeSale() {
      return this.selectedShopType.length > 0 && !this.likesAllSale;
    },
    iconSale() {
      if (this.likesAllSale) return "mdi-close-box";
      if (this.likesSomeSale) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllRegion() {
      return this.selectedRegions.length === this.regions.length;
    },
    likesSomeRegion() {
      return this.selectedRegions.length > 0 && !this.likesAllRegion;
    },
    iconRegion() {
      if (this.likesAllRegion) return "mdi-close-box";
      if (this.likesSomeRegion) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    selectedRoles: "validate",
    selectedShopType: "validate"
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.selectedRoles = [];
      this.selectedShopType = [];
      this.selectedRegions = [];
      this.description = "";
    },
    send() {
      this.validate();

      if (this.valid) {
        let formData = {};

        // Employee Roles
        if(this.selectedRoles.length > 0) {
          formData.employeeRoles = this.selectedRoles.map((e) => e.toLowerCase().replace(/\s/g, "-"));
        }
        // Shop types
        if(this.selectedShopType.length > 0) {
          formData.shopTypes = this.selectedShopType.map((e) => e.toLowerCase().replace(/\s/g, "-"));
        }
        // Regions
        if(this.selectedRegions.length > 0) {
          formData.regionIds = this.selectedRegions;
        }
        formData.Message = this.description || "";

        Notify.send({ data: formData })
          .then(() => {
            Swal.fire(
              'Notify!',
              'Your notify has been delivered.',
              'success'
            )
            this.reset()
          })
          .catch(e => {
            console.error(e.response);
          });
      }
    },
    toggle(type) {
      this.$nextTick(() => {
        if (type === "role") {
          if (this.likesAllRole) {
            this.selectedRoles = [];
          } else {
            this.selectedRoles = this.roles;
          }
        } else if (type === "sale") {
          if (this.likesAllSale) {
            this.selectedShopType = [];
          } else {
            this.selectedShopType = this.sales;
          }
        } else if (type === "region") {
          if (this.likesAllRegion) {
            this.selectedRegions = [];
          } else {
            this.selectedRegions = this.regions.map((e) => e.id);
          }
        }
      });
    }
  }
};
</script>
