/* eslint-disable */
import { Model } from '@vuex-orm/core'

class CTTRegion extends Model {
  static fields() {
    return {
      id: this.string(),
      name: this.string(),
    }
  }

  static list(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/regions`,
      ...options,
    }).then((res) => res.response.data)
  }
}

CTTRegion.entity = 'region'

export default CTTRegion