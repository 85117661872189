<template>
  <v-dialog
    v-model="value"
    @click:outside="_close"
    max-width="80%"
    scrollable
    persistent
  >
    <v-card :outlined="true">
      <v-card-title class="variable-title">
        Variable
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          style="margin-right: 2%"
          placeholder=" "
          :hide-details="true"
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <v-data-table
          class="clean-tb"
          item-key="index"
          :search="search"
          :headers="headers"
          :items="items"
          :footer-props="{ 'items-per-page-options': $itemsPerPage }"
          :items-per-page="perPage"
          no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
          no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
        >
          <template v-slot:item.title="{ item }">
            {{ item.title }}
            <v-icon class="ml-1" @click="_select(item.title)"> mdi-clipboard-text-outline </v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" color="error darken-1" @click="_close" text
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Variable from "@/store/models/variable";

export default {
  name: "SelectLink",
  components: {},
  props: ["value"],
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      perPage: 10,
      headers: [
        { text: "ชื่อ", value: "title", width: "10%", sortable: false },
        { text: "url", value: "rawURL", width: "30%", sortable: false },
      ],
      items: [],
    };
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const options = {};

      const res = await Variable.links(options);
      this.items = res || [];
      this.loading = false;
    },
    _select(value = "") {
      navigator.clipboard.writeText(`{{${value}}}`);
    },
    _close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none !important;
}

.col {
  padding: 5px 10px !important;
}

.variable-title {
  justify-content: center !important;
}
</style>
