<template>
  <v-layout v-if="value" class="mx-4" wrap>
    <v-flex xs12 md12>
      <v-row class="mx-0">
        <v-col cols="12" offset-1>
          <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
            <v-icon left> mdi-less-than </v-icon>
            ย้อนกลับ
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-2">
        <v-col cols="6" class="promoter-h1">
          {{ form.id ? "Edit" : "Create" }} Stamp Card
        </v-col>
        <v-col v-if="form.id" class="text-right">
          <span class="status-section">
            Status:
            <span
              :class="`${
                status === 'active'
                  ? 'green-text'
                  : status === 'waiting'
                  ? 'yellow-text'
                  : 'brown-text'
              }`"
              >{{ capitalize(status) }}</span
            >
          </span>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-form ref="form" v-model="valid" @submit.prevent="_save">
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field
              v-model="form.title"
              label="Stamp Card"
              :rules="[...rulesRequired]"
              required
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <v-menu
              v-model="vdate.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.startDate"
                  label="วันที่เริ่มต้น"
                  :rules="[...rulesRequired]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                locale="th"
                v-model="form.startDate"
                @input="vdate.start = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="vdate.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.endDate"
                  label="วันที่สิ้นสุด"
                  :rules="[...rulesRequired]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                locale="th"
                v-model="form.endDate"
                @input="vdate.end = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-textarea
              outlined
              label="Condition"
              v-model="form.condition"
              :rules="[...rulesRequired]"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.stampRewardId"
              :items="stampReward"
              label="Stamp Card Reward"
              outlined
              dense
              item-text="title"
              item-value="id"
              return-object
            />
          </v-col>
          <v-col>
            <v-btn text class="remove-reward" v-if="form.stampRewardId" @click="_removeReward">ลบของรางวัล</v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.approveStage"
              :items="approveStage"
              :rules="[required]"
              label="Approve Stage"
              outlined
              dense
              item-text="name"
              item-value="value"
              return-object
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.publish"
              :items="publish"
              :rules="[required]"
              label="Publish"
              outlined
              dense
              item-text="name"
              item-value="value"
              return-object
            />
          </v-col>
        </v-row>

        <!-- <div>------------------------------------- Mission --------------------------------------------------</div> -->
        <AreaPromoterMission v-if="form.id" :id="form.id" />

        <!-- <div>------------------------------------- Reward Delivery ------------------------------------------</div> -->
        <AreaPromoterRewardDelivery v-if="form.id" :id="form.id" />

        <div class="text-right">
          <v-btn color="error" @click="_back">ยกเลิก</v-btn>
          <v-btn
            v-if="form.id"
            color="success"
            @click="_save"
            class="ml-3 mr-2"
            :disabled="!valid"
            >บันทึก</v-btn
          >
          <v-btn
            v-else
            color="success"
            type="submit"
            class="ml-3 mr-2"
            :disabled="!valid"
            >บันทึก</v-btn
          >
        </div>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from "moment";
import { capitalize } from "lodash";

import AreaPromoterMission from "./AreaPromoterMission.vue";
import AreaPromoterRewardDelivery from "./AreaPromoterRewardDelivery.vue";

import StampRewardModel from "@/store/models/changtt/stampReward";

export default {
  name: "FormAreaPromoterCRM",
  props: ["value", "data"],
  components: {
    AreaPromoterMission,
    AreaPromoterRewardDelivery,
  },
  computed: {
    status() {
      return this.form.status;
    },
    stampReward() {
      return this.stampRewardList;
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      rulesRequired: [(v) => (v && v.length > 0) || "กรุณากรอกข้อมูล"],
      form: {},
      vdate: {
        start: false,
        end: false,
      },
      approveStage: [
        { name: "None", value: "none" },
        { name: "Area Promoter", value: "area.promoter" },
        { name: "Area Promoter -> Region Admin", value: "region.admin" },
      ],
      publish: [
        { name: "Public", value: "public" },
        { name: "Hidden", value: "hidden" },
      ],
      stampRewardList: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.form = {
            ...this.data,
            startDate: moment(this.data.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.data.endDate).format("YYYY-MM-DD"),
            stampRewardId: this.data.reward,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    capitalize,
    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "กรุณากรอกข้อมูล.";
      }
      return !!value || "กรุณากรอกข้อมูล.";
    },
    async fetchData() {
      this.loading = true;
      let options = {
        params: {
          listType: "all",
        },
      };
      const res = await StampRewardModel.list(options);
      const { data } = res;
      this.stampRewardList = (data && data.data) || [];
      this.loading = false;
    },
    _removeReward() {
      this.form.stampRewardId = undefined;
    },
    _back() {
      this.$emit("input", false);
    },
    _fetch() {
      this.$emit("fetch");
    },
    _save() {
      const valid = this.$refs.form.validate();
      if (valid) {
        const form = this.form;
        const formData = {
          ...form,
          startDate: moment(form.startDate).format("YYYY-MM-DD H:mm:ss"),
          endDate: moment(form.endDate)
            .add(23, "h")
            .add(59, "m")
            .add(59, "s")
            .format("YYYY-MM-DD H:mm:ss"),
          approveStage: form.approveStage.value || form.approveStage,
          publish: form.publish.value || form.publish,
          stampRewardId: form.stampRewardId && form.stampRewardId.id,
        };

        this.$emit("submit", formData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$green: green;
$yellow: orange;
$brown: brown;

.status-section {
  padding: 10px 30px;
  border: 1px solid #000;
  border-radius: 5px;
}

.green-text {
  color: $green;
}
.yellow-text {
  color: $yellow;
}
.brown-text {
  color: $brown;
}

.col {
  padding: 10px 5px 10px 5px !important;
}

.promoter-h1 {
  font-size: 24px;
}

.remove-reward {
  text-decoration: underline;
}
</style>
