/* eslint-disable */
import { Model } from '@vuex-orm/core'

class CTTVansales extends Model {
  static fields() {
    return {
      id: this.string(),
      birthDate: this.string(),
      citizenId: this.string(),
      code: this.string(),
      firstName: this.string(),
      lastName: this.string(),
      registered: this.boolean(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
    }
  }

  static list(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/vansales`,
      ...options,
    }).then((res) => res.response.data)
  }

  static sublist(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/sub-vansales`,
      ...options,
    }).then((res) => res.response.data)
  }

  static create(options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/chang-tt/vansales`,
      ...options,
    }).then((res) => res.response)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/chang-tt/vansales/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static transfer (user_id = 0, options = {}) {
    if(options.data) {
      options.data.relation = "vansales";
    }
    return this.api().request({
      method: 'PUT',
      url: `/chang-tt/users/${user_id}/shops/transfer`,
      ...options,
    }).then((res) => res.response)
  }

  static delete (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/vansales/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static unlink (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/users/unlink/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static linkBulk(shop_id = 0, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/chang-tt/shops/${shop_id}/vansales/link`,
      ...options,
    }).then((res) => res.response)
  }

  static unlinkBulk(shop_id = 0, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/shops/${shop_id}/vansales/unlink`,
      ...options,
    }).then((res) => res.response)
  }

  static unlinkShops(vansales_id = 0, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/vansales/${vansales_id}/shops`,
      ...options,
    }).then((res) => res.response)
  }
}

CTTVansales.entity = 'vansales'

export default CTTVansales