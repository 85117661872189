/* eslint-disable */
import { Model } from '@vuex-orm/core'

class StampRewardDelivery extends Model {
  static fields() {
    return {
      approver: this.attr(null),
      card: this.attr(null),
      createdAt: this.string(''),
      id: this.number(0),
      image: this.string(''),
      mission: this.attr(null),
      shop: this.attr(null),
      shopId: this.number(0),
      stampCardId: this.number(0),
      stampMissionId: this.number(0),
      status: this.string(''),
      updatedAt: this.string(''),
      shipper: this.attr(null),
      shipped_at: this.string(''),
      received_at: this.string(''),
    }
  }

  static list(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/stamp-get-rewards`,
        ...options,
      })
      .then((res) => res.response)
  }

  static download(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/stamp-get-rewards/xlsx`,
        ...options,
        responseType: 'blob',
      })
      .then((res) => res.response)
  }
}

StampRewardDelivery.entity = 'stampRewardDelivery'

export default StampRewardDelivery
