/* eslint-disable */
import { Model } from '@vuex-orm/core'

class StampApprove extends Model {
  static fields() {
    return {
      approver: this.attr(null),
      card: this.attr(null),
      createdAt: this.string(''),
      id: this.number(0),
      image: this.string(''),
      mission: this.attr(null),
      shop: this.attr(null),
      shopId: this.number(0),
      stampCardId: this.number(0),
      stampMissionId: this.number(0),
      status: this.string(''),
      updatedAt: this.string(''),
    }
  }

  static list(id, options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `chang-tt/stamp-missions/${id}/stamp-approves`,
        ...options,
      })
      .then((res) => res.response)
  }

  static approve(id, options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `chang-tt/stamp-approves/${id}/approve`,
        ...options,
      })
      .then((res) => res.response)
  }

  static reject(id, options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `chang-tt/stamp-approves/${id}/reject`,
        ...options,
      })
      .then((res) => res.response)
  }
}

StampApprove.entity = 'stampApprove'

export default StampApprove
