<template>
  <div>
    <SideBar bar_name="Profile" :user="user"></SideBar>
    <v-main>
      <v-container>
        <v-layout wrap style="margin: 5% 15% 0% 15%">
          <h1>User Information</h1>

          <v-flex xs12 md12>
            <v-card class="mx-auto" width="824">
              <v-list>
                <!-- <v-list-item>
                  <v-list-item-icon>
                    <v-icon>email</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Email: {{user.email}}</v-list-item-title>
                </v-list-item> -->

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>supervised_user_circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Role: {{mapping[user.role]}}</v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>map</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Region: {{user.a && user.a.regions && user.a.regions.join(", ")}}</v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>account_circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Name: {{user.name}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-flex>

        </v-layout>
      </v-container>

      <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

      <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
        <v-card color="primary" dark>
          <v-card-text>
            <br />กรุณารอสักครู่............
            <br />
            <br />

            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";


export default {
  token: "",
  user: {},
  name: "Profile",
  components: {
    SideBar
  },
  data() {
    return {
      password: "",
      confirm_password: "",
      user: this.$user,
      username_edit: this.$user && this.$user.name,
      mapping: { ci: "CI", admin: "Admin", normal: "Normal" },
      dialog_loading: false,
    };
  },
  methods: {
    editUserName() {
      this.dialog_loading = true
      let formData = new FormData();

      formData.append("username", this.username_edit);
      formData.append("email", this.user.email);
      formData.append("password", "");

      this.$http
        .put(`/api/profile`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then(response => {
          this.dialog_loading = false
          console.log(response.data);
          // console.log(response.data.token);
          this.user.token = response.data;
          localStorage.setItem("token", response.data.token);
          this.fetch();
          this.$swal_success("เปลี่ยนชื่อสำเร็จ");
        })
        .catch(e => {
          this.dialog_loading = false
          console.error(e.response.data);
          this.$swal(e.response.data.description);
          if (e.response.status == "401") {
            localStorage.setItem("token", "");
            this.$router.push({ path: "/" });
          }
        });
    },
    editPassword() {
      this.dialog_loading = true
      // console.log(this.user.name)
      let formData = new FormData();

      formData.append("username", "");
      formData.append("email", this.user.email);
      formData.append("password", this.password);

      if (this.password == this.confirm_password) {
        this.$http
          .put(`/api/profile`, formData, {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          })
          .then(response => {
            this.dialog_loading = false
            console.log(response.data);
            this.user.token = response.data;
            localStorage.setItem("token", response.data.token);
            this.fetch();
            this.$swal_success("เปลี่ยนรหัสสำเร็จ");
          })
          .catch(e => {
            this.dialog_loading = false
            console.error(e.response.data);
            this.$swal(e.response.data.description);
            if (e.response.status == "401") {
              localStorage.setItem("token", "");
              this.$router.push({ path: "/" });
            }
          });
      } else {
        this.$swal("กรุณาใส่ password และ confirm password ให้เหมือนกัน");
      }
    }
  }
};
</script>
