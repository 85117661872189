/* eslint-disable */
import { Model } from '@vuex-orm/core'

class CTTShop extends Model {
  static fields() {
    return {
      id: this.string(),
      image: this.string(),
      code: this.string(),
      name: this.string(),
      phone: this.string(),
      taxId: this.string(),
      address: this.string(),
      qrCode: this.string(),
      postcode: this.number(),
      verified: this.boolean(),
      type: this.attr(),
      subDistrict: this.attr(),
      district: this.attr(),
      province: this.attr(),
      region: this.attr(),
      area: this.attr(),
      owner: this.attr(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
    }
  }

  static list(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/shops`,
      ...options,
    }).then((res) => res.response.data)
  }

  static createAgent(options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/chang-tt/agents`,
      ...options,
    }).then((res) => res.response.data)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/chang-tt/shops/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static unlink (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/users/unlink/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static uploadImage (id, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/chang-tt/shops/${id}/images`,
      ...options,
    }).then((res) => res.response)
  }

  static agentListRetail(code = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/agents/${code}/customers`,
      ...options,
    }).then((res) => res.response.data)
  }

  static areaPromoterListRetail(id = 0, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/area-promoters/${id}/customers`,
      ...options,
    }).then((res) => res.response.data)
  }
  
  static vansalesListRetail(id = 0, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/vansales/${id}/customers`,
      ...options,
    }).then((res) => res.response.data)
  }

  static updateShopRelation(id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/chang-tt/relation/shops/${id}`,
      ...options,
    }).then((res) => res.response)
  }
}

CTTShop.entity = 'shop'

export default CTTShop