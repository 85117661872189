/* eslint-disable */
import { Model } from '@vuex-orm/core'

class User extends Model {
  static fields () {
    return {
      ID: this.number(),
      firstName: this.string(''),
      lastName: this.string(''),
      citizenId: this.string(''),
      birthDate: this.string(''),
      phone: this.string(''),
      ShopID: this.number(),
      code: this.string(''),
      userType: this.string(''),
      position: this.string(''),
      company: this.string(''),
      responseAreaLevel: this.string(''),
      CreatedAt: this.string(''),
      UpdatedAt: this.string(''),
      DeletedAt: this.string(''),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/users/cms`,
      ...options
    }).then((res) => res.response)
  }

  static create (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/user`,
      dataTransformer: () => {
        return []
      },
      ...options
    }).then((res) => res.response)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/v1/users/${id}`,
      ...options
    }).then((res) => res.response)
  }

  static delete (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/v1/users/${id}`,
      ...options
    }).then((res) => res.response)
  }

  static public (shopTypeId, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/users/public/shop-types/${shopTypeId}`,
      ...options
    }).then((res) => res.response)
  }
}

User.entity = 'user'

export default User