<template>
  <!--
 TODO: issue api point must be start 1
       split component add, edit
  -->
  <div>
    <v-layout align-center wrap>
      <v-container fluid>
        <v-flex style="margin-left: 2%">
          <v-row>
            <v-col md="6">
              <v-select
                outlined
                :items="catagories"
                label="Catagory"
                item-value="id"
                item-text="title"
                v-model="category"
                @change="onCatChange"
              />
            </v-col>
            <v-col md="6" class="text-end">
              <v-btn
                style="margin: 3%"
                @click="openAddDialog"
                v-if="user.role == 'ci'"
              >
                New
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>

        <draggable
          v-model="rewardItems"
          style="display: flex; flex-wrap: wrap; width: 100%"
          :animation="500"
          draggable=".item"
          @start="dragging = true"
          @end="dragging = false"
          :sort="sortable"
        >
          <!-- <div v-for="element in myArray" :key="element.id">{{element.name}}</div> -->
          <v-flex
            xs12
            sm4
            md3
            v-for="element in rewardItems"
            :key="element.id"
            class="item"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                max-width="400"
                style="margin: 5%; border-radius: 15px"
                height=""
              >
                <v-img
                  class="white--text"
                  :aspect-ratio="1.5"
                  :src="`${element.image}`"
                >
                  <p
                    style="
                      background-color: red;
                      text-align: center;
                      color: white;
                    "
                    v-if="!element.is_public"
                  >
                    Not posted yet
                  </p>
                  <v-expand-transition v-if="user.role == 'ci'">
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-3 white--text"
                      style="height: 100%"
                    >
                      <v-btn
                        color="white"
                        icon
                        @click="openEditDialog(element)"
                      >
                        <v-icon color="white">edit</v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>

                <v-card-text class="text-item">
                  <span class="text--primary">
                    <span>{{ element.title }}</span>
                    <br />
                  </span>
                  <span>รหัสของรางวัล {{ element.code }}</span>
                </v-card-text>

                <!-- <v-divider></v-divider> -->

                <!-- <v-card-actions style="justify-content: space-around; display: flex;">
                  <v-btn icon color="#424242" @click="openEditDialog(element)">
                    <v-icon>edit</v-icon>
                  </v-btn>
              </v-card-actions>-->
              </v-card>
            </v-hover>
          </v-flex>

          <NotFound v-if="rewardItems.length <= 0 && !dialog_loading" />

          <infinite-loading v-if="!dialog_loading" @infinite="infiniteHandler">
            <span slot="no-results"></span>
            <span slot="no-more"></span>
            <span slot="spinner"></span>
            <span slot="error"></span>
          </infinite-loading>
        </draggable>
      </v-container>
    </v-layout>

    <!-- <div>------------------------------------- Reward items Dialog ------------------------------------------------</div> -->

    <!-- @handleChange="handleChange" -->
    <AgentProductModal
      ref="childComponent"
      :add="add"
      :reward="reward"
      :dialog_edit="dialog_edit"
      :dialog_edit_stock="dialog_edit_stock"
      :dialog_add_new="dialog_add_new"
      :disabledSaveEditStock="disabledSaveEditStock"
      :disabledSaveEditReward="disabledSaveEditReward"
      :disabledSaveAddReward="disabledSaveAddReward"
      :editStockInfo="editStockInfo"
      :numberOption="numberOption"
      :rules="rules"
      :all_regions="all_regions"
      :tableList="tableList"
      :catagoryList="catagoryList"
      :dialog_delete="dialog_delete"
      :dialog_loading="dialog_loading"
      :disabledTable="disabledTable"
      @closeAddNewDialog="closeAddNewDialog"
      @addNewRewardItem="addNewRewardItem"
      @generateTable="generateTable"
      @clearList="clearList"
      @onRemoveImage="onRemoveImage"
      @onRegionSelected="onRegionSelected"
      @closeStock="closeStock"
      @preventRemoveOptions="preventRemoveOptions"
      @editRewardItem="editRewardItem"
      @closeEditDialog="closeEditDialog"
      @deleteRewardItem="deleteRewardItem"
      @editStock="editStock"
      @updateStock="updateStock"
      @isNewValue="isNewValue"
      @handleDeleteDialog="handleDeleteDialog"
    />

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />กรุณารอสักครู่....
          <br />
          <br />

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'

import NotFound from '@/components/NotFound'
import AgentProductModal from './AgentProductModal'

import Region from '@/store/models/region'
import Catalog from '@/store/models/catalog'
import Reward from '@/store/models/reward'

export default {
  name: 'Product',
  components: {
    draggable,
    AgentProductModal,
    NotFound,
  },
  props: ['user', 'tabId', 'tabName'],
  computed: {
    all_regions() {
      return Region.getsAll().reduce((o, e) => {
        o.push(e)
        // if (e.id === "All") {
        //   o.push({ id: 0, label: "Exclusive" });
        // }

        return o
      }, [])
    },
    isStockEditInputCorrect() {
      const { limitStock, remain } = this.editStockInfo
      return parseInt(limitStock) >= parseInt(remain)
    },
    isChoices() {
      if (this.reward.options[1].options.length >= 1) {
        return false
      } else {
        return true
      }
    },
  },
  data() {
    return {
      dialog_edit: false,
      dialog_add_new: false,
      dialog_edit_stock: false,
      dialog_delete: false,
      dragging: false,
      rewardItems: [],
      reward: {
        image: [],
        options: [],
        rewardImages: [],
        removeImages: [],
      },
      catagories: [],
      category: '',
      catagoryList: [],
      numberOption: 0,
      sortable: false,
      dialog_loading: true,
      des_edit: '',
      code_edit: '',
      add: {},
      shopID: this.tabId,
      shopName: this.tabName,
      per_page: 12,
      page: 1,
      lastPage: 1,
      tableList: [],
      editStockInfo: {},
      rules: {
        int: (v) =>
          /^[0-9]+$/.test(v) || Number.isInteger(v) || 'Must be Integer',
        required: (value) => value != '' || 'Field is required',
        limitStockValidate: (value, redeem) =>
          value >= redeem || 'Limit stock should equal or more than redeem',
      },
      disabledSaveEditStock: false,
      disabledSaveEditReward: false,
      disabledSaveAddReward: false,
      disabledTable: false,
    }
  },
  created() {
    this.fetch()
  },
  watch: {
    category: function (val) {
      this.setSortAble(val)
    },
    editStockInfo: {
      deep: true,
      async handler() {
        await this.$nextTick()
        this.disabledSaveEditStock =
          this.$refs.childComponent.$refs.formEditStock &&
          !this.$refs.childComponent.$refs.formEditStock.validate()
      },
    },
    add: {
      deep: true,
      async handler() {
        await this.$nextTick()
        const formAddValidate =
          this.$refs.childComponent.$refs.formAdd &&
          this.$refs.childComponent.$refs.formAdd.validate()

        let isCorrectOptionData = false
        if (this.numberOption > 0) {
          if (this.numberOption == 1 && this.tableList[0]) {
            isCorrectOptionData = true
          } else if (
            this.numberOption == 2 &&
            this.tableList[0] &&
            this.tableList[0].subOption
          ) {
            isCorrectOptionData = true
          }
        } else {
          isCorrectOptionData = true
        }

        this.disabledSaveAddReward = !formAddValidate || !isCorrectOptionData
      },
    },
    reward: {
      deep: true,
      async handler() {
        await this.$nextTick()
        const formAddValidate =
          this.$refs.childComponent.$refs.formAdd &&
          this.$refs.childComponent.$refs.formAdd.validate()
        const formEditValidate =
          this.$refs.childComponent.$refs.formEdit &&
          this.$refs.childComponent.$refs.formEdit.validate()

        let isCorrectOptionData = false
        if (this.numberOption > 0) {
          if (this.numberOption == 1 && this.tableList[0]) {
            isCorrectOptionData = true
          } else if (
            this.numberOption == 2 &&
            this.tableList[0] &&
            this.tableList[0].subOption
          ) {
            isCorrectOptionData = true
          }
        } else {
          isCorrectOptionData = true
        }

        this.disabledSaveAddReward = !formAddValidate || !isCorrectOptionData
        this.disabledSaveEditReward = !formEditValidate || !isCorrectOptionData
      },
    },
    tableList: {
      deep: true,
      async handler() {
        const { numberOption, tableList } = this
        let result = false
        if (numberOption > 0) {
          if (numberOption === 1 && this.tableList[0]) {
            _.forEach(tableList, (i) => {
              if (i.code === '') {
                result = true
              }
            })
          } else if (
            numberOption == 2 &&
            tableList[0] &&
            tableList[0].subOption
          ) {
            _.forEach(tableList, (i) => {
              _.forEach(i.subOption, (j) => {
                if (j.code === '') {
                  result = true
                }
              })
            })
          }
        }
        this.disabledTable = result
      },
    },
  },
  methods: {
    // handleChange() {
    //   console.log(this.add.category)
    // },
    isNewValue({ item }) {
      return !this.oldOptions.includes(item)
    },
    preventRemoveOptions(item) {
      if (item === this.oldOptions) {
        return false
      } else {
        return true
      }
    },
    onRegionSelected({ type, selectedIds }) {
      if (type === 'add') {
        this.add.region = selectedIds.map((id) => {
          const region = this.all_regions.find((region) => region.id === id)
          return { id: region.id, label: region.label }
        })
      } else if (type === 'edit') {
        this.reward.regions = selectedIds.map((id) => {
          const region = this.all_regions.find((region) => region.id === id)
          return { id: region.id, label: region.label }
        })
      }
    },
    fetch() {
      if (this.user.role != 'ci') {
        this.sortable = false
      }
      this.getCatagory()
    },
    getRewardItems(cat) {
      Reward.gets(this.shopID, {
        params: { catalog: cat, per_page: this.per_page, page: this.page },
      })
        .then((response) => {
          const rewards = (response.data && response.data.reward) || []

          this.lastPage = response.data.lastPage

          if (this.page === 1) {
            this.rewardItems = rewards
          } else {
            this.rewardItems = [...this.rewardItems, ...rewards]
          }

          this.dialog_loading = false
        })
        .catch((e) => {
          this.dialog_loading = false
          console.error(e.response)
        })
      return this.rewardItems
    },
    async infiniteHandler($state) {
      this.page += 1

      if (this.page <= this.lastPage) {
        this.dialog_loading = true
        const data = await this.getRewardItems(this.category)

        if (data.length > 0) {
          $state.loaded()
        } else {
          $state.complete()
        }
      }
    },
    getCatagory() {
      Catalog.gets({ params: { typeID: this.shopID } })
        .then((response) => {
          this.dialog_loading = false
          this.catagories =
            [
              { id: '', title: 'All' },
              ...(response.data && response.data.catalog),
            ] || []
          this.catagoryList =
            (response && response.data && response.data.catalog) || []
          this.categorySelect =
            this.catagoryList && this.catagoryList[0] && this.catagoryList[0].id
          this.onCatChange()
          this.setSortAble('')
        })
        .catch((e) => {
          this.dialog_loading = false
          console.error('getCatagory: ', e)
        })
    },
    onCatChange() {
      this.dialog_loading = true
      this.page = 1
      this.getRewardItems(this.category)
    },
    openAddDialog() {
      this.dialog_add_new = true
      this.$nextTick(() => {
        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
        this.$refs.childComponent.$refs.formAdd.resetValidation()
      })
    },
    openEditDialog(ele) {
      this.dialog_loading = true

      let reward
      Reward.get(ele.id)
        .then((response) => {
          reward = response.data.reward

          if (reward.options == null) {
            this.reward.options = []
          } else {
            this.numberOption = reward.options ? reward.options.length : 0

            this.oldOptions = reward.options.reduce((acc, curr) => {
              acc.push(...curr.options)
              return acc
            }, [])
          }

          this.reward = _.cloneDeep({ ...this.reward, ...reward })

          this.generateTable()
          this.dialog_loading = false
          this.dialog_edit = true
          this.$nextTick(() => {
            document.getElementsByClassName('v-card__text')[0].scrollTop = 0
          })
          this.des_edit = this.desc
          this.code_edit = this.code
        })
        .catch((e) => {
          this.dialog_loading = false
          console.error(e)
        })
    },
    async generateTable() {
      const { reward } = this

      if (reward.options.length <= 0) {
        return
      }

      // check options items
      reward.options.forEach((rOption, index) => {
        // more than limit
        if (rOption.options.length > 10) {
          reward.options[index].options.pop()
        }

        // have preserve character
        rOption.options.forEach((so, soIndex) => {
          if (so.includes('|')) {
            reward.options[index].options.splice(soIndex, 1)
          }
        })
      })

      const newList = []

      const isOptionItem1 =
        reward.options[0] && reward.options[0].options.length > 0
      const isOptionItem2 =
        reward.options[1] && reward.options[1].options.length > 0

      // if (reward.options.selected == '2 options') {
      if (isOptionItem1 && !isOptionItem2) {
        _.forEach(reward.options[0].options, (i) => {
          if (reward.id) {
            const oldStock = reward.rewardOptionStocks.find(
              (s) => s.option.split('|')[0] == i
            )
            if (!oldStock) {
              newList.push({
                reward_id: reward.id,
                option: i,
                mainName: i,
                point: '0',
                limitStock: '0',
                remain: 0,
                redeem: 0,
                code: '',
              })
            } else {
              newList.push({
                mainName: i,
                code: oldStock.code,
                point: oldStock.point,
                limitStock: oldStock.limit_stock,
                remain: oldStock.remain,
                ...oldStock,
              })
            }
          } else {
            newList.push({ code: '', mainName: i, point: '0', limitStock: '0' })
          }
        })
      } else {
        _.forEach(reward.options[0].options, (i) => {
          const list = []
          _.forEach(reward.options[1].options, (j) => {
            if (reward.id) {
              const oldStock = reward.rewardOptionStocks.find(
                (s) =>
                  s.option.split('|')[0] == i && s.option.split('|')[1] == j
              )
              if (!oldStock) {
                list.push({
                  reward_id: reward.id,
                  option: i + '|' + j,
                  subName: j,
                  point: '0',
                  limitStock: '0',
                  remain: 0,
                  redeem: 0,
                  code: '',
                })
              } else {
                list.push({
                  subName: j,
                  code: oldStock.code,
                  point: oldStock.point,
                  limitStock: oldStock.limit_stock,
                  remain: oldStock.remain,
                  ...oldStock,
                })
              }
            } else {
              list.push({ code: '', subName: j, point: '0', limitStock: '0' })
            }
          })
          newList.push({ mainName: i, subOption: list })
        })
      }

      this.tableList = newList

      await this.$nextTick()
    },
    editStock({ item, index }) {
      this.dialog_edit_stock = true
      if (
        _.findIndex(Object.keys(item), (i) => {
          return i === 'subOption'
        }) === 1
      ) {
        this.editStockInfo = {
          mainLabel: this.reward.options[0].title,
          code: this.reward.options[0].code,
          mainName: item.mainName,
          subLabel: this.reward.options[1].title,
          oldStock: item.subOption[index].limitStock,
          ...item.subOption[index],
          index,
          flag: true,
        }
      } else {
        this.editStockInfo = {
          mainLabel: this.reward.options[0].title,
          code: this.reward.options[0].code,
          oldStock: item.limitStock,
          ...item,
          index,
          flag: false,
        }
      }
    },
    closeStock() {
      this.dialog_edit_stock = false
      this.editStockInfo = {}
    },
    updateStock() {
      const { tableList, editStockInfo } = this
      if (editStockInfo.flag) {
        const index = _.findIndex(tableList, (i) => {
          return i.mainName === editStockInfo.mainName
        })
        this.tableList[index].subOption[editStockInfo.index] = {
          ...tableList[index].subOption[editStockInfo.index],
          point: parseInt(editStockInfo.point),
          limitStock: parseInt(editStockInfo.limitStock),
        }
      } else {
        this.tableList[editStockInfo.index] = {
          ...tableList[editStockInfo.index],
          point: parseInt(editStockInfo.point),
          limitStock: parseInt(editStockInfo.limitStock),
        }
      }

      this.closeStock()
    },
    addNewRewardItem() {
      const resultValidate = this.$refs.childComponent.$refs.formAdd.validate()
      if (!resultValidate) {
        return
      }

      const { add, tableList } = this
      // console.log('add , tableList', add, tableList)
      this.dialog_loading = true
      let formData = new FormData()

      let OptionStock = []

      tableList.forEach((opts) => {
        if (opts.subOption) {
          opts.subOption.forEach((so) => {
            OptionStock.push({
              code: so.code,
              option: opts.mainName + '|' + so.subName,
              point: parseInt(so.point),
              limit_stock: parseInt(so.limitStock),
              redeem: 0,
            })
          })
        } else {
          OptionStock.push({
            code: opts.code,
            option: opts.mainName,
            point: parseInt(opts.point),
            limit_stock: parseInt(opts.limitStock),
            redeem: 0,
          })
        }
      })

      let startDateFormat
      let endDateFormat
      if (this.add.startTime.includes('T')) {
        startDateFormat = this.add.startTime.split('T').join(' ').slice(0, -6)
      } else {
        startDateFormat = this.add.startTime + ':00'
      }

      if (this.add.endTime.includes('T')) {
        endDateFormat = this.add.endTime.split('T').join(' ').slice(0, -6)
      } else {
        endDateFormat = this.add.endTime + ':00'
      }

      // set new added images
      this.reward.rewardImages
        .filter((rm) => !rm.id)
        .forEach((image) => {
          formData.append('file', image.file, image.fileName)
        })

      let post = add.post !== undefined ? add.post : 'false'
      let phone = add.phone !== undefined ? add.phone : 'false'

      // console.log('this.reward.options', this.reward.options)
      // console.log('this.reward.options.selected', this.reward.options.selected)

      // console.log('Category_id', this.add.category)

      if (this.numberOption == 0) {
        formData.append('code', add.code)
        formData.append('point', parseInt(add.point))
        formData.append('limit_stock', parseInt(add.limit_stock))
      } else {
        // issue: api point must be start 1
        formData.append('code', '-')
        formData.append('point', 1)
        formData.append('optionStocks', JSON.stringify(OptionStock))
        formData.append('options', JSON.stringify(this.reward.options))
      }

      formData.append('title', add.title)
      formData.append('desc', add.desc)
      formData.append('isPublic', post)
      formData.append('shopTypeID', this.shopID)
      formData.append('startDate', startDateFormat)
      formData.append('endDate', endDateFormat)
      formData.append('requiredPhone', phone)
      formData.append('regions', JSON.stringify(this.add.region))

      this.createReward(formData)
    },
    editRewardItem() {
      const resultValidate = this.$refs.childComponent.$refs.formEdit.validate()
      if (!resultValidate) {
        return
      }

      let formData = new FormData()

      const rewardOptionStocksData = []
      this.tableList.forEach((item) => {
        if (item.subOption) {
          item.subOption.forEach((subItem) => {
            rewardOptionStocksData.push({
              id: subItem.id,
              code: subItem.code,
              reward_id: subItem.reward_id,
              option: subItem.option,
              point: parseInt(subItem.point),
              limit_stock: parseInt(subItem.limitStock),
            })
          })
        } else {
          rewardOptionStocksData.push({
            id: item.id,
            code: item.code,
            reward_id: item.reward_id,
            option: item.option,
            point: parseInt(item.point),
            limit_stock: parseInt(item.limitStock),
          })
        }
      })

      const regions = []
      this.reward.regions.forEach((r) => {
        regions.push({
          id: r.id,
          label: r.label,
        })
      })

      const rewardOptions = []
      this.reward.options.forEach((o) => {
        rewardOptions.push({
          options: o.options,
          title: o.title,
        })
      })

      let startDateFormat
      let endDateFormat
      if (this.reward.startDate.includes('T')) {
        startDateFormat = this.reward.startDate
          .split('T')
          .join(' ')
          .slice(0, -6)
      } else {
        startDateFormat = this.reward.startDate + ':00'
      }

      if (this.reward.endDate.includes('T')) {
        endDateFormat = this.reward.endDate.split('T').join(' ').slice(0, -6)
      } else {
        endDateFormat = this.reward.endDate + ':00'
      }

      if (this.numberOption == 0) {
        formData.append('code', this.reward.code)
        formData.append('point', parseInt(this.reward.point))
        formData.append('limit_stock', parseInt(this.reward.limitStock))
      } else {
        // issue: api point must be start 1
        formData.append('code', '-')
        formData.append('point', 1)
      }

      formData.append('title', this.reward.title)
      formData.append('desc', this.reward.description)
      formData.append('isPublic', this.reward.is_public)
      formData.append('shopTypeID', this.shopID)
      formData.append('startDate', startDateFormat)
      formData.append('endDate', endDateFormat)
      formData.append('options', JSON.stringify(rewardOptions))
      formData.append('optionStocks', JSON.stringify(rewardOptionStocksData))
      formData.append('requiredPhone', this.reward.requiredPhone)
      formData.append('regions', JSON.stringify(regions))
      formData.append('removeImages', JSON.stringify(this.reward.removeImages))
      formData.append('categoryId', this.reward.reward_catalog_id)

      // set new added images
      this.reward.rewardImages
        .filter((rm) => !rm.id)
        .forEach((image) => {
          formData.append('file', image.file, image.fileName)
        })

      this.updateReward(formData)
    },
    async createReward(formData) {
      this.dialog_loading = true
      await Reward.create(this.add.category, { data: formData })
        .then(() => {
          this.page = 1
          this.rewardItems = []
          this.getRewardItems()
          // this.getRewardItems(this.add.category)
          // this.category = this.add.category
          this.closeAddNewDialog()
        })
        .catch((e) => {
          console.error(e.response)
        })
      this.dialog_loading = false
    },
    async updateReward(formData) {
      this.dialog_loading = true

      await Reward.update(this.reward.id, { data: formData })
        .then(() => {
          this.page = 1
          this.rewardItems = []
          this.getRewardItems()
          // this.getRewardItems(this.category)
          this.closeEditDialog()
        })
        .catch((e) => {
          console.error(e.response)
        })
      this.dialog_loading = false
    },
    clearList(e = 0) {
      this.numberOption = e
      this.reward.options = []
      this.reward.rewardOptionStocks = []

      if (this.numberOption != 0) {
        for (let i = 0; i < this.numberOption; i++) {
          this.reward.options.push({ title: '', options: [] })
        }
      }

      // console.log('clearList', this.reward.options)

      this.tableList = []
    },
    async closeAddNewDialog() {
      this.add = {}
      this.reward = {
        image: [],
        options: [],
        rewardImages: [],
        removeImages: [],
      }

      this.numberOption = 0
      this.clearList()
      await this.$nextTick()
      this.$refs.childComponent.$refs.formAdd.resetValidation()
      this.dialog_add_new = false
    },
    async closeEditDialog() {
      this.add = {}
      this.reward = {
        image: [],
        options: [],
        rewardImages: [],
        removeImages: [],
      }

      this.numberOption = 0
      this.clearList()
      this.dialog_edit = false
    },
    deleteRewardItem() {
      this.dialog_loading = true
      Reward.delete(this.reward.id)
        .then(() => {
          this.page = 1
          this.rewardItems = []
          this.getRewardItems()
          // this.getRewardItems(this.category)
          this.closeEditDialog()
        })
        .catch((e) => {
          console.error(e.response)
        })
      this.dialog_loading = false
      this.dialog_delete = false
      this.dialog_edit = false
    },
    handleDeleteDialog({ open }) {
      this.dialog_delete = open
    },
    setSortAble(val) {
      if (this.user.role === 'ci' && val === '') {
        this.sortable = true
      } else {
        this.sortable = false
      }
    },
    onRemoveImage(val) {
      if (val !== undefined && val.id) {
        this.reward.removeImages.push(val.id)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.text-name {
  text-align: center;
  font-size: 20px;
}

.lg5-custom {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

.custom-croppie {
  width: 512px;
  height: 300px;
  margin: 0 auto;
}

.text-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
