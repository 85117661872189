<template>
  <div>
    <SideBar bar_name="Catagory" :user="user"></SideBar>

    <v-main>
      <v-container>
        <Tab :tabs="tabs">
          <template v-slot="slotProps">
            <AgentCatagory v-bind="{ user: slotProps.user, tabId: slotProps.tabId, tabName: slotProps.tabName }" />
          </template>
        </Tab>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Tab from "@/components/Tab";
import AgentCatagory from "@/components/Catagory/AgentCatagory";

export default {
  name: "Catagory",
  components: {
    SideBar,
    Tab,
    AgentCatagory
  },
  data() {
    return {
      tabs: this.$catalogTabs,
      user: this.$user,
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-name {
  text-align: center;
  font-size: 20px;
}
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

.custom-croppie {
  width: 128x;
  height: 128px;
  margin: 0 auto;
}
</style>
