<template>
  <div>
    <v-layout align-center wrap>
      <v-flex xs12 md12>
        <v-btn style="margin: 3%; float: right;" @click="postBanner" v-if="user.role == 'ci'">Post</v-btn>
      </v-flex>

      <draggable
        v-model="banners"
        style="display: flex; flex-wrap: wrap; width: 100%"
        :animation="500"
        draggable=".item"
        @start="dragging = true"
        @end="dragging = false"
        :sort="sortable"
      >
        <!-- <div v-for="element in myArray" :key="element.id">{{element.name}}</div> -->
        <v-flex xs12 md4 v-for="element in banners" :key="element.id" class="item">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="350"
              max-height="372"
              style="margin: 5%; border-radius: 15px;"
              :elevation="hover ? 12 : 2"
            >
              <v-img :aspect-ratio="2" v-bind:src="encodeURI(element.image)" style="color: black">
                <p
                  style="background-color: red; text-align: center; color:white"
                  v-if="!element.isPublic"
                >Not posted yet</p>
                <v-expand-transition v-if="user.role == 'ci'">
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-3 white--text"
                    style="height: 100%;"
                  >
                    <v-btn color="white" icon @click="openEditDialog(element)">
                      <v-icon color="white">edit</v-icon>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-flex>

        <v-flex xs12 md4 v-if="user.role == 'ci'">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="mx-auto"
              max-width="350"
              max-height="180"
              style="margin: 5%; border-radius: 15px; background-color: #ffffff00;"
              :elevation="hover ? 12 : 2"
            >
              <v-img :aspect-ratio="2" src>
                <v-card-actions style="display: flex; justify-content: center; height: 100%;">
                  <v-btn icon color="#424242" @click="openAddNewDialog">
                    <v-icon size="35px">add</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-img>
            </v-card>
          </v-hover>
        </v-flex>
      </draggable>

      <NotFound v-if="banners.length <= 0 && user.role != 'ci'" />
    </v-layout>

    <!-- <div>------------------------------------- Edit Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog" persistent max-width="790" scrollable>
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
          style="padding-left: 35px;"
          lass="headline"
        >Edit Banner</v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div style="padding: 20px;">
            <!-- <v-img
                v-if="url"
                :src="url"
                max-width="750"
                max-height="372"
                style="margin-bottom: 15px"
            />-->

            <v-img
              v-if="file==''"
              style="margin-bottom: 15px; margin: 0 auto;"
              :src="banner.image"
              max-width="570"
              max-height="312"
            />

            <vue-croppie
              v-show="file!=''"
              ref="croppieRef"
              :enableResize="false"
              @result="result"
              :viewport="{ width: 640, height: 360 }"
              resultType="blob"
              customClass="custom-croppie"
            ></vue-croppie>

            <v-file-input
              label="File input"
              outlined
              prepend-icon="mdi-camera"
              @change="onFileChange"
              accept="image/*"
              v-model="files"
              style="margin-top: 48px;"
            ></v-file-input>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px;">
            <!-- <div style="margin-bottom: 4%; display: flex; align-items: baseline;">
                 <v-switch v-model="switch1" :label="`Reward Link:`"></v-switch>
            </div>-->

            <v-switch
              v-model="switch_reward"
              :label="`Reward Link`"
              color="success"
              @change="toggle_reward"
            ></v-switch>
            
            <v-select
              outlined
              :items="catagories"
              label="Catagory"
              item-text="title"
              item-value="id"
              v-model="catagory"
              @change="onCatChange"
              :disabled="!switch_reward"
            ></v-select>

            <v-select
              outlined
              :items="rewardItems"
              label="Reward Item"
              item-text="description"
              item-value="id"
              :disabled="!switch_reward"
              v-model="product_id"
            ></v-select>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px;">
            <v-switch v-model="switch_link" :label="`Link`" color="success" @change="toggle_link"></v-switch>

            <v-text-field
              label="Link"
              required
              v-model="banner.externalLink"
              outlined
              :disabled="!switch_link"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="dialog_delete = true">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeEditDialog">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="editBanner">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Add New Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_add_new" persistent max-width="790" scrollable>
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
          style="padding-left: 35px;"
          lass="headline"
        >Add New Banner</v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <div style="padding: 20px;">
            <!-- <v-img
                v-if="url"
                :src="url"
                max-width="750"
                max-height="372"
                style="margin-bottom: 15px"
            />-->

            <v-img
              v-show="file==''"
              :aspect-ratio="2"
              style="margin-bottom: 15px"
              :src="require('@/assets/no-preview-available.png')"
            />

            <vue-croppie
              v-show="file!=''"
              ref="croppieRefAdd"
              :enableResize="false"
              @result="result"
              :viewport="{ width: 640, height: 360 }"
              resultType="blob"
              customClass="custom-croppie"
            ></vue-croppie>

            <v-file-input
              label="File input"
              outlined
              prepend-icon="mdi-camera"
              @change="onFileChange_add"
              accept="image/*"
              v-model="files"
              style="margin-top: 48px;"
            ></v-file-input>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px;">
            <v-switch
              v-model="switch_reward"
              :label="`Reward Link`"
              color="success"
              @change="toggle_reward"
            ></v-switch>

            <v-select
              outlined
              :items="catagories"
              label="Catagory"
              item-text="title"
              item-value="id"
              v-model="catagory"
              @change="onCatChange"
              :disabled="!switch_reward"
            ></v-select>

            <v-select
              outlined
              :items="rewardItems"
              label="Reward Item"
              item-text="description"
              item-value="id"
              :disabled="!switch_reward"
              v-model="product_id"
            ></v-select>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px;">
            <v-switch v-model="switch_link" :label="`Link`" color="success" @change="toggle_link"></v-switch>

            <v-text-field label="Link" required v-model="link" outlined :disabled="!switch_link"></v-text-field>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeAddNewDialog">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="addNewBanner" :disabled="this.file == ''">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Confirm delete banner Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_delete" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Confirm delete?</v-card-title>
        <v-card-text>Are you sure that you want to delete this banner. This banner will be gone permanently.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="dialog_delete = false" text>Cancel</v-btn>
          <v-btn color="red" text @click="deleteBanner">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />กรุณารอสักครู่............
          <br />
          <br />

          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";
import VueCroppie from "vue-croppie";
import _ from "lodash";
import "croppie/croppie.css"; // import the croppie css manually

import NotFound from "@/components/NotFound";
import Catalog from "@/store/models/catalog";
import Reward from "@/store/models/reward";
import Banner from "@/store/models/banner";

Vue.use(VueCroppie);

export default {
  name: "bannerManagement",
  components: {
    draggable,
    NotFound
  },
  props: ["user", "tabId", "tabName"],
  data() {
    return {
      dialogVis: null,
      banners: [],
      dialog: false,
      name: "",
      imageData: "",
      file: "",
      url: null,
      link: "",
      dialog_add_new: false,
      image: "",
      externalLink: "",
      dialog_delete: false,
      banner: {},
      files: [],
      catagories: [],
      rewardItems: [],
      catagory: 0,
      switch_reward: false,
      switch_link: false,
      product_id: 0,
      sortable: true,
      dialog_loading: true,
      cropped: null,
      file_name: ""
    };
  },
  created() {
    this.fetch();
  },
  watch: {},
  methods: {
    fetch() {
      if (this.user.role != "ci") {
        this.sortable = false;
      }
      this.getBanner();
      this.getCatagory();
    },
    getBanner() {
      this.dialog_loading = true;
      Banner.gets(this.tabId)
        .then(response => {
          this.banners = response.data || [];
          this.dialog_loading = false;
        })
        .catch(e => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },
    getCatagory() {
      Catalog.gets({ params: { typeID: this.tabId } })
        .then(response => {
          this.catagories = response.data && response.data.catalog || [];
          this.catagory = this.catagories && this.catagories[0] && this.catagories[0].id;
          this.getRewardItems(this.catagory);
        })
        .catch(e => {
          console.error(e.response);
        });
    },
    getRewardItems(cat) {
      Reward.gets(this.tabId, {
          params: { catalog: cat, per_page: 1000, page: 1 }
        })
        .then(response => {
          this.rewardItems = (response.data && response.data.reward) || [];
        })
        .catch(e => {
          console.error(e.response);
        });
    },
    onCatChange() {
      this.getRewardItems(this.catagory);
    },
    openEditDialog(ele) {
      if (!(ele.reward && ele.reward.id)) {
        this.switch_reward = false;
      } else {
        this.switch_reward = true;
        this.catagory = ele.reward && ele.reward.reward_catalog_id;
        this.product_id = ele.reward && ele.reward.id;
        this.getRewardItems(this.catagory);
      }

      if (ele.externalLink == "") {
        this.switch_link = false;
      } else {
        this.switch_link = true;
      }

      this.banner = _.cloneDeep(ele);
      this.dialog = true;
      this.$nextTick(() => {
        document.getElementsByClassName("v-card__text")[0].scrollTop = 0;
      });
    },
    openAddNewDialog() {
      this.dialog_add_new = true;
      this.$nextTick(() => {
        document.getElementsByClassName("v-card__text")[0].scrollTop = 0;
      });
    },
    closeAddNewDialog() {
      this.dialog_add_new = false;
      this.file = "";
      this.url = null;
      this.link = "";
      this.files = [];
      this.switch_reward = false;
      this.switch_link = false;
    },
    closeEditDialog() {
      this.dialog = false;
      this.file = "";
      this.url = null;
      this.files = [];
      this.switch_reward = false;
      this.switch_link = false;
      this.catagory = "";
      this.product_id = "";
    },
    onFileChange(e) {
      this.file = e;
      this.url = URL.createObjectURL(e);
      this.file_name = e.name;

      let img = new Image();

      this.$refs.croppieRef.bind({
        url: this.url
      });

      // img.onload = () => {
      //   console.log(`the image dimensions are ${img.width}x${img.height}`);
      //   if (img.width != 712 || img.height != 390) {
      //     this.file = "";
      //     this.url = null;
      //     this.files = [];
      //     this.$swal("Wrong image dimension. Required 712 x 390");
      //   }
      // };

      e.value = undefined
      img.src = this.url;
    },
    onFileChange_add(e) {
      this.file = e;
      this.url = URL.createObjectURL(e);
      this.file_name = e.name;

      this.$refs.croppieRefAdd.refresh();
      this.$nextTick(() => {
        this.$refs.croppieRefAdd.bind({
          url: this.url
        });
      });
      e.value = undefined
    },
    addNewBanner() {
      let formData = new FormData();

      formData.append("name", "Banner");

      if (this.switch_reward) {
        formData.append("rewardID", this.product_id);
      } else {
        formData.append("rewardID", "");
      }

      if (this.switch_link) {
        formData.append("externalLink", this.link);
      } else {
        formData.append("externalLink", "");
      }

      let options = {
        format: "jpeg",
        type: "blob"
      };
      this.$refs.croppieRefAdd.result(options, output => {
        this.cropped = output;
        formData.append("image", this.cropped, this.file_name);

        Banner.create(this.tabId, { data: formData })
          .then(() => {
            this.getBanner();
          })
          .catch(e => {
            this.dialog_loading = false;
            console.error(e.response);
          });
        this.closeAddNewDialog();
        this.dialog_loading = true;
      });
    },
    deleteBanner() {
      this.dialog_loading = true;
      Banner.delete(this.banner && this.banner.id)
        .then(response => {
          console.log(response.data);

          this.dialog_loading = false
          this.getBanner();
        })
        .catch(e => {
          this.dialog_loading = false
          console.error(e.response);
        });

      this.dialog_delete = false;
      this.dialog = false;
    },
    editBanner() {
      this.dialog_loading = true;

      let formData = new FormData();

      if (this.switch_reward) {
        formData.append("rewardId", this.product_id);
      } else {
        formData.append("rewardId", "");
      }

      if (this.switch_link) {
        formData.append("externalLink", this.banner.externalLink);
      } else {
        formData.append("externalLink", "");
      }

      if (this.file) {
        let options = {
          format: "jpeg",
          type: "blob"
        };

        this.$refs.croppieRef.result(options, output => {
          this.cropped = output;
          formData.append("image", this.cropped, this.file_name);

          this.updateBanner(formData)
        });
      } else {
        formData.append("image", "");

        this.updateBanner(formData)
      }

      this.closeEditDialog();
    },
    updateBanner(formData) {
      Banner.update(this.banner && this.banner.id || 0, { data: formData })
        .then(() => {
          this.dialog_loading = false;
          this.getBanner();
        })
        .catch(e => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },
    postBanner() {
      this.dialog_loading = true;

      Banner.public(this.tabId, { data: { banners: this.banners } })
        .then(() => {
          this.fetch();
          this.dialog_loading = false;
        })
        .catch(e => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },
    toggle_reward() {
      if (this.switch_reward) {
        this.switch_link = false;
      }
    },
    toggle_link() {
      if (this.switch_link) {
        this.switch_reward = false;
      }
    },
    result(output) {
      this.cropped = output;
    }
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-name {
  text-align: center;
  font-size: 20px;
}
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
.show-btns {
  color: rgba(0, 0, 0, 1) !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.custom-croppie {
  width: 570px;
  height: 312px;
  margin: 0 auto;
}
</style>
