<template>
  <div>
    <SideBar :bar_name="`Chang TT ${tabName}`" :user="user"></SideBar>
    <v-main>
      <v-container>
        <ApproveAreaPromoterCRMComponent v-bind="{ user, tabName }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";
import ApproveAreaPromoterCRMComponent from "@/components/ChangTT/ApproveAreaPromoterCRM";

export default {
  name: "CTTApproveAreaPromoterCRM",
  components: {
    SideBar,
    ApproveAreaPromoterCRMComponent,
  },
  data() {
    return {
      user: this.$user,
      tabName: "Approve Area Promoter CRM",
    };
  },
};
</script>
