<template>
  <div>
    <v-layout wrap>
      <div style="display: contents" v-for="each in region" :key="each.id">
        <v-flex
          xs2
          md2
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h1 style="margin-bottom: 0px">{{ each.name }}</h1>

          <v-btn
            icon
            color="#424242"
            @click="openAddDialog(each)"
            v-if="user.role != 'normal'"
          >
            <v-icon size="35px">add_circle</v-icon>
          </v-btn>

          <!-- <v-btn
                @click="openAddDialog(each)"
                v-if="user.role != 'normal'"
              >Add New Promotion
          </v-btn>-->
        </v-flex>

        <v-flex xs10 md10>
          <v-btn
            style="margin: 3%; float: right"
            @click="postPromotion(each)"
            v-if="user.role != 'normal'"
            >Post</v-btn
          >
        </v-flex>

        <draggable
          v-model="each.Promotion"
          style="
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: start;
          "
          :animation="500"
          draggable=".item"
          @start="dragging = true"
          @end="dragging = false"
          :sort="sortable"
        >
          <div
            v-for="element in each.Promotion"
            :key="element.id"
            class="item mx-2"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                width="250"
                height="280"
                style="margin-bottom: 10%; border-radius: 15px"
              >
                <v-img
                  class="white--text"
                  :src="`${element.image}`"
                  :aspect-ratio="1.5"
                >
                  <p
                    style="
                      background-color: red;
                      text-align: center;
                      color: white;
                    "
                    v-if="!element.isPublic"
                  >
                    Not posted yet
                  </p>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-3 white--text"
                      style="height: 100%"
                    >
                      <v-btn
                        color="white"
                        icon
                        @click="openEditDialog(element)"
                        v-if="user.role != 'normal'"
                      >
                        <v-icon color="white">edit</v-icon>
                      </v-btn>
                      <v-btn
                        color="white"
                        icon
                        @click="openDetailDialog(element)"
                        v-else
                      >
                        <v-icon color="white">search</v-icon>
                      </v-btn>
                    </div>
                  </v-expand-transition>
                </v-img>

                <v-card-text>
                  <div class="text-item">
                    <span class="text--primary">{{ element.title }}</span>
                  </div>
                  <div>Display: {{ dateFormat(element.displayTime) }}</div>
                  <div>Start: {{ dateFormat(element.startTime) }}</div>
                  <div>Stop: {{ dateFormat(element.stopTime) }}</div>
                </v-card-text>
              </v-card>
            </v-hover>
          </div>
        </draggable>
      </div>

      <NotFound v-if="region.length <= 0" />
    </v-layout>

    <!-- <div>------------------------------------- Detail Chang Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="detail_dialog" max-width="600" scrollable persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >ช้างขายดี</v-card-title
        >

        <v-card-text>
          <div style="padding: 20px">
            <!-- <v-img v-if="url" :src="url" style="width: 100%" /> -->
            <v-img
              v-if="file == ''"
              style="width: 100%"
              :src="`${promotion.image}`"
            />
          </div>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>Title:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                promotion.title
              }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Description:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                promotion.desc
              }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Start:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                moment(promotion.startTime).format('DD-MM-YYYY HH:mm')
              }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Stop:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                moment(promotion.stopTime).format('DD-MM-YYYY HH:mm')
              }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Display:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                moment(promotion.displayTime).format('DD-MM-YYYY HH:mm')
              }}</v-list-item-content>
            </v-list-item>

            <div v-if="switch_reward">
              <v-list-item>
                <v-list-item-content>Category:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  categoryData.title
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Reward:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  promotion.reward && promotion.reward.title
                }}</v-list-item-content>
              </v-list-item>
            </div>

            <div v-if="switch_link">
              <v-list-item>
                <v-list-item-content>Link:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <a href="promotion.externalURL" target="_blank">{{
                    promotion.externalURL
                  }}</a>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="headline grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn @click="closeDetailDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Edit Chang Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog" max-width="600" scrollable persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Edit ช้างขายดี</v-card-title
        >

        <v-card-text>
          <div style="padding: 20px">
            <!-- <v-img v-if="url" :src="url" style="width: 100%" /> -->

            <v-img
              v-if="file == ''"
              style="width: 100%"
              :src="`${promotion.image}`"
            />

            <vue-croppie
              v-show="file != ''"
              ref="croppieRef"
              :enableResize="false"
              @result="result"
              :viewport="{ width: 422, height: 280 }"
              resultType="blob"
              customClass="custom-croppie"
            ></vue-croppie>

            <v-file-input
              label="File input"
              outlined
              @change="onFileChange"
              accept="image/*"
              prepend-icon="mdi-camera"
              style="margin-top: 48px"
              v-model="files"
            ></v-file-input>

            <v-text-field
              label="Promotion Title"
              required
              v-model="promotion.title"
              outlined
              :rules="[rules.required]"
            ></v-text-field>

            <v-textarea
              outlined
              label="Description"
              v-model="promotion.desc"
              :rules="[rules.required]"
            ></v-textarea>

            <div
              style="display: flex; align-items: center; margin-bottom: 16px"
            >
              <div style="margin-right: 2%; width: 100px">Promo Start:</div>

              <VueCtkDateTimePicker
                v-model="promotion.startTime"
                format="YYYY-MM-DD HH:mm"
                formatted="DD-MM-YYYY HH:mm"
                noLabel
                noClearButton
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>

            <div
              style="display: flex; align-items: center; margin-bottom: 16px"
            >
              <div style="margin-right: 2%; width: 100px">Promo Stop:</div>

              <VueCtkDateTimePicker
                v-model="promotion.stopTime"
                format="YYYY-MM-DD HH:mm"
                formatted="DD-MM-YYYY HH:mm"
                noLabel
                noClearButton
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>

            <div
              style="display: flex; align-items: center; margin-bottom: 16px"
            >
              <div style="margin-right: 2%; width: 100px">Display:</div>

              <VueCtkDateTimePicker
                v-model="promotion.displayTime"
                format="YYYY-MM-DD HH:mm"
                formatted="DD-MM-YYYY HH:mm"
                noLabel
                noClearButton
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px">
            <v-switch
              v-model="switch_reward"
              :label="`Reward Link`"
              color="success"
              @change="toggle_reward"
            ></v-switch>

            <v-select
              outlined
              :items="categories"
              label="Category"
              v-model="category"
              @change="onCatChange"
              item-text="title"
              item-value="id"
              :disabled="!switch_reward"
            ></v-select>

            <v-select
              outlined
              :items="rewardItems"
              label="Reward Item"
              item-text="description"
              item-value="id"
              :disabled="!switch_reward"
              v-model="product_id"
            ></v-select>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px">
            <v-switch
              v-model="switch_link"
              :label="`Link`"
              color="success"
              @change="toggle_link"
            ></v-switch>

            <v-text-field
              label="Link"
              required
              v-model="promotion.externalURL"
              outlined
              :disabled="!switch_link"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="dialog_delete = true">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeEditDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="editPromotion"
            :disabled="this.promotion.desc == '' || this.promotion.title == ''"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Add Chang Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_add_new" max-width="600" scrollable persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Add New Promotion</v-card-title
        >

        <v-card-text>
          <div style="padding: 20px">
            <!-- <img v-if="url" :src="cropped" style="width: 100%" /> -->
            <img
              v-show="file == ''"
              style="width: 100%"
              src="http://ctt.trains.com/sitefiles/images/no-preview-available.png"
            />

            <vue-croppie
              v-show="file != ''"
              ref="croppieRefAdd"
              :enableResize="false"
              @result="result"
              :viewport="{ width: 422, height: 280 }"
              resultType="blob"
              customClass="custom-croppie"
            ></vue-croppie>

            <!-- <img v-bind:src="cropped" /> -->

            <!-- <button @click="crop()">Crop Via Callback</button> -->

            <v-file-input
              label="File input"
              outlined
              @change="onFileChange_add"
              accept="image/*"
              prepend-icon="mdi-camera"
              style="margin-top: 48px"
              v-model="files"
            ></v-file-input>

            <v-text-field
              label="Promotion Title"
              required
              v-model="name"
              outlined
              :rules="[rules.required]"
            ></v-text-field>

            <v-textarea
              outlined
              label="Description"
              v-model="description"
              :rules="[rules.required]"
            ></v-textarea>

            <div
              style="display: flex; align-items: center; margin-bottom: 16px"
            >
              <div style="margin-right: 2%; width: 100px">Promo Start:</div>

              <VueCtkDateTimePicker
                v-model="date_time_start_add"
                format="YYYY-MM-DD HH:mm"
                formatted="DD-MM-YYYY HH:mm"
                noLabel
                noClearButton
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>

            <div
              style="display: flex; align-items: center; margin-bottom: 16px"
            >
              <div style="margin-right: 2%; width: 100px">Promo Stop:</div>

              <VueCtkDateTimePicker
                v-model="date_time_stop_add"
                format="YYYY-MM-DD HH:mm"
                formatted="DD-MM-YYYY HH:mm"
                noLabel
                noClearButton
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>

            <div style="display: flex; align-items: center">
              <div style="margin-right: 2%; width: 100px">Display:</div>

              <VueCtkDateTimePicker
                v-model="date_time_display_add"
                format="YYYY-MM-DD HH:mm"
                formatted="DD-MM-YYYY HH:mm"
                noLabel
                noClearButton
                color="#4caf50"
                button-color="#4caf50"
              />
            </div>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px">
            <v-switch
              v-model="switch_reward"
              :label="`Reward Link`"
              color="success"
              @change="toggle_reward"
            ></v-switch>

            <v-select
              outlined
              :items="categories"
              label="Category"
              v-model="category"
              @change="onCatChange"
              item-text="title"
              item-value="id"
              :disabled="!switch_reward"
            ></v-select>

            <v-select
              outlined
              :items="rewardItems"
              label="Reward Item"
              item-text="description"
              item-value="id"
              :disabled="!switch_reward"
              v-model="product_id"
            ></v-select>
          </div>

          <v-divider></v-divider>

          <div style="padding: 20px">
            <v-switch
              v-model="switch_link"
              :label="`Link`"
              color="success"
              @change="toggle_link"
            ></v-switch>

            <v-text-field
              label="Link"
              required
              v-model="link"
              outlined
              :disabled="!switch_link"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeAddDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            v-on:click="addNewPromotion"
            :disabled="
              this.file == '' ||
              this.name == '' ||
              this.description == '' ||
              this.date_time_start_add == '' ||
              this.date_time_stop_add == null ||
              this.date_time_display_add == ''
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Delete Promotion Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_delete" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Confirm delete?</v-card-title>
        <v-card-text
          >Are you sure that you want to delete this Promotion. This promotion
          will be gone permanently.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="dialog_delete = false" text
            >Cancel</v-btn
          >
          <v-btn color="red" text @click="deletePromotion">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />กรุณารอสักครู่....
          <br />
          <br />

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import draggable from 'vuedraggable'
import moment from 'moment'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css' // import the croppie css manually
import _ from 'lodash'

import NotFound from '@/components/NotFound'
import Catalog from '@/store/models/catalog'
import Reward from '@/store/models/reward'
import Promotion from '@/store/models/promotion'

Vue.use(VueCroppie)

export default {
  name: 'Promotion',
  components: {
    draggable,
    VueCtkDateTimePicker,
    NotFound,
  },
  props: ['user', 'tabId', 'tabName'],
  data() {
    return {
      detail_dialog: false,
      dialog: false,
      url: null,
      dialog_add_new: false,
      dialog_delete: false,
      description: '',
      name: '',
      region: [],
      image_edit: '',
      useSeconds: true,
      cropped: null,
      full_d_t_start: '',
      full_d_t_stop: '',
      full_d_t_display: '',

      date_time_start_add: '',
      date_time_stop_add: '',
      date_time_display_add: '',

      promotion_name_edit: '',

      rules: {
        required: (value) => !!value || 'Required.',
      },

      promotion: {},
      reg: '',
      disabled_add: true,
      file: '',
      files: [],
      categories: [],
      rewardItems: [],
      category: 0,
      categoryData: {},
      switch_reward: false,
      switch_link: false,
      product_id: 0,
      sortable: true,
      dialog_loading: true,
      file_name: '',
      link: '',
      shopID: this.tabId,
      shopName: this.tabName,
    }
  },
  created() {
    this.fetch()
  },
  mounted() {},
  methods: {
    moment,
    fetch() {
      if (this.user.role == 'normal') {
        this.sortable = false
      }
      this.getPromotions()
      this.getCategory()
    },
    getCategory() {
      Catalog.gets({ params: { typeID: this.shopID } })
        .then((response) => {
          this.categories = (response.data && response.data.catalog) || []
          this.category = this.categories[0] && this.categories[0].id
          this.getRewardItems(this.category)
        })
        .catch((e) => {
          console.error(e.response)
        })
    },
    getRewardItems(cat) {
      Reward.gets(this.shopID, {
        params: { catalog: cat, per_page: 1000, page: 1 },
      })
        .then((response) => {
          this.rewardItems = (response.data && response.data.reward) || []
        })
        .catch((e) => {
          console.error(e.response)
        })
    },
    onCatChange() {
      this.getRewardItems(this.category)
    },
    getPromotions() {
      Promotion.gets(this.shopID)
        .then((response) => {
          this.dialog_loading = false
          this.region = response.data
        })
        .catch((e) => {
          this.dialog_loading = false
          console.error(e)
        })
    },
    openDetailDialog(ele) {
      this.promotion = _.cloneDeep(ele)
      if (!(ele.reward && ele.reward.id)) {
        if (ele.externalURL) {
          this.switch_link = true
        }
        this.switch_reward = false
      } else {
        this.switch_link = false
        this.switch_reward = true
        this.category = ele.reward && ele.reward.reward_catalog_id
        this.categoryData = this.categories.find((e) => e.id == this.category)
        this.product_id = ele.reward && ele.reward.id
      }
      this.detail_dialog = true
      this.$nextTick(() => {
        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
      })
    },
    openEditDialog(ele) {
      this.promotion = _.cloneDeep(ele)
      if (!(ele.reward && ele.reward.id)) {
        if (ele.externalURL) {
          this.switch_link = true
        }
        this.switch_reward = false
      } else {
        this.switch_link = false
        this.switch_reward = true
        this.category = ele.reward && ele.reward.reward_catalog_id
        this.product_id = ele.reward && ele.reward.id
      }
      this.dialog = true
      this.$nextTick(() => {
        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
      })
    },
    onFileChange(e) {
      this.file = e
      this.url = URL.createObjectURL(e)
      this.file_name = e.name
      // this.$refs.croppieRef.bind({
      //   url: "http://i.imgur.com/Fq2DMeH.jpg"
      // });

      this.$refs.croppieRef.bind({
        url: this.url,
      })
      e.value = undefined
    },
    onFileChange_add(e) {
      this.file = e
      this.url = URL.createObjectURL(e)
      this.file_name = e.name

      this.$refs.croppieRefAdd.refresh()
      this.$nextTick(() => {
        this.$refs.croppieRefAdd.bind({
          url: this.url,
        })
      })
      e.value = undefined
    },
    openAddDialog(r) {
      this.reg = r
      this.dialog_add_new = true

      this.$nextTick(() => {
        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
      })
    },
    closeAddDialog() {
      this.name = ''
      this.file = ''
      this.url = null
      this.files = []
      this.dialog_add_new = false
      this.description = ''
      this.category = null
      this.product_id = null

      this.date_time_start_add = ''
      this.date_time_stop_add = ''
      this.date_time_display_add = ''

      this.switch_reward = false
      this.switch_link = false

      this.cropped = null

      this.$nextTick(() => {
        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
      })
    },
    closeDetailDialog() {
      this.file = ''
      this.url = null
      this.files = []
      this.detail_dialog = false
      this.category = null
      this.product_id = null

      this.cropped = null

      this.full_d_t_start = ''
      this.full_d_t_stop = ''
      this.full_d_t_display = ''

      this.switch_reward = false
      this.switch_link = false

      this.$nextTick(() => {
        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
      })
    },
    closeEditDialog() {
      this.file = ''
      this.url = null
      this.files = []
      this.dialog = false
      this.category = null
      this.product_id = null

      this.cropped = null

      this.full_d_t_start = ''
      this.full_d_t_stop = ''
      this.full_d_t_display = ''

      this.switch_reward = false
      this.switch_link = false

      this.$nextTick(() => {
        document.getElementsByClassName('v-card__text')[0].scrollTop = 0
      })
    },
    addNewPromotion() {
      let formData = new FormData()

      formData.append('title', this.name)
      formData.append('description', this.description)
      formData.append(
        'startTime',
        moment(this.date_time_start_add).format('YYYY-MM-DD HH:mm:ss')
      )
      formData.append(
        'stopTime',
        moment(this.date_time_stop_add).format('YYYY-MM-DD HH:mm:ss')
      )
      formData.append(
        'displayTime',
        moment(this.date_time_display_add).format('YYYY-MM-DD HH:mm:ss')
      )

      if (this.switch_reward) {
        formData.append('rewardID', this.product_id)
      } else {
        formData.append('rewardID', '')
      }

      if (this.switch_link) {
        formData.append('externalURL', this.link)
      } else {
        formData.append('externalURL', '')
      }

      let options = {
        format: 'jpeg',
        type: 'blob',
      }
      this.$refs.croppieRefAdd.result(options, (output) => {
        this.cropped = output
        formData.append('imageData', this.cropped, this.file_name)

        if (
          this.date_time_start_add <= this.date_time_stop_add &&
          this.date_time_start_add <= this.date_time_display_add &&
          this.date_time_display_add <= this.date_time_stop_add
        ) {
          this.dialog_loading = true
          Promotion.create(this.shopID, this.reg.id, { data: formData })
            .then(() => {
              this.getPromotions()
              this.dialog_loading = false
            })
            .catch((e) => {
              console.error(e.response)
              this.dialog_loading = false
            })
          this.closeAddDialog()
        } else {
          this.$swal(
            'วันที่ promotion start ต้องมาก่อน วันที่ promotion stop และ promotion display ต้องอยู่ระหว่าง promotion start และ promotion stop'
          )
        }
      })
    },
    editPromotion() {
      let formData = new FormData()
      formData.append('title', this.promotion.title)
      formData.append('description', this.promotion.desc)
      formData.append(
        'startTime',
        moment(this.promotion.startTime).format('YYYY-MM-DD HH:mm:ss')
      )
      formData.append(
        'stopTime',
        moment(this.promotion.stopTime).format('YYYY-MM-DD HH:mm:ss')
      )
      formData.append(
        'displayTime',
        moment(this.promotion.displayTime).format('YYYY-MM-DD HH:mm:ss')
      )

      if (this.switch_reward) {
        formData.append('rewardID', this.product_id)
      } else {
        formData.append('rewardID', '')
      }

      if (this.switch_link) {
        formData.append('externalURL', this.promotion.externalURL)
      } else {
        formData.append('externalURL', '')
      }

      if (this.file) {
        let options = {
          format: 'jpeg',
          type: 'blob',
        }

        this.$refs.croppieRef.result(options, (output) => {
          this.cropped = output
          formData.append('imageData', this.cropped, this.file_name)

          this.updatePromotion(formData)
        })
      } else {
        this.updatePromotion(formData)
      }
    },
    updatePromotion(formData) {
      if (
        this.promotion.startTime <= this.promotion.stopTime &&
        this.promotion.startTime <= this.promotion.displayTime &&
        this.promotion.displayTime <= this.promotion.stopTime
      ) {
        this.dialog_loading = true
        Promotion.update(this.promotion.id, { data: formData })
          .then(() => {
            this.getPromotions()
          })
          .catch((e) => {
            this.dialog_loading = false
            console.error(e)
          })

        this.closeEditDialog()
      } else {
        this.$swal(
          'วันที่ promotion start ต้องมาก่อน วันที่ promotion stop และ promotion disply ต้องอยู่ระหว่าง promotion start และ promotion stop'
        )
      }
    },
    deletePromotion() {
      this.dialog_loading = true
      Promotion.delete(this.promotion.id)
        .then(() => {
          this.getPromotions()
          this.dialog_delete = false
          this.dialog_loading = false
        })
        .catch((e) => {
          this.dialog_delete = false
          this.dialog_loading = false
          console.error(e)
        })

      this.closeEditDialog()
    },
    postPromotion(each) {
      this.dialog_loading = true

      Promotion.public(this.shopID, each.id, {
        data: { promotions: each.Promotion },
      })
        .then(() => {
          this.getPromotions()
        })
        .catch((e) => {
          this.dialog_loading = false
          console.error(e)
        })
    },
    result(output) {
      this.cropped = output
    },
    toggle_reward() {
      if (this.switch_reward) {
        this.switch_link = false
      }
    },
    toggle_link() {
      if (this.switch_link) {
        this.switch_reward = false
      }
    },
    crop() {
      // Here we are getting the result via callback function
      // and set the result to this.cropped which is being
      // used to display the result above.
      let options = {
        format: 'jpeg',
        type: 'blob',
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output
      })
    },
    dateFormat(date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-name {
  text-align: center;
  font-size: 20px;
}
.lg5-custom {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.custom-croppie {
  width: 512px;
  height: 300px;
  margin: 0 auto;
}

.text-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
