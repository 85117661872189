import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './views/Dashboard.vue'
import Product from './views/Product.vue'
import Catagory from './views/Catagory.vue'
import Promotion from './views/Promotion.vue'
import PointManagement from './views/PointManagement.vue'
import BannerManagement from './views/BannerManagement.vue'
import RedeemedTransactions from './views/RedeemedTransactions.vue'
import AgentManagement from './views/AgentManagement.vue'
import UserManagement from './views/UserManagement.vue'
import Profile from './views/Profile.vue'
import SaleManagement from './views/SaleManagement.vue'
import Login from './views/Login'
import ForgetPassword from './views/ForgetPassword'
import Register from './views/Register'
import RedeemForm from './views/RedeemForm'
import ConfirmPassword from './views/ConfirmPassword'
import Notify from './views/Notify'
import ReportEmail from './views/ReportEmail'
import AgentLineManagement from './views/AgentLineManagement'
import EmployeeLineManagement from './views/EmployeeLineManagement'
import SmartAlert from './views/SmartAlert'
import AgentBranch from './views/AgentBranch'
// import TargetStep from './views/TargetStep'
// import Privileges from './views/Privileges'
import CTTDashboard from './views/CTTDashboard'
import CTTCampaignDashboard from './views/CTTCampaignDashboard'
import CTTRetailShop from './views/CTTRetailShop'
import CTTAreaPromoter from './views/CTTAreaPromoter'
import CTTAreaPromoterCRM from './views/CTTAreaPromoterCRM'
import CTTApproveAreaPromoterCRM from './views/CTTApproveAreaPromoterCRM'
import CTTReward from './views/CTTReward'
import CTTSpecialReward from './views/CTTSpecialReward'
import CTTVansales from './views/CTTVansales'

import PDFViewer from './views/PDFViewer'

// import Obs from './views/Obs'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: ForgetPassword,
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    // ------ Chang Family ------
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/product',
      name: 'product',
      component: Product,
    },
    {
      path: '/catagory',
      name: 'catagory',
      component: Catagory,
    },
    {
      path: '/promotion',
      name: 'promotion',
      component: Promotion,
    },
    {
      path: '/pointManagement',
      name: 'pointManagement',
      component: PointManagement,
    },
    {
      path: '/bannerManagement',
      name: 'bannerManagement',
      component: BannerManagement,
    },
    {
      path: '/redeemedTransactions',
      name: 'redeemedTransactions',
      component: RedeemedTransactions,
    },
    {
      path: '/userManagement',
      name: 'userManagement',
      component: UserManagement,
    },
    {
      path: '/agentManagement',
      name: 'agentManagement',
      component: AgentManagement,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/saleManagement',
      name: 'saleManagement',
      component: SaleManagement,
    },
    {
      path: '/redeemForm',
      name: 'redeemForm',
      component: RedeemForm,
    },
    {
      path: '/confirmPassword',
      name: 'confirmPassword',
      component: ConfirmPassword,
    },
    {
      path: '/notify',
      name: 'notify',
      component: Notify,
    },
    {
      path: '/reportEmail',
      name: 'reportEmail',
      component: ReportEmail,
    },
    {
      path: '/agentLineManagement',
      name: 'agentLineManagement',
      component: AgentLineManagement,
    },
    {
      path: '/employeeLineManagement',
      name: 'employeeLineManagement',
      component: EmployeeLineManagement,
    },
    {
      path: '/smartAlert',
      name: 'smartAlert',
      component: SmartAlert,
    },
    {
      path: '/agentBranch',
      name: 'agentBranch',
      component: AgentBranch,
    },
    // {
    //   path: '/targetStep',
    //   name: 'targetStep',
    //   component: TargetStep
    // },
    // {
    //   path: '/privileges',
    //   name: 'privileges',
    //   component: Privileges
    // },
    // ------ Chang TT ------
    {
      path: '/cttDashboard',
      name: 'cttDashboard',
      component: CTTDashboard,
    },
    {
      path: '/cttCampaignDashboard',
      name: 'cttCampaignDashboard',
      component: CTTCampaignDashboard,
    },
    {
      path: '/cttRetailShop',
      name: 'cttRetailShop',
      component: CTTRetailShop,
    },
    {
      path: '/cttAreaPromoter',
      name: 'cttAreaPromoter',
      component: CTTAreaPromoter,
    },
    {
      path: '/cttVansales',
      name: 'cttVansales',
      component: CTTVansales,
    },
    {
      path: '/cttAreaPromoterCRM',
      name: 'cttAreaPromoterCRM',
      component: CTTAreaPromoterCRM,
    },
    {
      path: '/cttReward',
      name: 'cttReward',
      component: CTTReward,
    },
    {
      path: '/cttSpecialReward',
      name: 'cttSpecialReward',
      component: CTTSpecialReward,
    },
    {
      path: '/cttApproveAreaPromoterCRM',
      name: 'cttApproveAreaPromoterCRM',
      component: CTTApproveAreaPromoterCRM,
    },
    {
      path: '/pdfViewer',
      name: 'pdfViewer',
      component: PDFViewer,
    },
    // {
    //   path: '/obs',
    //   name: 'obs',
    //   component: Obs,
    // },
  ],
})

router.beforeEach((to, from, next) => {
  const user = Vue.prototype.$user
  const ciPages = ['/notify', '/reportEmail', '/smartAlert', '/agentBranch']
  const regionPages = [
    '/cttDashboard',
    '/cttAreaPromoter',
    '/cttVansales',
    '/cttAreaPromoterCRM',
    '/cttReward',
    '/cttSpecialReward',
    '/cttApproveAreaPromoterCRM',
  ]

  // CI
  if (ciPages.includes(to.path) && user.role != 'ci') {
    return next('/product')
  }

  // Region Admin
  if (
    regionPages.includes(to.path) &&
    !(user.role === 'ci' || user.role === 'admin')
  ) {
    return next('/product')
  }

  next()
})

export default router
