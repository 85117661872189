/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Variable extends Model {
  static fields() {
    return {
      id: this.string(),
      name: this.string(),
      variable: this.string(),
      field: this.string(),
      description: this.string(),
      type: this.string(),
      filter: this.boolean(false),
      display: this.boolean(true),
      rcpt: this.attr([]),
      source: this.attr([]),
    }
  }

  static list(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/alert/variables`,
      dataTransformer: (response) => {
        return response && response.data || []
      },
      ...options
    }).then((res) => {
      const data = res.response.data;
      return data.reduce((o, e) => {
        const fields = e.field.split(",")
        o = fields.reduce((oo, ee) => {
          const variable = `${e.variable.trim()}.${ee.trim()}`;
          oo.push(`${e.name} {{${variable}}}`)

          return oo;
        }, o);

        return o;
      }, []);
    })
  }

  static group(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/alert/variables`,
      dataTransformer: (response) => {
        return response && response.data || []
      },
      ...options
    }).then((res) => res.response.data)
  }

  static links(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/alert/variables/links`,
      dataTransformer: (response) => {
        return response && response.data || []
      },
      ...options
    }).then((res) => res.response.data)
  }
}

Variable.entity = 'variable'

export default Variable