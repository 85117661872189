<template>
  <div>
    <v-layout v-if="!dialog" wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer></v-spacer>
            <v-btn
              class="mr-3 chang-btn"
              :loading="loading"
              color="#006b35"
              @click="_new()"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              เพิ่ม
            </v-btn>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="ค้นหา"
              outlined
              dense
              :hide-details="true"
              @keydown.enter="_getByQuery"
            ></v-text-field>
          </v-card-title>

          <v-card-text>
            <v-card class="mb-4">
              <v-card-text>
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="headers"
                  :items="indexedItems"
                  :footer-props="{ 'items-per-page-options': $itemsPerPage }"
                  :items-per-page="limit"
                  :loading="loading"
                  :options.sync="options"
                  :server-items-length="countItems"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                >
                  <template v-slot:item.fullName="{ item }">
                    {{ `${item.firstName} ${item.lastName}` }}
                  </template>
                  <template v-slot:item.birthDate="{ item }">
                    {{ _birthDate(item.birthDate) }}
                  </template>
                  <template v-slot:item.registered="{ item }">
                    <span v-html="_registered(item.registered)"></span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      :loading="loading"
                      @click="_edit(item)"
                      depressed
                      block
                    >
                      <v-icon left>
                        mdi-pencil-outline
                      </v-icon>
                      แก้ไข
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Form -----------------------------------------------------</div> -->
    <FormAreaPromoter v-model="dialog" :data="this.form" @fetch="fetchData" @submit="_save" />
  </div>
</template>

<script>
import _ from "lodash";

import FormAreaPromoter from "@/components/ChangTT/FormAreaPromoter";

import AreaPromoterModel from "@/store/models/changtt/areaPromoter";

const formDefault = {};

export default {
  name: "AreaPromoterComponent",
  components: {
    FormAreaPromoter,
  },
  props: ["user", "tabName"],
  computed: {
    indexedItems() {
      return (
        this.items.map((item, index) => ({
          index,
          ...item,
        })) || []
      );
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      limit: 10,
      countItems: 0,
      confirm: {
        status: false,
      },
      headers: [
        {
          text: "รหัสพนักงาน",
          value: "code",
          sortable: false,
        },
        { text: "ชื่อ-นามสกุล", value: "fullName", sortable: false, },
        { text: "เลขบัตรประชาชน", value: "citizenId", sortable: false, },
        { text: "วันเกิด", value: "birthDate", sortable: false, },
        { text: "สถานะไลน์", value: "registered", sortable: false, },
        { text: "", align: "center", value: "actions", sortable: false, },
      ],
      items: [],
      dialog: false,
      form: formDefault,
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let options = { params: {} };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.limit = this.options.itemsPerPage;
      } else {
        options.params.limit = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      const res = await AreaPromoterModel.list(options);
      this.items = (res && res.data) || [];
      this.countItems = res && res.total || 0;
      this.loading = false;
    },
    _getByQuery() {
      this.selected = null;
      this.options.page = 1;
      this.fetchData();
    },
    _birthDate(birthdate = "") {
      return this.$stringDateAdToBe(birthdate);
    },
    _registered(registered = false) {
      return (registered)? '<span style="color: #1f9812;">ลงทะเบียนแล้ว</span>': '<span style="color: #ff0000;">ยังไม่ได้ลงทะเบียน</span>';
    },
    _new() {
      this.dialog = true;
      this.form = _.cloneDeep(formDefault);
    },
    _edit(data = {}) {
      this.dialog = true;
      this.form = data;
    },
    async _save(data = {}) {
      this.loading = true;

      const options = {
        data,
      };
      let res;
      if (data.id) {
        res = await AreaPromoterModel.update(data.id, options).catch((err) =>
          console.error(err)
        );
      } else {
        res = await AreaPromoterModel.create(options).catch((err) =>
          console.error(err)
        );
      }
      if (res) {
        this.fetchData();
        this.dialog = false;
        this.form = {};
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>
