<template>
  <v-flex xs12 md12 class="mt-4" v-if="value">
    <v-card :outlined="true">
      <v-card-title>
        <v-col cols="6">
          {{_textTitle()}}
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn color="#fff" class="ml-2" @click="_add"><v-icon left> mdi-plus </v-icon>เพิ่มร้านค้า</v-btn>
          <v-btn color="#fff" class="ml-2" @click="_remove"><v-icon left> mdi-minus </v-icon>นำร้านออก</v-btn>
          <v-btn color="#fff" class="ml-2" @click="_transfer"><v-icon left> mdi-swap-horizontal </v-icon>เปลี่ยนผู้ดูแล</v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="clean-tb"
          item-key="index"
          :headers="headers"
          :items="indexedItems"
          :footer-props="{ 'items-per-page-options': $itemsPerPage }"
          :items-per-page="perPage"
          :loading="loading"
          :options.sync="options"
          :server-items-length="countItems"
          no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
          no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
        >
          <template v-slot:header.actions="{}">
            <v-checkbox :error="selectErr" v-model="selectAll"></v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td class="py-1">
                <v-img
                  :lazy-src="_image(item.image)"
                  max-width="150"
                  :src="_image(item.image)"
                ></v-img>
              </td>
              <td class="pb-1">{{item.code}}</td>
              <td class="pb-1">{{item.name}}</td>
              <td class="pb-1">{{_address(item)}}</td>
              <td class="pb-1 text-center">
                <v-checkbox :error="selectErr" v-model="item.selected"></v-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>

        <!-- <div>------------------------------------- Select Dialog -----------------------------------------------------</div> -->
        <v-dialog v-model="select.status" persistent max-width="600">
          <v-card>
            <v-card-title class="headline">{{ select.text }}?</v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="select.data"
                :items="selectItems"
                item-text="text"
                item-value="id"
                :search-input.sync="searchselect"
                :error="false"
                :hide-details="true"
                :label="select.text"
                no-filter
                return-object
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="black"
                :loading="loading"
                @click="select.status = false"
                text
                >ยกเลิก</v-btn
              >
              <v-btn
                color="primary"
                :loading="loading"
                @click="_select"
                text
                >บันทึก</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->
        <v-dialog v-model="confirm.status" persistent max-width="600">
          <v-card>
            <v-card-title class="headline"
              >ยืนยัน {{ confirm.title }}?</v-card-title
            >
            <v-card-text>{{ confirm.title }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="black"
                :loading="loading"
                @click="confirm.status = false"
                text
                >ยกเลิก</v-btn
              >
              <v-btn
                color="error"
                :loading="loading"
                @click="_confirm"
                text
                >{{ confirm.title }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import ShopModel from "@/store/models/changtt/shop";
import AgentModel from "@/store/models/agent";
import PartnerModel from "@/store/models/changtt/partner";
import AreaPromoterModel from "@/store/models/changtt/areaPromoter";
import VansalesModel from "@/store/models/changtt/vansales";

const defaultSelect = {
  status: false,
  text: "",
  data: {},
}

export default {
  name: "ShopRetailLink",
  components: {},
  props: ["value", "type", "data"],
  computed: {
    indexedItems() {
      return (
        this.items.map((item, index) => ({
          index,
          ...item,
        })) || []
      );
    },
    selectItems() {
      return this.selectList || [];
    },
    shopType() {
      // if(this.data && this.data.shopTypeId === 1) {
        return "เอเย่นต์";
      // } else if(this.data && this.data.shopTypeId === 2) {
      //   return "ซับเอเย่นต์";
      // } else {
      //   return "ซับซื้อตรง";
      // }
    },
  },
  created() {
    this.form = this.data
    if (this.form.id) {
      this.fetchRetail();
    }
  },
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      perPage: 10,
      countItems: 0,
      headers: [
        { text: "รูปภาพ", value: "image", width: 160, sortable: false },
        { text: "รหัสร้าน", value: "code", sortable: false },
        { text: "ชื่อร้าน", value: "name", sortable: false },
        { text: "ที่อยู่", value: "address", sortable: false },
        { text: "เลือกทั้งหมด", align: "center", width: 100, value: "actions", sortable: false },
      ],
      items: [],
      form: {},
      searchselect: "",
      select: defaultSelect,
      selectList: [],
      selectTMO: null,
      selectAll: false,
      selectErr: false,
      confirm: {
        status: false,
      },
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.form = this.data;

          if (this.form.id) {
            this.fetchRetail();
          }
        }
      },
      deep: true,
    },
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchRetail();
        }
      },
      deep: true,
    },
    searchselect: {
      handler(value) {
        if((this.select.data && this.select.data.text && this.select.data.text) != value) {
          this._listSelect();
        }
      },
    },
    selectAll: {
      handler(value) {
        this.items = this.items.map((e) => {
          e.selected = value;
          return e;
        });
      },
    }
  },
  methods: {
    async fetchRetail() {
      this.loading = true;

      if(this.form.id) {
        let options = { params: {} };
  
        if (this.search) {
          options.params.query = this.search;
        }
  
        if (this.options && this.options.itemsPerPage) {
          options.params.perPage = this.options.itemsPerPage;
        } else {
          options.params.perPage = 10;
        }
  
        if (this.options && this.options.page) {
          options.params.page = this.options.page;
        }
  
        let res = {}
        switch (this.type) {
          case "agent":
            res = await ShopModel.agentListRetail(this.form.code, options);
          break;
          case "vansales":
            res = await ShopModel.vansalesListRetail(this.form.id, options);
          break;
          default:
            res = await ShopModel.areaPromoterListRetail(this.form.id, options);
          break;
        }
        this.items = (res && res.data) || [];
        this.countItems = res && res.total || 0;
      }

      this.loading = false;
    },
    _textTitle() {
      if(this.type === "agent") {
        return `ร้านค้าปลีกภายใต้${this.shopType} ${this.form.name} ${(this.form.code)? "("+this.form.code+")": ""}`
      }

      return "ร้านค้าปลีกที่ดูแล";
    },
    _image(img) {
      if(img) {
        return img;
      }
      return require('@/assets/no-preview-available.png');
    },
    _address(data = {}) {
      return `${data.address} ${data.subDistrict && data.subDistrict.name} ${data.district && data.district.name} ${data.province && data.province.name} ${data.postcode}`
    },
    _add() {
      this.loading = true;
      let text = "เลือกร้านปลีก";
      this.select = {
        status: true,
        data: {},
        text,
        source: "retail"
      };

      this._listRetail()
      this.loading = false;
    },
    _remove() {
      const rmItems = this.indexedItems.reduce((o, e) => {
        if(e.selected) {
          o.push(e.id);
        }
        return o;
      }, []);

      if(rmItems.length > 0) {
        this.confirm = {
          status: true,
          type: "unlink",
          title: "นำร้านออก",
          data: rmItems,
        }
        this.selectErr = false;
      } else {
        this.selectErr = true;
      }
    },
    _listSelect() {
      const se = this.select
      switch (se.source) {
        case "agent":
          this._listAgent();
          break;

        case "areapromoter":
          this._listAreaPromoter();
          break;

        case "vansales":
          this._listVansales();
          break;
      
        default:
          this._listRetail();
          break;
      }
    },
    _listAgent() {
      if(this.selectTMO) {
        clearTimeout(this.selectTMO)
      }
      this.selectTMO = setTimeout(async () => {
        let search = ""
        if(this.select.data && this.searchselect != this.select.data.text) {
          search = this.searchselect
        }

        let res = [];
        let options = {
          params: { 
            page: 1,
            perPage: 20,
            query: search,
            shopTypeIds: "1,2,3",
          }
        }
        if(this.shopType === "เอเย่นต์") {
          res = await AgentModel
            .listAgent(options)
            .catch((err) => console.error(err));
        } else {
          res = await AgentModel
            .listSubAgent(options)
            .catch((err) => console.error(err));
        }

        this.selectList = res.data.map((e) => {
          e.text = `${e.code}: ${e.name} ${e.region && e.region.id && "ภาค"+e.region.id}`
          return e;
        }) || [];
      }, 500);
    },
    _listRetail() {
      if(this.selectTMO) {
        clearTimeout(this.selectTMO)
      }
      this.selectTMO = setTimeout(async () => {
        let search = ""
        if(this.select.data && this.searchselect != this.select.data.text) {
          search = this.searchselect
        }

        const res = await ShopModel
          .list({ params: { agentType: "retail", page: 1, limit: 20, query: search } })
          .catch((err) => console.error(err));
        this.selectList = res.data.map((e) => {
          e.text = `${e.code}: ${e.name}, ${e.district && e.district.name} ${e.subDistrict && e.subDistrict.name} ${e.province && e.province.name} ${e.postcode}`
          return e;
        }) || [];
      }, 500);
    },
    _listAreaPromoter() {
      if(this.selectTMO) {
        clearTimeout(this.selectTMO)
      }
      this.selectTMO = setTimeout(async () => {
        let search = ""
        if(this.select.data && this.searchselect != this.select.data.text) {
          search = this.searchselect
        }

        const res = await AreaPromoterModel
          .list({ params: { page: 1, limit: 20, query: search } })
          .catch((err) => console.error(err));
        this.selectList = res.data.map((e) => {
          e.text = `${e.code}: ${e.firstName} ${e.lastName}, ${e.phone}`
          return e;
        }) || [];
      }, 500);
    },
    _listVansales() {
      if(this.selectTMO) {
        clearTimeout(this.selectTMO)
      }
      this.selectTMO = setTimeout(async () => {
        let search = ""
        if(this.select.data && this.searchselect != this.select.data.text) {
          search = this.searchselect
        }

        let options = {
          params: { 
            page: 1, 
            limit: 20, 
            query: search, 
            agentSubType: "agent,sub-agent,sub-direct" 
          }
        }

        const res = await VansalesModel
          .list(options)
          .catch((err) => console.error(err));
        this.selectList = res.data.map((e) => {
          let shopType = "ซับเอเย่นต์"
          let shopName = ""
          if(e.shops && e.shops[0]) {
            if(e.shops[0].agentSubType && e.shops[0].agentSubType === "agent") {
              shopType = "เอเย่นต์"
            }
            shopName = `${e.shops[0].code} ${e.shops[0].name}`
          }

          e.text = `${e.firstName} ${e.lastName}, ${e.phone} ${shopType && '( '+shopType+': '+shopName+' )'}`
          return e;
        }) || [];
      }, 500);
    },
    async _select() {
      await this.submitSelect();

      this.select = defaultSelect;
      await this.fetchRetail();
    },
    async submitSelect() {
      const se = this.select;
      const data = se.data;
      
      if(se.source === "retail") {
        await this.link(data);
      } else {
        await this.transfer(data);
      }
    },
    async createAgent(agent = {}) {
      // Create Shop Agent
      var optsh = {
        data: {
          code: agent.code,
          name: agent.name,
          phone: agent.phone_number,
          address: agent.address,
          agentSubType: agent.type && agent.type.name || "agent"
        }
      }
      return ShopModel.createAgent(optsh).catch((err) => console.error(err));
    },
    async link(data = {}) {
      switch (this.type) {
        case "agent":
          var ag = await this.createAgent(this.data);
          return PartnerModel
            .create(data.id, ag.id, {})
            .catch((err) => console.error(err));
        case "vansales":
          return VansalesModel
            .linkBulk(data.id, { data: { vansalesId: [this.data.id] } })
            .catch((err) => console.error(err));
        default:
          return AreaPromoterModel
            .linkBulk(data.id, { data: { areaPromoterId: [this.data.id] } })
            .catch((err) => console.error(err));
      }
    },
    async _confirm() {
      const confirm = this.confirm;
      if(confirm.status) {
        if(confirm.type === "unlink") {
          await this._unlink()
        }
      }

      this.confirm = {
        status: false,
      }
      this.fetchRetail();
    },
    _transfer() {
      this.loading = true;
      let text = "เลือก";
      let source = this.type;
      switch (this.type) {
        case "agent":
          text = `เลือก${this.shopType}`;
        break;
        case "vansales":
          text = "เลือกรถเร่";
        break;
        default:
          text = "เลือก Area Promoter";
        break;
      }
      const tfItems = this.indexedItems.reduce((o, e) => {
        if(e.selected) {
          o.push(e.id);
        }
        return o;
      }, []);

      if(tfItems.length > 0) {
        this.select = {
          status: true,
          data: {},
          text,
          source
        };
        this.selectErr = false;
      } else {
        this.selectErr = true;
      }

      this._listSelect();
      this.loading = false;
    },
    async transfer(data = {}) {
      const form = this.form || {};
      const shops = this.indexedItems.reduce((o, e) => {
        if(e.selected) {
          o.push(e.id);
        }
        return o;
      }, []);
      const transferId = data.id;
      switch (this.type) {
        case "agent":
          var from = await this.createAgent(this.data);
          var to = await this.createAgent(data);
          await PartnerModel
            .transfer({ data: { fromId: from.id, toId: to.id, retailId: shops } })
            .catch((err) => console.error(err));
        break;
        case "vansales":
          await VansalesModel
            .transfer(form.id, { data: { transferId, shops } })
            .catch((err) => console.error(err));
        break;
        default:
          await AreaPromoterModel
            .transfer(form.id, { data: { transferId, shops } })
            .catch((err) => console.error(err));
        break;
      }
      this.selectAll = false;
    },
    async _unlink() {
      const form = this.form || {};
      const shops = this.confirm.data || [];
      switch (this.type) {
        case "agent":
          var ag = await this.createAgent(this.data);
          await PartnerModel
            .removeBuyers(ag.id, { data: { shopId: shops } })
            .catch((err) => console.error(err));
        break;
        case "vansales":
          await VansalesModel
            .unlinkShops(form.id, { data: { shops } })
            .catch((err) => console.error(err));
        break;
        default:
          await AreaPromoterModel
            .unlinkShops(form.id, { data: { shops } })
            .catch((err) => console.error(err));
        break;
      }
      this.selectAll = false;
    }
  }
};
</script>
