import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c(VLayout,{staticClass:"mx-4",attrs:{"wrap":""}},[_c(VFlex,{attrs:{"xs12":"","md12":""}},[_c(VRow,{staticClass:"mx-0"},[_c(VCol,{attrs:{"cols":"12","offset-1":""}},[_c(VBtn,{staticClass:"px-0",attrs:{"text":"","color":"rgba(0, 0, 0, 0.6)"},on:{"click":_vm._back}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-less-than ")]),_vm._v(" ย้อนกลับ ")],1)],1)],1),_c(VRow,{staticClass:"mx-0 mb-2"},[_c(VCol,{staticClass:"promoter-h1",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.form.id ? "Edit" : "Create")+" Reward ")])],1),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm._save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Reward","rules":[..._vm.rulesRequired],"required":"","outlined":"","dense":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('GalleryUploadFile',{attrs:{"label":"Image","max":1,"option":_vm.upOption,"editable":true,"accept":"image/*"},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextarea,{attrs:{"outlined":"","label":"Description","rules":[..._vm.rulesRequired]},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm._back}},[_vm._v("ยกเลิก")]),_c(VBtn,{staticClass:"ml-3 mr-2",attrs:{"color":"success","type":"submit","disabled":!_vm.valid}},[_vm._v("บันทึก")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }