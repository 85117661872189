import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"80%","scrollable":"","persistent":""},on:{"click:outside":_vm._close},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"outlined":true}},[_c(VCardTitle,{staticClass:"variable-title"},[_vm._v(" Variable "),_c(VSpacer),_c(VTextField,{staticStyle:{"margin-right":"2%"},attrs:{"append-icon":"search","label":"Search","placeholder":" ","hide-details":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCardText,[_c(VDataTable,{staticClass:"clean-tb",attrs:{"item-key":"index","search":_vm.search,"headers":_vm.headers,"items":_vm.items,"footer-props":{ 'items-per-page-options': _vm.$itemsPerPage },"items-per-page":_vm.perPage,"no-data-text":"ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล","no-results-text":"ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_vm._v(" "+_vm._s(item.title)+" "),_c(VIcon,{staticClass:"ml-1",on:{"click":function($event){return _vm._select(item.title)}}},[_vm._v(" mdi-clipboard-text-outline ")])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"error darken-1","text":""},on:{"click":_vm._close}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }