/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Reward extends Model {
  static fields() {
    return {
      id: this.number(),
      reward_catalog_id: this.number(),
      title: this.string(''),
      description: this.string(''),
      image: this.string(''),
      CreatedAt: this.string(''),
      UpdatedAt: this.string(''),
      DeletedAt: this.string(''),
      is_public: this.boolean(false),
      shop_type_id: this.number(),
      code: this.string(''),
      point: this.number(0),
      order: this.number(0),
    }
  }

  static gets(shopId, options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/v1/shop-types/${shopId}/rewards`,
        ...options,
      })
      .then((res) => res.response)
  }

  static get(id, options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/v1/rewards/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }

  static create(category, options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/v1/rewards/catalog/${category}`,
        ...options,
      })
      .then((res) => res.response)
  }

  static async update(id, options = {}) {
    return await this.api()
      .request({
        method: 'PUT',
        url: `/v1/rewards/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }

  static delete(id, options = {}) {
    return this.api()
      .request({
        method: 'DELETE',
        url: `/v1/rewards/${id}`,
        dataTransformer: () => [],
        ...options,
      })
      .then((res) => res.response)
  }

  static public(shopTypeId, options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/v1/rewards/public/shop-types/${shopTypeId}`,
        ...options,
      })
      .then((res) => res.response)
  }
}

Reward.entity = 'reward'

export default Reward
