<template>
  <v-dialog v-model="value" @click:outside="_close" max-width="80%" scrollable persistent>
    <v-card :outlined="true">
      <v-card-title class="variable-title">
        Variable
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          style="margin-right: 2%"
          placeholder=" "
          :hide-details="true"
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <v-data-table
          class="clean-tb"
          item-key="index"
          :search="search"
          :headers="headers"
          :items="items"
          :footer-props="{ 'items-per-page-options': $itemsPerPage }"
          :items-per-page="perPage"
          no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
          no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
        >
          <template v-slot:item.field="{ item }">
            <v-tooltip v-for="(v, i) in item.field.split(',')" :key="i" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  @click="_select(item, v)"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="mr-2 mb-2"
                >
                  {{ `${item.variable}.${v.trim()}` }}
                </v-chip>
              </template>
              <span>{{
                `${(option && option.operand && "Select") || "Copy"}`
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          color="error darken-1"
          @click="_close"
          text
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Variable from "@/store/models/variable";

export default {
  name: "SelectVariable",
  components: {},
  props: ["value", "option"],
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      perPage: 10,
      headers: [
        { text: "ชื่อ", value: "name", width: "10%", sortable: false },
        { text: "ตัวแปร", value: "field", width: "30%", sortable: false },
        { text: "ประเภท", value: "type", width: "10%", sortable: false },
        { text: "คำอธิบาย", value: "description", sortable: false },
      ],
      items: [],
    };
  },
  watch: {
    value: {
      handler(value) {
        if(value) {
          this.fetchData();
        }
      },
      deep: true,
    },
    option: {
      handler(value) {
        if(value.status) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const option = this.option
      let options = {
        params: {
          relation: `${option.rcpt || ""}-${option.source || ""}`
        }
      };

      const res = await Variable.group(options);
      this.items = res || [];
      this.loading = false;
    },
    _close() {
      this.$emit("input", false);
    },
    _select(item, value) {
      const option = this.option;
      const variable = `${item.variable.trim()}.${value.trim()}`;

      if (option && option.operand) {
        this.$emit("click", `${item.name} {{${variable}}}`);
      } else {
        navigator.clipboard.writeText(`{{${variable}}}`);
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none !important;
}

.col {
  padding: 5px 10px !important;
}

.variable-title {
  justify-content: center !important;
}
</style>
