/* eslint-disable */
import { Model } from '@vuex-orm/core'

class SmartAlert extends Model {
  static fields() {
    return {
      id: this.string(),
      title: this.string(),
      filter: this.attr([]),
      start: this.string(),
      end: this.string(),
      datetype: this.attr({}),
      rcpt: this.attr(),
      source: this.attr(),
      text: this.attr(),
    }
  }

  static create(options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/alert/create`,
      ...options,
    }).then((res) => res.response)
  }

  static test(options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/alert/test`,
      ...options,
    }).then((res) => res.response)
  }

  static gets(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/alerts`,
      ...options,
    }).then((res) => res.response.data)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/v1/alert/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static delete (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/v1/alert/${id}`,
      ...options,
    }).then((res) => res.response)
  }
}

SmartAlert.entity = 'smartalert'

export default SmartAlert