<template>
  <div>
    <PDFViewer
      :source="src"
      style="height: 100vh; width: 100vw"
      :controls="controls"
    />
  </div>
</template>

<script>
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer'

export default {
  components: {
    PDFViewer,
  },
  data: () => ({
    controls: [
      // 'download',
      'print',
      'rotate',
      'zoom',
      'catalog',
      'switchPage',
      ]
  }),
  props: {
    src: {
      type: String,
    }
  },
}
</script>