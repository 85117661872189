/* eslint-disable */
import { Model } from '@vuex-orm/core'

class ReportEmail extends Model {
  static fields () {
    return {
      id: this.number(),
      name: this.string(),
      email: this.string(),
      remark: this.string(),
      sendFlag: this.boolean(false),
      sendMonthly: this.boolean(false),
      sendWeekly: this.boolean(false),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/report-email`,
      ...options,
    }).then((res) => res.response.data)
  }

  static create (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/report-email`,
      dataTransformer: () => [],
      ...options,
    }).then((res) => res.response)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/v1/report-email/${id}`,
      dataTransformer: () => [],
      ...options,
    }).then((res) => res.response)
  }

  static delete (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/v1/report-email/${id}`,
      dataTransformer: () => [],
      ...options,
    }).then((res) => res.response)
  }
}

ReportEmail.entity = 'reportEmail'

export default ReportEmail