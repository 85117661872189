/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Authen extends Model {
  static fields () {
    return {
      id: this.number(),
      token: this.string('')
    }
  }

  static login (data) {
    return this.api().post('/auth', data)
  }

  static register (options) {
    return this.api().request({
      method: 'POST',
      url: '/v1/user/confirm',
      ...options
    }).then((res) => res.response)
  }

  static forgotPassword (options = {}) {
    return this.api().request({
      method: 'POST',
      url: '/v1/user/recover/request',
      ...options,
    }).then((res) => res.response)
  }

  static confirmPassword (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/user/recover/confirm`,
      ...options,
    }).then((res) => res.response)
  }
}

Authen.entity = 'authen'

export default Authen