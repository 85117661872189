<template>
  <div id="divoutside">
    <v-app-bar class="top-bar" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ bar_name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <!-- <v-btn icon v-on="on" style="margin-right:2%" @click="navigate(`profile`)">
            <v-icon>account_circle</v-icon>
          </v-btn> -->
          <v-btn
            v-on="on"
            class="ma-2 text-capitalize"
            outlined
            color="white"
            @click="navigate(`profile`)"
          >
            User : TSPACE DIGITAL
          </v-btn>
          <v-btn
            v-on="on"
            class="ma-2 text-capitalize white green--text"
            outlined
            color="white"
            v-if="_showSwitchApp()"
            @click="_switchAppName()"
          >
            {{ appBtnName }}
          </v-btn>
        </template>
      </v-menu>

      <!-- <v-progress-linear
        :active="true"
        :indeterminate="true"
        absolute
        bottom
      ></v-progress-linear>  -->
    </v-app-bar>

    <!-- <v-card height="1000px" width="500" class="mx-auto"> -->
    <!-- Chang Family -->
    <v-navigation-drawer
      class="green darken-3 drawer"
      dark
      absolute
      v-model="drawer"
      app
      id="sidebar"
      v-if="_isChangFamily()"
    >
      <v-list class="text-name">
        <!-- <v-list-tile> -->
        <v-list-item-content>
          <v-list-item-title class="title">{{ appName }}</v-list-item-title>
        </v-list-item-content>
        <!-- </v-list-tile> -->
      </v-list>

      <v-list>
        <v-list-item @click="navigate(`dashboard`)" v-model="dashboard">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <div>------------------------------------- Line Management -----------------------------------------------------</div> -->

        <v-list-group
          id="lineuser"
          prepend-icon="mdi-alpha-l-box-outline"
          :value="
            bar_name == 'Agent Line User Management' ||
            bar_name == 'Employee Line User Management' ||
            bar_name == 'Agent Branch User Management'
          "
          color="white"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Line User </v-list-item-title>
          </template>

          <v-list-item
            id="agentline"
            @click="navigate(`agentLineManagement`)"
            v-model="agentLineManagement"
          >
            <v-list-item-content>
              <v-list-item-title>Agent</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            id="AgentBranch"
            @click="navigate(`AgentBranch`)"
            v-model="AgentBranch"
          >
            <v-list-item-content>
              <v-list-item-title>Agent Branch</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            id="employeeline"
            @click="navigate(`employeeLineManagement`)"
            v-model="employeeLineManagement"
          >
            <v-list-item-content>
              <v-list-item-title>Employee</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- <div>------------------------------------- Agent and Sale -----------------------------------------------------</div> -->

        <v-list-group
          id="agentandsale"
          prepend-icon="assignment_ind"
          :value="true"
          color="white"
          no-action
          v-if="bar_name == 'Agent Management' || bar_name == 'Sale Management'"
        >
          <template v-slot:activator>
            <v-list-item-title>Agent&Sale </v-list-item-title>
          </template>

          <v-list-item
            id="agent"
            @click="navigate(`agentManagement`)"
            v-model="agentManagement"
          >
            <v-list-item-content>
              <v-list-item-title>Agent</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            id="sale"
            @click="navigate(`saleManagement`)"
            v-model="saleManagement"
          >
            <v-list-item-content>
              <v-list-item-title>Sale</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          id="agentandsale"
          prepend-icon="assignment_ind"
          :value="false"
          color="white"
          no-action
          v-else
        >
          <template v-slot:activator>
            <v-list-item-title>Agent&Sale </v-list-item-title>
          </template>

          <v-list-item id="agent" @click="navigate(`agentManagement`)">
            <v-list-item-content>
              <v-list-item-title>Agent</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item id="sale" @click="navigate(`saleManagement`)">
            <v-list-item-content>
              <v-list-item-title>Sale</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- <div>------------------------------------- Point Management -----------------------------------------------------</div> -->

        <v-list-group
          id="pointmanagement"
          prepend-icon="attach_money"
          :value="true"
          color="white"
          no-action
          v-if="
            bar_name == 'Point Management' ||
            bar_name == 'Redeemed Transactions'
          "
        >
          <template v-slot:activator>
            <v-list-item-title> Point </v-list-item-title>
          </template>

          <v-list-item
            id="point"
            @click="navigate(`pointmanagement`)"
            v-model="pointManagement"
            v-if="user.role != 'normal'"
          >
            <v-list-item-content>
              <v-list-item-title>Point</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            id="redeem"
            @click="navigate(`redeemedTransactions`)"
            v-model="redeemTransaction"
          >
            <v-list-item-content>
              <v-list-item-title>Redeemed Transactions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          id="pointmanagement"
          prepend-icon="attach_money"
          :value="false"
          color="white"
          no-action
          v-else
        >
          <template v-slot:activator>
            <v-list-item-title> Point </v-list-item-title>
          </template>

          <v-list-item
            id="point"
            @click="navigate(`pointmanagement`)"
            v-if="user.role != 'normal'"
          >
            <v-list-item-content>
              <v-list-item-title>Point</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item id="redeem" @click="navigate(`redeemedTransactions`)">
            <v-list-item-content>
              <v-list-item-title>Redeemed Transactions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- <div>------------------------------------- Chag kai dee -----------------------------------------------------</div> -->

        <v-list-item
          @click="navigate(`promotion`)"
          v-model="elephant"
          id="changkaidee"
        >
          <v-list-item-icon>
            <v-icon>stars</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ช้างขายดี ช้างขายดี</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <div>------------------------------------- Reward -----------------------------------------------------</div> -->

        <v-list-group
          id="reward"
          prepend-icon="favorite"
          :value="true"
          color="white"
          no-action
          v-if="
            bar_name == 'Banner Management' ||
            bar_name == 'Catagory' ||
            bar_name == 'Reward Items'
          "
        >
          <template v-slot:activator>
            <v-list-item-title>Reward</v-list-item-title>
          </template>

          <v-list-item
            id="banner"
            @click="navigate(`bannerManagement`)"
            v-model="bannerManagement"
          >
            <v-list-item-content>
              <v-list-item-title>Banner Management</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            id="catagory"
            @click="navigate(`catagory`)"
            v-model="catagory"
          >
            <v-list-item-content>
              <v-list-item-title>Category</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            id="product"
            @click="navigate(`product`)"
            v-model="rewardItems"
          >
            <v-list-item-content>
              <v-list-item-title>Reward Items</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          id="reward"
          prepend-icon="favorite"
          :value="false"
          color="white"
          no-action
          v-else
        >
          <template v-slot:activator>
            <v-list-item-title>Reward</v-list-item-title>
          </template>

          <v-list-item id="banner" @click="navigate(`bannerManagement`)">
            <v-list-item-content>
              <v-list-item-title>Banner Management</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item id="catagory" @click="navigate(`catagory`)">
            <v-list-item-content>
              <v-list-item-title>Category</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item id="product" @click="navigate(`product`)">
            <v-list-item-content>
              <v-list-item-title>Reward Items</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- <div>------------------------------------- Target Step -----------------------------------------------------</div> -->

        <!-- <v-list-item @click="navigate(`targetStep`)" v-model="targetStep" id="TargetStep" v-if="user.role === 'ci'">
            <v-list-item-icon>
              <v-icon>mdi-bullseye-arrow</v-icon>
            </v-list-item-icon>

            <v-list-item-content >
              <v-list-item-title>เป้าปิดสเต็ป</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

        <!-- <div>------------------------------------- Notify -----------------------------------------------------</div> -->

        <v-list-item
          @click="navigate(`notify`)"
          v-model="notify"
          id="Notify"
          v-if="user.role === 'ci'"
        >
          <v-list-item-icon>
            <v-icon>mdi-bell-ring</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Notify</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <div>------------------------------------- Smart Alert -----------------------------------------------------</div> -->

        <v-list-item
          @click="navigate(`smartAlert`)"
          v-model="smartAlert"
          id="smartAlert"
          v-if="user.role === 'ci'"
        >
          <v-list-item-icon>
            <v-icon>mdi-alert</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Smart Alert</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <div>------------------------------------- Report Email -----------------------------------------------------</div> -->

        <v-list-item
          @click="navigate(`reportEmail`)"
          v-model="reportEmail"
          id="ReportEmail"
          v-if="user.role === 'ci'"
        >
          <v-list-item-icon>
            <v-icon>mdi-at</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Report Email</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <div>------------------------------------- Privileges -----------------------------------------------------</div> -->

        <!-- <v-list-item @click="navigate(`privileges`)" v-model="Privileges" id="Privileges">
            <v-list-item-icon>
              <v-icon>card_giftcard</v-icon>
            </v-list-item-icon>

            <v-list-item-content >
              <v-list-item-title>Privileges</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

        <!-- <div>------------------------------------- User Mangement -----------------------------------------------------</div> -->

        <v-list-item
          id="userManagement"
          @click="navigate(`userManagement`)"
          v-model="userManagement"
          v-if="user.role != 'normal'"
        >
          <v-list-item-icon>
            <v-icon>group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>User Management</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item id="help" @click="handleHelp()" v-model="help">
          <v-list-item-icon>
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Help Desk</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item id="obs" @click="handleObs()">
          <v-list-item-icon>
            <v-icon>mdi-ticket-confirmation</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Lucky Draw Coupon</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logOut">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Chang TT -->
    <v-navigation-drawer
      class="green darken-3 drawer"
      dark
      absolute
      v-model="drawer"
      app
      id="sidebar"
      v-else
    >
      <v-list class="text-name">
        <!-- <v-list-tile> -->
        <v-list-item-content>
          <v-list-item-title class="title">{{ appName }}</v-list-item-title>
        </v-list-item-content>
        <!-- </v-list-tile> -->
      </v-list>

      <v-list>
        <!-- <v-list-item @click="navigate(`cttDashboard`)" v-model="cttDashboard">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-group
          id="all-cttDashboard"
          prepend-icon="mdi-alpha-l-box-outline"
          :value="
            bar_name == 'Chang Retail Register' ||
            bar_name == 'Activities Campaign Dashboard'
          "
          color="white"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Dashboard</v-list-item-title>
          </template>

          <v-list-item
            id="cttDashboard"
            @click="navigate(`cttDashboard`)"
            v-model="cttDashboard"
          >
            <v-list-item-content>
              <v-list-item-title>Chang Retail Register</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            id="cctCampaignDashboard"
            @click="navigate(`cttCampaignDashboard`)"
            v-model="cttCampaignDashboard"
          >
            <v-list-item-content>
              <v-list-item-title
                >Activities Campaign Dashboard</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item @click="navigate(`cttRetailShop`)" v-model="cttRetailShop">
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ข้อมูลร้านค้าปลีก</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="navigate(`cttVansales`)" v-model="cttVansales">
          <v-list-item-icon>
            <v-icon>mdi-truck</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ข้อมูลรถเร่</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="navigate(`cttAreaPromoter`)"
          v-model="cttAreaPromoter"
        >
          <v-list-item-icon>
            <v-icon>mdi-texture-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>จัดการ Area Promoter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="navigate(`cttAreaPromoterCRM`)"
          v-model="cttAreaPromoterCRM"
          v-if="user.role === 'ci'"
        >
          <v-list-item-icon>
            <v-icon>mdi-recycle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>จัดการ Stamp Card</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="navigate(`cttSpecialReward`)"
          v-model="cttSpecialReward"
          v-if="user.role === 'ci'"
        >
          <v-list-item-icon>
            <v-icon>mdi-gift</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ของรางวัลพิเศษ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="navigate(`cttApproveAreaPromoterCRM`)"
          v-model="cttApproveAreaPromoterCRM"
          v-if="user.role === 'admin'"
        >
          <v-list-item-icon>
            <v-icon>mdi-check-decagram</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>จัดการ Approve Mission</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="navigate(`cttReward`)"
          v-model="cttReward"
          v-if="user.role === 'ci'"
        >
          <v-list-item-icon>
            <v-icon>mdi-heart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>จัดการ Reward</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item id="cttHelp" @click="handleCttHelp()" v-model="cttHelp">
          <v-list-item-icon>
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Help Desk</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logOut">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- </v-card> -->
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'SideBar',
  props: ['bar_name', 'role', 'user'],
  data() {
    return {
      appName: this.$appName,
      appBtnName: '',
      drawer: true,
      dashboard: false,
      agentLineManagement: false,
      AgentBranch: false,
      employeeLineManagement: false,
      agentManagement: false,
      saleManagement: false,
      pointManagement: false,
      redeemTransaction: false,
      elephant: false,
      bannerManagement: false,
      catagory: false,
      rewardItems: false,
      targetStep: false,
      notify: false,
      reportEmail: false,
      userManagement: false,
      help: false,
      smartAlert: false,
      cttDashboard: false,
      cttCampaignDashboard: false,
      cttRetailShop: false,
      cttVansales: false,
      cttAreaPromoter: false,
      cttAreaPromoterCRM: false,
      cttSpecialReward: false,
      cttApproveAreaPromoterCRM: false,
      cttReward: false,
      cttHelp: false,
    }
  },
  created() {
    // Chang Family
    this.dashboard = this.bar_name == 'Dashboard'
    this.agentLineManagement = this.bar_name == 'Agent Line User Management'
    this.AgentBranch = this.bar_name == 'Agent Branch User Management'
    this.employeeLineManagement =
      this.bar_name == 'Employee Line User Management'
    this.agentManagement = this.bar_name == 'Agent Management'
    this.saleManagement = this.bar_name == 'Sale Management'
    this.pointManagement = this.bar_name == 'Point Management'
    this.redeemTransaction = this.bar_name == 'Redeemed Transactions'
    this.elephant = this.bar_name == 'ช้างขายดี! ช้างขายดี!'
    this.bannerManagement = this.bar_name == 'Banner Management'
    this.catagory = this.bar_name == 'Catagory'
    this.rewardItems = this.bar_name == 'Reward Items'
    this.targetStep = this.bar_name == 'เป้าปิดสเต็ป'
    this.notify = this.bar_name == 'Notify'
    this.reportEmail = this.bar_name == 'Report Email'
    this.userManagement = this.bar_name == 'User Management'
    this.help = this.help == 'Help'
    this.smartAlert = this.bar_name == 'Smart Alert'

    // Chang TT
    this.cttDashboard = this.bar_name == 'Chang Retail Register'
    this.cttCampaignDashboard = this.bar_name == 'Activities Campaign Dashboard'
    this.cttRetailShop = this.bar_name == 'Chang TT Retail Shop'
    this.cttVansales = this.bar_name == 'Chang TT Vansales'
    this.cttAreaPromoter = this.bar_name == 'Chang TT Area Promoter'
    this.cttAreaPromoterCRM = this.bar_name == 'Chang TT Area Promoter CRM'
    this.cttSpecialReward = this.bar_name == 'Chang TT Special Reward'
    this.cttApproveAreaPromoterCRM =
      this.bar_name == 'Chang TT Approve Area Promoter CRM'
    this.cttReward = this.bar_name == 'Chang TT Reward'
    this.cttHelp = this.cttHelp == 'Chang TT Help'

    this._setApp()
    sessionStorage.removeItem('pathPdf')
  },
  methods: {
    navigate(page) {
      this.$router.push({ path: page }).catch(() => {})
    },
    // handleObs() {
    //   this.$router.push({ path: '/obs' }).catch(() => {})
    // },
    handleHelp() {
      // this.$router.push({ name: 'pdfViewer', params: { pathPdf: '/helpdesk/CF_Helpdesk.pdf' } })
      sessionStorage.setItem('pathPdf', '/helpdesk/CF_Helpdesk.pdf')
      const routeData = this.$router.resolve({ name: 'pdfViewer' })
      window.open(routeData.href, '_blank')
    },
    handleCttHelp() {
      // this.$router.push({ name: 'pdfViewer', params: { pathPdf: '/helpdesk/CTT_Helpdesk.pdf' } })
      sessionStorage.setItem('pathPdf', '/helpdesk/CTT_Helpdesk.pdf')
      const routeData = this.$router.resolve({ name: 'pdfViewer' })
      window.open(routeData.href, '_blank')
    },
    _setApp() {
      const appList = this.$appList
      // Chang TT
      if (
        this.cttDashboard ||
        this.cttCampaignDashboard ||
        this.cttRetailShop ||
        this.cttVansales ||
        this.cttAreaPromoter ||
        this.cttAreaPromoterCRM ||
        this.cttApproveAreaPromoterCRM ||
        this.cttReward ||
        this.cttSpecialReward ||
        this.cttHelp
      ) {
        this.appName = appList['Chang TT'].name
      }

      this._setAppBtnName()
    },
    _switchAppName() {
      if (this._isChangFamily()) {
        return this.navigate('/cttDashboard')
      }
      return this.navigate('/dashboard')
    },
    _setAppBtnName() {
      const appName = this.appName
      const appList = this.$appList

      this.appBtnName = 'Switch to ' + appList[appName].switch
      return this.appBtnName
    },
    _isChangFamily() {
      const appList = Object.keys(this.$appList)
      if (this.appName === appList[0]) {
        return true
      }
      return false
    },
    _showSwitchApp() {
      if (
        !(this.user.ral === 'super-admin' || this.user.ral === 'region-admin')
      ) {
        return false
      }
      return true
    },
    logOut() {
      localStorage.setItem('token', '')
      Vue.prototype.$user = undefined
      this.navigate('/')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-bar {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3) !important;
  background-color: #01592a !important;
}

.drawer-ctt .v-list-item--active {
  background-image: linear-gradient(to right, #fffadb 0%, #ceb783);
}

.drawer-ctt .v-list-item--active .v-list-item__content .v-list-item__title,
.drawer-ctt .v-list-item--active .v-list-item__icon {
  color: var(--chang-color);
}

.text-name {
  text-align: center;
  font-size: 20px;
}

.drawer {
  position: fixed;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.3) !important;
  background-image: linear-gradient(to bottom, #01592a, #01974a) !important;
}
</style>
