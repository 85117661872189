/* eslint-disable */
import { Model } from '@vuex-orm/core'

class SpecialReward extends Model {
  static fields() {
    return {
      id: this.number(),
      title: this.string(''),
      description: this.string(''),
      image: this.string(''),
      createdAt: this.string(''),
      updatedAt: this.string(''),
    }
  }

  static list(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/premium-products`,
        ...options,
      })
      .then((res) => res.response)
  }

  static redeemList(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/premium-product-redeems`,
        ...options,
      })
      .then((res) => res.response)
  }

  static download(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/premium-product-redeems/export`,
        ...options,
        responseType: 'blob',
      })
      .then((res) => res.response)
  }

  static create(options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/chang-tt/premium-products`,
        ...options,
      })
      .then((res) => res.response)
  }

  static update(id, options = {}) {
    return this.api()
      .request({
        method: 'PUT',
        url: `/chang-tt/premium-products/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }

  static delete(id, options = {}) {
    return this.api()
      .request({
        method: 'DELETE',
        url: `/chang-tt/premium-products/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }
}

SpecialReward.entity = 'specialReward'

export default SpecialReward
