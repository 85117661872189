<template>
  <v-flex md12 class="not-found">
    {{text || 'ไม่พบข้อมูล'}}
  </v-flex>
</template>

<script>
export default {
  name: "NotFound",
  props: ["text"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.not-found {
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}
</style>
