import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SideBar',{attrs:{"bar_name":"Profile","user":_vm.user}}),_c(VMain,[_c(VContainer,[_c(VLayout,{staticStyle:{"margin":"5% 15% 0% 15%"},attrs:{"wrap":""}},[_c('h1',[_vm._v("User Information")]),_c(VFlex,{attrs:{"xs12":"","md12":""}},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"824"}},[_c(VList,[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("supervised_user_circle")])],1),_c(VListItemTitle,[_vm._v("Role: "+_vm._s(_vm.mapping[_vm.user.role]))])],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("map")])],1),_c(VListItemTitle,[_vm._v("Region: "+_vm._s(_vm.user.a && _vm.user.a.regions && _vm.user.a.regions.join(", ")))])],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("account_circle")])],1),_c(VListItemTitle,[_vm._v("Name: "+_vm._s(_vm.user.name))])],1)],1)],1)],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","width":"300","height":"5000"},model:{value:(_vm.dialog_loading),callback:function ($$v) {_vm.dialog_loading=$$v},expression:"dialog_loading"}},[_c(VCard,{attrs:{"color":"primary","dark":""}},[_c(VCardText,[_c('br'),_vm._v("กรุณารอสักครู่............ "),_c('br'),_c('br'),_c(VProgressLinear,{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }