<template>
  <div>
    <SideBar bar_name="Chang TT Area Promoter" :user="user"></SideBar>
    <v-main>
      <v-container>
        <AreaPromoterComponent v-bind="{ user }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";
import AreaPromoterComponent from "@/components/ChangTT/AreaPromoter";

export default {
  name: "CTTAreaPromoter",
  components: {
    SideBar,
    AreaPromoterComponent,
  },
  data() {
    return {
      user: this.$user,
    };
  },
};
</script>
