<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 md12 class="text-end" style="margin-top: 10px;margin-right: 10px;">
        <v-btn @click="addDialog" v-if="user.role == 'ci'">New</v-btn>
      </v-flex>

      <v-flex xs12 md12 style="margin-top: 15px;">
        <v-card>
          <v-card-title>
            Report Email
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              style="margin-right: 10px;"
              @change="searchData"
            ></v-text-field>

            <v-select
              outlined
              :items="filterRegions"
              item-text="label"
              item-value="id"
              label="Region"
              style="width: 150px;margin-right: 10px;"
              v-model="region"
              @change="searchData"
              v-if="filterRegions.length > 2"
            ></v-select>

            <v-select
              outlined
              v-model="type"
              :items="types"
              item-text="text"
              item-value="value"
              label="Type"
              style="width: 150px;"
              @change="searchData"
            >
            </v-select>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="{'items-per-page-options': $itemsPerPage}"
            :items-per-page="perPage"
            class="elevation-1"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.sendMonthly="{ item }">
              <v-icon v-if="item.sendMonthly === true" color="green">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="red">mdi-close-circle-outline</v-icon>
            </template>

            <template v-slot:item.sendWeekly="{ item }">
              <v-icon v-if="item.sendWeekly === true" color="green">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="red">mdi-close-circle-outline</v-icon>
            </template>

            <template v-slot:item.regions="{ item }">
              {{setTextRegions(item.Regions)}}
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon @click="editDialog(item)" style="margin-right: 10px;">edit</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog" max-width="600" scrollable persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{(form.id)? "Edit": "Add"}} Report Email</v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.regions"
                  :items="regions"
                  item-text="label"
                  item-value="id"
                  label="Region"
                  :rules="[v => (v && v.length > 0) || 'Region is required']"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="form.regions && form.regions.length > 0 ? 'indigo darken-4' : ''"
                        >{{ iconRegion }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip>
                      <span>{{ item.label }}</span>
                    </v-chip>
                    <span
                      v-if="(index + 1) >= form.regions && form.regions.length"
                      class="grey--text caption"
                    >({{ form.regions && form.regions.length || 0 }} items)</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="form.reportType"
                  :items="types"
                  item-text="text"
                  item-value="value"
                  label="Type"
                  :rules="[v => (v && v.length > 0) || 'Type is required']"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Name"
                  :rules="[v => (v != '') || 'Name is required']"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.email"
                  label="E-mail"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="dialog_delete = true" v-if="form.id">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="saveData"
            :disabled="!((form.regions && form.regions.length > 0) && form.reportType && form.name && form.email)"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Confirm delete email Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_delete" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Confirm delete?</v-card-title>
        <v-card-text>Are you sure that you want to delete this email. This email will be gone permanently.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="dialog_delete = false" text>Cancel</v-btn>
          <v-btn color="red" text @click="deleteDialog">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Region from "@/store/models/region"
import ReportEmail from "@/store/models/reportEmail"

export default {
  name: "ReportEmail",
  components: {},
  props: ["user"],
  computed: {
    regions() {
      return Region.all();
    },
    filterRegions() {
      return Region.getsAll();
    },
    likesAllRegion() {
      return (this.form.regions && this.form.regions.length) === this.regions.length;
    },
    likesSomeRegion() {
      return (this.form.regions && this.form.regions.length > 0) && !this.likesAllRegion;
    },
    iconRegion() {
      if (this.likesAllRegion) return "mdi-close-box";
      if (this.likesSomeRegion) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  data() {
    return {
      search: "",
      region: "All",
      type: "all",
      loading: true,
      dialog: false,
      dialog_delete: false,
      confirm: false,
      valid: false,
      headers: [
        {
          text: "ชื่อ",
          value: "name",
          sortable: false
        },
        {
          text: "อีเมล์",
          value: "email",
          sortable: false,
        },
        {
          text: "รายเดือน",
          value: "sendMonthly",
          align: "center",
          sortable: false,
        },
        {
          text: `รายสัปดาห์`,
          value: "sendWeekly",
          align: "center",
          sortable: false,
        },
        {
          text: `ภาค`,
          value: "regions",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],
      types: [{ value: 'all', text: 'All' }, { value: 'monthly', text: 'Monthly' }, { value: 'weekly', text: 'Weekly' }],
      items: [],
      form: {},
      selectedRegions: [],
      perPage: 10,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^[^@]+@[^@]+\.[^@]+$/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    searchData() {
      this.getData();
    },
    getData() {
      let data = { params: {} };
      if (this.user.role === "ci") {
        if (this.region === "All") {
          data = { params: { regionId: "" } };
        } else {
          data = { params: { regionId: this.region } };
        }
      }

      if(this.type === "monthly") {
        data.params.monthly = true;
        data.params.weekly = false;
      } else if(this.type === "weekly") {
        data.params.monthly = false;
        data.params.weekly = true;
      }

      if (this.search) {
        data.params.text = this.search;
      }

      ReportEmail.gets(data)
        .then(response => {
          this.items = response.data || [];
          this.loading = false;
        })
        .catch(e => {
          console.error(e.response);
          this.loading = false;
        });
    },
    addDialog() {
      this.form = {
        reportType: "all",
      };
      this.dialog = true;
    },
    editDialog(item) {
      this.form = {
        id: item.id,
        name: item.name,
        email: item.email
      };
      this.form.regions = item.Regions.map((e) => {
        return e.id;
      });
      if(item.sendMonthly && item.sendWeekly) {
        this.form.reportType = "all";
      } else if(item.sendMonthly) {
        this.form.reportType = "monthly";
      } else {
        this.form.reportType = "weekly";
      }

      this.dialog = true;
    },
    deleteDialog() {
      this.loading = true;
      ReportEmail.delete(this.form.id)
        .then(() => {
          this.getData()
          this.loading = false;
          this.dialog_delete = false;
          this.dialog = false;
        })
        .catch(e => {
          console.error(e.response);
          this.dialog_delete = false;
          this.loading = false;
        });
    },
    saveData() {
      this.loading = true;
      // Update
      if(this.form.id) {
        ReportEmail.update(this.form.id, { data: this.form })
          .then(() => {
            this.getData()
            this.loading = false;
            this.dialog = false;
          })
          .catch(e => {
            console.error(e.response);
            this.loading = false;
          });
      // Create
      } else {
        ReportEmail.create({ data: this.form })
          .then(() => {
            this.getData()
            this.loading = false;
            this.dialog = false;
          })
          .catch(e => {
            console.error(e.response);
            this.loading = false;
          });
      }
    },
    closeDialog() {
      this.form = {}
      this.dialog = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllRegion) {
          this.form = {
            ...this.form,
            regions: []
          }
        } else {
          this.form = {
            ...this.form,
            regions: this.regions.map((e) => e.id)
          }
        }
      });
    },
    setTextRegions(regions) {
      return regions.reduce((o, e) => {
        if(o) {
          o += `, ${e.label}`
        } else {
          o = e.label
        }

        return o
      }, '')
    }
  }
};
</script>
