<template>
  <div>
    <v-layout v-if="!dialog" wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="ค้นหา"
              style="margin-right: 2%"
              :hide-details="true"
              @keydown.enter="_getByQuery"
            ></v-text-field>
            <v-btn
              class="mr-3"
              :loading="loading"
              color="success"
              @click="_new()"
            >
              สร้าง
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-card class="mb-4">
              <v-card-text>
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="headers"
                  :items="items"
                  :footer-props="{ 'items-per-page-options': $itemsPerPage }"
                  :items-per-page="perPage"
                  :loading="loading"
                  :options.sync="options"
                  :server-items-length="countItems"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                >
                  <template v-slot:item.start="{ item }">
                    {{ _dateFormat(item.start) }}
                  </template>
                  <template v-slot:item.end="{ item }">
                    {{ _dateFormat(item.end) }}
                  </template>
                  <template v-slot:item.runTime="{ item }">
                    {{ _dateTimeFormat(item.runTime) }}
                  </template>
                  <template v-slot:item.type="{ item }">
                    {{ _sendType(item.type) }}
                  </template>
                  <template v-slot:item.updated_at="{ item }">
                    {{ _dateTimeFormat(item.updated_at) }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon class="mr-2" @click="_edit(item)">edit</v-icon>
                    <v-icon @click="_delete(item)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Form -----------------------------------------------------</div> -->
    <FormSmartAlert v-model="dialog" :data="this.form" @click="_save" />

    <!-- <div>------------------------------------- Confirm Dialog  -----------------------------------------------------</div> -->

    <v-dialog v-model="confirm.status" max-width="800" scrollable persistent>
      <v-card>
        <v-card-title class="headline"
          >Confirm {{ confirm.type }}?</v-card-title
        >
        <v-card-text
          >Are you sure you want to {{ confirm.type }}
          {{ (confirm.data && confirm.data.name) || "this" }}.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="loading"
            @click="confirm.status = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            :color="(confirm.type === 'sync' && 'success') || 'error'"
            :loading="loading"
            @click="_confirm"
            text
            >{{ confirm.type }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import FormSmartAlert from "@/components/Alert/FormSmart";

import SmartAlert from "@/store/models/smartalert";

const formDefault = {
  filter: [{}],
  dateType: "date",
  dateContent: {},
  type: "list",
};

export default {
  name: "SmartAlert",
  components: {
    FormSmartAlert,
  },
  props: ["user", "tabName"],
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["updated_at"],
        sortDesc: [true],
      },
      perPage: 10,
      countItems: 0,
      confirm: {
        status: false,
      },
      headers: [
        { text: "ชื่อ", value: "title" },
        { text: "วันที่เริ่ม", value: "start" },
        { text: "วันที่สิ้นสุด", value: "end" },
        { text: "ประเภทวันที่", value: "dateType" },
        { text: "ผู้รับ", align: "center", value: "rcpt" },
        {
          text: "แหล่งข้อมูล",
          align: "center",
          value: "source",
        },
        {
          text: "ส่งแบบ (แหล่งข้อมูล)",
          align: "center",
          value: "type",
        },
        {
          text: "วันที่ส่ง",
          align: "center",
          value: "runTime",
        },
        {
          text: "วันที่แก้ไข",
          align: "center",
          value: "updated_at",
        },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      items: [],
      dialog: false,
      form: formDefault,
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }

        // Sort
        if (ne.sortBy[0] != pe.sortBy[0] || ne.sortDesc[0] != pe.sortDesc[0]) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let options = { params: {} };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.perPage = this.options.itemsPerPage;
      } else {
        options.params.perPage = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      if (this.options && this.options.sortBy && this.options.sortBy[0]) {
        let sort = "asc";
        if (this.options.sortDesc && this.options.sortDesc[0]) {
          sort = "desc";
        }
        let field = _.cloneDeep(this.options.sortBy[0]);
        if (field === "dateType") {
          field = "date_type";
        }
        if (field === "runTime") {
          field = "run_time";
        }

        options.params.order = `${field}:${sort}`;
      }

      const res = await SmartAlert.gets(options);
      this.items = (res && res.data) || [];
      this.countItems = res && res.total;
      this.loading = false;
    },
    _getByQuery() {
      this.selected = null;
      this.options.page = 1;
      this.fetchData();
    },
    _dateFormat(date = "") {
      let bd = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (bd === "Invalid date") {
        return "";
      }
      return bd;
    },
    _dateTimeFormat(date = "") {
      let bd = moment(date, "YYYY-MM-DDTHH:mm:ssZ").format(
        "DD/MM/YYYY HH:mm:ss"
      );
      if (bd === "Invalid date") {
        return "";
      }
      return bd;
    },
    _sendType(type = "") {
      const sendtype = this.$smSendtype;
      for (let i = 0; i < sendtype.length; i++) {
        if (sendtype[i].value === type) {
          return sendtype[i].text || "";
        }
      }

      return "";
    },
    _new() {
      this.dialog = true;
      this.form = formDefault;
    },
    _edit(data = {}) {
      this.dialog = true;
      this.form = data;
    },
    _delete(data = {}) {
      this.confirm = {
        status: true,
        type: "delete",
        data,
      };
    },
    async _save(data = {}) {
      this.loading = true;

      const options = {
        data,
      };
      let res;
      if (data.id) {
        res = await SmartAlert.update(data.id, options).catch((err) =>
          console.error(err)
        );
      } else {
        res = await SmartAlert.create(options).catch((err) =>
          console.error(err)
        );
      }
      if (res) {
        this.fetchData();
        this.dialog = false;
        this.form = {};
      }

      this.loading = false;
    },
    async _confirm() {
      this.loading = true;
      this.confirm.status = false;
      const confirm = this.confirm;
      const data = confirm.data || {};

      let res;
      if (confirm.type === "delete") {
        res = await SmartAlert.delete(data.id, {}).catch((err) =>
          console.error(err)
        );
      }
      if (res) {
        this.fetchData();
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>
