/* eslint-disable */
import { Model } from '@vuex-orm/core'

class StampReward extends Model {
  static fields() {
    return {
      id: this.number(),
      name: this.string(''),
      condition: this.string(''),
      image: this.string(''),
      createdAt: this.string(''),
      updatedAt: this.string(''),
      endTime: this.string(''),
      isLimit: this.boolean(false),
      limitid: this.number(),
      pointid: this.number(),
      publish: this.boolean(false),
      rewardId: this.number(),
      startTime: this.string(''),
    }
  }

  static list(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/stamp-rewards`,
        ...options,
      })
      .then((res) => res.response)
  }

  static create(options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/chang-tt/stamp-rewards`,
        ...options,
      })
      .then((res) => res.response)
  }

  static update(id, options = {}) {
    return this.api()
      .request({
        method: 'PUT',
        url: `/chang-tt/stamp-rewards/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }

  static delete(id, options = {}) {
    return this.api()
      .request({
        method: 'DELETE',
        url: `/chang-tt/stamp-rewards/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }
}

StampReward.entity = 'stampReward'

export default StampReward
