import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{staticClass:"pa-2"},[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.data.title || "ยืนยันการลบข้อมูล?")+" ")]),_c(VCardText,[_c('div',[_vm._v(" "+_vm._s(_vm.data.text)+" "),(_vm.textArea)?_c(VTextarea,{attrs:{"label":"หมายเหตุ","rows":"5","outlined":"","dense":""},model:{value:(_vm.data.note),callback:function ($$v) {_vm.$set(_vm.data, "note", $$v)},expression:"data.note"}}):_vm._e()],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.$emit('confirm', false)}}},[_vm._v(" "+_vm._s(_vm.data.cancelText || "ยกเลิก")+" ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.$emit('confirm', true)}}},[_vm._v(" "+_vm._s(_vm.data.confirmText || "ยืนยัน")+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }