/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Banner extends Model {
  static fields () {
    return {
      id: this.number(),
      image: this.string(),
      isPublic: this.boolean(false),
      name: this.string(),
      order: this.number(),
      shop_type_id: this.number(),
    }
  }

  static gets (shopTypeId, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shop-types/${shopTypeId}/banners`,
      ...options,
    }).then((res) => res.response)
  }

  static create (shopTypeId, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/shop-types/${shopTypeId}/banners`,
      ...options,
    }).then((res) => res.response)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/v1/banners/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static delete (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/v1/banners/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static public (shopTypeId, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/shop-types/${shopTypeId}/banners/public`,
      ...options,
    }).then((res) => res.response)
  }
}

Banner.entity = 'banner'

export default Banner