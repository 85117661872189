import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,{staticStyle:{"background-image":"linear-gradient(to right, #79D455 , #016445)","height":"100%"}},[_c(VContainer,[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticStyle:{"width":"400px"},attrs:{"src":require('../assets/Chang-Family-2020-Logo.png')}})]),_c(VCard,{staticStyle:{"padding":"40px"},attrs:{"width":"500"}},[_c(VLayout,{attrs:{"text-xs-center":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c('h2',{staticClass:"headline mb-3",staticStyle:{"font-family":"CHANG Sans REG !important","text-align":"center"}},[_vm._v("สวัสดีคุณ "+_vm._s(this.$route.query.email)+" กรุณาตั้งรหัสผ่านสำหรับการเข้าใช้งานครั้งแรก")])]),_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":"Password","type":'password'},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":" Confirm Password","type":'password'},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VBtn,{attrs:{"block":"","color":"blue","disabled":_vm.password == '' || _vm.confirm_password == ''},on:{"click":_vm.confirmPassword}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }