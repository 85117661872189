<template>
  <v-flex xs12 md12 style="margin-top: 10px">
    <v-card :outlined="true">
      <v-card-title>
        Mission
        <v-spacer></v-spacer>
        <v-btn
          class="chang-btn"
          :loading="loading"
          color="#006b35"
          @click="_new()"
        >
          <v-icon left> mdi-plus </v-icon>
          เพิ่ม
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-card class="mb-4">
          <v-card-text>
            <v-data-table
              class="clean-tb"
              item-key="index"
              :headers="headers"
              :items="indexedItems"
              :footer-props="{ 'items-per-page-options': $itemsPerPage }"
              :items-per-page="limit"
              :loading="loading"
              :options.sync="options"
              :server-items-length="countItems"
              no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
              no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
            >
              <template v-slot:item.rewardImage="{ item }">
                <v-img :src="item.rewardImage" width="100" contain />
              </template>
              <template v-slot:item.startDate="{ item }">
                {{ moment(item.startDate).format("DD/MM/YYYY HH:mm") }}
              </template>
              <template v-slot:item.endDate="{ item }">
                {{ moment(item.endDate).format("DD/MM/YYYY HH:mm") }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  :loading="loading"
                  @click="_view(item)"
                  depressed
                  class="mr-2"
                >
                  <v-icon left> mdi-magnify </v-icon>
                  ดูข้อมูล
                </v-btn>
                <v-btn
                  :loading="loading"
                  @click="_edit(item)"
                  depressed
                  class="mr-2"
                >
                  <v-icon left> mdi-pencil-outline </v-icon>
                  แก้ไข
                </v-btn>
                <v-btn
                  v-if="item.allJoin === 0"
                  :loading="loading"
                  @click="_deleteModal(item)"
                  depressed
                >
                  <v-icon left> mdi-trash-can-outline </v-icon>
                  ลบ
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- <div>------------------------------------- Form -----------------------------------------------------</div> -->
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-form ref="form" v-model="valid" @submit.prevent="_save">
          <v-card-text>
            <v-text-field
              v-model="form.title"
              label="Mission"
              :rules="[...rulesRequired]"
              required
              outlined
              dense
            />
            <GalleryUploadFile
              label="Banner"
              v-model="form.banner"
              :max="1"
              :option="upOption"
              :editable="true"
              accept="image/*"
            />
            <v-text-field
              v-model="form.message"
              label="Message"
              :rules="[...rulesRequired]"
              required
              outlined
              dense
            />
            <v-menu
              v-model="vdate.start"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.startDate"
                  label="วันที่เริ่มต้น"
                  :rules="[...rulesRequired]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                locale="th"
                v-model="form.startDate"
                @input="vdate.start = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="vdate.end"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.endDate"
                  label="วันที่สิ้นสุด"
                  :rules="[...rulesRequired]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                locale="th"
                v-model="form.endDate"
                @input="vdate.end = false"
              ></v-date-picker>
            </v-menu>
            <v-row>
              <v-col cols="9">
                <v-autocomplete
                  v-model="form.stampRewardId"
                  :items="stampReward"
                  label="Mission Reward"
                  outlined
                  dense
                  item-text="title"
                  item-value="id"
                  return-object
                />
              </v-col>
              <v-col>
                <v-btn text class="remove-reward" v-if="form.stampRewardId" @click="_removeReward">ลบของรางวัล</v-btn>
              </v-col>
            </v-row>
            <v-textarea
              outlined
              label="Condition"
              v-model="form.condition"
              :rules="[...rulesRequired]"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="_back">ยกเลิก</v-btn>
            <v-btn
              color="success"
              type="submit"
              class="ml-3 mr-2"
              :disabled="!valid"
              >บันทึก</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- View -----------------------------------------------------</div> -->
    <v-dialog
      v-model="viewDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title
            >Stamp: {{ viewData.card && viewData.card.title }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="viewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title> Mission: {{ viewData.title }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="viewData.startDate"
                label="วันที่เริ่มต้น"
                readonly
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="viewData.endDate"
                label="วันที่สิ้นสุด"
                readonly
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="6">
              <v-textarea
                outlined
                label="Condition"
                readonly
                v-model="viewData.condition"
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            class="clean-tb"
            item-key="index"
            :headers="missionHeaders"
            :items="indexedApproveItems"
            :footer-props="{ 'items-per-page-options': $itemsPerPage }"
            :items-per-page="limit"
            :loading="loading"
            :options.sync="approveOptions"
            :server-items-length="countJoinItems"
            no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
            no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
          >
            <template v-slot:top>
              <v-row no-gutters>
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="ค้นหา"
                    class="mr-2"
                    outlined
                    dense
                    :hide-details="true"
                    @keydown.enter="_getByQuery"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ moment(item.createdAt).format("DD/MM/YYYY HH:mm") }}
            </template>
            <template v-slot:item.image="{ item }">
              <v-img :src="item.image" width="100" contain />
            </template>
            <template v-slot:item.status="{ item }">
              <span
                :class="`${
                  item.status === 'approved'
                    ? 'green-text'
                    : item.status === 'waiting' || item.status === 'waiting-region'
                    ? 'yellow-text'
                    : 'brown-text'
                }`"
                >{{ capitalize(item.status) }}</span
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn :loading="loading" @click="_approve(item)" depressed block>
                <v-icon left> mdi-magnify </v-icon>
                ดูข้อมูล
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Approve / Reject ---------------------------------------------</div> -->
    <v-dialog v-model="approveDialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn icon @click="approveDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="valid" @submit.prevent="_saveApprove">
          <v-card-text v-if="approveData">
            <v-text-field
              v-model="approveData.shopName"
              label="Shop"
              readonly
              outlined
              dense
              hide-details
            />
            <div class="text-center my-3">
              <v-img :src="approveData.image" width="100%" contain />
            </div>
            <div
              class="mb-2"
              v-for="(item, index) in generateKey(approveData.approver)"
              :key="index"
            >
              {{ capitalize(removeDot(item)) }}
              <v-text-field
                v-model="approveData.approver[item].note"
                label="หมายเหตุ"
                readonly
                required
                outlined
                dense
                hide-details
              />
            </div>
          </v-card-text>
          <!-- <v-card-actions>
            <v-spacer />
            <v-btn color="success" type="submit" class="mr-2">Approve</v-btn>
            <v-btn color="error" @click="_saveApprove('reject')">Reject</v-btn>
            <v-spacer />
          </v-card-actions> -->
        </v-form>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="_delete" />
  </v-flex>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import ConfirmModal from "@/components/Confirm";
import GalleryUploadFile from "@/components/GalleryUploadFile";

import StampApproveModel from "@/store/models/changtt/stampApprove";
import StampMissionModel from "@/store/models/changtt/stampMission";
import StampRewardModel from "@/store/models/changtt/stampReward";

const formDefault = {};

export default {
  name: "AreaPromoterMissionComponent",
  props: {
    id: {
      type: Number,
    },
  },
  components: {
    ConfirmModal,
    GalleryUploadFile,
  },
  computed: {
    indexedItems() {
      return (
        this.items.map((item, index) => ({
          index: index + 1,
          status: `${item.allJoin} / ${item.approve} / ${item.waiting} / ${item.reject}`,
          rewardImage: item.reward && item.reward.image,
          ...item,
        })) || []
      );
    },
    indexedApproveItems() {
      return (
        this.joinItems.map((item, index) => ({
          index: index + 1,
          ...item,
        })) || []
      );
    },
    stampReward() {
      return this.stampRewardList;
    },
  },
  data() {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      approveOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      limit: 10,
      countItems: 0,
      countJoinItems: 0,
      headers: [
        {
          text: "No",
          value: "index",
          sortable: false,
        },
        {
          text: "Reward",
          value: "rewardImage",
          sortable: false,
        },
        {
          text: "Mission Start Date",
          value: "startDate",
          sortable: false,
        },
        { text: "Mission End Date", value: "endDate", sortable: false },
        { text: "Mission", value: "title", sortable: false },
        {
          text: "All / Approve / Waiting Approve / Reject",
          value: "status",
          sortable: false,
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      items: [],
      joinItems: [],
      dialog: false,
      viewDialog: false,
      approveDialog: false,
      form: formDefault,
      valid: false,
      rulesRequired: [(v) => (v && v.length > 0) || "กรุณากรอกข้อมูล"],
      vdate: {
        start: false,
        end: false,
      },
      formData: {
        title: null,
        message: null,
        startDate: null,
        endDate: null,
        condition: null,
      },
      upOption: {
        width: 1280,
        height: 720,
        aspectRatio: 1280 / 720,
      },
      search: "",
      viewData: {},
      approveData: {},
      missionHeaders: [
        {
          text: "No",
          value: "index",
          sortable: false,
        },
        {
          text: "Date",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "Shop",
          value: "shop.name",
          sortable: false,
        },
        { text: "Image", value: "image", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      stampRewardList: [],
      conf: {
        status: false,
        title: "ยืนยันการลบภารกิจ?",
        text: `ภารกิจ`,
        cancelText: `ยกเลิก`,
        confirmText: `ยืนยัน`,
      },
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }
      },
      deep: true,
    },
    approveOptions: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchApprove();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    moment,
    capitalize(text) {
      return _.capitalize(text);
    },
    removeDot(text) {
      return text.replace(".", " ");
    },
    generateKey(obj) {
      return (typeof obj === "object" && Object.keys(obj)) || [];
    },
    _getByQuery() {
      this.approveOptions.page = 1;
      this.fetchApprove();
    },
    async fetchData() {
      this.loading = true;
      let options = { params: {} };

      if (this.options && this.options.itemsPerPage) {
        options.params.limit = this.options.itemsPerPage;
      } else {
        options.params.limit = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      const res = await StampMissionModel.list(this.id, options);
      const { data } = res;
      this.items = (data && data.data) || [];
      this.countItems = (data && data.total) || 0;
      this.loading = false;
    },
    async fetchApprove() {
      this.loading = true;
      let options = { params: {} };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.approveOptions && this.approveOptions.itemsPerPage) {
        options.params.limit = this.approveOptions.itemsPerPage;
      } else {
        options.params.limit = 10;
      }

      if (this.approveOptions && this.approveOptions.page) {
        options.params.page = this.approveOptions.page;
      }

      const res = await StampApproveModel.list(this.viewData.id, options);
      const { data } = res;
      this.joinItems = (data && data.data) || [];
      this.countJoinItems = (data && data.total) || 0;
      this.loading = false;
    },
    async fetchReward() {
      this.loading = true;
      let options = {
        params: {
          listType: "all",
        },
      };
      const res = await StampRewardModel.list(options);
      const { data } = res;
      this.stampRewardList = (data && data.data) || [];
      this.loading = false;
    },
    _view(data = {}) {
      this.viewDialog = true;
      this.viewData = {
        ...data,
        startDate: moment(data.startDate).format("YYYY-MM-DD"),
        endDate: moment(data.endDate).format("YYYY-MM-DD"),
      };
      this.fetchApprove();
    },
    _removeReward() {
      this.form.stampRewardId = undefined;
    },
    _approve(data = {}) {
      this.approveDialog = true;
      this.approveData = {
        ...data,
        shopName: data.shop.name,
      };
    },
    _deleteModal(item) {
      this.conf = {
        ...this.conf,
        status: true,
        text: `ภารกิจ ${item.title}`,
        data: item,
      };
    },
    async _delete(value = false) {
      if (value) {
        const { data } = this.conf;
        let res;
        res = await StampMissionModel.delete(data.id, {}).catch((err) =>
          console.error(err)
        );
        if (res) {
          this.fetchData();
        }
      }

      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
    _back() {
      this.dialog = false;
      this.form = {};
      this.$refs.form.reset();
      document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
    },
    _new() {
      this.dialog = true;
      this.form = _.cloneDeep(this.formData);
      this.fetchReward();
    },
    _edit(data = {}) {
      this.dialog = true;
      this.form = {
        ...data,
        startDate: moment(data.startDate).format("YYYY-MM-DD"),
        endDate: moment(data.endDate).format("YYYY-MM-DD"),
        stampRewardId: (data.reward && data.reward.id) || data.stampRewardId,
      };
      this.fetchReward();
    },
    async _save() {
      this.loading = true;

      const valid = this.$refs.form.validate();
      const form = this.form;
      let formData = new FormData();
      if (valid) {
        formData.append("title", form.title);
        formData.append("message", form.message);
        formData.append("condition", form.condition);
        if (typeof form.banner !== "string")
          formData.append("banner", form.banner);
        formData.append(
          "startDate",
          moment(form.startDate).format("YYYY-MM-DD H:mm:ss")
        );
        formData.append(
          "endDate",
          moment(form.endDate)
            .add(23, "h")
            .add(59, "m")
            .add(59, "s")
            .format("YYYY-MM-DD H:mm:ss")
        );
        formData.append("stampCardId", this.id);
        if (form.stampRewardId !== null && form.stampRewardId !== undefined)
          formData.append(
            "stampRewardId",
            (form.stampRewardId && form.stampRewardId.id) || form.stampRewardId
          );
      }
      const options = {
        data: formData,
      };
      let res;
      if (form.id) {
        res = await StampMissionModel.update(form.id, options).catch((err) =>
          console.error(err)
        );
      } else {
        res = await StampMissionModel.create(options).catch((err) =>
          console.error(err)
        );
      }
      if (res) {
        this.fetchData();
        this.dialog = false;
        this.form = {};
      }

      this.loading = false;
    },
    async _saveApprove(status = "approve") {
      console.log(status);
    },
  },
};
</script>

<style lang="scss" scoped>
$green: green;
$yellow: orange;
$brown: brown;

.green-text {
  color: $green;
}
.yellow-text {
  color: $yellow;
}
.brown-text {
  color: $brown;
}
.theme--light.v-sheet--outlined {
  border: none !important;
}

.remove-reward {
  text-decoration: underline;
}
</style>
