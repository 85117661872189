<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="lightbox-dialog"
    data-testid="lightBox"
    @click:outside="_close"
  >
    <div class="text-right py-2">
      <v-icon large color="white" class="mr-3" @click="_close"
        >mdi-close-circle</v-icon
      >
    </div>
    <v-carousel height="80vh" v-if="items.length > 1">
      <v-carousel-item v-for="(item, i) in items" :key="i">
        <v-img
          :src="item.src || item.image || item"
          :contain="true"
          height="100%"
        />
      </v-carousel-item>
    </v-carousel>
    <div v-else class="fix-height" v-for="(item, i) in items" :key="i">
      <v-img
        :src="item.src || item.image || item"
        :contain="true"
        height="100%"
      />
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    _close() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style lang="scss">
.lightbox-dialog {
  box-shadow: none !important;
  .fix-height {
    height: 80vh;
  }
}
</style>