/* eslint-disable */
import { Model } from '@vuex-orm/core'

class ShopUser extends Model {
  static fields() {
    return {
      id: this.string(),
      shopId: this.number(),
      shop: this.attr(null),
      userId: this.string(''),
      name: this.string(''),
      birthDate: this.string(''),
      type: this.string(''),
      citizenId: this.string(''),
      phone: this.string(''),
      status: this.string(''),
      shopUserLinkId: this.string(''),
    }
  }

  static list(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shopUsers/list`,
      dataTransformer: (response) => response && response.data && response.data.data || [],
      ...options
    }).then((res) => res && res.response && res.response.data)
  }

  static async bind(options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/shopUsers/bind`,
      ...options
    }).then((res) => res.response.data)
  }

  static async unbind(id, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/shopUsers/unbind/${id}`,
      ...options
    }).then((res) => res.response.data)
  }
}

ShopUser.entity = 'shopuser'

export default ShopUser