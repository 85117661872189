<template>
  <div style="margin-top: 10px">
    <v-layout wrap>
      <!-- <v-flex xs4 md4 v-if="user.role == 'ci'">
        <v-file-input outlined label="File input" @change="onFileChange" v-model="files"></v-file-input>
      </v-flex>

      <v-flex xs4 md4>
        <v-btn
          v-if="user.role == 'ci'"
          style="margin: 3%; "
          @click="uploadPoint"
          :disabled="file == null"
        >Upload</v-btn>

        <v-btn v-if="user.role != 'normal'" style="margin: 3%; " @click="downloadPoint">Download</v-btn>
      </v-flex> -->

      <v-flex xs2 md2 v-if="user.role != 'normal'">
        <v-btn style="margin: 3%" @click="downloadPoint">Download</v-btn>
      </v-flex>

      <v-flex xs4 md4></v-flex>

      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card>
          <v-card-title>
            {{ tabName }} Point
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              style="margin-right: 2%"
              @change="getPointbyFilter"
            ></v-text-field>

            <v-select
              v-model="type"
              outlined
              :items="tiers"
              item-text="name"
              item-value="id"
              label="Type"
              style="width: 1%; margin-right: 2%"
              @change="getPointbyFilter"
            ></v-select>

            <v-select
              outlined
              :items="regions"
              item-text="label"
              item-value="id"
              label="Region"
              style="width: 5%"
              v-model="region"
              @change="getPointbyFilter"
              v-if="regions.length > 2"
            ></v-select>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="point_agent"
            :footer-props="{ 'items-per-page-options': $itemsPerPage }"
            :items-per-page="perPage"
            class="elevation-1"
            :search="search"
            :loading="loading"
            :options.sync="options"
            :server-items-length="countItems"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.pointSummary.totalPoint="{ item }">{{
              $numberFormat(item.pointSummary.totalPoint)
            }}</template>

            <template v-slot:item.pointSummary.totalRedeem="{ item }">{{
              $numberFormat(item.pointSummary.totalRedeem)
            }}</template>

            <template v-slot:item.pointSummary.remainPoint="{ item }">{{
              $numberFormat(item.pointSummary.remainPoint)
            }}</template>

            <template v-slot:item.action="{ item }">
              <v-icon @click="openDetailDialog(item)">search</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" width="50%" persistent scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Agent Point Detail</v-card-title
        >

        <v-card-text>
          <v-data-table
            :headers="headers2"
            :items="point_detail"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading2"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.group="{ item }">{{
              pointDateFormat(item.group)
            }}</template>

            <template v-slot:item.sumPoint="{ item }">{{
              $numberFormat(item.sumPoint)
            }}</template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="headline grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn @click="closeDetailDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />Uploading....
          <br />
          <br />

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

import Region from "@/store/models/region";
import Tier from "@/store/models/tier";
import Point from "@/store/models/point";

export default {
  name: "Dashboard",
  components: {},
  props: ["user", "tabId", "tabName"],
  data() {
    return {
      search: "",
      dialog: false,
      dialog_loading: false,
      loading: true,
      headers2: [
        {
          text: "Month - Year",
          align: "left",
          value: "group",
          sortable: false,
        },
        {
          text: "Point",
          align: "right",
          value: "sumPoint",
          sortable: false,
        },
      ],
      point_agent: [],
      point_detail: [],
      agent: {},
      files: [],
      file: null,
      region: "All",
      loading2: false,
      type: "All",
      tiers: [],
      options: {},
      perPage: 10,
      countItems: 0,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Agent Name",
          align: "left",
          sortable: false,
          value: "shopName",
          width: 130,
        },
        {
          text: `Type`,
          value: "shopTier",
          sortable: false,
          width: 130,
        },
        {
          text: "Total Point",
          align: "right",
          value: "pointSummary.totalPoint",
          sortable: false,
          width: 130,
        },
        {
          text: "Used Point",
          align: "right",
          value: "pointSummary.totalRedeem",
          sortable: false,
          width: 130,
        },
        {
          text: "Remaining Point",
          align: "right",
          value: "pointSummary.remainPoint",
          sortable: false,
          width: 130,
        },
        {
          text: "More Detail",
          value: "action",
          sortable: false,
          width: 130,
          align: "center",
        },
      ];
    },
    regions() {
      return Region.getsAll();
    },
  },
  watch: {
    options: {
      handler() {
        this.getAgentPoint();
      },
      deep: false,
    },
  },
  created() {
    this.getTiers();
  },
  methods: {
    getAgentPoint() {
      this.loading = true;
      let data = { params: {} };
      const regionId = this.region === "All" ? 0 : this.region;

      // Query
      if (this.search) {
        data.params.query = this.search;
      }

      // Query
      if (this.type && this.type != "All") {
        data.params.tier = this.type;
      }

      // Per page
      if (this.options && this.options.itemsPerPage) {
        data.params.perPage = this.options.itemsPerPage;
      }

      // Page
      if (this.options && this.options.page) {
        data.params.page = this.options.page;
      }

      Point.gets(this.tabId, regionId, data)
        .then((response) => {
          this.point_agent = response.data || [];
          this.countItems = response.total;
          this.loading = false;
        })
        .catch((e) => {
          console.error(e.response);
          this.loading = false;
        });
    },

    getTiers() {
      Tier.gets({ params: { shopTypeId: this.tabId } })
        .then((response) => {
          this.tiers =
            (response &&
              response.data && [
                { id: "All", name: "All" },
                ...response.data,
              ]) ||
            [];
        })
        .catch((e) => {
          console.error(e.response);
        });
    },

    getPointDetail() {
      this.loading2 = true;
      Point.detail(this.agent && this.agent.shopId)
        .then((response) => {
          this.loading2 = false;
          this.point_detail = (response && response.data) || [];
        })
        .catch((e) => {
          this.loading2 = false;
          console.error(e.response);
        });
    },

    openDetailDialog(agent) {
      this.agent = agent;
      this.getPointDetail();
      this.dialog = true;
    },

    closeDetailDialog() {
      this.dialog = false;
      this.point_detail = [];
      this.agent = {};
    },

    onFileChange(e) {
      console.log(e);
      this.file = e;
      // this.url = URL.createObjectURL(e);
    },

    uploadPoint() {
      console.log("uploading....");
      this.dialog_loading = true;
      let formData = new FormData();

      formData.append("file", this.file);

      this.$http
        .post(`/api/upload/point`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.files = [];
          this.getAgentPoint();
          this.dialog_loading = false;
        })
        .catch((e) => {
          this.dialog_loading = false;
          console.error(e.response);
        });
    },

    getPointbyFilter() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.getAgentPoint();
      }
    },

    downloadPoint() {
      const FileDownload = require("js-file-download");

      let data = { params: {}, timeout: 15000 };

      // Region
      const regionId = this.region === "All" ? 0 : this.region;
      if (regionId) {
        data.params.regionId = regionId;
      }

      // Query
      if (this.search) {
        data.params.query = this.search;
      }

      // Query
      if (this.type && this.type != "All") {
        data.params.tier = this.type;
      }

      Point.download(this.tabId, data)
        .then((response) => {
          FileDownload(response, `point_data_${this.tabId}.xlsx`);
        })
        .catch((e) => {
          console.error(e.response);
        });
    },

    pointDateFormat(val) {
      const months = this.$months;
      const year = String(moment().year()).slice(0, 2);
      const res = Object.keys(months).reduce((o, e) => {
        o = o.toUpperCase();
        if (o[0] === "Q") {
          o = o.replace(new RegExp(`${e}-`, "gi"), `${months[e]}-`);
          o = o.replace(new RegExp(`${e}'`, "gi"), `${months[e]} ${year}`);
        } else {
          o = o.replace(new RegExp(`${e}'`, "gi"), `${months[e]} ${year}`);
        }
        return o;
      }, val);
      return res;
    },
  },
};
</script>
