<template>
  <v-main style="background-image: linear-gradient(to right, #79D455 , #016445); height: 100%;">
    <v-container>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <div style="display: flex; justify-content: center;">
            <img
              style="width: 400px;"
              :src="require('../assets/Chang-Family-2020-Logo.png')"
            />
          </div>

          <v-card style=" padding: 40px;" width="500">
            <v-layout text-xs-center wrap>
              <v-flex xs12>
                <h2
                  class="headline mb-3"
                  style="font-family: CHANG Sans REG !important; text-align: center"
                >สวัสดีคุณ {{this.$route.query.email}} กรุณาตั้งรหัสผ่านสำหรับการเข้าใช้งานครั้งแรก</h2>
              </v-flex>

              <!-- <v-flex xs12>
                <p
                  class="display-5 mb-3"
                ></p>
              </v-flex>-->

              <v-flex xs12>
                <v-text-field v-model="password" label="Password" :type="'password'"></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="confirm_password"
                  label=" Confirm Password"
                  :type="'password'"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-btn
                  block
                  color="blue"
                  @click="confirmPassword"
                  :disabled="password == '' || confirm_password == ''"
                >Submit</v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Authen from '../store/models/auth'

export default {
  name: "ForgetPassword",
  components: {},
  data() {
    return {
      password: "",
      confirm_password: ""
    };
  },
  methods: {
    navigate(page) {
      this.$router.push({ path: page });
    },
    confirmPassword() {
      let formData = new FormData();

      formData.append("email", this.$route.query.email);
      formData.append("password", this.password);

      if (
        this.password == this.confirm_password &&
        this.password != "" &&
        this.confirm_password != ""
      ) {
        Authen.register({ headers: { Authorization: `Bearer ${this.$route.query.token}` }, data: formData })
          .then(() => {
            this.$swal_success("ตั้งรหัสผ่านสำเร็จ");
            this.navigate("/");
          })
          .catch(e => {
            console.error(e.response);
          });
      } else {
        this.$swal("Password and confirm password are invalid");
      }
    }
  }
};
</script>
