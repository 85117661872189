<template>
  <v-layout v-if="value" class="mx-4" wrap>
    <v-flex xs12 md12>
      <v-row class="mx-0">
        <v-col cols="12" offset-1>
          <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
            <v-icon left> mdi-less-than </v-icon>
            ย้อนกลับ จัดการรถเร่{{shopType}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-2">
        <v-col cols="6" class="promoter-h1">
          รายละเอียดรถเร่{{shopType}}
        </v-col>
        <v-col cols="6">
          <v-btn color="#c7c7cc" class="ml-4 black--text float-right" v-if="form.id" @click="_delete(form)">ยกเลิกผู้ใช้งาน</v-btn>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-form ref="form" v-model="valid" @submit.prevent="_save">
        <v-card :outlined="true">
          <v-card-title>
            ข้อมูลพนักงานรถเร่
          </v-card-title>

          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6">
                <v-text-field
                  v-model="form.firstName"
                  label="ชื่อรถเร่"
                  :rules="rulesRequired"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.lastName"
                  label="นามสกุลรถเร่"
                  :rules="rulesRequired"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-text-field
                  v-model="form.phone"
                  label="เบอร์โทรศัพท์"
                  :rules="[...rulesRequired, ...rulesNumber]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.agent.name"
                  :label="shopType"
                  :rules="[...rulesRequired]"
                  required
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-text-field
                  v-model="form.phoneOther"
                  label="เบอร์โทรศัพท์เพิ่มเติม / Line"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.note"
                  label="หมายเหตุ"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="form.id">
              <v-col cols="6">
                <v-text-field
                  v-model="form.registeredtext"
                  label="สถานะออนไลน์"
                  :hide-details="true"
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="form.registered">
                <v-btn color="#f4cccc" class="mr-4 red--text" @click="_unlink(form)">Unlink</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mb-3">
            <v-btn
              color="success"
              type="submit"
              class="ml-3 mr-2"
              :disabled="!valid"
              >บันทึก</v-btn
            >
            <v-btn color="error" @click="_back">ยกเลิก</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-flex>
    
    <!-- Shop Retail -->
    <ShopRetailLinkComponent v-model="this.form.id" :data="this.form" type="vansales" />

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="confirm.status" persistent max-width="600">
      <v-card>
        <v-card-title class="headline"
          >Confirm {{ confirm.type }}?</v-card-title
        >
        <v-card-text
          >Are you sure you want to {{ confirm.type }}
          {{ (confirm.data && confirm.data.code) || "this" }}.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="loading"
            @click="confirm.status = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="error"
            :loading="loading"
            @click="_confirm"
            text
            >{{ confirm.type }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ShopRetailLinkComponent from "@/components/ChangTT/ShopRetailLink";

import VansalesModel from "@/store/models/changtt/vansales";

export default {
  name: "FormAreaPromoter",
  components: {
    ShopRetailLinkComponent,
  },
  props: ["value", "data"],
  computed: {
    shopType() {
      if(this.data && this.data.agent && this.data.agent.shopTypeId == 1) {
        return "เอเย่นต์";
      } else if(this.data && this.data.agent && this.data.agent.shopTypeId == 2) {
        return "ซับเอเย่นต์";
      } else {
        return "ซับซื้อตรง";
      }
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      rulesRequired: [
        (v) => (v && v.length > 0) || 'กรุณากรอกข้อมูล',
      ],  
      rulesCode: [
        (v) => !v || /^[0-9a-zA-Z]+$/.test(v) || "รูปแบบข้อมูลไม่ถูกต้อง",
      ],
      rulesNumber: [
        (v) => !v || /^[0-9]+$/.test(v) || "รูปแบบข้อมูลไม่ถูกต้อง",
      ],
      form: {},
      confirm: {
        status: false,
      },
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.form = this.data;
          this.form.registeredtext = this._registed(this.data.registered)
        }
      },
      deep: true,
    },
  },
  methods: {
    _back() {
      this.$emit("input", false);
    },
    _fetch() {
      this.$emit("fetch");
    },
    _image(img) {
      if(img) {
        return img;
      }
      return require('@/assets/no-preview-available.png');
    },
    _address(data = {}) {
      return `${data.address} ${data.subDistrict && data.subDistrict.name} ${data.district && data.district.name} ${data.province && data.province.name} ${data.postcode}`
    },
    _registed(registered = false) {
      return (registered)? 'ลงทะเบียนแล้ว': 'ยังไม่ได้ลงทะเบียน';
    },
    _unlink(data = {}) {
      this.confirm = {
        status: true,
        type: "unlink",
        data,
      }
    },
    _delete(data = {}) {
      this.confirm = {
        status: true,
        type: "delete",
        data,
      }
    },
    _save() {
      const valid = this.$refs.form.validate();
      if(valid) {
        const form = this.form;
        this.$emit("submit", form);
      }
    },
    async _confirm() {
      this.loading = true;
      this.confirm.status = false;
      const confirm = this.confirm;
      const data = confirm.data || {};

      let res;
      if (confirm.type === "unlink") {
        res = await VansalesModel.unlink(data.id, {}).catch((err) =>
          console.error(err)
        );
        if (res) {
          this.form.registered = false;
          this.form.registeredtext = this._registed(false);
          this._fetch();
        }
      } else if(confirm.type === "delete") {
        res = await VansalesModel.delete(data.id, {}).catch((err) =>
          console.error(err)
        );
        if (res) {
          this._fetch()
          setTimeout(() => {
            this._back()
          }, 300);
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped>
  .col {
    padding: 10px 5px 10px 5px !important;
  }

  .promoter-h1 {
    font-size: 24px;
  }
</style>
