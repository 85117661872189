<template>
  <v-layout v-if="value" class="mx-4" wrap>
    <v-flex xs12 md12>
      <v-row class="mx-0">
        <v-col cols="12" offset-1>
          <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
            <v-icon left> mdi-less-than </v-icon>
            ย้อนกลับ
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-2">
        <v-col cols="6" class="promoter-h1">
          {{ form.id ? "Edit" : "Create" }} Reward
        </v-col>
      </v-row>
      <!-- Form -->
      <v-form ref="form" v-model="valid" @submit.prevent="_save">
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field
              v-model="form.title"
              label="Reward"
              :rules="[...rulesRequired]"
              required
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <GalleryUploadFile
              label="Image"
              v-model="form.image"
              :max="1"
              :option="upOption"
              :editable="true"
              accept="image/*"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-textarea
              outlined
              label="Description"
              v-model="form.description"
              :rules="[...rulesRequired]"
            />
          </v-col>
        </v-row>

        <div class="text-right">
          <v-btn color="error" @click="_back">ยกเลิก</v-btn>
          <v-btn
            color="success"
            type="submit"
            class="ml-3 mr-2"
            :disabled="!valid"
            >บันทึก</v-btn
          >
        </div>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { capitalize } from "lodash";

import GalleryUploadFile from "@/components/GalleryUploadFile";

export default {
  name: "FormReward",
  props: ["value", "data"],
  components: {
    GalleryUploadFile,
  },
  computed: {
    status() {
      return "active";
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      rulesRequired: [(v) => (v && v.length > 0) || "กรุณากรอกข้อมูล"],
      form: {},
      upOption: {
        width: 1280,
        height: 720,
        aspectRatio: 1280 / 720,
      },
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.form = this.data;
        }
      },
      deep: true,
    },
  },
  methods: {
    capitalize,
    _back() {
      this.$emit("input", false);
    },
    _fetch() {
      this.$emit("fetch");
    },
    _save() {
      const valid = this.$refs.form.validate();
      if (valid) {
        const form = this.form;
        let formData = new FormData();

        formData.append("title", form.title);
        formData.append("description", form.description);
        if (typeof form.image !== "string")
          formData.append("image", form.image);

        this.$emit("submit", formData, form.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$green: green;
$yellow: orange;
$brown: brown;

.status-section {
  padding: 10px 30px;
  border: 1px solid #000;
  border-radius: 5px;
}

.green-text {
  color: $green;
}
.yellow-text {
  color: $yellow;
}
.brown-text {
  color: $brown;
}

.col {
  padding: 10px 5px 10px 5px !important;
}

.promoter-h1 {
  font-size: 24px;
}
</style>
