/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Point extends Model {
  static fields () {
    return {
      shopId: this.number(),
      shopName: this.string(),
      shopCode: this.string(),
      shopTier: this.string(),
    }
  }

  static gets (shopTypeId = 0, regionId = 0, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shops/shop-types/${shopTypeId}/regions/${regionId}`,
      dataTransformer: (response) => {
        return response && response.data && response.data.data && response.data.data.map((e) => {
          e.id = e.shopId
          return e
        }) || []
      },
      ...options
    }).then((res) => res.response.data)
  }

  static detail (shopId, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shops/${shopId}/settlePoints`,
      dataTransformer: (response) => {
        return response && response.data && response.data.data && response.data.data.map((e, i) => {
          e.id = i + 1
          return e
        }) || []
      },
      ...options
    }).then((res) => res.response.data)
  }

  static download (shopTypeId, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shops/shop-types/${shopTypeId}/download`,
      ...options,
      responseType: "blob"
    })
    .then((res) => res.response.data)
  }
}

Point.entity = 'point'

export default Point