/* eslint-disable */
import { Model } from '@vuex-orm/core'

class CTTAreaPromoter extends Model {
  static fields() {
    return {
      id: this.string(),
      birthDate: this.string(),
      citizenId: this.string(),
      code: this.string(),
      firstName: this.string(),
      lastName: this.string(),
      registered: this.boolean(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
    }
  }

  static create(options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/chang-tt/area-promoters`,
      ...options,
    }).then((res) => res.response)
  }

  static list(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/area-promoters`,
      ...options,
    }).then((res) => res.response.data)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/chang-tt/area-promoters/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static transfer (user_id = 0, options = {}) {
    if(options.data) {
      options.data.relation = "area.promoter";
    }
    return this.api().request({
      method: 'PUT',
      url: `/chang-tt/users/${user_id}/shops/transfer`,
      ...options,
    }).then((res) => res.response)
  }

  static delete (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/area-promoters/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static unlink (id, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/users/unlink/${id}`,
      ...options,
    }).then((res) => res.response)
  }

  static linkBulk(shop_id = 0, options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/chang-tt/shops/${shop_id}/area-promoters/link`,
      ...options,
    }).then((res) => res.response)
  }

  static unlinkBulk(shop_id = 0, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/shops/${shop_id}/area-promoters/unlink`,
      ...options,
    }).then((res) => res.response)
  }

  static unlinkShops(area_promoter_id = 0, options = {}) {
    return this.api().request({
      method: 'DELETE',
      url: `/chang-tt/area-promoters/${area_promoter_id}/shops`,
      ...options,
    }).then((res) => res.response)
  }
}

CTTAreaPromoter.entity = 'areapromoter'

export default CTTAreaPromoter