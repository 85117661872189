/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Tier extends Model {
  static fields () {
    return {
      shopId: this.number(),
      shopName: this.string(),
      shopCode: this.string(),
      shopTier: this.string(),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/tiers`,
      dataTransformer: (response) =>  response && response.data && response.data.data || [],
      ...options
    }).then((res) => res.response.data)
  }
}

Tier.entity = 'tier'

export default Tier