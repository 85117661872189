<template>
  <v-layout v-if="value" class="mx-4" wrap>
    <v-flex xs12 md12>
      <v-row class="mx-0">
        <v-col cols="12" offset-1>
          <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
            <v-icon left> mdi-less-than </v-icon>
            ย้อนกลับ จัดการ Area Promoter
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-2">
        <v-col cols="6" class="promoter-h1">
          รายละเอียด Area Promoter
        </v-col>
        <v-col cols="6">
          <v-btn color="#c7c7cc" class="ml-4 black--text float-right" v-if="form.id" @click="_delete(form)">ยกเลิกผู้ใช้งาน</v-btn>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-form ref="form" v-model="valid" @submit.prevent="_save">
        <v-card :outlined="true">
          <v-card-title>
            ข้อมูลพนักงาน
          </v-card-title>

          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6">
                <v-text-field
                  v-model="form.code"
                  label="รหัสพนักงาน"
                  :rules="[...rulesRequired, ...rulesCode]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.citizenId"
                  label="เลขบัตรประชาขน"
                  :rules="[...rulesRequired, ...rulesNumber, ...rulesLength]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-text-field
                  v-model="form.firstName"
                  label="ชื่อ"
                  :rules="rulesRequired"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.lastName"
                  label="นามสกุล"
                  :rules="rulesRequired"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-menu
                  ref="bdate"
                  v-model="vdate.birthDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthDateFormatted"
                      label="วันเกิด"
                      :hide-details="true"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="th"
                    v-model="form.birthDate"
                    :active-picker.sync="activePicker"
                    @change="_setBirthDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.phone"
                  label="เบอร์โทรศัพท์"
                  :rules="[...rulesRequired, ...rulesNumber]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="form.id">
              <v-col cols="6">
                <v-text-field
                  v-model="form.registeredtext"
                  label="สถานะออนไลน์"
                  :hide-details="true"
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="form.registered">
                <v-btn color="#f4cccc" class="mr-4 red--text" @click="_unlink(form)">Unlink</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mb-3">
            <v-btn
              color="success"
              type="submit"
              class="ml-3 mr-2"
              :disabled="!valid"
              >บันทึก</v-btn
            >
            <v-btn color="error" @click="_back">ยกเลิก</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-flex>
    
    <!-- Shop Retail -->
    <ShopRetailLinkComponent v-model="this.form.id" :data="this.form" type="areapromoter" />

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="confirm.status" persistent max-width="600">
      <v-card>
        <v-card-title class="headline"
          >Confirm {{ confirm.type }}?</v-card-title
        >
        <v-card-text
          >Are you sure you want to {{ confirm.type }}
          {{ (confirm.data && confirm.data.code) || "this" }}.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="loading"
            @click="confirm.status = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="error"
            :loading="loading"
            @click="_confirm"
            text
            >{{ confirm.type }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from "moment";

import ShopRetailLinkComponent from "@/components/ChangTT/ShopRetailLink";

import AreaPromoterModel from "@/store/models/changtt/areaPromoter";

export default {
  name: "FormAreaPromoter",
  components: {
    ShopRetailLinkComponent,
  },
  props: ["value", "data"],
  computed: {},
  data() {
    return {
      loading: false,
      valid: false,
      rulesRequired: [
        (v) => (v && v.length > 0) || 'กรุณากรอกข้อมูล',
      ],  
      rulesCode: [
        (v) => !v || /^[0-9a-zA-Z]+$/.test(v) || "รูปแบบข้อมูลไม่ถูกต้อง",
      ],
      rulesNumber: [
        (v) => !v || /^[0-9]+$/.test(v) || "รูปแบบข้อมูลไม่ถูกต้อง",
      ],
      rulesLength: [
        v => v.length === 13 || "รูปแบบข้อมูลไม่ถูกต้อง"
      ],
      form: {},
      activePicker: null,
      vdate: {
        birthDate: false,
      },
      birthDateFormatted: null,
      confirm: {
        status: false,
      },
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.form = this.data;
          this.form.registeredtext = this._registed(this.form.registered);
          this.birthDateFormatted = this.$stringDateAdToBe(this.form.birthDate);
        }
      },
      deep: true,
    },
    "vdate.birthDate": {
      handler(value) {
        value && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      deep: true,
    },
  },
  methods: {
    _back() {
      this.$emit("input", false);
    },
    _fetch() {
      this.$emit("fetch");
    },
    _image(img) {
      if(img) {
        return img;
      }
      return require('@/assets/no-preview-available.png');
    },
    _address(data = {}) {
      return `${data.address} ${data.subDistrict && data.subDistrict.name} ${data.district && data.district.name} ${data.province && data.province.name} ${data.postcode}`
    },
    _registed(registered = false) {
      return (registered)? 'ลงทะเบียนแล้ว': 'ยังไม่ได้ลงทะเบียน';
    },
    _unlink(data = {}) {
      this.confirm = {
        status: true,
        type: "unlink",
        data,
      }
    },
    _delete(data = {}) {
      this.confirm = {
        status: true,
        type: "delete",
        data,
      }
    },
    _setBirthDate(date) {
      const ndate = this.$stringDateAdToBe(date);

      this.$refs.bdate.save(date);
      this.birthDateFormatted = ndate;
    },
    _save() {
      const valid = this.$refs.form.validate();
      if(valid) {
        const form = this.form;
        form.birthDate = moment(form.birthDate).format("YYYY-MM-DD")

        this.$emit("submit", form);
      }
    },
    async _confirm() {
      this.loading = true;
      this.confirm.status = false;
      const confirm = this.confirm;
      const data = confirm.data || {};

      let res;
      if (confirm.type === "unlink") {
        res = await AreaPromoterModel.unlink(data.id, {}).catch((err) =>
          console.error(err)
        );
        if (res) {
          this.form.registered = false;
          this._registed(false);
        }
      } else if(confirm.type === "delete") {
        res = await AreaPromoterModel.delete(data.id, {}).catch((err) =>
          console.error(err)
        );
        if (res) {
          this._fetch()
          setTimeout(() => {
            this._back()
          }, 300);
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped>
  .col {
    padding: 10px 5px 10px 5px !important;
  }

  .promoter-h1 {
    font-size: 24px;
  }
</style>
