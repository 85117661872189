<template>
  <div class="loading">
    <v-progress-circular
      :size="70"
      :width="7"
      color="green darken-3"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: [],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: 10vh;
}
</style>
