/* eslint-disable */
import { Model } from '@vuex-orm/core'

class CTTUser extends Model {
  static fields() {
    return {
      id: this.number(),
      code: this.string(),
      gender: this.string(),
      firstName: this.string(),
      lastName: this.string(),
      email: this.string(),
      phone: this.string(),
      phoneOther: this.string(),
      citizenId: this.string(),
      birthDate: this.string(),
      note: this.string(),
      qrCode: this.string(),
      qrUrl: this.string(),
      shop: this.attr(),
      utm: this.attr(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
    }
  }

  static retailListAgent(id, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/shops/${id}/agents`,
      ...options,
    }).then((res) => res.response.data)
  }

  static retailListSubAgent(id, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/shops/${id}/sub-agents`,
      ...options,
    }).then((res) => res.response.data)
  }

  static retailListAreaPromoter(id, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/shops/${id}/area-promoters`,
      ...options,
    }).then((res) => res.response.data)
  }

  static retailListVansales(id, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/shops/${id}/vansales`,
      ...options,
    }).then((res) => res.response.data)
  }

  static retailListSubVansales(id, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/shops/${id}/sub-vansales`,
      ...options,
    }).then((res) => res.response.data)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/chang-tt/users/${id}`,
      ...options,
    }).then((res) => res.response)
  }
}

CTTUser.entity = 'user'

export default CTTUser