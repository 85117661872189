import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VLayout,{attrs:{"wrap":""}},[(_vm.user.role != 'normal')?_c(VFlex,{attrs:{"xs2":"","md2":""}},[_c(VBtn,{staticStyle:{"margin":"3%"},on:{"click":_vm.downloadSale}},[_vm._v("Download")])],1):_vm._e(),_c(VFlex,{staticStyle:{"margin":"10px"},attrs:{"xs4":"","md4":""}}),_c(VFlex,{staticStyle:{"margin-top":"10px"},attrs:{"xs12":"","md12":""}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Sale "),_c(VSpacer),_c(VTextField,{staticStyle:{"margin-right":"2%"},attrs:{"append-icon":"search","label":"Search"},on:{"change":_vm.getSalebyQuery},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.regions.length > 2)?_c(VSelect,{staticStyle:{"width":"5%"},attrs:{"outlined":"","items":_vm.regions,"item-text":"label","item-value":"id","label":"Region"},on:{"change":_vm.getSalebyRegion},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}):_vm._e()],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sale_data,"footer-props":{'items-per-page-options': _vm.$itemsPerPage},"items-per-page":_vm.perPage,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.countItems,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.areas",fn:function({ item }){return [_vm._v(_vm._s(_vm.tableAreas(item)))]}}])},[_c(VIcon,{on:{"click":function($event){_vm.dialog = true}}},[_vm._v("search")])],1)],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","width":"300","height":"5000"},model:{value:(_vm.dialog_loading),callback:function ($$v) {_vm.dialog_loading=$$v},expression:"dialog_loading"}},[_c(VCard,{attrs:{"color":"primary","dark":""}},[_c(VCardText,[_c('br'),_vm._v("Uploading.... "),_c('br'),_c('br'),_c(VProgressLinear,{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }