/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Catalog extends Model {
  static fields () {
    return {
      id: this.number(),
      order: this.number(0),
      title: this.string(''),
      logo: this.string(''),
      is_public: this.boolean(false),
      CreatedAt: this.string(''),
      UpdatedAt: this.string(''),
      DeletedAt: this.string(''),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: '/v1/reward-catalogs',
      ...options
    }).then((res) => res.response)
  }

  static update (id, options = {}) {
    return this.api().request({
      method: 'PUT',
      url: `/v1/reward-catalogs/${id}`,
      ...options
    }).then((res) => res.response)
  }

  static public (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/reward-catalogs/public`,
      ...options
    }).then((res) => res.response)
  }
}

Catalog.entity = 'catalog'

export default Catalog