<template>
  <div>
    <SideBar :bar_name="tabName" :user="user"></SideBar>
    <v-main>
      <v-container>
        <EmployeeLine v-bind="{ user, tabName }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import EmployeeLine from "@/components/Sale/EmployeeLine";

export default {
  name: "EmployeeLineManagement",
  components: {
    SideBar,
    EmployeeLine,
  },
  data() {
    return {
      user: this.$user,
      tabName: "Employee Line User Management",
    };
  },
};
</script>
