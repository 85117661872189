<template>
  <div>
    <SideBar :bar_name="`Chang TT ${tabName}`" :user="user"></SideBar>
    <v-main>
      <v-container>
        <SpecialReward v-bind="{ user, tabName: 'ของรางวัลพิเศษ' }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";
import SpecialReward from "@/components/ChangTT/SpecialReward";

export default {
  name: "CTTSpecialReward",
  components: {
    SideBar,
    SpecialReward,
  },
  data() {
    return {
      user: this.$user,
      tabName: "Special Reward",
    };
  },
};
</script>
