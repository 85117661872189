<template>
  <!-- <div > -->
  <v-main
    style="
      background-image: linear-gradient(to right, #79d455, #016445);
      height: 100%;
    "
  >
    <v-container>
      <v-form v-model="valid" @submit.prevent="login">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <div style="display: flex; justify-content: center">
              <img
                style="width: 400px"
                :src="require('../assets/Chang-Family-2020-Logo.png')"
              />
            </div>

            <v-card style="padding: 40px" class="ma-auto" width="500">
              <v-layout text-xs-center wrap>
                <v-flex mb-4>
                  <h2
                    class="headline mb-3"
                    style="
                      font-family: CHANG Sans REG !important;
                      text-align: center;
                    "
                  >
                    กรุณา login เพื่อเข้าสู่ระบบ
                  </h2>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="email"
                    label="อีเมล์"
                    required
                    :rules="emailRules"
                    id="usernameInput"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    v-model="password"
                    label="รหัสผ่าน"
                    :type="'password'"
                    :rules="passwordRules"
                    id="passwordInput"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 style="margin-top: 24px">
                  <v-btn
                    block
                    color="success"
                    type="submit"
                    id="loginBtn"
                    :disabled="!valid"
                    >เข้าสู่ระบบ</v-btn
                  >
                </v-flex>

                <v-flex
                  xs12
                  style="text-align: center; margin-top: 4%; color: green"
                >
                  <v-btn
                    text
                    color="green"
                    dark
                    @click="navigate('/forgetPassword')"
                    >ลืมรหัสผ่าน?</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>

    <!-- <div>------------------------------------- Loading Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="dialog_loading" persistent width="300" height="5000">
      <v-card color="primary" dark>
        <v-card-text>
          <br />Login in......
          <br />
          <br />

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>

  <!-- <v-container style="height:100%">
      <v-row style="height:100%">
        <v-col cols="12" style="height:100%; padding: 5%;">
  <v-row align="center" justify="center" style="height:100%">-->
  <!-- style=" padding: 25px; background-color: #fafafa; border-radius: 10px;" -->

  <!-- </v-row>
        </v-col>
      </v-row>

  </v-container>-->
  <!-- </div> -->
</template>

<script>
import Authen from "@/store/models/auth";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      dialog_loading: false,
      valid: false,
      passwordRules: [
        (v) => !!v || "Password is required",
        // v => v.length >= 6 || 'Password must be less than 6 characters',
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || // eslint-disable-line
          "E-mail must be valid",
      ],
    };
  },
  methods: {
    navigate(page) {
      this.$router.push({ path: page });
    },
    login() {
      if (this.valid) {
        this.dialog_loading = true;
        let formData = new FormData();

        formData.append("channel", "email");
        formData.append("email", this.email);
        formData.append("password", this.password);

        Authen.login(formData)
          .then((res) => {
            this.dialog_loading = false;
            if (res.response.data && res.response.data.access_token) {
              this.navigate("/product");
            }
          })
          .catch((e) => {
            this.dialog_loading = false;
            console.error(e.response.data);
          });
      }
    },
  },
};
</script>
