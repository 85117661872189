import Vue from 'vue'
import Vuetify from 'vuetify'
import router from './router'
import vuetify from './plugins/vuetify'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import VueCroppie from 'vue-croppie'
import VueHtmlToPaper from 'vue-html-to-paper'
import browserDetect from 'vue-browser-detect-plugin'
import 'croppie/croppie.css' // import the croppie css manually
import InfiniteLoading from 'vue-infinite-loading'

import App from './App.vue'
import store from './store'
import { parseJWT } from './plugins/auth'
import {
  appName,
  appList,
  agentTabs,
  catalogTabs,
  itemsPerPage,
  months,
  userRoles,
  shopRelation,
  operator,
  smSendtype,
  stringDateBeToAd,
  stringDateAdToBe,
} from './plugins/common'

import Loading from '@/components/Loading'
import NotFound from '@/components/NotFound'
import LightBox from "@/components/LightBox.vue";

Vue.config.productionTip = false

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
}

Vue.use(browserDetect)
Vue.use(VueCroppie)
Vue.use(VueHtmlToPaper, options)
Vue.use(InfiniteLoading, {})
Vue.use(Vuetify)

Vue.prototype.$appName = appName
Vue.prototype.$appList = appList
Vue.prototype.$agentTabs = agentTabs
Vue.prototype.$catalogTabs = catalogTabs
Vue.prototype.$userRoles = userRoles
Vue.prototype.$shopRelation = shopRelation
Vue.prototype.$itemsPerPage = itemsPerPage
Vue.prototype.$months = months
Vue.prototype.$operator = operator
Vue.prototype.$smSendtype = smSendtype
Vue.prototype.$stringDateBeToAd = stringDateBeToAd
Vue.prototype.$stringDateAdToBe = stringDateAdToBe

Vue.prototype.$numberFormat = (number = 0, digit = 0) => {
  return number.toLocaleString(undefined, { minimumFractionDigits: digit })
}

Vue.prototype.$parseJson = (json = '') => {
  if (typeof json === 'string') {
    try {
      return JSON.parse(json)
    } catch (error) {
      return
    }
  }

  return
}

Vue.prototype.$swal = function(text) {
  Swal.fire('Error...', text, 'error')
}

Vue.prototype.$swal_nobtn = function(text) {
  Swal.fire({
    type: 'error',
    text: text,
    showConfirmButton: false,
    allowOutsideClick: false,
  })
}

Vue.prototype.$swal_success = function(text) {
  Swal.fire('Success', text, 'success')
}

Vue.prototype.$parseJwt = function(token) {
  return parseJWT(token)
}

Vue.component('Loading', Loading)
Vue.component('NotFound', NotFound)
Vue.component('LightBox', LightBox)

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
