import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import axios from 'axios'
import _ from 'lodash'
import Swal from 'sweetalert2'

import router from '../router'
import { getTokenAndUser, getUser } from '../plugins/auth'
import Authen from './models/auth'
import Agent from './models/agent'
import Catalog from './models/catalog'
import Reward from './models/reward'
import Promotion from './models/promotion'
import Region from './models/region'
import Banner from './models/banner'
import Employee from './models/employee'
import Redeemed from './models/redeemed'
import Notify from './models/notify'
import Point from './models/point'
import Tier from './models/tier'
import User from './models/user'
import ReportEmail from './models/reportEmail'
import Variable from './models/variable'
import SmartAlert from './models/smartalert'
import ShopUser from './models/shopuser'
import GwCoupon from './models/gwCoupon'

import CTTShopType from './models/changtt/shopType'
import CTTRegion from './models/changtt/region'
import CTTProvince from './models/changtt/province'
import CTTDistrict from './models/changtt/district'
import CTTSubDistrict from './models/changtt/subDistrict'
import CTTPostcode from './models/changtt/postcode'
import CTTUser from './models/changtt/user'
import CTTShop from './models/changtt/shop'
import CTTAreaPromoter from './models/changtt/areaPromoter'
import CTTVansales from './models/changtt/vansales'
import CTTPartner from './models/changtt/partner'
import CTTStampCard from './models/changtt/stampCard'
import CTTStampApprove from './models/changtt/stampApprove'
import CTTStampMission from './models/changtt/stampMission'
import CTTStampReward from './models/changtt/stampReward'
import CTTStampRewardDelivery from './models/changtt/stampRewardDelivery'
import CTTSpecialReward from './models/changtt/specialReward'

const { token, user } = getTokenAndUser()
let headers = {}
if (token) {
  headers = {
    Authorization: `Bearer ${token}`,
  }
}

const setUserVue = (user) => {
  Vue.prototype.$user = user
}
setUserVue(user)

const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers,
  timeout: 1000 * 20, // 20 seconds
})

base.interceptors.response.use(
  (response) => {
    if (response.data && response.data.access_token) {
      localStorage.setItem('token', response.data.access_token)
      base.defaults.headers.Authorization = `Bearer ${response.data.access_token}`

      // User
      const user = getUser(response.data.access_token)
      setUserVue(user)

      // Region
      Region.gets({}).then((response) => {
        return response
      })
    }

    return response
  },
  (error) => {
    const err0 = _.get(error, 'response.data.data.message', '')
    const err1 = _.get(error, 'response.data.data', '')
    const err2 = _.get(error, 'response.data.message', '')
    const err3 = _.get(error, 'response.data', '')
    const err4 = _.get(error, 'response.data.description', '')
    const status = _.get(error, 'response.status')
    const url = _.get(error, 'response.config.url')

    let text = err0 || err1 || err2 || err3 || err4 || ''
    text = typeof text === 'string' ? text.trim().replace('\n', '<br/>') : ''

    if (
      (status === 401 || text === 'missing or malformed jwt') &&
      url !== '/auth'
    ) {
      base.defaults.headers.Authorization = ''
      localStorage.setItem('token', '')
      router.push({ path: '/' }).catch(() => {})
    } else {
      text = !text ? 'เกิดข้อผิดพลาดกรุณาติดต่อเจ้าหน้าที่' : text
      Swal.fire('Error...', text, 'error')
    }

    // whatever you want to do with the error
    throw error
  }
)

Vue.use(Vuex)
VuexORM.use(VuexORMAxios, {
  axios: base,
})

const database = new VuexORM.Database()
database.register(Authen)
database.register(Agent)
database.register(Catalog)
database.register(Reward)
database.register(Promotion)
database.register(Region)
database.register(Banner)
database.register(Employee)
database.register(Redeemed)
database.register(Notify)
database.register(Point)
database.register(Tier)
database.register(User)
database.register(ReportEmail)
database.register(Variable)
database.register(SmartAlert)
database.register(ShopUser)
database.register(GwCoupon)

database.register(CTTShopType)
database.register(CTTRegion)
database.register(CTTProvince)
database.register(CTTDistrict)
database.register(CTTSubDistrict)
database.register(CTTPostcode)
database.register(CTTUser)
database.register(CTTShop)
database.register(CTTAreaPromoter)
database.register(CTTVansales)
database.register(CTTPartner)
database.register(CTTStampCard)
database.register(CTTStampApprove)
database.register(CTTStampMission)
database.register(CTTStampReward)
database.register(CTTStampRewardDelivery)
database.register(CTTSpecialReward)

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
})
