<template>
  <div>
    <v-flex xs12 md12 class="mt-1" v-if="!dialog && !viewretail.show">
      <v-row class="mx-0 mb-2">
        <v-col cols="4" class="title-h1">
          จัดการรถเร่
        </v-col>
        <v-col cols="8" class="d-inline-block">
          <v-row no-gutters>
            <v-select
              v-model="type"
              :items="types"
              item-text="label"
              item-value="value"
              label="ประเภทร้าน"
              :hide-details="true"
              class="mr-2 select-shop-type"
              outlined
              dense
              @change="_getByQuery"
            />
            <v-select
              v-model="region"
              :items="regions"
              item-text="label"
              item-value="id"
              label="ภาค"
              :hide-details="true"
              class="mr-2"
              outlined
              dense
              @change="_getByQuery"
            />
            <v-text-field
              v-model="search"
              append-icon="search"
              label="ค้นหา"
              outlined
              dense
              :hide-details="true"
              @keydown.enter="_getByQuery"
            ></v-text-field>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        class="clean-tb tb-agent"
        item-key="index"
        :headers="headers"
        :items="indexedItems"
        :footer-props="{ 'items-per-page-options': itemsPerPage }"
        :items-per-page="perPage"
        :loading="loading"
        :options.sync="options"
        :server-items-length="countItems"
        hide-default-header
        no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
        no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="py-3">
              <v-card>
                <v-card-title>
                  {{item.code}} {{item.name}} {{item.region && item.region.label && '('+item.region.label+')'}} จำนวนรถเร่ {{item.vansales && item.vansales.length || 0}} คัน
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-3 chang-btn"
                    :loading="loading"
                    color="#006b35"
                    @click="_new(item)"
                  >
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    เพิ่มรถเร่
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    color=""
                    @click="_view(item)"
                  >
                    <v-icon left>
                      mdi-store
                    </v-icon>
                    ดูร้านค้าปลีก
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    class="clean-tb"
                    :headers="headers"
                    :items="_indexedVansales(item.vansales)"
                    :hide-default-footer="true"
                    no-data-text="ไม่พบข้อมูล"
                    no-results-text="ไม่พบข้อมูล"
                  >
                    <template v-slot:item.fullName="{ item: iv }">
                      {{ `${iv.firstName} ${iv.lastName}` }}
                    </template>
                    <template v-slot:item.registered="{ item: iv }">
                      <span v-html="_registered(iv.registered)"></span>
                    </template>
                    <template v-slot:item.actions="{ item: iv }">
                      <v-btn
                        :loading="loading"
                        @click="_edit(item, iv)"
                        depressed
                        block
                      >
                        <v-icon left>
                          mdi-pencil-outline
                        </v-icon>
                        แก้ไข
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>

    <!-- <div>------------------------------------- Form -----------------------------------------------------</div> -->
    <FormVansales v-model="dialog" :data="this.form" @fetch="fetchData" @submit="_save" />

    <!-- Shop Retail -->
    <v-layout v-if="viewretail.show" class="mx-4" wrap>
      <v-flex xs12 md12>
        <v-row class="mx-0">
          <v-col cols="12" offset-1>
            <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_closeview">
              <v-icon left> mdi-less-than </v-icon>
              ย้อนกลับ จัดการรถเร่
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
      <ShopRetailLinkComponent v-model="viewretail.show" :data="viewretail.data" type="agent" />
    </v-layout>
  </div>
</template>

<script>
import _ from "lodash";

import FormVansales from "@/components/ChangTT/FormVansales";
import ShopRetailLinkComponent from "@/components/ChangTT/ShopRetailLink";

import AgentModel from "@/store/models/agent";
import ShopModel from "@/store/models/changtt/shop";
import VansalesModel from "@/store/models/changtt/vansales";
import RegionModel from "@/store/models/region";

const formDefault = {
  agent: {},
};

export default {
  name: "VansalesComponent",
  components: {
    FormVansales,
    ShopRetailLinkComponent,
  },
  props: ["user", "tabName"],
  computed: {
    indexedItems() {
      return (
        this.items.map((item, index) => ({
          index,
          ...item,
        })) || []
      );
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      itemsPerPage: [
        5,
        10,
      ],
      perPage: 5,
      countItems: 0,
      confirm: {
        status: false,
      },
      headers: [
        { text: "ลำดับ", value: "index", sortable: false, align: "center", width: 50 },
        { text: "ชื่อ-นามสกุลรถเร่", value: "fullName", sortable: false },
        { text: "เบอรโทร", value: "phone", sortable: false, width: "25%" },
        { text: "สถานะไลน์", value: "registered", sortable: false, width: "15%" },
        { text: "", align: "center", value: "actions", sortable: false, width: "10%" },
      ],
      items: [],
      dialog: false,
      form: formDefault,
      viewretail: {
        show: false,
        data: {},
      },
      types: [{
        label: "เอเย่นต์",
        value: "1"
      }, {
        label: "ซับเอเย่นต์",
        value: "2"
      }, {
        label: "ซับซื้อตรง",
        value: "3"
      }],
      type: "1",
      regions: [],
      region: null,
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
    this.fetchRegion();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let options = { params: {} };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.region) {
        options.params.regionID = this.region;
      }

      if (this.type && this.type) {
        options.params.shopTypeId = this.type;
      } else {
        options.params.shopTypeId = this.types && this.types[0] && this.types[0].value || "1";
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.perPage = this.options.itemsPerPage;
      } else {
        options.params.perPage = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      const res = await AgentModel.listAgentForCTT(options).catch(() => this.loading = true);
      this.items = (res && res.data) || [];
      this.countItems = res && res.total || 0;
      this.loading = false;
    },
    async fetchRegion() {
      this.loading = true;

      const res = await RegionModel.gets().catch(() => this.loading = false);
      this.regions = res || [];
      this.regions.unshift({ id: null, label: "เลือกภาค" })

      this.loading = false;
    },
    _getByQuery() {
      this.selected = null;
      this.options.page = 1;
      this.fetchData();
    },
    _indexedVansales(items = []) {
      return (
        items.map((item, index) => ({
          index: index + 1,
          ...item,
        })) || []
      );
    },
    _registered(registered = false) {
      return (registered)? '<span style="color: #1f9812;">ลงทะเบียนแล้ว</span>': '<span style="color: #ff0000;">ยังไม่ได้ลงทะเบียน</span>';
    },
    _view(agent = {}) {
      this.viewretail = {
        show: true,
        data: agent,
      }
    },
    _closeview() {
      this.viewretail = {
        show: false,
        data: {},
      }
    },
    _new(agent = {}) {
      this.dialog = true;
      this.form = _.cloneDeep(formDefault);
      this.form.agent = _.cloneDeep(agent);
    },
    _edit(agent = {}, data = {}) {
      this.dialog = true;
      this.form = data;
      this.form.agent = agent;
    },
    async _save(data = {}) {
      this.loading = true;

      const options = {
        data,
      };
      let res;
      if (data.id) {
        res = await VansalesModel.update(data.id, options).catch((err) =>
          console.error(err)
        );
      } else {
        const agent = data.agent || {}

        // Create Shop Agent
        const optsh = {
          data: {
            code: agent.code,
            name: agent.name,
            phone: agent.phone_number,
            address: agent.address,
            agentSubType: agent.type && agent.type.name || "agent"
          }
        }
        const sh = await ShopModel.createAgent(optsh).catch((err) =>
          console.error(err)
        );

        // Create Vansales
        options.data.shopId = sh.id;
        res = await VansalesModel.create(options).catch((err) =>
          console.error(err)
        );
      }
      if (res) {
        this.fetchData();
        this.dialog = false;
        this.form = {};
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.title-h1 {
  font-size: 24px;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}

.theme--light.tb-agent .v-data-footer {
  border-top: 0 !important;
}

.select-shop-type {
  width: 150px !important;
}
</style>
