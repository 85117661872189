/* eslint-disable */
import { Model } from '@vuex-orm/core'

class CTTDistrict extends Model {
  static fields() {
    return {
      id: this.string(),
      name: this.string(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
    }
  }

  static list(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/districts`,
      ...options,
    }).then((res) => res.response.data)
  }
}

CTTDistrict.entity = 'district'

export default CTTDistrict