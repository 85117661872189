<template>
  <div>
    <SideBar :bar_name="tabName" :user="user" />
    <v-main>
      <v-container>
        <RetailShop v-bind="{ user, tabName: 'ข้อมูลร้านค้าปลีก' }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import RetailShop from "@/components/ChangTT/RetailShop";

export default {
  name: "CTTRetailShop",
  components: {
    SideBar,
    RetailShop,
  },
  data() {
    return {
      user: this.$user,
      tabName: "Chang TT Retail Shop",
    };
  },
};
</script>
