<template>
  <div>
    <v-layout wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="ค้นหาด้วย รหัส ชื่อ เลขบัตร บริษัท ตำแหน่ง"
              style="margin-right: 2%"
              :hide-details="true"
              @keydown.enter="_getByQuery"
            ></v-text-field>
            <v-btn
              class="mr-3"
              :loading="loading"
              :disabled="loading || !selected"
              color="success"
              @click="_sync(selected)"
            >
              Sync
            </v-btn>

            <v-btn
              :loading="loading"
              :disabled="
                loading ||
                !selected ||
                (selected && selected.status !== 'ลงทะเบียนแล้ว')
              "
              color="#F4CCCC"
              @click="_unlink(selected)"
            >
              Unlink
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-card class="mb-4">
              <v-card-text>
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="headers"
                  :items="indexedItems"
                  :footer-props="{ 'items-per-page-options': $itemsPerPage }"
                  :items-per-page="perPage"
                  :loading="loading"
                  :item-class="_itemClass"
                  :options.sync="options"
                  :server-items-length="countItems"
                  @click:row="_selected"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                >
                  <template v-slot:item.birthdate="{ item }">
                    {{ _birthDate(item.birthdate) }}
                  </template>
                  <template v-slot:item.status="{ item }">
                    <span
                      :class="item.status === 'ยังไม่ลงทะเบียน' && 'red--text'"
                      >{{ item.status }}</span
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->

    <v-dialog v-model="confirm.status" persistent max-width="600">
      <v-card>
        <v-card-title class="headline"
          >Confirm {{ confirm.type }}?</v-card-title
        >
        <v-card-text
          >Are you sure you want to {{ confirm.type }}
          {{ (confirm.data && confirm.data.name) || "this" }}.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="loading"
            @click="confirm.status = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            :color="(confirm.type === 'sync' && 'success') || 'error'"
            :loading="loading"
            @click="_confirm"
            text
            >{{ confirm.type }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

import Region from "@/store/models/region";
import Employee from "@/store/models/employee";

export default {
  name: "AgentLineManagement",
  components: {},
  props: ["user", "tabId", "tabName"],
  computed: {
    regions() {
      return Region.getsAll();
    },
    indexedItems() {
      return (
        this.items.map((item, index) => ({
          index,
          ...item,
        })) || []
      );
    },
  },
  data() {
    return {
      search: "",
      region: "All",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      perPage: 10,
      countItems: 0,
      confirm: {
        status: false,
      },
      headers: [
        {
          text: "รหัสพนักงาน",
          value: "code",
          sortable: false,
        },
        { text: "ชื่อ-นามสกุล", value: "full_name", sortable: false },
        { text: "เลขบัตรประชาชน", value: "citizen_id", sortable: false },
        { text: "วันเกิด", value: "birthdate", sortable: false },
        { text: "บริษัท", value: "company", sortable: false },
        { text: "ตำแหน่ง", value: "position", sortable: false },
        { text: "role", value: "ral", sortable: false },
        { text: "Line OA", value: "channelName", sortable: false },
        { text: "สถานะ", align: "center", value: "status", sortable: false },
      ],
      items: [],
      selected: null,
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.selected = null;
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let options = { params: {} };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.perPage = this.options.itemsPerPage;
      } else {
        options.params.perPage = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      const res = await Employee.users(options);
      this.items = (res && res.data) || [];
      this.countItems = res && res.total;
      this.loading = false;
    },
    _getByQuery() {
      this.selected = null;
      this.options.page = 1;
      this.fetchData();
    },
    _birthDate(birthdate = "") {
      let bd = moment(birthdate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (bd === "Invalid date") {
        return "";
      }
      return bd;
    },
    _itemClass(item) {
      return item.id === (this.selected && this.selected.id)
        ? "row-selected"
        : "";
    },
    _selected(data) {
      const selected = this.selected;

      if ((selected && selected.index) === data.index) {
        this.selected = null;
      } else {
        this.selected = data;
      }
    },
    _sync(data = {}) {
      this.confirm = {
        status: true,
        type: "sync",
        data,
      };
    },
    _unlink(data = {}) {
      this.confirm = {
        status: true,
        type: "unlink",
        data,
      };
    },
    async _confirm() {
      this.loading = true;
      this.confirm.status = false;
      const confirm = this.confirm;
      const data = confirm.data || {};

      let res;
      if (confirm.type === "sync") {
        res = await Employee.sync(data.code, {}).catch((err) =>
          console.error(err)
        );
      } else if (confirm.type === "unlink") {
        const form = new FormData();
        form.append("employee_code", data.code);

        res = await Employee.unlink({ data: form }).catch((err) =>
          console.error(err)
        );
        this.selected = null;
      }
      if (res) {
        this.fetchData();
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>

<style>
.row-selected {
  background-color: #b3d6b588 !important;
}
</style>
