<template>
  <v-layout v-if="value" class="mx-4" wrap>
    <v-flex xs12 md12>
      <v-row class="mx-0">
        <v-col cols="12" offset-1>
          <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
            <v-icon left> mdi-less-than </v-icon>
            ย้อนกลับ
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mx-0 my-2">
        <v-card-title class="d-block">
          <div>Stamp Card : {{ cardName }}</div>
          <div>Mission : {{ missionName }}</div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="data.startDate"
                label="วันที่เริ่มต้น"
                readonly
                outlined
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="data.endDate"
                label="วันที่สิ้นสุด"
                readonly
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-textarea
                outlined
                label="Condition"
                readonly
                v-model="data.condition"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="ค้นหา"
                class="mr-2"
                outlined
                dense
                :hide-details="true"
                @keydown.enter="_getByQuery"
            /></v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            class="clean-tb"
            item-key="index"
            :headers="headers"
            :items="indexedItems"
            :footer-props="{ 'items-per-page-options': $itemsPerPage }"
            :items-per-page="limit"
            :loading="loading"
            :options.sync="options"
            :server-items-length="countItems"
            no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
            no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ moment(item.createdAt).format("DD/MM/YYYY HH:mm") }}
            </template>
            <template v-slot:item.image="{ item }">
              <v-img :src="item.image" width="100" contain />
            </template>
            <template v-slot:item.status="{ item }">
              <span
                :class="`${
                  item.status === 'approved'
                    ? 'green-text'
                    : item.status === 'waiting-region'
                    ? 'yellow-text'
                    : 'brown-text'
                }`"
                >{{ capitalize(item.status) }}</span
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="item.status === 'waiting-region'"
                :loading="loading"
                @click="_approve(item)"
                depressed
                block
              >
                <v-icon left> mdi-check </v-icon>
                Approve / Reject
              </v-btn>
              <div v-else>-</div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- <div>------------------------------------- Approve / Reject ---------------------------------------------</div> -->
    <v-dialog v-model="approveDialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn icon @click="approveDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="valid" @submit.prevent="_saveApprove()">
          <v-card-text>
            <v-text-field
              v-model="approveData.shopName"
              label="Shop"
              readonly
              outlined
              dense
              hide-details
            />
            <div class="text-center my-3">
              <v-img :src="approveData.image" width="100%" contain />
            </div>
            <v-text-field
              v-model="approveData.note"
              label="หมายเหตุ"
              outlined
              dense
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" type="submit" :disabled="!valid" class="mr-2"
              >Approve</v-btn
            >
            <v-btn
              color="error"
              :disabled="!valid"
              @click="_saveApprove('reject')"
              >Reject</v-btn
            >
            <v-spacer />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { capitalize } from "lodash";
import moment from "moment";

import StampApproveModel from "@/store/models/changtt/stampApprove";

export default {
  name: "FormApproveAreaPromoterCRM",
  props: ["value", "data"],
  computed: {
    indexedItems() {
      return (
        this.items.map((item, index) => ({
          index: index + 1,
          ...item,
        })) || []
      );
    },
    status() {
      return "active";
    },
    cardName() {
      return this.data.card.title;
    },
    missionName() {
      return this.data.title;
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "No",
          value: "index",
          sortable: false,
        },
        {
          text: "Date",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "Shop",
          value: "shop.name",
          sortable: false,
        },
        { text: "Image", value: "image", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      search: "",
      items: [],
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      limit: 10,
      countItems: 0,
      approveDialog: false,
      approveData: {},
      rulesRequired: [(v) => (v && v.length > 0) || "กรุณากรอกข้อมูล"],
      valid: false,
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.form = this.data;
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  methods: {
    moment,
    capitalize,
    _getByQuery() {
      this.selected = null;
      this.options.page = 1;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      let options = { params: { status: "waiting-region" } };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.limit = this.options.itemsPerPage;
      } else {
        options.params.limit = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      const res = await StampApproveModel.list(this.data.id, options);
      const { data } = res;
      this.items = (data && data.data) || [];
      this.countItems = (data && data.total) || 0;
      this.loading = false;
    },
    _approve(data = {}) {
      this.approveDialog = true;
      this.approveData = { ...data, shopName: data.shop.name };
    },
    _back() {
      this.$emit("input", false);
    },
    _fetch() {
      this.$emit("fetch");
    },
    async _saveApprove(status = "approve") {
      const { approveData } = this;
      let res;
      const data = { note: approveData.note };
      const options = {
        data,
      };
      if (status === "approve") {
        res = await StampApproveModel.approve(approveData.id, options).catch(
          (err) => console.error(err)
        );
        if (res) {
          this._fetch();
          setTimeout(() => {
            this._back();
          }, 300);
        }
      } else {
        res = await StampApproveModel.reject(approveData.id, options).catch(
          (err) => console.error(err)
        );
        if (res) {
          this._fetch();
          setTimeout(() => {
            this._back();
          }, 300);
        }
      }
      this.approveDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$green: green;
$yellow: orange;
$brown: brown;

.status-section {
  padding: 10px 30px;
  border: 1px solid #000;
  border-radius: 5px;
}

.green-text {
  color: $green;
}
.yellow-text {
  color: $yellow;
}
.brown-text {
  color: $brown;
}

.col {
  padding: 10px 5px 10px 5px !important;
}

.promoter-h1 {
  font-size: 24px;
}
</style>
