<template>
  <div>
    <v-layout v-if="!dialog" wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer></v-spacer>
            <v-row no-gutters>
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="ค้นหา"
                  class="mr-2"
                  outlined
                  dense
                  :hide-details="true"
                  @keydown.enter="_getByQuery"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="status"
                  :items="statusList"
                  item-text="name"
                  item-value="value"
                  label="สถานะ"
                  class="mr-2"
                  :hide-details="true"
                  outlined
                  dense
                  @change="_getByQuery"
                />
              </v-col>
            </v-row>
            <v-btn
              class="chang-btn"
              :loading="loading"
              color="#006b35"
              @click="_new()"
            >
              <v-icon left> mdi-plus </v-icon>
              เพิ่ม
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-card class="mb-4">
              <v-card-text>
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="headers"
                  :items="indexedItems"
                  :footer-props="{ 'items-per-page-options': $itemsPerPage }"
                  :items-per-page="limit"
                  :loading="loading"
                  :options.sync="options"
                  :server-items-length="countItems"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                >
                  <template v-slot:item.startTime="{ item }">
                    {{ moment(item.startTime).format("DD/MM/YYYY HH:mm") }}
                  </template>
                  <template v-slot:item.endTime="{ item }">
                    {{ moment(item.endTime).format("DD/MM/YYYY HH:mm") }}
                  </template>
                  <template v-slot:item.limit="{ item }">
                    <div v-if="item.limit === 0 || item.isLimit === false">-</div>
                    <div v-else>{{ item.limit }}</div>
                  </template>
                  <template v-slot:item.createdAt="{ item }">
                    {{ moment(item.createdAt).format("DD/MM/YYYY HH:mm") }}
                  </template>
                  <template v-slot:item.publish="{ item }">
                    {{ item.publish === true ? "Public" : "Hidden" }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      :loading="loading"
                      @click="_edit(item)"
                      class="mr-2"
                      depressed
                    >
                      <v-icon left> mdi-pencil-outline </v-icon>
                      แก้ไข
                    </v-btn>
                    <v-btn
                      :loading="loading"
                      @click="_deleteModal(item)"
                      depressed
                    >
                      <v-icon left> mdi-trash-can-outline </v-icon>
                      ลบ
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <div>------------------------------------- Form -----------------------------------------------------</div> -->
    <FormSpecialReward
      v-model="dialog"
      :data="this.form"
      @fetch="fetchData"
      @submit="_save"
    />

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="_delete" />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import FormSpecialReward from "@/components/ChangTT/FormSpecialReward";
import ConfirmModal from "@/components/Confirm";

import SpecialRewardModel from "@/store/models/changtt/specialReward";

const formDefault = {};

export default {
  name: "SpecialRewardComponent",
  components: {
    FormSpecialReward,
    ConfirmModal,
  },
  props: ["user", "tabName"],
  computed: {
    indexedItems() {
      const { items } = this;
      let index = 0;
      const result = _.reduce(
        items,
        (o, e) => {
          const currentDate = moment();
          const startDate = moment(e.startTime);
          const endDate = moment(e.endTime);
          o.push({
            ...e,
            index: index + 1,
            status:
              startDate <= currentDate && endDate >= currentDate
                ? "active"
                : endDate < currentDate
                ? "closed"
                : "waiting",
          });
          index++;
          return o;
        },
        []
      );
      return result;
      // return (
      //   this.items.map((item, index) => ({
      //     index: index + 1,
      //     ...item,
      //   })) || []
      // );
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      limit: 10,
      countItems: 0,
      conf: {
        status: false,
        title: "ยืนยันการลบรางวัล?",
        text: `ของรางวัลพิเศษ`,
        cancelText: `ยกเลิก`,
        confirmText: `ยืนยัน`,
      },
      headers: [
        {
          text: "No",
          value: "index",
          sortable: false,
        },
        {
          text: "วันที่เริ่มต้น",
          value: "startTime",
          sortable: false,
        },
        {
          text: "วันที่สิ้นสุด",
          value: "endTime",
          sortable: false,
        },
        { text: "ชื่อของรางวัลพิเศษ", value: "name", sortable: false },
        { text: "จำนวนจำกัด", value: "limit", sortable: false },
        { text: "คะแนนที่ใช้", value: "point", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Publish", value: "publish", sortable: false },
        {
          text: "Create Date",
          value: "createdAt",
          sortable: false,
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      items: [],
      dialog: false,
      form: formDefault,
      status: "",
      statusList: [
        { name: "All", value: "" },
        { name: "Active", value: "active" },
        { name: "Waiting", value: "waiting" },
        { name: "Closed", value: "closed" },
      ],
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    moment,
    capitalize(text) {
      return _.capitalize(text);
    },
    async fetchData() {
      this.loading = true;
      let options = { params: {} };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.limit = this.options.itemsPerPage;
      } else {
        options.params.limit = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      if (this.status) {
        options.params.status = this.status;
      }

      const res = await SpecialRewardModel.list(options);
      const { data } = res;
      this.items = (data && data.data) || [];
      this.countItems = (data && data.total) || 0;
      this.loading = false;
    },
    _getByQuery() {
      this.selected = null;
      this.options.page = 1;
      this.fetchData();
    },
    _new() {
      this.dialog = true;
      this.form = _.cloneDeep(formDefault);
    },
    _edit(data = {}) {
      this.dialog = true;
      this.form = data;
    },
    _deleteModal(item) {
      this.conf = {
        ...this.conf,
        status: true,
        text: `ของรางวัลพิเศษ ${item.name}`,
        data: item,
      };
    },
    async _delete(value = false) {
      if (value) {
        const { data } = this.conf;
        let res;
        res = await SpecialRewardModel.delete(data.id, {}).catch((err) =>
          console.error(err)
        );
        if (res) {
          this.fetchData();
        }
      }

      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
    async _save(data = {}, id) {
      this.loading = true;

      const options = {
        data,
      };
      let res;
      if (id) {
        res = await SpecialRewardModel.update(id, options).catch((err) =>
          console.error(err)
        );
      } else {
        res = await SpecialRewardModel.create(options).catch((err) =>
          console.error(err)
        );
      }
      if (res) {
        this.fetchData();
        this.dialog = false;
        this.form = {};
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$green: green;
$yellow: orange;
$brown: brown;

.green-text {
  color: $green;
}
.yellow-text {
  color: $yellow;
}
.brown-text {
  color: $brown;
}
.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>
