<template>
  <div>
    <SideBar bar_name="Chang TT Vansales" :user="user"></SideBar>
    <v-main>
      <v-container>
        <VansalesComponent v-bind="{ user }" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";
import VansalesComponent from "@/components/ChangTT/Vansales";

export default {
  name: "CTTVansales",
  components: {
    SideBar,
    VansalesComponent,
  },
  data() {
    return {
      user: this.$user,
    };
  },
};
</script>
