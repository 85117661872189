/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Agent extends Model {
  static fields() {
    return {
      id: this.string(''),
      address: this.string(''),
      areaId: this.number(0),
      code: this.string(''),
      name: this.string(''),
      phone_number: this.string(''),
      points: this.number(0),
      region: this.attr(null),
      regionId: this.number(0),
      shopTierId: this.string(0),
      shopTypeId: this.string(0),
      shopUsers: this.attr(null),
      type: this.attr(null),
    }
  }

  static gets(shopTypeID = 0, options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shop-types/${shopTypeID}/shops`,
      ...options
    }).then((res) => res.response.data)
  }

  static listAgent(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/agents/list`,
      ...options
    }).then((res) => res.response.data)
  }

  static listSubAgent(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/sub-agents/list`,
      ...options
    }).then((res) => res.response.data)
  }

  static listAgentForCTT(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/chang-tt/agents`,
      ...options
    }).then((res) => res.response.data)
  }

  static upload(data) {
    return this.api().post('/api/upload/agents', data).then((res) => res.response.data)
  }

  static download(shopTypeID, options) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shop-types/${shopTypeID}/shops/download`,
      ...options,
      responseType: "blob"
    })
      .then((res) => res.response.data)
  }

  static sync(code = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shopUsers/${code}/sync`,
      ...options
    }).then((res) => res.response.data)
  }

  static async unlink(options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/shopUsers/unlink`,
      ...options
    }).then((res) => res.response.data)
  }

  static users(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shopUsers`,
      ...options
    }).then((res) => res.response.data)
  }

  static branch(options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/shopUsers/binds`,
      ...options
    }).then((res) => res.response.data)
  }
}

Agent.entity = 'agent'

export default Agent