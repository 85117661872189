/* eslint-disable */
import { Model } from '@vuex-orm/core'

class StampCard extends Model {
  static fields() {
    return {
      id: this.number(),
      approveStage: this.string(''),
      title: this.string(''),
      condition: this.string(''),
      createdAt: this.string(''),
      endDate: this.string(''),
      missions: this.attr(null),
      numberOfMission: this.number(0),
      publish: this.string(''),
      stampRewardId: this.number(0),
      startDate: this.string(''),
      status: this.string(''),
      updatedAt: this.string(''),
      reward: this.attr(null),
    }
  }

  static list(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/stamp-cards`,
        ...options,
      })
      .then((res) => res.response)
  }

  static create(options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/chang-tt/stamp-cards`,
        ...options,
      })
      .then((res) => res.response)
  }

  static update(id, options = {}) {
    return this.api()
      .request({
        method: 'PUT',
        url: `/chang-tt/stamp-cards/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }

  static delete(id, options = {}) {
    return this.api()
      .request({
        method: 'DELETE',
        url: `/chang-tt/stamp-cards/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }
}

StampCard.entity = 'stampCard'

export default StampCard
