<template>
  <div>
    <SideBar bar_name="Activities Campaign Dashboard" :user="user"></SideBar>
    <v-main>
      <v-container>
        <iframe :src="url" :height="window.height - 80" width="100%"></iframe>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";

export default {
  name: "CTTDashboard",
  components: {
    SideBar,
  },
  data() {
    return {
      datacollection: null,
      user: this.$user,
      url: "",
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  async beforeCreate() {
    const { VUE_APP_CMS_USER, VUE_APP_CMS_PASSWORD } = process.env;

    await fetch("https://dashboard-changfamily.tspace.tech/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        // user: "cms-tt",
        // email: "",
        // password: "tspace@2022",
        user: VUE_APP_CMS_USER,
        email: "",
        password: VUE_APP_CMS_PASSWORD,
      }),
    })
      .then(function (r) {
        return r.json();
      })
      .catch(function (e) {
        console.error("Err:", e);
      });
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    const { VUE_APP_CMS_USER, VUE_APP_CMS_PASSWORD, VUE_APP_DASHBOARD_CAMPAIGN_TT_URL } = process.env;
    this.fillData();

    fetch("https://dashboard-changfamily.tspace.tech/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        // user: "cms-tt",
        // email: "",
        // password: "tspace@2022",
        user: VUE_APP_CMS_USER,
        email: "",
        password: VUE_APP_CMS_PASSWORD,
      }),
    })
      .then((r) => {
        setTimeout(() => {
          this.url = VUE_APP_DASHBOARD_CAMPAIGN_TT_URL;
          // "https://dashboard-changfamily.tspace.tech/d/4OeXs3H4z/activities-campaign-dashboard-dev-in-progress?orgId=1";
        }, 1000);
        return r.json();
      })
      .catch((e) => {
        console.error("Err:", e);
      });
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
          {
            label: "Data Two",
            backgroundColor: "#f87966",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
