import { parseJSON } from './common'

export const getToken = () => {
  return localStorage.getItem("token")
}

export const getUser = (token) => {
  if(!token) {
    token = getToken()
  }
  return parseJWT(token)
}

export const getTokenAndUser = () => {
  const token = localStorage.getItem("token")
  return {
    token,
    user: parseJWT(token)
  }
}

export const parseJWT = (token = "") => {
  if(!token) return {}

  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const data = parseJSON(jsonPayload);

  switch (data.u && data.u.ral) {
    case 'super-admin':
      data.u.role = 'ci'
      break;

    case 'region-admin':
      data.u.role = 'admin'
      break;
  
    default:
      data.u.role = 'normal'
      break;
  }

  return {
    ...data.u,
    a: data.a
  }
}