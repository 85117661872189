/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Region extends Model {
  static fields () {
    return {
      id: this.number(),
      label: this.string('')
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: `v1/regions`,
      ...options
    }).then((res) => res.response.data)
  }

  static getsAll (options = {}) {
    return [
      { id: 'All', label: 'All' },
      ...Region.all()
    ]
  }
}

Region.entity = 'region'

export default Region