<template>
  <div>
    <!-- <div>------------------------------------- Edit reward items Dialog -----------------------------------------------------</div> -->

    <v-dialog
      v-model="dialog_edit"
      scrollable
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit reward items
        </v-card-title>

        <v-card-text v-if="reward.id">
          <v-form ref="formEdit">
            <div style="padding: 20px">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    label="Title"
                    required
                    v-model="reward.title"
                    outlined
                    counter
                    maxlength="60"
                    dense
                  />
                </v-col>
                <v-col v-if="numberOption === 0">
                  <v-text-field
                    label="Code"
                    required
                    v-model="reward.code"
                    outlined
                    counter
                    maxlength="60"
                    dense
                  />
                </v-col>
              </v-row>

              <v-row v-if="numberOption === 0">
                <v-col>
                  <v-text-field
                    label="Point"
                    :rules="[rules.int]"
                    required
                    v-model="reward.point"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Limit Stock"
                    :rules="[
                      rules.int,
                      (v) => rules.limitStockValidate(v, reward.redeem),
                    ]"
                    required
                    v-model="reward.limitStock"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Redeem"
                    v-model="reward.redeem"
                    outlined
                    type="number"
                    dense
                    disabled
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Remain Stock"
                    v-model="reward.remain"
                    outlined
                    type="number"
                    dense
                    disabled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div style="width: 100px">Start Date</div>
                  <VueCtkDateTimePicker
                    v-model="reward.startDate"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    noLabel
                    noClearButton
                    color="#4caf50"
                    button-color="#4caf50"
                  />
                </v-col>
                <v-col>
                  <div style="width: 100px">End Date</div>
                  <VueCtkDateTimePicker
                    v-model="reward.endDate"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    noLabel
                    noClearButton
                    color="#4caf50"
                    button-color="#4caf50"
                    :error="
                      !endDateMoreThanStartDate(
                        reward.startDate,
                        reward.endDate
                      )
                    "
                  />
                  <div
                    style="color: #ff4500"
                    v-if="
                      !endDateMoreThanStartDate(
                        reward.startDate,
                        reward.endDate
                      )
                    "
                  >
                    End date should more than start date
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-select
                    outlined
                    :items="catagoryList"
                    label="Catagory"
                    item-value="id"
                    item-text="title"
                    v-model="reward.reward_catalog_id"
                    dense
                  />
                </v-col>
                <v-col cols="2">
                  <v-switch v-model="reward.is_public" label="Active" />
                </v-col>
              </v-row>

              <v-textarea
                outlined
                label="Description"
                v-model="reward.description"
                hide-details
                dense
              />

              <v-row>
                <v-col cols="2">
                  <v-switch v-model="reward.requiredPhone" label="Phone" />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <v-select
                    outlined
                    :items="all_regions"
                    label="ภาคการขาย"
                    item-value="id"
                    item-text="label"
                    v-model="reward.regions"
                    multiple
                    chips
                    @change="
                      (selectedIds) =>
                        $emit('onRegionSelected', { type: 'edit', selectedIds })
                    "
                  />
                </v-col>
              </v-row>

              <MultipleGalleryUploadFile
                label="Image"
                v-model="reward.rewardImages"
                :max="10"
                :option="upOption"
                :editable="false"
                :removeable="true"
                accept="image/*"
                @remove="(e) => $emit('onRemoveImage', e)"
              />

              <v-row>
                <v-col md="6">
                  <v-select
                    outlined
                    :items="numberOptionList"
                    label="Option"
                    :value="numberOption"
                    dense
                    @change="(e) => $emit('clearList', e)"
                    item-value="value"
                    item-text="name"
                    disabled
                  />
                </v-col>
              </v-row>

              <div v-if="reward.options.length >= 1">
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      label="Option 1"
                      required
                      v-model="reward.options[0].title"
                      outlined
                      dense
                      disabled
                    />
                  </v-col>
                </v-row>
                <v-combobox
                  v-model="reward.options[0].options"
                  hide-selected
                  hint="Maximum of 10 choices"
                  label="Add choice"
                  multiple
                  small-chips
                  outlined
                  persistent-hint
                  :disabled="reward.options[0].title === ''"
                  @change="$emit('generateTable')"
                  @remove="$emit('preventRemoveOptions')"
                >
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :color="`${item.title} lighten-3`"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item }}
                      </span>
                      <v-icon
                        v-if="$emit('isNewValue', { item })"
                        small
                        @click="parent.selectItem(item)"
                      >
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
                <div v-if="reward.options.length == 2">
                  <v-row>
                    <v-col md="6">
                      <v-text-field
                        label="Option 2"
                        required
                        v-model="reward.options[1].title"
                        outlined
                        dense
                        disabled
                      />
                    </v-col>
                  </v-row>
                  <v-combobox
                    v-model="reward.options[1].options"
                    hide-selected
                    hint="Maximum of 10 choices"
                    label="Add choice"
                    multiple
                    small-chips
                    outlined
                    persistent-hint
                    :disabled="reward.options[1].title === ''"
                    @change="$emit('generateTable')"
                    @remove="$emit('preventRemoveOptions')"
                  >
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                      >
                        <span class="pr-2">
                          {{ item }}
                        </span>
                        <!-- <v-icon small @click="parent.selectItem(item)"> -->
                        <v-icon
                          v-if="$emit('isNewValue', { item })"
                          small
                          @click="parent.selectItem(item)"
                        >
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>

                <!--------------------- options --------------------->
                <div
                  class="generate-table-container mt-5"
                  v-if="
                    reward.options &&
                    reward.options.length > 0 &&
                    reward.options[0].options.length > 0
                  "
                >
                  <v-row>
                    <v-col class="text-center"> Code </v-col>
                    <v-col class="text-center">
                      {{ reward.options[0].title }}
                    </v-col>
                    <v-col
                      class="text-center"
                      v-if="reward.options.length == 2"
                    >
                      {{ reward.options[1].title }}
                    </v-col>
                    <v-col class="text-center"> Point Redeem </v-col>
                    <v-col class="text-center"> Limit Stock </v-col>
                    <v-col class="text-center"> Stock </v-col>
                    <v-col class="text-center"> Action </v-col>
                  </v-row>
                  <v-row v-for="(item, i) in tableList" :key="`arr1-${i}`">
                    <v-col
                      :class="`text-center align-self-center${
                        item.id === undefined ? '' : ' py-5'
                      }`"
                      v-if="numberOption == 1"
                    >
                      <div v-if="item.id === undefined">
                        <v-text-field
                          required
                          v-model="item.code"
                          outlined
                          counter
                          maxlength="60"
                          dense
                        />
                      </div>
                      <div v-else>
                        {{ item.code }}
                      </div>
                    </v-col>
                    <v-col
                      class="text-center align-self-center"
                      v-if="numberOption == 2"
                    >
                      <div
                        :class="`text-center align-self-center${
                          item1.id === undefined ? '' : ''
                        }`"
                        v-for="(item1, j) in item.subOption"
                        :key="`arr2-${j}`"
                      >
                        <div class="border-top" v-if="j > 0" />
                        <div
                          :class="`${
                            item1.id === undefined ? ' code-section' : ' pa-2'
                          }`"
                        >
                          <div v-if="item1.id === undefined">
                            <v-text-field
                              required
                              v-model="item1.code"
                              outlined
                              counter
                              maxlength="60"
                              dense
                            />
                          </div>
                          <div v-else>
                            {{ item1.code }}
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col class="text-center align-self-center">
                      {{ item.mainName }}
                    </v-col>
                    <v-col
                      :class="`text-center align-self-center${
                        item.id === undefined ? ' blank-space' : ' py-5'
                      }`"
                      v-if="numberOption == 1"
                    >
                      {{ item.point }}
                    </v-col>
                    <v-col
                      :class="`text-center align-self-center${
                        item.id === undefined ? ' blank-space' : ' py-5'
                      }`"
                      v-if="numberOption == 1"
                    >
                      {{ item.limitStock }}
                    </v-col>
                    <v-col
                      :class="`text-center align-self-center${
                        item.id === undefined ? ' blank-space' : ' py-5'
                      }`"
                      v-if="numberOption == 1"
                    >
                      {{ item.remain }}
                    </v-col>
                    <v-col
                      :class="`text-center align-self-center${
                        item.id === undefined
                          ? ' no-code-button'
                          : ' code-button'
                      }`"
                      v-if="numberOption == 1"
                    >
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          () =>
                            $emit('editStock', {
                              item,
                              index: numberOption === 1 ? i : j,
                            })
                        "
                      >
                        แก้ไข
                      </v-btn>
                    </v-col>
                    <v-col
                      class="text-center align-self-center"
                      v-if="numberOption == 2"
                    >
                      <div
                        v-for="(item1, j) in item.subOption"
                        :key="`arr2-${j}`"
                      >
                        <div class="border-top" v-if="j > 0" />
                        <div
                          :class="`${
                            item1.id === undefined ? ' pa-9' : ' pa-2'
                          }`"
                        >
                          {{ item1.subName }}
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="text-center align-self-center"
                      v-if="numberOption == 2"
                    >
                      <div
                        v-for="(item1, j) in item.subOption"
                        :key="`subOption-${j}`"
                      >
                        <div class="border-top" v-if="j > 0" />
                        <div
                          :class="`${
                            item1.id === undefined ? ' pa-9' : ' pa-2'
                          }`"
                        >
                          {{ item1.point }}
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="text-center align-self-center"
                      v-if="numberOption == 2"
                    >
                      <div
                        v-for="(item1, j) in item.subOption"
                        :key="`subOption-${j}-${item1.limitStock}`"
                      >
                        <div class="border-top" v-if="j > 0" />
                        <div
                          :class="`${
                            item1.id === undefined ? ' pa-9' : ' pa-2'
                          }`"
                        >
                          {{ item1.limitStock }}
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="text-center align-self-center"
                      v-if="numberOption == 2"
                    >
                      <div
                        v-for="(item1, j) in item.subOption"
                        :key="`subOption-${j}`"
                      >
                        <div class="border-top" v-if="j > 0" />
                        <div
                          :class="`${
                            item1.id === undefined ? ' pa-9' : ' pa-2'
                          }`"
                        >
                          {{ item1.remain }}
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="text-center align-self-center"
                      v-if="numberOption == 2"
                    >
                      <div
                        v-for="(item1, j) in item.subOption"
                        :key="`subOption-${j}`"
                      >
                        <div class="border-top" v-if="j > 0" />
                        <div
                          :class="`${
                            item1.id === undefined
                              ? ' no-code-action-container'
                              : ' action-container'
                          }`"
                        >
                          <v-btn
                            color="green darken-1"
                            text
                            @click="
                              () =>
                                $emit('editStock', {
                                  item,
                                  index: numberOption === 1 ? i : j,
                                })
                            "
                          >
                            แก้ไข
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="$emit('handleDeleteDialog', { open: true })"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('closeEditDialog')"
            >Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="$emit('editRewardItem')"
            :disabled="
              dialog_loading ||
              validateEdit ||
              flagImage ||
              !endDateMoreThanStartDate(reward.startDate, reward.endDate) ||
              disabledSaveEditReward
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div-------------------------------------- Edit stock Dialog ---------------------------------------------------------------</div> -->

    <v-dialog v-model="dialog_edit_stock" max-width="500px">
      <v-form ref="formEditStock">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Update products to stock
          </v-card-title>
          <v-card-text>
            <div style="padding: 20px">
              <h1>
                {{ editStockInfo.mainLabel }} : {{ editStockInfo.mainName }}
              </h1>
              <h1 class="mt-3" v-if="editStockInfo.subLabel">
                {{ editStockInfo.subLabel }} : {{ editStockInfo.subName }}
              </h1>
              <h1 class="mt-3">Code : {{ editStockInfo.code }}</h1>
              <div class="mt-5">
                <h3>Limit Stock : {{ editStockInfo.oldStock }}</h3>
                <h3>
                  Stock :
                  {{ editStockInfo.remain }}
                </h3>
                <h3>
                  Redeem :
                  {{ editStockInfo.redeem }}
                </h3>
              </div>
            </div>
            <div class="mt-3">
              <v-row>
                <v-col offset-md="4" class="align-self-center">
                  Point Redeem
                </v-col>
                <v-col>
                  <v-text-field
                    :rules="[rules.int]"
                    v-model.number="editStockInfo.point"
                    outlined
                    dense
                    type="number"
                  />
                </v-col>
                <v-col cols="2" class="align-self-center"> Point </v-col>
              </v-row>
              <v-row>
                <v-col offset-md="4" class="align-self-center">
                  Limit Stock
                </v-col>
                <v-col>
                  <v-text-field
                    :rules="[
                      rules.int,
                      (v) => rules.limitStockValidate(v, editStockInfo.redeem),
                    ]"
                    v-model="editStockInfo.limitStock"
                    outlined
                    dense
                    type="number"
                  />
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error darken-1" @click="$emit('closeStock')">
              Cancel
            </v-btn>
            <v-btn
              color="primary darken-1"
              @click="$emit('updateStock')"
              :disabled="disabledSaveEditStock"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- <div>------------------------------------- Add new reward items Dialog -----------------------------------------------------</div> -->

    <v-dialog
      v-model="dialog_add_new"
      scrollable
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add new reward item
        </v-card-title>

        <v-card-text>
          <v-form ref="formAdd">
            <div style="padding: 20px">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    label="Title"
                    required
                    v-model="add.title"
                    outlined
                    counter
                    maxlength="60"
                    dense
                  />
                </v-col>
                <v-col v-if="numberOption === 0">
                  <v-text-field
                    label="Code"
                    required
                    v-model="add.code"
                    outlined
                    counter
                    maxlength="60"
                    dense
                  />
                </v-col>
              </v-row>
              <v-row v-if="numberOption === 0">
                <v-col>
                  <v-text-field
                    label="Point"
                    :rules="[rules.int]"
                    required
                    v-model="add.point"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    label="Limit Stock"
                    :rules="[rules.int]"
                    required
                    v-model="add.limit_stock"
                    outlined
                    type="number"
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div style="width: 100px">Start Date</div>
                  <VueCtkDateTimePicker
                    v-model="add.startTime"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    noLabel
                    noClearButton
                    color="#4caf50"
                    button-color="#4caf50"
                  />
                </v-col>
                <v-col>
                  <div style="width: 100px">End Date</div>
                  <VueCtkDateTimePicker
                    v-model="add.endTime"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    noLabel
                    noClearButton
                    color="#4caf50"
                    button-color="#4caf50"
                    :error="
                      add.endTime
                        ? !endDateMoreThanStartDate(add.startTime, add.endTime)
                        : false
                    "
                  />
                  <div
                    style="color: #ff4500"
                    v-if="
                      add.endTime
                        ? !endDateMoreThanStartDate(add.startTime, add.endTime)
                        : false
                    "
                  >
                    End date should more than start date
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-select
                    outlined
                    :items="catagoryList"
                    label="Catagory"
                    item-value="id"
                    item-text="title"
                    v-model="add.category"
                    dense
                  />
                  <!-- <v-select
                    outlined
                    :items="catagoryList"
                    label="Catagory"
                    item-value="id"
                    item-text="title"
                    v-model="add.category"
                    dense
                    @change="$emit('handleChange')"
                  /> -->
                </v-col>
                <v-col cols="2">
                  <v-switch v-model="add.post" label="Active" />
                </v-col>
              </v-row>

              <v-textarea
                outlined
                label="Description"
                v-model="add.desc"
                hide-details
                dense
              />

              <v-row>
                <v-col cols="2">
                  <v-switch v-model="add.phone" label="Phone" />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <v-select
                    outlined
                    :items="all_regions"
                    label="ภาคการขาย"
                    item-value="id"
                    item-text="label"
                    v-model="add.region"
                    multiple
                    chips
                    @change="
                      (selectedIds) =>
                        $emit('onRegionSelected', { type: 'add', selectedIds })
                    "
                  />
                </v-col>
              </v-row>

              <MultipleGalleryUploadFile
                label="Image"
                v-model="reward.rewardImages"
                :max="10"
                :option="upOption"
                :editable="false"
                :removeable="true"
                accept="image/*"
                @remove="$emit('onRemoveImage')"
              />

              <v-row>
                <v-col md="6">
                  <v-select
                    outlined
                    :items="numberOptionList"
                    label="Option"
                    :value="numberOption"
                    dense
                    @change="(e) => $emit('clearList', e)"
                    item-value="value"
                    item-text="name"
                  />
                </v-col>
              </v-row>

              <div v-if="reward.options.length >= 1">
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      label="Option 1"
                      required
                      v-model="reward.options[0].title"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
                <v-combobox
                  v-model="reward.options[0].options"
                  hide-selected
                  hint="Maximum of 10 choices"
                  label="Add choice"
                  multiple
                  small-chips
                  outlined
                  persistent-hint
                  :disabled="reward.options[0].title === ''"
                  @change="$emit('generateTable')"
                >
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>

              <div v-if="reward.options.length == 2">
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      label="Option 2"
                      required
                      v-model="reward.options[1].title"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>

                <v-combobox
                  hide-selected
                  hint="Maximum of 10 choices"
                  label="Add choice"
                  multiple
                  small-chips
                  outlined
                  persistent-hint
                  v-model="reward.options[1].options"
                  :disabled="reward.options[1].title === ''"
                  @change="$emit('generateTable')"
                >
                  <!-- :error="isChoices" -->
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </div>

              <!--------------------- options --------------------->
              <div
                class="generate-table-container mt-5"
                v-if="
                  reward.options &&
                  reward.options.length > 0 &&
                  reward.options[0].options.length > 0
                "
              >
                <v-row>
                  <v-col class="text-center"> Code </v-col>
                  <v-col class="text-center">
                    {{ reward.options[0].title }}
                  </v-col>
                  <v-col class="text-center" v-if="numberOption == 2">
                    {{ reward.options[1].title }}
                  </v-col>
                  <v-col class="text-center"> Point Redeem </v-col>
                  <v-col class="text-center"> Limit Stock </v-col>
                </v-row>
                <v-row v-for="(item, i) in tableList" :key="`arr1-${i}`">
                  <v-col v-if="numberOption == 1">
                    <v-text-field
                      required
                      v-model="item.code"
                      outlined
                      counter
                      maxlength="60"
                      dense
                    />
                  </v-col>
                  <v-col v-if="numberOption == 2">
                    <div
                      :class="`${
                        item.subOption.length > 1 && j !== 0 ? 'mt-2' : ''
                      }`"
                      v-for="(item1, j) in item.subOption"
                      :key="`subOption-${j}`"
                    >
                      <v-text-field
                        required
                        v-model="item1.code"
                        outlined
                        counter
                        maxlength="60"
                        dense
                      />
                    </div>
                  </v-col>
                  <v-col class="text-center align-self-center">
                    {{ item.mainName }}
                  </v-col>
                  <v-col v-if="numberOption == 1">
                    <v-text-field
                      :rules="[rules.int]"
                      outlined
                      dense
                      v-model.lazy="item.point"
                      type="number"
                    />
                  </v-col>
                  <v-col v-if="numberOption == 1">
                    <v-text-field
                      :rules="[rules.int]"
                      outlined
                      dense
                      v-model="item.limitStock"
                      type="number"
                    />
                  </v-col>
                  <v-col
                    :class="`text-center align-self-center${
                      reward.options[1].options.length === 0 ? ' py-6' : ''
                    }`"
                    v-if="numberOption == 2"
                  >
                    <div
                      v-for="(item1, j) in item.subOption"
                      :key="`arr2-${j}`"
                    >
                      <div
                        :class="`border-top${
                          item.subOption.length > 4 ? ' pb-3' : ' pb-4'
                        }`"
                        v-if="j > 0"
                      />
                      <div
                        :class="item.subOption.length === 1 ? 'pa-8' : 'pa-5'"
                      >
                        {{ item1.subName }}
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="numberOption == 2">
                    <div
                      :class="`${
                        item.subOption.length > 1 && j !== 0 ? 'mt-2' : ''
                      }`"
                      v-for="(item1, j) in item.subOption"
                      :key="`subOption-${j}`"
                    >
                      <v-text-field
                        :rules="[rules.int]"
                        outlined
                        dense
                        v-model="item1.point"
                        type="number"
                      />
                    </div>
                  </v-col>
                  <v-col v-if="numberOption == 2">
                    <div
                      :class="`${
                        item.subOption.length > 1 && j !== 0 ? 'mt-2' : ''
                      }`"
                      v-for="(item1, j) in item.subOption"
                      :key="`subOption-${j}`"
                    >
                      <v-text-field
                        :rules="[rules.int]"
                        outlined
                        dense
                        v-model="item1.limitStock"
                        type="number"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('closeAddNewDialog')"
            >Cancel</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="$emit('addNewRewardItem')"
            :disabled="
              dialog_loading ||
              validateAdd ||
              flagImage ||
              !endDateMoreThanStartDate(add.startTime, add.endTime) ||
              disabledSaveAddReward ||
              disabledTable
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Delete reward items Dialog -----------------------------------------</div> -->

    <v-dialog v-model="dialog_delete" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Confirm delete?</v-card-title>
        <v-card-text
          >Are you sure that you want to delete this reward item. This reward
          item will be gone permanently.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            @click="$emit('handleDeleteDialog', { open: false })"
            text
            >Cancel</v-btn
          >
          <v-btn color="red" text @click="$emit('deleteRewardItem')"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

import MultipleGalleryUploadFile from '@/components/MultipleGalleryUploadFile'

export default {
  name: 'ProductModal',
  components: {
    MultipleGalleryUploadFile,
    VueCtkDateTimePicker,
  },
  computed: {
    validateAdd() {
      const { add } = this
      const check =
        !add.title ||
        !add.desc ||
        !add.category ||
        !add.startTime ||
        !add.endTime ||
        !add.region
      return check
    },
    validateEdit() {
      const { reward } = this
      const check =
        !reward.reward_catalog_id ||
        !reward.title ||
        !reward.description ||
        !reward.startDate ||
        !reward.endDate ||
        !reward.regions
      return check
    },
  },
  watch: {
    reward: {
      deep: true,
      async handler() {
        const { rewardImages } = this.reward
        const flag = rewardImages.length === 0 ? true : false
        this.flagImage = flag
      },
    },
  },
  data() {
    return {
      flagImage: true,
      numberOptionList: [
        { value: 0, name: 'No option' },
        { value: 1, name: '1 option' },
        { value: 2, name: '2 options' },
      ],
      upOption: {
        maxWidth: 1000,
      },
    }
  },
  props: [
    'add',
    'reward',
    'dialog_edit',
    'dialog_edit_stock',
    'dialog_add_new',
    'dialog_delete',
    'disabledSaveEditStock',
    'disabledSaveEditReward',
    'disabledSaveAddReward',
    'dialog_loading',
    'editStockInfo',
    'numberOption',
    'rules',
    'all_regions',
    'tableList',
    'catagoryList',
    'disabledTable',
  ],
  methods: {
    endDateMoreThanStartDate(startDate, endDate) {
      return new Date(endDate) > new Date(startDate)
    },
  },
}
</script>

<style lang="scss" scoped>
.generate-table-container {
  .border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0 -12px;
  }
  .row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .col {
    &:nth-child(n + 3) {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
    &:nth-child(1) {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
    .no-code-action-container {
      padding: 29px 0;
    }
    .action-container {
      padding: 1px 0;
    }
  }
  .blank-space {
    padding-top: 34px;
    padding-bottom: 34px;
  }
  .code-section {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .code-button {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .no-code-button {
    padding-top: 27px;
    padding-bottom: 27px;
  }
}
</style>
