<template>
  <v-dialog
    v-model="value"
    @click:outside="_close"
    max-width="70%"
    scrollable
    persistent
  >
    <v-card :outlined="true">
      <v-card-title class="variable-title">
        เลือกผู้ใช้งาน
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          style="margin-right: 2%"
          placeholder=" "
          :hide-details="true"
          @keydown.enter="_getByQuery"
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <v-data-table
          class="clean-tb"
          item-key="index"
          :search="search"
          :headers="headers"
          :items="items"
          :footer-props="{ 'items-per-page-options': $itemsPerPage }"
          :items-per-page="perPage"
          :loading="loading"
          :options.sync="options"
          :server-items-length="countItems"
          no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
          no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
        >
          <template v-slot:item.type="{ item }">
            {{ _shopRelation(item.type) }}
          </template>
          <template v-slot:item.select="{ item }">
            <v-btn
              class="float-right select-btn"
              :loading="loading"
              :disabled="loading"
              color="#D9EAD3"
              @click="_select(item)"
            >
              <v-icon dark left>mdi-plus-circle</v-icon>
              เลือก
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" color="error darken-1" @click="_close" text
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShopUser from "@/store/models/shopuser";

export default {
  name: "SelectShopUser",
  components: {},
  props: ["value", "data"],
  data() {
    return {
      search: "",
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      perPage: 10,
      countItems: 0,
      headers: [
        {
          text: "รหัสเอเยนต์",
          value: "shop.code",
          width: "10%",
          sortable: false,
        },
        {
          text: "ชื่อเอเยนต์",
          value: "shop.name",
          width: "30%",
          sortable: false,
        },
        { text: "ชื่อ-นามสกุล", value: "name", width: "20%", sortable: false },
        { text: "ความสัมพันธ์", value: "type", width: "10%", sortable: false },
        { text: "ดำเนินการ", value: "select", width: "5%", align: "center", sortable: false },
      ],
      items: [],
    };
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.fetchData();
        }
      },
      deep: true,
    },
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let options = {
        params: {
          query: this.search || "",
          shopTypeId: 1,
          hasShopUserLink: 0,
          page: this.options.page || 1,
          perpage: this.options.itemsPerPage || 10,
          excShopId: this.data.shopId || undefined,
        },
      };

      const res = await ShopUser.list(options);

      this.items = (res && res.data) || [];
      this.countItems = res && res.total;
      this.loading = false;
    },
    _getByQuery() {
      this.options.page = 1;
      this.fetchData();
    },
    _shopRelation(rel = "master") {
      return this.$shopRelation[rel] || "-";
    },
    _close() {
      this.$emit("input", false);
    },
    _select(item) {
      this.$emit("select", {
        parent: this.data,
        child: item,
      });
    },
  },
};
</script>

<style scoped>
.select-btn {
  padding: 0 10px !important;
  margin: 5px 0 !important;
  height: 32px !important;
  width: 100% !important;
}

.select-btn {
  color: #008000;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}

.col {
  padding: 5px 10px !important;
}

.variable-title {
  justify-content: center !important;
}
</style>
