<template>
  <v-tabs background-color="white" color="green darken-3" right>
    <v-tab v-for="(item, key) in list" :key="key">{{ item }}</v-tab>

    <v-tab-item v-for="(item, key) in list" :key="item">
      <slot :user="$user" :tabId="key" :tabName="item"></slot>
    </v-tab-item>
  </v-tabs>
</template>

<script>
export default {
  name: "SideBar",
  props: ["tabs"],
  data() {
    return {
      user: this.$user,
      list: this.tabs
    };
  },
  created() {
    // this.genTabs()
  },
  methods: {
    genTabs() {
      let shopType
      if(this.user && this.user.a && this.user.a.shopType) {
        shopType = this.user.a.shopType
      }

      this.list = Object.keys(this.tabs).reduce((o, e) => {
        const find = shopType.find((ee) => ee == e)
        if(find) {
          o[+e] = this.tabs[e]
        }

        return o
      }, {})
    },
    navigate(page) {
      this.$router.push({ path: page });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
