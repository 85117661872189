<template>
  <v-dialog v-bind="$attrs" v-model="modal" persistent :max-width="width">
    <v-card class="pa-2">
      <v-toolbar v-if="disableBottomAction" dark color="white">
        <slot name="actionHeader"></slot>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!disableCancel"
          color="error"
          text
          @click="submitAction(false)"
        >
          {{ data ? data.cancelText : "ยกเลิก" }}
        </v-btn>
        <v-btn
          v-if="!confirmIcon"
          color="success"
          :disabled="disabled"
          @click="submitAction(true)"
        >
          {{ data ? data.confirmText : "ยืนยัน" }}
        </v-btn>
        <v-btn
          v-if="confirmIcon"
          color="error"
          icon
          @click="submitAction(true)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="headline">
        <slot name="header"></slot>
      </v-card-title>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions v-if="!disableBottomAction">
        <slot name="action">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!disableCancel"
            color="grey"
            text
            @click="submitAction(false)"
          >
            {{ data ? data.cancelText : "ปิดหน้าต่าง" }}
          </v-btn>
          <v-btn
            v-if="!confirmIcon && !disableConfirm"
            color="success"
            :disabled="disabled"
            @click="submitAction(true)"
          >
            {{ data ? data.confirmText : "ยืนยัน" }}
          </v-btn>
          <v-btn
            v-if="confirmIcon && !disableConfirm"
            color="error"
            icon
            @click="submitAction(true)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    submitAction(flag = false) {
      document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      if (flag) this.$emit("confirm", true);
      else this.$emit("confirm", false);
    },
  },
  props: {
    modal: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
    width: {
      type: Number,
      default: 600,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableBottomAction: {
      type: Boolean,
      default: false,
    },
    disableCancel: {
      type: Boolean,
      default: false,
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    confirmIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>