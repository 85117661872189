/* eslint-disable */
import { Model } from '@vuex-orm/core'

class Employee extends Model {
  static fields () {
    return {
      id: this.number(),
      code: this.string(),
      prefix: this.string(),
      firstname: this.string(),
      lastname: this.string(),
      full_name: this.string(),
      phone: this.string(),
      citizen_id: this.string(),
      birthdate: this.string(),
      position: this.string(),
      company: this.string(),
      ral: this.string(),
      status: this.string(),
      regionName: this.string(),
      areaName: this.string(),
    }
  }

  static gets (options = {}) {
    return this.api().request({
      method: 'GET',
      url: '/v1/employees',
      ...options
    }).then((res) => res.response.data)
  }

  static download (options) {
    return this.api().request({
      method: 'GET',
      url: '/v1/employees/download',
      ...options,
      responseType: "blob"
    })
    .then((res) => res.response.data)
  }

  static users (options = {}) {
    return this.api().request({
      method: 'GET',
      url: '/v1/employeeUsers',
      ...options
    }).then((res) => res.response.data)
  }

  static sync (code = "", options = {}) {
    return this.api().request({
      method: 'GET',
      url: `/v1/employeeUsers/${code}/sync`,
      ...options
    }).then((res) => res.response.data)
  }

  static unlink (options = {}) {
    return this.api().request({
      method: 'POST',
      url: `/v1/employeeUsers/unlink`,
      ...options
    }).then((res) => res.response.data)
  }
}

Employee.entity = 'employee'

export default Employee