/* eslint-disable */
import { Model } from '@vuex-orm/core'

class StampMission extends Model {
  static fields() {
    return {
      allJoin: this.number(0),
      approve: this.number(0),
      banner: this.string(''),
      card: this.attr(null),
      condition: this.string(''),
      createdAt: this.string(''),
      endDate: this.string(''),
      id: this.number(0),
      message: this.string(''),
      reject: this.number(0),
      reward: this.attr(null),
    }
  }

  static list(id, options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/stamp-cards/${id}/stamp-missions/summarize`,
        ...options,
      })
      .then((res) => res.response)
  }

  static missionList(options = {}) {
    return this.api()
      .request({
        method: 'GET',
        url: `/chang-tt/stamp-missions/summarize`,
        ...options,
      })
      .then((res) => res.response)
  }

  static create(options = {}) {
    return this.api()
      .request({
        method: 'POST',
        url: `/chang-tt/stamp-missions`,
        ...options,
      })
      .then((res) => res.response)
  }

  static update(id, options = {}) {
    return this.api()
      .request({
        method: 'PUT',
        url: `/chang-tt/stamp-missions/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }

  static delete(id, options = {}) {
    return this.api()
      .request({
        method: 'DELETE',
        url: `/chang-tt/stamp-missions/${id}`,
        ...options,
      })
      .then((res) => res.response)
  }
}

StampMission.entity = 'stampMission'

export default StampMission
