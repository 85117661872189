<template>
  <PDFContainer :src="src" />
</template>

<script>
import PDFContainer from '@/components/PDFContainer';

export default {
  components: {
    PDFContainer
  },
  data: () => ({
    src: ''
  }),
  mounted() {
    this.src = sessionStorage.getItem("pathPdf")
    if(this.src == '') window.close();
  }
}
</script>