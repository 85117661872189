<template>
  <div>
    <SideBar bar_name="Notify" :user="user"></SideBar>

    <v-main>
      <v-container fluid>
        <NotifyComp :user="user" :sales="sales" :roles="roles" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar"
import NotifyComp from "@/components/Notify/Notify"

export default {
  name: "Notify",
  components: {
    SideBar,
    NotifyComp
  },
  data() {
    return {
      roles: Object.values(this.$userRoles),
      sales: Object.values(this.$agentTabs),
      user: this.$user,
    };
  }
};
</script>
