<template>
  <v-layout v-if="value" wrap>
    <v-flex xs12 md12 class="retail-shop-form">
      <v-row>
        <v-col cols="12" offset-1>
          <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
            <v-icon left> mdi-less-than </v-icon>
            ย้อนกลับ ข้อมูลร้านค้าปลีก
          </v-btn>
        </v-col>
      </v-row>
      <h2>รายละเอียดร้านค้าปลีก</h2>
      <!-- Form -->
      <v-form ref="form" v-model="valid" @submit.prevent="_save">
        <v-card class="mt-2 pa-4">
          <v-card-text>
            <h3 class="black--text mb-4">ข้อมูลร้านค้า</h3>
            <div class="image-section mb-4">
              <GalleryUploadFile
                v-model="form.image"
                :max="1"
                :option="upOption"
                :editable="true"
                accept="image/*"
              />
            </div>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="form.name"
                  :rules="rules.required"
                  required
                  outlined
                  dense
                >
                  <template v-slot:label>
                    ชื่อร้าน <span class="red--text">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="form.code"
                  disabled
                  outlined
                  dense
                >
                  <template v-slot:label>
                    รหัสร้าน
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="form.phone"
                  outlined
                  dense
                >
                  <template v-slot:label>
                    เบอร์โทรศัพท์
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="form.type.id"
                  :items="shoptypes"
                  item-text="name"
                  item-value="id"
                  label="ประเภทร้าน"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="form.status"
                  label="ยืนยันตัวตน"
                  background-color="#c7c7cc"
                  disabled
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="form.lineStatus"
                  label="สถานะไลน์"
                  background-color="#c7c7cc"
                  disabled
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-btn
                  v-if="this.user.registered"
                  class="unlink-button"
                  rounded
                  color="#ffdbdb"
                  elevation="0"
                  @click="_unlink"
                >
                  Unlink
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="form.address"
                  label="ที่อยู่"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="form.province.id"
                  :items="provinces"
                  item-text="name"
                  item-value="id"
                  label="จังหวัด"
                  :rules="rules.required"
                  required
                  outlined
                  dense
                >
                  <template v-slot:label>
                    จังหวัด <span class="red--text">*</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="form.district.id"
                  :items="districts"
                  item-text="name"
                  item-value="id"
                  label="อำเภอ"
                  :rules="rules.required"
                  required
                  outlined
                  dense
                >
                  <template v-slot:label>
                    อำเภอ <span class="red--text">*</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="form.subDistrict.id"
                  :items="subDistricts"
                  item-text="name"
                  item-value="id"
                  label="ตำบล"
                  :rules="rules.required"
                  required
                  outlined
                  dense
                >
                  <template v-slot:label>
                    ตำบล <span class="red--text">*</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="form.postcode"
                  :items="postcodes"
                  item-text="postcode"
                  item-value="postcode"
                  label="รหัสไปรษณีย์"
                  :rules="rules.required"
                  required
                  outlined
                  dense
                >
                  <template v-slot:label>
                    รหัสไปรษณีย์ <span class="red--text">*</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Map Section -->
        <v-card class="mt-4 pa-4">
          <v-card-text>
            <h3 class="black--text mb-4">แผนที่ร้าน</h3>
            <div v-if="coordinates && coordinates.length === 2">
              <MapSet
                key="thumbnail-1"
                :mode="'thumbnail'"
                :latlng="coordinates"
              />
              <div class="edit-overlay" @click="_mapEdit">แก้ไขแผนที่</div>
            </div>
            <div v-else class="map-empty cursor-pointer" @click="_mapEdit">
              ยังไม่มีข้อมูล
            </div>
          </v-card-text>
        </v-card>

        <!-- Map Modal -->
        <MapSet
          key="modal-1"
          :mode="'modal'"
          :gps="true"
          :open="mapModal"
          :latlng="coordinates"
          @confirm="_setLocation"
          @close="_closeLocation"
        />

        <!-- Shop Information Section -->
        <v-card class="mt-4 pa-4">
          <v-card-text>
            <h3 class="black--text mb-4">ข้อมูลเจ้าของ</h3>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="user.firstName"
                  label="ชื่อ"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="user.lastName"
                  label="นามสกุล"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="user.gender"
                  :items="genderItems"
                  item-text="text"
                  item-value="value"
                  label="เพศ"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="user.citizenId"
                  label="เลขบัตรประชาชน"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="bdate"
                  v-model="vdate.birthDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthDateFormatted"
                      label="วันเดือนปีเกิด"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                      dense
                    />
                  </template>
                  <v-date-picker
                    locale="th"
                    v-model="user.birthDate"
                    :active-picker.sync="activePicker"
                    @change="_setBirthDate"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Shop Owner Section -->
        <v-card class="mt-4 pa-4">
          <v-card-text>
            <div>
              <h3 class="black--text mb-4">ข้อมูลผู้ดูแล</h3>
              <v-row class="align-center">
                <v-col class="success--text">
                  <h3>เอเย่นต์</h3>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="success" :loading="isLoading" @click="_addSelect('agent')">
                    <v-icon>mdi-plus</v-icon>
                    <span>เพิ่ม</span>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="mb-4">
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="agentHeader"
                  :items="agentList"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item, index }">
                    <v-btn @click="_delete('agent', agentList, item, index)" depressed block
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
            <hr />
            <div class="mt-4">
              <v-row class="align-center">
                <v-col class="success--text">
                  <h3>รถเร่เอเย่นต์</h3>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="success" :loading="isLoading" @click="_addSelect('vansales')">
                    <v-icon>mdi-plus</v-icon>
                    <span>เพิ่ม</span>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="mb-4">
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="vansalesHeader"
                  :items="vansalesList"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    {{`${item.firstName}${(item.lastName)? " "+item.lastName: ""}`}}
                  </template>
                  <template v-slot:item.agentCode="{ item }">
                    {{_agentCode(item.shops)}}
                  </template>
                  <template v-slot:item.agentName="{ item }">
                    {{_agentName(item.shops)}}
                  </template>
                  <template v-slot:item.actions="{ item, index }">
                    <v-btn @click="_delete('vansales', vansalesList, item, index)" depressed block
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
            <hr />
            <div class="mt-4">
              <v-row class="align-center">
                <v-col class="success--text">
                  <h3>ซับเอเย่นต์/ซับซื้อตรง</h3>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="success" :loading="isLoading" @click="_addSelect('sub-agent')">
                    <v-icon>mdi-plus</v-icon>
                    <span>เพิ่ม</span>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="mb-4">
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="agentHeader"
                  :items="subAgentList"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  hide-default-footer
                >
                  <template v-slot:item.actions="{ item, index }">
                    <v-btn @click="_delete('sub-agent', subAgentList, item, index)" depressed block
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
            <hr />
            <div class="mt-4">
              <v-row class="align-center">
                <v-col class="success--text">
                  <h3>รถเร่ซับเอเย่นต์/รถเร่ซับซื้อตรง</h3>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="success" :loading="isLoading" @click="_addSelect('sub-vansales')">
                    <v-icon>mdi-plus</v-icon>
                    <span>เพิ่ม</span>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="mb-4">
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="vansalesHeader"
                  :items="subVansalesList"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    {{`${item.firstName}${(item.lastName)? " "+item.lastName: ""}`}}
                  </template>
                  <template v-slot:item.agentCode="{ item }">
                    {{_agentCode(item.shops)}}
                  </template>
                  <template v-slot:item.agentName="{ item }">
                    {{_agentName(item.shops)}}
                  </template>
                  <template v-slot:item.actions="{ item, index }">
                    <v-btn @click="_delete('sub-vansales', subVansalesList, item, index)" depressed block
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
            <hr />
            <div class="mt-4">
              <v-row class="align-center">
                <v-col class="success--text">
                  <h3>Area Promoter</h3>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="success" :loading="isLoading" @click="_addSelect('areapromoter')">
                    <v-icon>mdi-plus</v-icon>
                    <span>เพิ่ม</span>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="mb-4">
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="areaPromoterHeader"
                  :items="areaPromoterList"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    {{`${item.firstName}${(item.lastName)? " "+item.lastName: ""}`}}
                  </template>
                  <template v-slot:item.actions="{ item, index }">
                    <v-btn @click="_delete('areapromoter', areaPromoterList, item, index)" depressed block
                      ><v-icon small>mdi-trash-can-outline</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <!-- Action Section -->
        <v-row class="mt-4 mx-1">
          <v-btn
            class="mr-4"
            color="success"
            :loading="isLoading"
            :disabled="!valid"
            type="submit"
          >
            บันทึก
          </v-btn>
          <v-btn outlined :loading="isLoading" @click="_back"> ยกเลิก </v-btn>
        </v-row>
      </v-form>
    </v-flex>

    <!-- <div>------------------------------------- Select Dialog -----------------------------------------------------</div> -->
    <v-dialog v-model="select.status" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">เลืือก {{ select.text }}?</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="select.data"
            :items="selectItems"
            item-text="text"
            item-value="id"
            :search-input.sync="searchselect"
            :error="false"
            :hide-details="true"
            :label="select.text"
            return-object
            outlined
            dense
            clearable
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="isLoading"
            @click="select.status = false"
            text
            >ยกเลิก</v-btn
          >
          <v-btn
            color="primary"
            :loading="isLoading"
            :disabled="validateSelect"
            @click="_select"
            text
            >บันทึก</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div>------------------------------------- Confirm Dialog -----------------------------------------------------</div> -->
    <v-dialog v-model="confirm.status" persistent max-width="600">
      <v-card>
        <v-card-title class="headline"
          >Confirm {{ confirm.type }}?</v-card-title
        >
        <v-card-text
          >Are you sure you want to {{ confirm.type }}
          {{ (confirm.data && confirm.data.code) || "this" }}.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            :loading="isLoading"
            @click="confirm.status = false"
            text
            >Cancel</v-btn
          >
          <v-btn
            color="error"
            :loading="isLoading"
            @click="_confirm"
            text
            >{{ confirm.type }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import _ from "lodash";

import MapSet from "@/components/MapSet";

import GalleryUploadFile from "@/components/GalleryUploadFile";

import ShopTypeModel from "@/store/models/changtt/shopType";
import ProvinceModel from "@/store/models/changtt/province";
import DistrictModel from "@/store/models/changtt/district";
import SubDistrictModel from "@/store/models/changtt/subDistrict";
import PostcodeModel from "@/store/models/changtt/postcode";
import ShopModel from "@/store/models/changtt/shop";
import UserModel from "@/store/models/changtt/user";
import AgentModel from "@/store/models/agent";
import AreaPromoterModel from "@/store/models/changtt/areaPromoter";
import VansalesModel from "@/store/models/changtt/vansales";

export default {
  name: "RetailShopForm",
  props: ["value", "loading", "data", "submit", "unlink", "refetch"],
  components: {
    GalleryUploadFile,
    MapSet,
  },
  computed: {
    genderItems() {
      return [{
        "value": "men",
        "text": "ชาย"
      }, {
        "value": "women",
        "text": "หญิง"
      }, {
        "value": "unspecified",
        "text": "ไม่ระบุ"
      }];
    },
    selectItems() {
      return this.selectList || [];
    },
    validateSelect() {
      return this.select.data === null ? true : false;
    }
  },
  data() {
    const rules = {
      required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      number: [(v) => v >= 0 || "กรุณากรอกข้อมูล"],
      code: [
        (v) =>
          !v || /^[0-9a-zA-Z\\,\s]+$/.test(v) || "Ex. 0062000653, 0062000654",
      ],
    };

    return {
      valid: false,
      isLoading: false,
      rules,
      form: {
        type: {},
        owner: {},
        area: {},
        region: {},
        subDistrict: {},
        district: {},
        province: {},
      },
      user: {},
      activePicker: null,
      vdate: {
        birthDate: false,
      },
      birthDateFormatted: null,
      confirm: {
        status: false,
      },
      agentHeader: [
        { text: "รหัสเอเย่นต์", value: "code" },
        { text: "ชื่อเอเย่นต์", value: "name" },
        { text: "ประเภท", value: "type.label" },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],
      vansalesHeader: [
        // { text: "รูปภาพ", value: "image" },
        { text: "ชื่อ-นามสกุล", value: "name" },
        { text: "เบอร์มือถือ", value: "phone" },
        { text: "รหัสเอเย่นต์", value: "agentCode" },
        { text: "ชื่อเอเย่นต์", value: "agentName" },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],
      areaPromoterHeader: [
        // { text: "รูปภาพ", value: "image" },
        { text: "รหัสพนักงาน", value: "code" },
        { text: "ชื่อ-นามสกุล", value: "name" },
        {
          text: "",
          align: "center",
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],
      mapModal: false,
      upOption: {
        width: 1280,
        height: 720,
        aspectRatio: 1280 / 720,
      },
      shoptypes: [],
      provinces: [],
      districts: [],
      subDistricts: [],
      postcodes: [],
      coordinates: null,
      agentList: [],
      subAgentList: [],
      vansalesList: [],
      subVansalesList: [],
      areaPromoterList: [],
      delete: {
        agent: [],
        "sub-agent": [],
        vansales: [],
        "sub-vansales": [],
        areapromoter: [],
      },
      new: {
        agent: {},
        "sub-agent": {},
        vansales: {},
        "sub-vansales": {},
        areapromoter: {},
      },
      searchselect: "",
      select: {
        status: false,
        text: "",
        data: null,
      },
      selectList: [],
      selectTMO: null,
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.initData();
          this.setData();
        }
      },
      deep: true,
    },
    refetch: {
      handler() {
        if (this.refetch) {
          this.setData();
        }
      },
      deep: true,
    },
    loading: {
      handler() {
        if(this.loading != this.isLoading) {
          this.isLoading = this.loading;
        }
      },
    },
    searchselect: {
      handler(value) {
        if((this.select.data && this.select.data.text && this.select.data.text) != value) {
          this._querySelection();
        }
      },
    },
    "form.id": {
      handler() {
        if (this.form && this.form.id) {
          this.fetchAgent();
          this.fetchSubAgent();
          this.fetchVansales();
          this.fetchSubVansales();
          this.fetchAreaPromoter();
        }
      },
      deep: true,
    },
    "form.province.id": {
      handler() {
        if (this.value) {
          this.fetchDistrict();
        }
      },
      deep: true,
    },
    "form.district.id": {
      handler() {
        if (this.value) {
          this.fetchSubDistrict();
        }
      },
      deep: true,
    },
    "form.subDistrict.id": {
      handler() {
        if (this.value) {
          this.fetchPostcode();
        }
      },
      deep: true,
    },
    "vdate.birthDate": {
      handler(value) {
        value && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      deep: true,
    },
  },
  methods: {
    initData() {
      this.fetchShopType();
      this.fetchProvince();
    },
    async fetchShopType() {
      const options = {
        params: {
          listType: "all"
        }
      }
      const res = await ShopTypeModel.list(options);
      this.shoptypes = (res && res.data) || [];

      // check form
      if(this.form && this.form.type && this.form.type.id) {
        const findId = this.shoptypes.find((e) => e.id === this.form.type.id)
        if(!findId) {
          this.form.type = {};
        }
      }
    },
    async fetchProvince() {
      const options = {
        params: {
          listType: "all"
        }
      }
      const res = await ProvinceModel.list(options);
      this.provinces = (res && res.data) || [];

      // check form
      if(this.form && this.form.province && this.form.province.id) {
        const findId = this.provinces.find((e) => e.id === this.form.province.id)
        if(!findId) {
          this.form.province = {};
        }
      }
    },
    async fetchDistrict() {
      if(this.form && this.form.province && this.form.province.id) {
        const options = {
          params: {
            listType: "all",
            provinceId: this.form.province.id,
          }
        }
        const res = await DistrictModel.list(options);
        this.districts = (res && res.data) || [];

        // check form
        if(this.form && this.form.district && this.form.district.id) {
          const findId = this.districts.find((e) => e.id === this.form.district.id)
          if(!findId) {
            this.form.district = {};
          }
        }
      }
    },
    async fetchSubDistrict() {
      if(this.form && this.form.district && this.form.district.id) {
        const options = {
          params: {
            listType: "all",
            districtId: this.form.district.id,
          }
        }
        const res = await SubDistrictModel.list(options);
        this.subDistricts = (res && res.data) || [];

        // check form
        if(this.form && this.form.subDistrict && this.form.subDistrict.id) {
          const findId = this.subDistricts.find((e) => e.id === this.form.subDistrict.id)
          if(!findId) {
            this.form.subDistrict = {};
          }
        }
      }
    },
    async fetchPostcode() {
      if(this.form && this.form.subDistrict && this.form.subDistrict.id) {
        const options = {
          params: {
            listType: "all",
            subDistrictId: this.form.subDistrict.id,
          }
        }
        const res = await PostcodeModel.list(options);
        this.postcodes = (res && res.data) || [];

        // check form
        if(this.form && this.form.postcode) {
          const findId = this.postcodes.find((e) => e.postcode === this.form.postcode)
          if(!findId) {
            this.form.postcode = null;
          }
        }
      }
    },
    async fetchAgent() {
      if(this.form && this.form.id) {
        const options = {
          params: {
            listType: "all",
          }
        }
        const res = await UserModel.retailListAgent(this.form.id, options);
        this.agentList = (res && res.data) || [];
      }
    },
    async fetchSubAgent() {
      if(this.form && this.form.id) {
        const options = {
          params: {
            listType: "all",
          }
        }
        const res = await UserModel.retailListSubAgent(this.form.id, options);
        this.subAgentList = (res && res.data) || [];
      }
    },
    async fetchVansales() {
      if(this.form && this.form.id) {
        const options = {
          params: {
            listType: "all",
          }
        }
        const res = await UserModel.retailListVansales(this.form.id, options);
        this.vansalesList = (res && res.data) || [];
      }
    },
    async fetchSubVansales() {
      if(this.form && this.form.id) {
        const options = {
          params: {
            listType: "all",
          }
        }
        const res = await UserModel.retailListSubVansales(this.form.id, options);
        this.subVansalesList = (res && res.data) || [];
      }
    },
    async fetchAreaPromoter() {
      if(this.form && this.form.id) {
        const options = {
          params: {
            listType: "all",
          }
        }
        const res = await UserModel.retailListAreaPromoter(this.form.id, options);
        this.areaPromoterList = (res && res.data) || [];
      }
    },
    clearSelectList() {
      this.selectList = [];
    },
    async fetchAgentList() {
      this.clearSelectList();
      const exceptCode = this.agentList.reduce((o, e) => {
        if(e.code) {
          if(o) {
            o += ",";
          }
          o += ""+e.code;
        }
        return o;
      }, "");

      const options = {
        params: {
          query: this.searchselect,
          page: 1,
          limit: 20,
          exceptCode,
        }
      };
      const res = await AgentModel.listAgent(options).catch(() => this.isLoading = false);
      res.data = res.data.map((e) => {
        e.text = `${e.code || ''}: ${e.name || ''}`
        return e;
      })
      this.selectList = (res && res.data) || [];
    },
    async fetchSubAgentList() {
      this.clearSelectList();
      const exceptCode = this.subAgentList.reduce((o, e) => {
        if(e.code) {
          if(o) {
            o += ",";
          }
          o += ""+e.code;
        }
        return o;
      }, "");

      const options = {
        params: {
          query: this.searchselect,
          page: 1,
          limit: 20,
          exceptCode,
        }
      };
      const res = await AgentModel.listSubAgent(options).catch(() => this.isLoading = false);
      res.data = res.data.map((e) => {
        e.text = `${e.code || ''}: ${e.name || ''}`
        return e;
      })
      this.selectList = (res && res.data) || [];
    },
    async fetchAreaPromoterList() {
      this.clearSelectList();
      const exceptId = this.areaPromoterList.reduce((o, e) => {
        if(e.id) {
          if(o) {
            o += ",";
          }
          o += ""+e.id;
        }
        return o;
      }, "");

      const options = {
        params: {
          query: this.searchselect,
          page: 1,
          limit: 20,
          exceptId,
        }
      };
      const res = await AreaPromoterModel.list(options).catch(() => this.isLoading = false);
      res.data = res.data.map((e) => {
        e.text = `${e.code || ''}: ${e.firstName || ''} ${e.lastName || ''}`
        return e;
      })
      this.selectList = (res && res.data) || [];
    },
    async fetchVansalesList() {
      this.clearSelectList();
      const exceptId = this.vansalesList.reduce((o, e) => {
        if(e.id) {
          if(o) {
            o += ",";
          }
          o += ""+e.id;
        }
        return o;
      }, "");

      const options = {
        params: {
          query: this.searchselect,
          page: 1,
          limit: 20,
          exceptId,
        }
      };
      const res = await VansalesModel.list(options).catch(() => this.isLoading = false);
      res.data = res.data.map((e) => {
        e.text = `${e.firstName} ${e.lastName || ''} ${e.shops && e.shops[0] && "("+e.shops[0].code+": "+e.shops[0].name+")" || ""}`
        return e;
      })
      this.selectList = (res && res.data) || [];
    },
    async fetchSubVansalesList() {
      this.clearSelectList();
      const exceptId = this.subVansalesList.reduce((o, e) => {
        if(e.id) {
          if(o) {
            o += ",";
          }
          o += ""+e.id;
        }
        return o;
      }, "");

      const options = {
        params: {
          query: this.searchselect,
          page: 1,
          limit: 20,
          exceptId,
        }
      };
      const res = await VansalesModel.sublist(options).catch(() => this.isLoading = false);
      res.data = res.data.map((e) => {
        e.text = `${e.firstName} ${e.lastName || ''} ${e.shops && e.shops[0] && "("+e.shops[0].code+": "+e.shops[0].name+")" || ""}`
        return e;
      })
      this.selectList = (res && res.data) || [];
    },
    setData() {
      const { verified } = this.data;

      // Set User
      this.user = this.data.owner && this.data.owner[0] || {}
      // Set Birth Date
      if(this.user.birthDate) {
        this.birthDateFormatted = this.$stringDateAdToBe(this.user.birthDate);
      }

      // Set Shop
      this.form = {
        ...this.data,
        type: this.data.type || {},
        region: this.data.region || {},
        area: this.data.area || {},
        province: this.data.province || {},
        district: this.data.district || {},
        subDistrict: this.data.subDistrict || {},
        vansales: this.data.vansales || [],
        verified: !verified ? "ยังไม่ยืนยันตัวตนแล้ว" : "ยืนยันตัวตนแล้ว",
        lineStatus: this._registed(this.user.registered),
      };
      this.coordinates = this.data.pointLocation && this.data.pointLocation.coordinates;
      this.agentList = [];
      this.subAgentList = [];
      this.vansalesList = [];
      this.subVansalesList = [];
      this.areaPromoterList = [];
      this.delete = {
        agent: [],
        "sub-agent": [],
        vansales: [],
        "sub-vansales": [],
        areapromoter: [],
      };
      this.new = {
        agent: {},
        "sub-agent": {},
        vansales: {},
        "sub-vansales": {},
        areapromoter: {},
      }
    },
    _back() {
      if(!this.form.id) {
        this.$refs.form.reset();
      }
      this.$emit("input", false);
    },
    _setBirthDate(date) {
      const ndate = this.$stringDateAdToBe(date);

      this.$refs.bdate.save(date);
      this.birthDateFormatted = ndate;
    },
    _setLocation(coordinates) {
      if(coordinates) {
        if(!this.form.pointLocation) {
          this.form.pointLocation = {}
        }
        this.coordinates = [coordinates.lat, coordinates.lng];
        this.form.pointLocation.coordinates = this.coordinates;
      }
      this._closeLocation();
    },
    _closeLocation() {
      this.mapModal = false;
    },
    _mapEdit() {
      this.mapModal = true;
    },
    _registed(registered = false) {
      return (registered)? 'ลงทะเบียนแล้ว': 'ยังไม่ได้ลงทะเบียน';
    },
    _agentCode(shops = []) {
      return shops[0] && shops[0].code || ""
    },
    _agentName(shops = []) {
      return shops[0] && shops[0].name || ""
    },
    _unlink() {
      this.confirm = {
        status: true,
        type: "unlink",
        data: this.user,
      }
    },
    async _confirm() {
      this.isLoading = true;
      this.confirm.status = false;
      const confirm = this.confirm;
      const data = confirm.data || {};

      let res;
      if (confirm.type === "unlink") {
        res = await ShopModel.unlink(data.id, {}).catch((err) =>
          console.error(err)
        );
        if (res) {
          this.form.lineStatus = this._registed(false);
          this.user.registered = false;
        }
      }

      this.isLoading = false;
    },
    _querySelection() {
      const type = this.select && this.select.type || "";
      if(this.selectTMO) {
        clearTimeout(this.selectTMO)
      }
      this.selectTMO = setTimeout(() => {
        switch (type) {
          case "sub-agent":
            this.fetchSubAgentList();
            break;
          case "areapromoter":
            this.fetchAreaPromoterList();
            break;
          case "vansales":
            this.fetchVansalesList();
            break;
          case "sub-vansales":
            this.fetchSubVansalesList();
            break;
          default:
            this.fetchAgentList();
            break;
        }
      }, 500);
    },
    async _addSelect(type = '') {
      this.isLoading = true;
      let text = "";
      switch (type) {
        case "sub-agent":
          text = "ซับเอเย่นต์/ซับซื้อตรง";
          break;
        case "areapromoter":
          text = "Area Promoter";
          break;
        case "vansales":
          text = "รถเร่เอเย่นต์";
          break;
        case "sub-vansales":
          text = "รถเร่ซับเอเย่นต์/รถเร่ซับซื้อตรง";
          break;
        default:
          text = "เอเย่นต์";
          break;
      }
      this.select = {
        status: true,
        data: null,
        type,
        text,
      };

      await this._querySelection()
      this.isLoading = false;
    },
    _delete(type = "agent", items = [], item, index) {
      items.splice(index, 1);
      this.delete[type].push(item);
      // remove new
      if(this.new[type] && this.new[type][item.id]) {
        delete this.new[type][item.id];
      }

      // vansales
      if(type === "vansales" && item.shops && Array.isArray(item.shops) && item.shops[0]) {
        const shAgent = item.shops[0];
        const agentData = this.agentList.reduce((o, e, i) => {
          if(e.id === shAgent.id) {
            o.data = e;
            o.index = i;
          }
          return o;
        }, { data: null, index: null });

        // Remove agent
        if(agentData.data && agentData.index >= 0) {
          const vs = items.find((e) => e.shops && e.shops[0] && e.shops[0].id === shAgent.id);
          if(!vs) {
            this._delete("agent", this.agentList, agentData.data, agentData.index)
          }
        }
      }

      // sub vansales
      if(type === "sub-vansales" && item.shops && Array.isArray(item.shops) && item.shops[0]) {
        const shAgent = item.shops[0];
        const agentData = this.subAgentList.reduce((o, e, i) => {
          if(e.id === shAgent.id) {
            o.data = e;
            o.index = i;
          }
          return o;
        }, { data: null, index: null });

        // Remove agent
        if(agentData.data && agentData.index >= 0) {
          const vs = items.find((e) => e.shops && e.shops[0] && e.shops[0].id === shAgent.id);
          if(!vs) {
            this._delete("sub-agent", this.subAgentList, agentData.data, agentData.index)
          }
        }
      }

      // agent
      if(type === "agent" && item.id) {
        const vansales = this.vansalesList;
        const lenvansales = vansales.length;

        // Remove vansales
        let i = 0;
        while (i<lenvansales) {
          const e = vansales[i];
          if(!e) {
            break;
          }

          if(e.shops && e.shops[0] && e.shops[0].id && e.shops[0].id === item.id) {
            this._delete(type = "vansales", vansales, e, i);
          } else {
            i++;
          }
        }
      }

      // sub agent
      if(type === "sub-agent" && item.id) {
        const vansales = this.subVansalesList;
        const lenvansales = vansales.length;

        // Remove vansales
        let i = 0;
        while (i<lenvansales) {
          const e = vansales[i];
          if(!e) {
            break;
          }

          if(e.shops && e.shops[0] && e.shops[0].id && e.shops[0].id === item.id) {
            this._delete(type = "sub-vansales", vansales, e, i);
          } else {
            i++;
          }
        }
      }
    },
    _select() {
      const data = _.cloneDeep(this.select && this.select.data || {});
      data.add = true;
      const type = this.select && this.select.type;
      switch (type) {
        case "areapromoter": {
          this.areaPromoterList.push(data);
          this.new["areapromoter"][data.id] = data;
          break;
        }
        case "vansales": {
          if(data.shops && Array.isArray(data.shops) && data.shops[0]) {
            const agent = _.cloneDeep(data.shops[0]);

            // add
            const agData = this.agentList.find((e) => e.id === agent.id);

            if(!agData) {
              this.agentList.push(agent);
            }
          }

          this.vansalesList.push(data);
          this.removeDelete(type, data.id);
          this.new["vansales"][data.id] = data;

          break;
        }
        case "sub-vansales": {
          if(data.shops && Array.isArray(data.shops) && data.shops[0]) {
            const agent = _.cloneDeep(data.shops[0]);

            // add
            const agData = this.subAgentList.find((e) => e.id === agent.id);

            if(!agData) {
              this.subAgentList.push(agent);
            }
          }

          this.subVansalesList.push(data);
          this.removeDelete(type, data.id);
          this.new["vansales"][data.id] = data;

          break;
        }
        case "agent": {
          this.addAgentList(type, data);
          this.removeDelete(type, data.id);
          break;
        }
        case "sub-agent": {
          this.addAgentList(type, data);
          this.removeDelete(type, data.id);
          break;
        }
      }

      this.select = {
        status: false,
        text: "",
        data: null,
      };
    },
    removeDelete(type = "", id = 0) {
      if(this.delete && this.delete[type] && Array.isArray(this.delete[type]) && this.delete[type].length > 0) {
        const delIndex = this.delete[type].findIndex((e) => e.id === id);
        if(delIndex >= 0) {
          this.delete[type].splice(delIndex, 1);
        }
      }
    },
    async addAgentList(type = "", data = {}) {
      // Create Shop Agent
      const optsh = {
        data: {
          code: data.code,
          name: data.name,
          phone: data.phone_number,
          address: data.address,
          agentSubType: data.type && data.type.name || "agent"
        }
      }
      const sh = await ShopModel.createAgent(optsh).catch((err) =>
        console.error(err)
      );
      sh.add = true;
      
      if(type == "sub-agent") {
        this.subAgentList.push(sh);
      } else {
        this.agentList.push(sh);
      }
      this.new["agent"][sh.id] = sh;
    },
    checkDup(arr1, arr2) {
      // return arr1.concat(arr2).filter(item => !arr1.includes(item) && !arr2.includes(item));
      return arr1.filter(item => !arr2.includes(item));
    },
    _save() {
      const addPartners = [...Object.values(this.new["agent"]), ...Object.values(this.new["sub-agent"])];
      const addVansales = [...Object.values(this.new["vansales"]), ...Object.values(this.new["sub-vansales"])];
      const addAreapromoters = Object.values(this.new["areapromoter"]);

      const removePartners = [...this.delete["agent"], ...this.delete["sub-agent"]];
      const removeVansales = [...this.delete["vansales"], ...this.delete["sub-vansales"]];
      const removeAreapromoters = this.delete["areapromoter"];

      const add = {
        addPartners: this.checkDup(addPartners, removePartners).map(s => s.id),
        addVansales: this.checkDup(addVansales, removeVansales).map(s => s.id),
        addAreapromoters: this.checkDup(addAreapromoters, removeAreapromoters).map(s => s.id),
      }
      const del = {
        removePartners: removePartners.map(s => s.id),
        removeVansales: removeVansales.map(s => s.id),
        removeAreapromoters: removeAreapromoters.map(s => s.id),
      }


      this.$emit("submit", this.form, this.user, add, del);
    },
  },
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgb(136, 136, 136) !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: #ffffff !important;
}

.col {
  padding: 10px 12px !important;
}

.retail-shop-form {
  margin: 1rem 3rem;
}

.v-toolbar__content {
  padding-left: 0px;
}

.image-section {
  /* background: #000;
  border-radius: 6px;
  height: 200px;
  width: 300px; */
}

.map-section {
  height: 300px;
  width: auto;
}

.unlink-button {
  color: #ff0000;
}

.edit-overlay {
  position: absolute;
  padding: 5px 10px;
  opacity: 0.85;
  background-color: #808080;
  left: 32px;
  bottom: 31px;
  right: 32px;
  cursor: pointer;
  z-index: 2;
  text-align: center;
  border-radius: 0 0 10px 10px;
}

.map-empty {
  display: flex;
  height: 200px;
  background-color: #cccccc;
  border-radius: 10px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
