import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,{staticStyle:{"background-image":"linear-gradient(to right, #79D455 , #016445)","height":"100%"}},[_c(VContainer,[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticStyle:{"width":"400px"},attrs:{"src":require('../assets/Chang-Family-2020-Logo.png')}})]),_c(VCard,{staticStyle:{"padding":"40px"},attrs:{"width":"500"}},[_c(VLayout,{attrs:{"text-xs-center":"","wrap":""}},[_c(VFlex,{attrs:{"mb-4":""}},[_c('h2',{staticClass:"headline mb-3",staticStyle:{"font-family":"CHANG Sans REG !important","text-align":"center"}},[_vm._v("กรณีที่ลืมรหัสผ่าน กรุณาใส่ email เพื่อรีเซ็ทรหัสผ่านของคุณ")])]),_c(VFlex,{attrs:{"xs12":""}},[_c(VTextField,{attrs:{"label":"email","required":"","id":"usernameInput"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendEmail.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VFlex,{staticStyle:{"margin-top":"24px"},attrs:{"xs12":""}},[_c(VBtn,{attrs:{"block":"","color":"blue","id":"loginBtn","disabled":_vm.email == ''},on:{"click":_vm.sendEmail}},[_vm._v("ส่ง email")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }