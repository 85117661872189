<template>
  <!-- <div> -->
  <v-main style="background-image: linear-gradient(to right, #79D455 , #016445); height: 100%;">
    <v-container>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <div style="display: flex; justify-content: center;">
            <img
              style="width: 400px;"
              :src="require('../assets/Chang-Family-2020-Logo.png')"
            />
          </div>

          <v-card style=" padding: 40px;" width="500">
            <v-layout text-xs-center wrap>
              <v-flex mb-4>
                <h2
                  class="headline mb-3"
                  style="font-family: CHANG Sans REG !important; text-align: center"
                >กรณีที่ลืมรหัสผ่าน กรุณาใส่ email เพื่อรีเซ็ทรหัสผ่านของคุณ</h2>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="email"
                  label="email"
                  required
                  v-on:keyup.enter="sendEmail"
                  id="usernameInput"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 style="margin-top: 24px;">
                <v-btn
                  block
                  color="blue"
                  @click="sendEmail"
                  id="loginBtn"
                  :disabled="email == ''"
                >ส่ง email</v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>


  </v-main>
  <!-- </div> -->
</template>

<script>
import Authen from '../store/models/auth'

export default {
  name: "ForgetPassword",
  components: {},
  data() {
    return {
      email: "",
      dialog_loading: false
    };
  },
  methods: {
    navigate(page) {
      this.$router.push({ path: page });
    },
    sendEmail() {
      let formData = new FormData();
      formData.append("email", this.email);

      Authen.forgotPassword({ data: formData })
        .then(() => {
          this.$swal_success("ส่ง Email สำเร็จ");
          this.navigate("/");
        })
        .catch(e => {
          console.error(e.response.data);
        });
    }
  }
};
</script>
