import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.dialog)?_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{staticStyle:{"margin-top":"10px"},attrs:{"xs12":"","md12":""}},[_c(VCard,{attrs:{"outlined":true}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.tabName)+" "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":"ค้นหา","outlined":"","dense":"","hide-details":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm._getByQuery.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCardText,[_c(VCard,{staticClass:"mb-4"},[_c(VCardText,[_c(VDataTable,{staticClass:"clean-tb",attrs:{"item-key":"index","headers":_vm.headers,"items":_vm.indexedItems,"footer-props":{ 'items-per-page-options': _vm.$itemsPerPage },"items-per-page":_vm.limit,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.countItems,"no-data-text":"ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล","no-results-text":"ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.startDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.startDate).format("DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.endDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.endDate).format("DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.status",fn:function({ item }){return [_c('span',{class:`${
                      item.status === 'active'
                        ? 'green-text'
                        : item.status === 'waiting'
                        ? 'yellow-text'
                        : 'brown-text'
                    }`},[_vm._v(_vm._s(_vm.capitalize(item.status)))])]}},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"loading":_vm.loading,"depressed":"","block":""},on:{"click":function($event){return _vm._view(item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" ดูข้อมูล ")],1)]}}],null,false,1191467197)})],1)],1)],1)],1)],1)],1):_vm._e(),_c('FormApproveAreaPromoterCRM',{attrs:{"data":this.form},on:{"fetch":_vm.fetchData},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }