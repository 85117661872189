<template>
  <div>
    <v-layout v-if="!dialog" wrap>
      <v-flex xs12 md12 style="margin-top: 10px">
        <v-card :outlined="true">
          <v-card-title>
            {{ tabName }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="ค้นหา"
              outlined
              dense
              :hide-details="true"
              @keydown.enter="_getByQuery"
            />
          </v-card-title>

          <v-card-text>
            <v-card class="mb-4">
              <v-card-text>
                <v-data-table
                  class="clean-tb"
                  item-key="index"
                  :headers="headers"
                  :items="indexedItems"
                  :footer-props="{ 'items-per-page-options': $itemsPerPage }"
                  :items-per-page="limit"
                  :loading="loading"
                  :options.sync="options"
                  :server-items-length="countItems"
                  no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                  no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
                >
                  <template v-slot:item.startDate="{ item }">
                    {{ moment(item.startDate).format("DD/MM/YYYY HH:mm") }}
                  </template>
                  <template v-slot:item.endDate="{ item }">
                    {{ moment(item.endDate).format("DD/MM/YYYY HH:mm") }}
                  </template>
                  <template v-slot:item.status="{ item }">
                    <span
                      :class="`${
                        item.status === 'active'
                          ? 'green-text'
                          : item.status === 'waiting'
                          ? 'yellow-text'
                          : 'brown-text'
                      }`"
                      >{{ capitalize(item.status) }}</span
                    >
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      :loading="loading"
                      @click="_view(item)"
                      depressed
                      block
                    >
                      <v-icon left> mdi-magnify </v-icon>
                      ดูข้อมูล
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <div>------------------------------------- Form -----------------------------------------------------</div> -->
    <FormApproveAreaPromoterCRM
      v-model="dialog"
      :data="this.form"
      @fetch="fetchData"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import FormApproveAreaPromoterCRM from "./FormApproveAreaPromoterCRM.vue";

import StampMissionModel from "@/store/models/changtt/stampMission";

const formDefault = {};

export default {
  name: "ApproveAreaPromoterMissionComponent",
  props: ["user", "tabName"],
  components: {
    FormApproveAreaPromoterCRM,
  },
  computed: {
    indexedItems() {
      return (
        this.items.map((item, index) => {
          const currentDate = moment();
          const startDate = moment(item.startDate);
          const endDate = moment(item.endDate);
          return {
            index,
            overall: `${item.waiting}`,
            status:
              startDate <= currentDate && endDate >= currentDate
                ? "active"
                : endDate < currentDate
                ? "closed"
                : "waiting",
            ...item,
          };
        }) || []
      );
    },
  },
  data() {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      limit: 10,
      countItems: 0,
      headers: [
        {
          text: "Mission Start Date",
          value: "startDate",
          sortable: false,
        },
        { text: "Mission End Date", value: "endDate", sortable: false },
        { text: "Stamp Card", value: "card.title", sortable: false },
        { text: "Mission", value: "title", sortable: false },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Waiting Approve",
          value: "overall",
          sortable: false,
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      items: [],
      dialog: false,
      form: formDefault,
      search: "",
      approveData: {},
    };
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    moment,
    capitalize(text) {
      return _.capitalize(text);
    },
    _getByQuery() {
      this.options.page = 1;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      let options = {
        params: {
          mustApprove: 1,
          waitApprove: 1,
        },
      };

      if (this.search) {
        options.params.query = this.search;
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.limit = this.options.itemsPerPage;
      } else {
        options.params.limit = 10;
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page;
      }

      const res = await StampMissionModel.missionList(options);
      const { data } = res;
      this.items = (data && data.data) || [];
      this.countItems = (data && data.total) || 0;
      this.loading = false;
    },
    _view(data = {}) {
      this.dialog = true;
      this.form = {
        ...data,
        startDate: moment(data.startDate).format("YYYY-MM-DD"),
        endDate: moment(data.endDate).format("YYYY-MM-DD"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$green: green;
$yellow: orange;
$brown: brown;

.green-text {
  color: $green;
}
.yellow-text {
  color: $yellow;
}
.brown-text {
  color: $brown;
}
.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>
