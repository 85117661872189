import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,_vm._b({attrs:{"persistent":"","max-width":_vm.width},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},'v-dialog',_vm.$attrs,false),[_c(VCard,{staticClass:"pa-2"},[(_vm.disableBottomAction)?_c(VToolbar,{attrs:{"dark":"","color":"white"}},[_vm._t("actionHeader"),_c(VSpacer),(!_vm.disableCancel)?_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.submitAction(false)}}},[_vm._v(" "+_vm._s(_vm.data ? _vm.data.cancelText : "ยกเลิก")+" ")]):_vm._e(),(!_vm.confirmIcon)?_c(VBtn,{attrs:{"color":"success","disabled":_vm.disabled},on:{"click":function($event){return _vm.submitAction(true)}}},[_vm._v(" "+_vm._s(_vm.data ? _vm.data.confirmText : "ยืนยัน")+" ")]):_vm._e(),(_vm.confirmIcon)?_c(VBtn,{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.submitAction(true)}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],2):_vm._e(),_c(VCardTitle,{staticClass:"headline"},[_vm._t("header")],2),_c(VCardText,[_vm._t("body")],2),(!_vm.disableBottomAction)?_c(VCardActions,[_vm._t("action",function(){return [_c(VSpacer),(!_vm.disableCancel)?_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.submitAction(false)}}},[_vm._v(" "+_vm._s(_vm.data ? _vm.data.cancelText : "ปิดหน้าต่าง")+" ")]):_vm._e(),(!_vm.confirmIcon && !_vm.disableConfirm)?_c(VBtn,{attrs:{"color":"success","disabled":_vm.disabled},on:{"click":function($event){return _vm.submitAction(true)}}},[_vm._v(" "+_vm._s(_vm.data ? _vm.data.confirmText : "ยืนยัน")+" ")]):_vm._e(),(_vm.confirmIcon && !_vm.disableConfirm)?_c(VBtn,{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.submitAction(true)}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()]})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }