<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
import Region from '@/store/models/region'

export default {
  name: 'App',
  components: {},
  data: () => ({
    user: {},
  }),
  watch: {
    $route(to) {
      // console.log(from.name);

      if (localStorage.getItem('token') != '') {
        // console.log('this.$user: ', this.$user)
        window['_paq'].push(['setUserId', this.$user && this.$user.email])
      }

      window['_paq'].push(['setCustomUrl', '/' + to.name])
      window['_paq'].push(['setDocumentTitle', to.name])
      window['_paq'].push(['trackPageView'])
    },
  },
  created() {
    // console.log(this.$browserDetect.isChrome);

    if (this.$route.name !== 'obs' && this.$user && this.$user.id) {
      Region.gets({}).then((response) => {
        return response
      })
    }

    // if (
    //   this.$browserDetect.isChrome ||
    //   this.$browserDetect.isChromeIOS ||
    //   this.$browserDetect.isFirefox ||
    //   this.$browserDetect.isSafari
    // ) {
    //   // this.$swal_nobtn('เว็บไซต์นี้ไม่สามารถใช้งานได้ด้วย browser นี้. กรุณใช้ Chrome, Firefox, หรือ Safari ในการใช้งาน');
    // } else {
    //   this.$swal_nobtn(
    //     "เว็บไซต์นี้ไม่สามารถใช้งานได้ด้วย browser นี้. กรุณใช้ Chrome, Firefox, หรือ Safari ในการใช้งาน"
    //   );
    //   this.navigate("/");
    // }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@font-face {
  font-family: 'CHANG Sans REG';
  src: url('./assets/changsansregvxpc.ttf');
  font-weight: normal;
  font-style: normal;
}

html * {
  font-family: 'CHANG Sans REG';
}

.font-chang {
  font-family: 'CHANG Sans REG' !important;
}

.v-application .headline,
.v-application .caption {
  font-family: inherit !important;
}

.container {
  width: 100% !important;
  max-width: 100% !important;
}

div.date-time-picker {
  &:not(.standard-component) {
    > div {
      > input.field-input {
        font-family: 'CHANG Sans REG' !important;
        font-size: 16px !important;
        border-color: rgba(0, 0, 0, 0.42);
        &:hover {
          border-color: inherit;
        }
      }
    }
  }
  &.standard-component {
    > div {
      > input.field-input {
        font-family: 'CHANG Sans REG' !important;
        font-size: 16px !important;
      }
    }
  }
}

.field.has-value .field-label {
  top: -5px !important;
  left: 0 !important;
  font-family: 'CHANG Sans REG' !important;
}

:root {
  --chang-color: #006b35;
}

.sync-btn {
  margin-right: 25px !important;
}

.sync-btn > span.v-btn__content {
  width: 55.766px !important;
}

.clean-tb.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: hidden !important;
}

.clean-tb.theme--light.v-data-table .v-data-footer {
  border-top: none !important;
}

.chang-btn {
  color: white !important;
  background-color: var(--chang-color) !important;
}

.notfound {
  font-size: 1.2rem !important;
}
</style>
