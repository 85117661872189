<template>
  <div>
    <SideBar bar_name="Redeemed Transactions" :user="user"></SideBar>

    <v-main>
      <v-container>

        <Tab :tabs="tabs">
          <template v-slot="slotProps">
            <AgentTransaction v-bind="{ user: slotProps.user, tabId: slotProps.tabId, tabName: slotProps.tabName }" />
          </template>
        </Tab>

      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Tab from "@/components/Tab";
import AgentTransaction from "@/components/RedeemTransaction/AgentTransaction"

export default {
  name: "redeemedTransactions",
  components: {
    SideBar,
    Tab,
    AgentTransaction
  },
  data() {
    return {
      tabs: this.$agentTabs,
      user: this.$user
    };
  }
};
</script>

<style scoped>
/* .v-input--selection-controls__input{
  color: #000000
} */
</style>
