<template>
  <div>
    <SideBar bar_name="Sale Management" :user="user"></SideBar>

    <v-main>
      <v-container> 
        <SaleAgent :user="user" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import SaleAgent from "@/components/Sale/SaleAgent"

export default {
  name: "SaleManagement",
  components: {
    SideBar,
    SaleAgent
  },
  data() {
    return {
      tabs: this.$agentTabs,
      user: this.$user
    };
  }
};
</script>
