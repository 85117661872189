<template>
  <div>
    <SideBar bar_name="Banner Management" :user="user"></SideBar>

    <v-main>
      <v-container>

        <Tab :tabs="tabs">
          <template v-slot="slotProps">
            <AgentBanner v-bind="{ user: slotProps.user, tabId: slotProps.tabId, tabName: slotProps.tabName }" />
          </template>
        </Tab>
        
      </v-container>

    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import Tab from "@/components/Tab";
import AgentBanner from "@/components/Banner/AgentBanner";

export default {
  name: "bannerManagement",
  components: {
    SideBar,
    Tab,
    AgentBanner
  },
  data() {
    return {
      tabs: this.$catalogTabs,
      user: this.$user
    };
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-name {
  text-align: center;
  font-size: 20px;
}
@media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
.show-btns {
  color: rgba(0, 0, 0, 1) !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.custom-croppie {
  width: 570px;
  height: 312px;
  margin: 0 auto;
}
</style>
